import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { Proposal, ProposalPastOHF } from "../models";
import TextField from "../../common/TextField";
import CheckboxGroup from "../../common/CheckboxGroup";
import RadioGroup from "../../common/RadioGroup";
import Subquestion from "../../common/Subquestion";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
import { MAKE_OPTIONS } from "../../../api/constants";
import { getValue } from "../../../api/utils";

const getPastOHFs = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalPastOHF.filter({ proposal: id }).orderBy("id").toModelArray();
    }
);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted"
    },
    summaryRoot: {
        width: "100%"
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        },
        marginBottom:16
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
});

class ActivityTab extends Component {
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Activity Details - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const { id } = this.props.proposal;
        const { ormProposalUpdate, history, handleUnsavedFields, handleNext, proposal } = this.props;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }
        values.federal_funds_confirmation_document = proposal.federal_funds_confirmation_document;
        values.modify_date_label = proposal.modify_date_label;
        values.signup_criteria = proposal.signup_criteria;
        values.illustration = proposal.illustration;
        values.financial_audit = proposal.financial_audit;
        values.board_members = proposal.board_members;

        // Clear out fields that may no longer be shown
        if (values.fee_title_ownership.indexOf("Other") === -1) {
            values.fee_title_ownership_other = null;
        }
        if (values.fee_land_designated.indexOf("City Owned") === -1) {
            values.fee_land_designated_city = null;
        }
        if (values.fee_land_designated.indexOf("Other") === -1) {
            values.fee_land_designated_other = null;
        }
        if (values.criteria_ms_desc !== "false" && values.criteria_ms_desc !== false) {
            values.criteria_ms_desc = null; //p/ap
        }
        if (values.fee_governement_approval !== "false" && values.fee_governement_approval !== false) {
            values.fee_governement_approval_desc = null; //p/ap
        }
        if (values.fee_free_of_protection !== "false" && values.fee_free_of_protection !== false) {
            values.fee_free_of_protection_desc = null; //p/ap
        }
        if (values.easement_free_of_protection !== "false" && values.easement_free_of_protection !== false) {
            values.easement_free_of_protection_desc = null; //p/ap
        }
        if (values.restoration_follow_bmp !== "false" && values.restoration_follow_bmp !== false) {
            values.restoration_follow_bmp_desc = null; //p/ap
        }
        if (values.restoration_on_protected_land !== "true" && values.restoration_on_protected_land !== true) {
            values.restoration_on_protected_land_yes = []; //p/ap
            values.restoration_on_protected_land_yes_other = null; //p/ap
        }
        if (values.restoration_on_protected_land !== "false" && values.restoration_on_protected_land !== false) {
            values.rep_protected_desc = null; //p/ap
        }
        if (values.restoration_on_protected_land_yes.indexOf("Other") === -1) {
            values.restoration_on_protected_land_yes_other = null; //p/ap
        }
        if (values.planting_crops !== "true" && values.planting_crops !== true) {
            values.planting_crops_desc = null; //p/ap
            values.planting_crops_gmo = null; //p/ap
        }
        if (values.fee_hunting_fishing !== "true" && values.fee_hunting_fishing !== true) {
            values.fee_hunting_fishing_desc = null; //p/ap
        }
        if (values.fee_hunting_fishing_after !== "true" && values.fee_hunting_fishing_after !== true) {
            values.fee_hunting_fishing_after_desc = null; //p/ap
        }
        if (values.easement_public_use !== "true" && values.easement_public_use !== true) {
            values.easement_public_use_desc = null; //p/ap
        }
        if (values.feeeasement_trailroads !== "true" && values.feeeasement_trailroads !== true) {
            values.trail_types = null; //p/ap
            values.trails_continued_use = null; //p/ap
            values.trails_continued_use_desc = null; //p/ap
        }
        if (values.trails_continued_use !== "true" && values.trails_continued_use !== true) {
            values.trails_continued_use_desc = null; //p/ap
        }
        if (values.feeeasement_trailroads_new !== "true" && values.feeeasement_trailroads_new !== true) {
            values.trail_types_new = null; //p/ap
            values.trail_types_new_maintenance = null; //p/ap
        }
        if (values.land_restored !== "false" && values.land_restored !== false) {
            values.land_restored_how = null; //p/ap
        }

        ormProposalUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    }

    applyOther = (value, element) => {
        const { ormProposalUpdateLocalOnly } = this.props;
        const { id } = this.props.proposal;
        ormProposalUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    render() {
        const { 
            classes,
            proposal,
            handleUnsavedFields,
            settings,
            ActionMenu,
            ormProposalPastOHFDelete,
            ormProposalPastOHFCreate,
            ormProposalPastOHFUpdate,
            pastOHFs, authState
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || proposal.status === "New")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        const year = parseInt(proposal.ml_year);

        const activity_types = getValue(proposal, "activity_types");
        const ACTIVITY_FEE = activity_types.indexOf("Protect in Fee") > -1;
        const ACTIVITY_EASEMENT = activity_types.indexOf("Protect in Easement") > -1;
        const ACTIVITY_RESTORE_ENHANCE = activity_types.indexOf("Restore") > -1 || activity_types.indexOf("Enhance") > -1;
        const ACTIVITY_OTHER = activity_types.indexOf("Other") > -1;
        
        const fee_title_ownership_OTHER = getValue(proposal, "fee_title_ownership").indexOf("Other") > -1;  
        const fee_land_designated_CITY = getValue(proposal, "fee_land_designated").indexOf("City Owned") > -1;   
        const fee_land_designated_OTHER = getValue(proposal, "fee_land_designated").indexOf("Other") > -1;  

        const criteria_ms_NO = getValue(proposal, "criteria_ms") === "false" || getValue(proposal, "criteria_ms") === false;
        const fee_governement_approval_NO = getValue(proposal, "fee_governement_approval") === "false" || getValue(proposal, "fee_governement_approval") === false;
        const fee_free_of_protection_NO = getValue(proposal, "fee_free_of_protection") === "false" || getValue(proposal, "fee_free_of_protection") === false;
        const easement_free_of_protection_NO = getValue(proposal, "easement_free_of_protection") === "false" || getValue(proposal, "easement_free_of_protection") === false;
        const restoration_follow_bmp_NO = getValue(proposal, "restoration_follow_bmp") === "false" || getValue(proposal, "restoration_follow_bmp") === false;
        const restoration_on_protected_land_YES = getValue(proposal, "restoration_on_protected_land") === "true" || getValue(proposal, "restoration_on_protected_land") === true;
        const restoration_on_protected_land_NO = getValue(proposal, "restoration_on_protected_land") === "false" || getValue(proposal, "restoration_on_protected_land") === false;
        const restoration_on_protected_land_yes_OTHER = getValue(proposal, "restoration_on_protected_land_yes").indexOf("Other") > -1;
        const planting_crops_YES = getValue(proposal, "planting_crops") === "true" || getValue(proposal, "planting_crops") === true;
        const fee_hunting_fishing_YES = getValue(proposal, "fee_hunting_fishing") === "true" || getValue(proposal, "fee_hunting_fishing") === true;
        const fee_hunting_fishing_after_YES = getValue(proposal, "fee_hunting_fishing_after") === "true" || getValue(proposal, "fee_hunting_fishing_after") === true;
        const easement_public_use_YES = getValue(proposal, "easement_public_use") === "true" || getValue(proposal, "easement_public_use") === true;
        const feeeasement_trailroads_YES = getValue(proposal, "feeeasement_trailroads") === "true" || getValue(proposal, "feeeasement_trailroads") === true;
        const trails_continued_use_YES = getValue(proposal, "trails_continued_use") === "true" || getValue(proposal, "trails_continued_use") === true;
        const feeeasement_trailroads_new_YES = getValue(proposal, "feeeasement_trailroads_new") === "true" || getValue(proposal, "feeeasement_trailroads_new") === true;
        const land_restored_NO = getValue(proposal, "land_restored") === "false" || getValue(proposal, "land_restored") === false;

        const received_ohf_dollars_YES = getValue(proposal, "received_ohf_dollars") === "true" || getValue(proposal, "received_ohf_dollars") === true;
        
        return (
            <Form
                getApi={el => (this.form = el)}
                key={proposal.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={proposal.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HelpLabel
                                    inputLabel="Activity Details"
                                    title={true}
                                    showLabel={true}
                                    helpText={settings.activity_tab_help}
                                />
                                {ActionMenu}
                            </Grid>
                            <ExpansionPanel
                                square
                                defaultExpanded={true}
                                classes={{root: classes.summaryRoot}}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    classes={{
                                        root: classes.expansionSummaryRoot
                                    }}>
                                    <Typography variant="h3">Requirements</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        {year >= 2015 && (
                                            <RadioGroup
                                                field="criteria_ms"
                                                name="criteria_ms"
                                                disabled={is_read_only}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes", value: "true" },
                                                    { label: "No (Explain)", value: "false" }
                                                ]}
                                                alignment={true}
                                            >
                                                If funded, this proposal will meet all applicable criteria set forth in&nbsp;
                                                <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97a.056" target="_blank">MS 97A.056</a>?
                                            </RadioGroup>      
                                        )}
                                        {criteria_ms_NO && year >= 2015 && (
                                            <Subquestion component={
                                                <TextField
                                                    field="criteria_ms_desc"
                                                    disabled={is_read_only}
                                                    label="Explain why proposal will not meet all applicable criteria."
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {ACTIVITY_FEE && (
                                            <RadioGroup
                                                field="fee_governement_approval"
                                                disabled={is_read_only}
                                                name="fee_governement_approval"
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes", value: "true" },
                                                    { label: "No (Explain)", value: "false" }
                                                ]}
                                                alignment={true}
                                            >
                                                <HelpLabel
                                                    inputLabel={
                                                        year <= 2018 ? "Will local government approval be sought prior to acquisition?" :
                                                        <span>
                                                            Will county board or other local government approval <u>be formally sought**</u> prior to acquisition,
                                                            &nbsp;<a rel="noopener noreferrer" href='https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.13' target='_blank'>per 97A.056 subd 13(j)</a>?
                                                        </span>
                                                    }
                                                    showLabel={true}
                                                    helpText="**: To formally seek approval entails requesting approval from the county board or other applicable government body by affirmative vote at a public meeting."
                                                />
                                            </RadioGroup>
                                        )}
                                        {ACTIVITY_FEE && fee_governement_approval_NO && (
                                            <Subquestion component={
                                                <TextField
                                                    field="fee_governement_approval_desc"
                                                    disabled={is_read_only}
                                                    label="Describe any measures to inform local governments of land acquisition under their jurisdiction."
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {ACTIVITY_FEE && (
                                            <RadioGroup
                                                field="fee_free_of_protection"
                                                name="fee_free_of_protection"
                                                disabled={is_read_only}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes", value: "true" },
                                                    { label: "No (Explain)", value: "false" }
                                                ]}
                                                alignment={true}
                                                label="Is the land you plan to acquire (fee title) free of any other permanent protection?"
                                            />
                                        )}
                                        {ACTIVITY_FEE && fee_free_of_protection_NO && (
                                            <Subquestion component={
                                                <TextField
                                                    field="fee_free_of_protection_desc"
                                                    disabled={is_read_only}
                                                    label="Describe the permanent protection and justification for additional protection."
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {ACTIVITY_EASEMENT && (
                                            <RadioGroup
                                                field="easement_free_of_protection"
                                                name="easement_free_of_protection"
                                                disabled={is_read_only}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes", value: "true" },
                                                    { label: "No (Explain)", value: "false" }
                                                ]}
                                                alignment={true}
                                                label="Is the land you plan to acquire (easement) free of any other permanent protection?"
                                            />
                                        )}
                                        {ACTIVITY_EASEMENT && easement_free_of_protection_NO && (
                                            <Subquestion component={
                                                <TextField
                                                    field="easement_free_of_protection_desc"
                                                    disabled={is_read_only}
                                                    label="Describe the expected public use."
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {ACTIVITY_RESTORE_ENHANCE && year >= 2015 && (
                                            <RadioGroup
                                                field="restoration_follow_bmp"
                                                name="restoration_follow_bmp"
                                                disabled={is_read_only}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes", value: "true" },
                                                    { label: "No (Explain)", value: "false" }
                                                ]}
                                                alignment={true}
                                            >
                                                Will restoration and enhancement work follow best management practices including&nbsp;
                                                <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/84.973" target="_blank">MS 84.973</a>
                                                &nbsp;Pollinator Habitat Program?
                                            </RadioGroup>
                                        )}
                                        {ACTIVITY_RESTORE_ENHANCE && year >= 2015 && restoration_follow_bmp_NO && (
                                            <Subquestion component={
                                                <TextField
                                                    field="restoration_follow_bmp_desc"
                                                    disabled={is_read_only}
                                                    label="Explain why the work will not follow best management practices."
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {ACTIVITY_RESTORE_ENHANCE && (
                                            <RadioGroup
                                                field="restoration_on_protected_land"
                                                name="restoration_on_protected_land"
                                                disabled={is_read_only}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes (Select)", value: "true" },
                                                    { label: "No", value: "false" }
                                                ]}
                                                alignment={true}
                                            >
                                                Is the restoration and enhancement activity on permanently protected land per&nbsp;
                                                <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.24" target="_blank">97A.056</a>, 
                                                Subd 13(f), tribal lands, and/or public waters per&nbsp;
                                                <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/103G.005#stat.103G.005.15" target="_blank">MS 103G.005</a>, 
                                                Subd. 15?
                                            </RadioGroup>
                                        )}
                                        {ACTIVITY_RESTORE_ENHANCE && year <= 2016 && restoration_on_protected_land_NO && (
                                            <Subquestion component={
                                                <TextField
                                                    field="rep_protected_desc"
                                                    disabled={is_read_only}
                                                    label="Describe the rationale for restoration and/or enhancement on non-protected land."
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {ACTIVITY_RESTORE_ENHANCE && restoration_on_protected_land_YES && (
                                            <Subquestion component={
                                                <div>
                                                    <CheckboxGroup
                                                        field="restoration_on_protected_land_yes"
                                                        label="Where does the activity take place?"
                                                        disabled={is_read_only}
                                                        row={true}
                                                        eventHandle={this.applyOther}
                                                        options={MAKE_OPTIONS([
                                                            "WMA",
                                                            "WPA",
                                                            'SNA',
                                                            'AMA',
                                                            'Permanently Protected Conservation Easements',
                                                            'County/Municipal',
                                                            'Refuge Lands',
                                                            'Public Waters',
                                                            'State Wilderness Areas',
                                                            'State Recreation Areas',
                                                            'State Forests',
                                                            'Other'
                                                        ])}
                                                    />
                                                    {restoration_on_protected_land_yes_OTHER && (
                                                        <Subquestion component={
                                                            <TextField
                                                                field="restoration_on_protected_land_yes_other"
                                                                label="What is the other activity location?"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                            />
                                                        } />
                                                    )}
                                                </div>
                                            } />
                                        )}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                square
                                defaultExpanded={true}
                                classes={{root: classes.summaryRoot}}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    classes={{
                                        root: classes.expansionSummaryRoot
                                    }}>
                                    <Typography variant="h3">Land Use</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <RadioGroup
                                            field="planting_crops"
                                            name="planting_crops"
                                            disabled={is_read_only}
                                            fullWidth
                                            eventHandle={this.applyOther}
                                            options={[
                                                { label: "Yes (Explain)", value: "true" },
                                                { label: "No", value: "false" }
                                            ]}
                                            alignment={true}
                                            label="Will there be planting of any crop on OHF land purchased or restored in this program?"
                                        />
                                        {planting_crops_YES && (
                                            <Subquestion component={
                                                <TextField
                                                    field="planting_crops_desc"
                                                    disabled={is_read_only}
                                                    label="Explain what will be planted."
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {planting_crops_YES && year <= 2020 && (
                                            <Subquestion component={
                                                <RadioGroup
                                                    field="planting_crops_gmo"
                                                    name="planting_crops_gmo"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                    eventHandle={this.applyOther}
                                                    options={[
                                                        { label: "Yes", value: "true" },
                                                        { label: "No", value: "false" }
                                                    ]}
                                                    alignment={true}
                                                    label="Are any of the crop types planted GMO treated?"
                                                />
                                            } />
                                        )}
                                        {ACTIVITY_FEE && (
                                            <RadioGroup
                                                field="fee_hunting_fishing"
                                                name="fee_hunting_fishing"
                                                disabled={is_read_only}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes (Explain)", value: "true" },
                                                    { label: "No", value: "false" }
                                                ]}
                                                alignment={true}
                                                label="Is this land currently open for hunting and fishing?"
                                            />
                                        )}
                                        {ACTIVITY_FEE && fee_hunting_fishing_YES && (
                                            <Subquestion component={
                                                <TextField
                                                    field="fee_hunting_fishing_desc"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                >
                                                    <HelpLabel
                                                        inputLabel="Describe any variation from the State of Minnesota regulations."
                                                        showLabel={true}
                                                        helpText={
                                                            <div>
                                                                <a rel="noopener noreferrer" href="http://www.dnr.state.mn.us/rlp/index.html" target="_blank">Minnesota DNR</a>
                                                            </div>
                                                        }
                                                    />
                                                </TextField>
                                            } />
                                        )}
                                        {ACTIVITY_FEE && year >= 2015 && (
                                            <RadioGroup
                                                field="fee_hunting_fishing_after"
                                                name="fee_hunting_fishing_after"
                                                disabled={is_read_only}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes (Explain)", value: "true" },
                                                    { label: "No", value: "false" }
                                                ]}
                                                alignment={true}
                                                label="Will the land be open for hunting and fishing after completion?"
                                            />
                                        )}
                                        {ACTIVITY_FEE && year >= 2015 && fee_hunting_fishing_after_YES && (
                                            <Subquestion component={
                                                <TextField
                                                    field="fee_hunting_fishing_after_desc"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                >
                                                    <HelpLabel
                                                        inputLabel="Describe any variation from the State of Minnesota regulations."
                                                        showLabel={true}
                                                        helpText={
                                                            <div>
                                                                <a rel="noopener noreferrer" href="http://www.dnr.state.mn.us/rlp/index.html" target="_blank">Minnesota DNR</a>
                                                            </div>
                                                        }
                                                    />
                                                </TextField>
                                            } />
                                        )}
                                        {ACTIVITY_FEE && year >= 2023 && (
                                            <CheckboxGroup
                                                field="fee_title_ownership"
                                                label="Who will eventually own the fee title land?"
                                                disabled={is_read_only}
                                                row={true}
                                                eventHandle={this.applyOther}
                                                options={MAKE_OPTIONS([
                                                    "State of MN",
                                                    "County",
                                                    'Federal',
                                                    'Local Unit of Government',
                                                    'Tribal',
                                                    'NGO',
                                                    'Other'
                                                ])}
                                            />
                                        )}
                                        {ACTIVITY_FEE && fee_title_ownership_OTHER && (
                                            <Subquestion component={
                                                <TextField
                                                    field="fee_title_ownership_other"
                                                    disabled={is_read_only}
                                                    label="Who is the other owner?"
                                                    fullWidth
                                                />
                                            } />
                                        )}
                                        {ACTIVITY_FEE && year >= 2023 && (
                                            <CheckboxGroup
                                                field="fee_land_designated"
                                                label="Land acquired in fee will be designated as a"
                                                disabled={is_read_only}
                                                row={true}
                                                eventHandle={this.applyOther}
                                                options={MAKE_OPTIONS([
                                                    "WMA",
                                                    "SNA",
                                                    'AMA',
                                                    'State Forest',
                                                    'County Forest',
                                                    'WPA',
                                                    'National Wildlife Refuge',
                                                    'SRA',
                                                    'Tribal',
                                                    'City Owned',
                                                    "Other"
                                                ])}
                                            />
                                        )}
                                        {ACTIVITY_FEE && fee_land_designated_CITY && (
                                            <Subquestion component={
                                                <TextField
                                                    field="fee_land_designated_city"
                                                    disabled={is_read_only}
                                                    label="Specify City"
                                                    fullWidth
                                                />
                                            } />
                                        )}
                                        {ACTIVITY_FEE && fee_land_designated_OTHER && (
                                            <Subquestion component={
                                                <TextField
                                                    field="fee_land_designated_other"
                                                    disabled={is_read_only}
                                                    label="Specify Other Designation"
                                                    fullWidth
                                                />
                                            } />
                                        )}
                                        {ACTIVITY_EASEMENT && (
                                            <RadioGroup
                                                field="easement_public_use"
                                                name="easement_public_use"
                                                disabled={is_read_only}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes (Explain)", value: "true" },
                                                    { label: "No", value: "false" }
                                                ]}
                                                alignment={true}
                                                label="Will the eased land be open for public use?"
                                            />
                                        )}
                                        {ACTIVITY_EASEMENT && easement_public_use_YES && (
                                            <Subquestion component={
                                                <TextField
                                                    field="easement_public_use_desc"
                                                    disabled={is_read_only}
                                                    label="Describe the expected public use."
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {(ACTIVITY_FEE || ACTIVITY_EASEMENT) && year >= 2016 && (
                                            <RadioGroup
                                                field="feeeasement_trailroads"
                                                name="feeeasement_trailroads"
                                                disabled={is_read_only}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes (Explain)", value: "true" },
                                                    { label: "No", value: "false" }
                                                ]}
                                                alignment={true}
                                                label="Are there currently trails or roads on any of the proposed acquisitions?"
                                            />
                                        )}
                                        {(ACTIVITY_FEE || ACTIVITY_EASEMENT) && year >= 2016 && feeeasement_trailroads_YES && (
                                            <Subquestion component={
                                                <TextField
                                                    field="trail_types"
                                                    disabled={is_read_only}
                                                    label="Describe the types of trails or roads and the allowable uses."
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {(ACTIVITY_FEE || ACTIVITY_EASEMENT) && year >= 2016 && feeeasement_trailroads_YES && (
                                            <Subquestion component={
                                                <div>
                                                    <RadioGroup
                                                        field="trails_continued_use"
                                                        disabled={is_read_only}
                                                        name="trails_continued_use"
                                                        fullWidth
                                                        eventHandle={this.applyOther}
                                                        options={[
                                                            { label: "Yes (Explain)", value: "true" },
                                                            { label: "No", value: "false" }
                                                        ]}
                                                        alignment={true}
                                                        label="Will the trails or roads remain and uses continue to be allowed after OHF acquisition?"
                                                    />
                                                    {trails_continued_use_YES && (
                                                        <Subquestion component={
                                                            <TextField
                                                                field="trails_continued_use_desc"
                                                                disabled={is_read_only}
                                                                label="How will maintenance and monitoring be accomplished?"
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                                rowsMax={20}
                                                            />
                                                        } />
                                                    )}
                                                </div>
                                            } />
                                        )}
                                        {(ACTIVITY_FEE || ACTIVITY_EASEMENT) && year >= 2016 && (
                                            <RadioGroup
                                                field="feeeasement_trailroads_new"
                                                name="feeeasement_trailroads_new"
                                                disabled={is_read_only}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes (Explain)", value: "true" },
                                                    { label: "No", value: "false" }
                                                ]}
                                                alignment={true}
                                                label="Will new trails or roads be developed or improved as a result of the OHF acquisition?"
                                            />
                                        )}
                                        {(ACTIVITY_FEE || ACTIVITY_EASEMENT) && year >= 2016 && feeeasement_trailroads_new_YES && (
                                            <Subquestion component={
                                                <TextField
                                                    field="trail_types_new"
                                                    disabled={is_read_only}
                                                    label="Describe the types of trails or roads and the allowable uses."
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {(ACTIVITY_FEE || ACTIVITY_EASEMENT) && year >= 2016 && feeeasement_trailroads_new_YES && (
                                            <Subquestion component={
                                                <TextField
                                                    field="trail_types_new_maintenance"
                                                    disabled={is_read_only}
                                                    label="How will maintenance and monitoring be accomplished?"
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {(ACTIVITY_FEE || ACTIVITY_EASEMENT) && year >= 2021 && (
                                            <RadioGroup
                                                field="land_restored"
                                                disabled={is_read_only}
                                                name="land_restored"
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes", value: "true" },
                                                    { label: "No (Explain)", value: "false" }
                                                ]}
                                                alignment={true}
                                                label="Will the land that you acquire (fee or easement) be restored or enhanced within this proposal's funding and availability?"
                                            />
                                        )}
                                        {(ACTIVITY_FEE || ACTIVITY_EASEMENT) && year >= 2021 && land_restored_NO && (
                                            <Subquestion component={
                                                <TextField
                                                    field="land_restored_how"
                                                    disabled={is_read_only}
                                                    label="Explain how, when, and source of the R/E work."
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    rowsMax={20}
                                                />
                                            } />
                                        )}
                                        {ACTIVITY_OTHER && year <= 2016 && (
                                            <TextField
                                                field="activity_other_desc"
                                                disabled={is_read_only}
                                                label="Describe your other activity to undertake"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                rowsMax={20}
                                            />
                                        )}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            {year >= 2021 && (
                                <ExpansionPanel
                                    square
                                    defaultExpanded={true}
                                    classes={{root: classes.summaryRoot}}
                                >
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                        classes={{
                                            root: classes.expansionSummaryRoot
                                        }}>
                                        <Typography variant="h3">Other OHF Appropriation Awards</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="received_ohf_dollars"
                                                disabled={is_read_only}
                                                name="received_ohf_dollars"
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes (Explain)", value: "true" },
                                                    { label: "No", value: "false" }
                                                ]}
                                                alignment={true}
                                                label="Have you received OHF dollars in the past through LSOHC?"
                                            />
                                            {received_ohf_dollars_YES && (
                                                <Subquestion component={
                                                    <>
                                                    <Typography gutterBottom>
                                                        Please complete the following table showing past approps and spending to date for this program:
                                                        <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormProposalPastOHFCreate({proposal: proposal.id})}>
                                                            <AddCircleIcon />
                                                            &nbsp;&nbsp;&nbsp;Add Row
                                                        </Button>
                                                    </Typography>
                                                    <PastOHFTable
                                                        ormProposalPastOHFUpdate={ormProposalPastOHFUpdate}
                                                        ormProposalPastOHFDelete={ormProposalPastOHFDelete}
                                                        rows={pastOHFs}
                                                        is_read_only={is_read_only}
                                                        classes={classes}
                                                    />
                                                    </>
                                                } />
                                            )}
                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )}
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Timeline <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

class PastOHFTable extends Component {
    columnData = [
        { id: "year", numeric: false, label: "Approp Year", allowSort: false },
        { id: "requested", numeric: false, label: "Approp Amount Received", allowSort: false },
        { id: "spent", numeric: false, label: "Approp Amount Spent to Date", allowSort: false },
        { id: "leverage_reported", numeric: false, label: "Leverage as Reported in AP", allowSort: false },
        { id: "leverage_realized", numeric: false, label: "Leverage Realized to Date", allowSort: false },
        { id: "acres_ap", numeric: false, label: "Total Acres Affected in AP", allowSort: false },
        { id: "acres_todate", numeric: false, label: "Total Acres Affected to Date", allowSort: false },
        { id: "complete", numeric: false, label: "Program Complete and Final Report Approved?", allowSort: false },
        { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" },
    ];
    typingTimer = [];

    updateRecord = (value, element, id) => {
        const { ormProposalPastOHFUpdate } = this.props;

        clearTimeout(this.typingTimer[element]);
        this.typingTimer[element] = setTimeout(function() {
            ormProposalPastOHFUpdate({
                id: id,
                [element]: value,
            });
        }, 1000);
    };

    render() {
        const { rows, classes, ormProposalPastOHFDelete, is_read_only } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {rows.map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    <Form key={n.id + "year"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat useYear eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="year" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "requested"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat useTextFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="requested" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "spent"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat useTextFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="spent" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "leverage_reported"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat useTextFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="leverage_reported" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "leverage_realized"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat useTextFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="leverage_realized" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "acres_ap"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat useAcres eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="acres_ap" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "acres_todate"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat useAcres eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="acres_todate" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "complete"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <RadioGroup
                                                    field="complete"
                                                    name="complete"
                                                    tableFormat
                                                    disabled={is_read_only}
                                                    eventHandle={(v, e) => this.updateRecord(v, e, n.id)}
                                                    options={[
                                                        { label: "Yes", value: "true" },
                                                        { label: "No", value: "false" }
                                                    ]}
                                                    alignment={true}
                                                    label=""
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title="Delete Record">
                                        <Button
                                            color="primary"
                                            disabled={is_read_only}
                                            className={classes.deleteWidth}
                                            onClick={() => ormProposalPastOHFDelete(n.id)}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {rows.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={9} className={classes.centerAlign}>
                                No Records Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

ActivityTab = connect(
    (state, ownProps) => ({
        pastOHFs: getPastOHFs(state, ownProps),
        authState: state.auth
    }),
    {
        ...Proposal.actions,
        ...ProposalPastOHF.actions
    }
)(ActivityTab);

export default withStyles(styles)(withRouter(ActivityTab));
