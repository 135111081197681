import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        fetch("/error/post", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                error: error.stack,
                error_info: errorInfo
            })
        })
    }
  
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <>
                <h1>You have encountered an error.</h1>
                <p>LSOHC staff have been alerted to the issue. It would be helpful to send staff precislely what you were doing before this occured and any other data you think would be helpful. </p>
                </>
            )
        }

        return this.props.children; 
    }
}
export default ErrorBoundary;