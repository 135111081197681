import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    header: {
        backgroundColor: "#eeeeee",
        margin: "0 -20px",
        padding: "20px 30px 10px 30px !important",
        flexBasis: "auto",
        width: "calc(100% + 40px)",
        maxWidth: "calc(100% + 40px)",
        borderBottom: "3px solid " + theme.palette.primary.main,
        marginTop: -28
    },
});

class Footer extends Component {
    render() {
        const { classes, title } = this.props;

        return (
            <Grid item xs={12} className={classes.header}>
                <Typography variant="h2">{title}</Typography>
            </Grid>
        );
    }
}

export default withStyles(styles)(Footer);
