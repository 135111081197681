import orm, { Model } from "../common/orm";

export class RealPropertyInterest extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "RealPropertyInterest";
    }
}
orm.register(RealPropertyInterest);
