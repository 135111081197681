import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import TextField from "../common/TextField";

import { connect } from "react-redux";
import * as authActions from "./actions";

const styles = {
    passwordError: {
        color: "#be0000",
        textAlign: "center"
    }
};

class PasswordPage extends Component {
    errorValidator = values => {
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        return {
            email: validateEmail(values.email)
        };
    };

    passwordSubmit = (values, e, formApi) => {
        this.props.authPasswordReset(values).then(() => formApi.clearAll());
    };

    render() {
        const { classes, passwordReset } = this.props;
        return (
            <AppContainer>
                <Grid container justify="center">
                    <Grid item xs={11} sm={10} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h2" gutterBottom>
                                    Password Reset
                                </Typography>
                                <Form
                                    dontValidateOnMount="true"
                                    validateOnSubmit="true"
                                    validateError={this.errorValidator}
                                    onSubmit={this.passwordSubmit}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Typography className={classes.passwordError}>
                                                {passwordReset.error}
                                            </Typography>
                                            {passwordReset.success && (
                                                <Typography gutterBottom>
                                                    A password reset email has been sent to the specified email address:{" "}
                                                    <b>{passwordReset.success.email}</b>. If a user with this address exists
                                                    then you will receive instructions on how to reset. If you do not
                                                    receive an email within five minutes check your spam folder.
                                                </Typography>
                                            )}
                                            <TextField field="email" label="Email Address" fullWidth />
                                            <br />
                                            <br />
                                            <Submit
                                                label="Reset"
                                                loading={passwordReset.pending}
                                                altAction="/"
                                                altLabel="Return to Home Page"
                                            />
                                        </form>
                                    )}
                                </Form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

PasswordPage = connect(
    state => ({ passwordReset: (state.auth && state.auth.passwordReset) || {} }),
    authActions
)(PasswordPage);

export default withStyles(styles)(withRouter(PasswordPage));
