import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { FinalReport } from "../models";
import { createSelector } from "../../common/orm";
import TextField from "../../common/TextField";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import Subquestion from "../../common/Subquestion";
import HelpLabel from "../../common/HelpLabel";
import { getValue } from "../../../api/utils";

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        },
        marginBottom:16
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    totalRow: {
        backgroundColor: "rgb(221, 221, 221) !important",
        borderTop: "3px solid #000",
        height:36
    },
    widthSixty: {
        width: 160
    }
});

const getBudgets = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.FinalBudget.filter({ final: id }).orderBy("order").toModelArray()
        .map(budget => ({
            _personnel: budget.budgetpersonnel.all().toRefArray(),
            _capital: budget.budgetcapital.all().toRefArray(),
            ...budget.ref
        }));;
    }
);

const dollars_format = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});
const acres_format = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 0
});

const acresRender = (rowData) => {
    return rowData ? acres_format.format(rowData) : 0
};

const dollarsRender = (rowData) => {
    return rowData ? dollars_format.format(rowData) : 0
};

class OutputTab extends Component {
    parentFormKey = window.performance.now();

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Final Report: Output Tables - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    };

    componentWillUnmount() {
        this.props.onRef(undefined);
    };

    updateAP(values, fromStepper) {
        const { ormFinalReportUpdate, history, handleUnsavedFields, handleNext, final_report } = this.props;
        const { id } = final_report;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }
        values.modify_date_label = final_report.modify_date_label;
        values.signup_criteria = final_report.signup_criteria;

        ormFinalReportUpdate({
            id: id,
            activeStep: values.activeStep,
            output_target_shoreline_final: values.output_target_shoreline_final,
            acre_goals: values.acre_goals
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    };

    render() {
        const { 
            classes,
            final_report,
            handleUnsavedFields,
            ormFinalReportUpdateLocalOnly,
            ormFinalReportUpdate,
            settings,
            budgets,
            ActionMenu, authState
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || final_report.status === "Draft")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        const SHOW_NATIVE_PRAIRIE_TABLE = (
            getValue(final_report, "output_acres_resourcetype_restore_prairie") > 0 ||
            getValue(final_report, "output_acres_resourcetype_feepilt_prairie") > 0 ||
            getValue(final_report, "output_acres_resourcetype_feewopilt_prairie") > 0 ||
            getValue(final_report, "output_acres_resourcetype_easement_prairie") > 0 ||
            getValue(final_report, "output_acres_resourcetype_enhance_prairie") > 0
        );

        var GrandTotal = 0;
        budgets.forEach(function(b) {
            if (b.item === "Grand Total") {
                GrandTotal += b.funding_request_final;
            }
        });

        var temp = parseFloat(GrandTotal) - parseFloat(final_report.output_dollars_resourcetype_total_total_final);
        if (temp !== final_report.formData._t2) {
            final_report.formData._t1 = GrandTotal;
            final_report.formData._t2 = temp;
            this.parentFormKey = window.performance.now();
        }

        temp = parseFloat(GrandTotal) - parseFloat(final_report.output_dollars_ecologicalsection_total_total_final);
        if (temp !== final_report.formData._t4) {
            final_report.formData._t3 = GrandTotal;
            final_report.formData._t4 = temp;
            this.parentFormKey = window.performance.now();
        }

        return (
            <Form
                getApi={el => (this.form = el)}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={final_report.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateAP(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HelpLabel
                                    inputLabel="Output Tables"
                                    showLabel={true}
                                    title={true}
                                    helpText={settings.output_tables_tab_help}
                                />
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3" gutterBottom>Acres by Resource Type (Table 1)</Typography>
                                <OutputTableAcresByResource
                                    ormFinalReportUpdate={ormFinalReportUpdate} 
                                    final_report={final_report}
                                    is_read_only={is_read_only}
                                    classes={classes}
                                    ormFinalReportUpdateLocalOnly={ormFinalReportUpdateLocalOnly}
                                />
                            </Grid>
                            {SHOW_NATIVE_PRAIRIE_TABLE && (
                                <Subquestion component={
                                    <>
                                    <Typography variant="h3" gutterBottom>How many of these Prairie acres are Native Prairie? (Table 1b)</Typography>
                                    <OutputTableNativePrairie
                                        ormFinalReportUpdate={ormFinalReportUpdate} 
                                        final_report={final_report}
                                        is_read_only={is_read_only}
                                        classes={classes}
                                        ormFinalReportUpdateLocalOnly={ormFinalReportUpdateLocalOnly}
                                    />
                                    </>
                                } />
                            )}
                            <Grid item xs={12}>
                                <Typography style={{float:"left",marginTop:12}} variant="h3">Total Request Funding by Resource Type (Table 2)</Typography>
                                <Form
                                    key={this.parentFormKey}
                                    dontValidateOnMount={true}
                                    validateOnSubmit={true}
                                    defaultValues={final_report.formData}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <div style={{float:"right",marginTop:-20}}>
                                                <TextField
                                                    disabled={true}
                                                    field="_t1" useTextFormat
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Budget Total</i>
                                                </TextField>
                                                <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>-</span>
                                                <TextField
                                                    disabled={true}
                                                    field="output_dollars_resourcetype_total_total_final" useTextFormat
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Amount allocated in table</i>
                                                </TextField>
                                                <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>=</span>
                                                <TextField
                                                    disabled={true}
                                                    field="_t2" useTextFormat
                                                    color={final_report.formData._t2 === 0 ? "green" : final_report.formData._t2 > 0 ? "yellow" : "red"}
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Balance left to allocate</i>
                                                </TextField>
                                            </div>
                                        </form>
                                    )}
                                </Form>
                                <OutputTableDollarsByResource
                                    ormFinalReportUpdate={ormFinalReportUpdate} 
                                    final_report={final_report}
                                    is_read_only={is_read_only}
                                    classes={classes}
                                    ormFinalReportUpdateLocalOnly={ormFinalReportUpdateLocalOnly}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3" gutterBottom>Acres within each Ecological Section (Table 3)</Typography>
                                <OutputTableAcresBySection
                                    ormFinalReportUpdate={ormFinalReportUpdate} 
                                    final_report={final_report}
                                    is_read_only={is_read_only}
                                    classes={classes}
                                    ormFinalReportUpdateLocalOnly={ormFinalReportUpdateLocalOnly}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{float:"left",marginTop:12}} variant="h3">Total Requested Funding within each Ecological Section (Table 4)</Typography>
                                <Form
                                    key={this.parentFormKey}
                                    dontValidateOnMount={true}
                                    validateOnSubmit={true}
                                    defaultValues={final_report.formData}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <div style={{float:"right",marginTop:-20}}>
                                                <TextField
                                                    disabled={true}
                                                    field="_t3" useTextFormat
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Budget Total</i>
                                                </TextField>
                                                <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>-</span>
                                                <TextField
                                                    disabled={true}
                                                    field="output_dollars_ecologicalsection_total_total_final" useTextFormat
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Amount allocated in table</i>
                                                </TextField>
                                                <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>=</span>
                                                <TextField
                                                    disabled={true}
                                                    field="_t4" useTextFormat
                                                    color={final_report.formData._t4 === 0 ? "green" : final_report.formData._t4 > 0 ? "yellow" : "red"}
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Balance left to allocate</i>
                                                </TextField>
                                            </div>
                                        </form>
                                    )}
                                </Form>
                                <OutputTableDollarsBySection
                                    ormFinalReportUpdate={ormFinalReportUpdate} 
                                    final_report={final_report}
                                    classes={classes}
                                    is_read_only={is_read_only}
                                    ormFinalReportUpdateLocalOnly={ormFinalReportUpdateLocalOnly}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField disabled={true} field="output_target_shoreline" label="Target Lake/Stream/River (original Feet or Miles)" />
                            </Grid>                                
                            <Grid item xs={12}>
                                <TextField disabled={is_read_only} field="output_target_shoreline_final" label="Target Lake/Stream/River (final Feet or Miles)" />
                            </Grid>                          
                            <Grid item xs={12}>
                                <TextField 
                                    rows={4}
                                    rowsMax={20}
                                    fullWidth
                                    multiline
                                    disabled={is_read_only} 
                                    field="acre_goals" 
                                    maxWords={!is_read_only && "100"}
                                    label="Explain the success/shortage of acre goals" />
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Outcomes <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

class OutputTableAcresByResource extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element, row) => {
        const { final_report } = this.props;

        value = parseInt(value) || 0;

        // Get Row Total
        var total = (parseInt(row.wetland_final === element ? value : getValue(final_report, row.wetland_final)) || 0) + 
        (parseInt(row.prairie_final === element ? value : getValue(final_report, row.prairie_final)) || 0) + 
        (parseInt(row.forest_final === element ? value : getValue(final_report, row.forest_final)) || 0) + 
        (parseInt(row.habitat_final === element ? value : getValue(final_report, row.habitat_final)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_wetland")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_wetland_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.wetland_final === element ? value : getValue(final_report, r.wetland_final)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_prairie_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_final === element ? value : getValue(final_report, r.prairie_final)) || 0)
            });
        } else if (element.includes("_forest")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_forest_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forest_final === element ? value : getValue(final_report, r.forest_final)) || 0)
            });
        } else if (element.includes("_habitat")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_habitat_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.habitat_final === element ? value : getValue(final_report, r.habitat_final)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_wetland")) {
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_forest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_habitat_final")) || 0);
        }
        if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_wetland_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_forest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_habitat_final")) || 0);
        }
        if (element.includes("_forest")) {
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_wetland_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_habitat_final")) || 0);
        }
        if (element.includes("_habitat")) {
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_wetland_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_resourcetype_total_forest_final")) || 0);
        }

        return {
            id: final_report.id,
            [element]: value,
            [row.total_final]: total,
            [updateField]: totalCol,
            output_acres_resourcetype_total_total_final: finalTotal
        };
    }

    updateRecord = (value, element, row) => {        
        const { ormFinalReportUpdate, ormFinalReportUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormFinalReportUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormFinalReportUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { final_report, classes, is_read_only } = this.props;
        const priority_resources_activity = getValue(final_report, "priority_resources_activity");
        var columnData = [
            { id: "type", title: "Type", allowSort: false }
        ];
        if (priority_resources_activity.indexOf("Wetlands") > -1) {
            columnData.push({ id: "wetland", label: "Wetland (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "wetland_final", label: "Wetland (final acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "prairie_final", label: "Prairie (final acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Forest") > -1) {
            columnData.push({ id: "forest", label: "Forest (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "forest_final", label: "Forest (final acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Habitat") > -1) {
            columnData.push({ id: "habitat", label: "Habitat (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "habitat_final", label: "Habitat (final acres)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (original acres)", numeric: true, allowSort: false });
        columnData.push({ id: "total_final", label: "Total (final acres)", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(final_report, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    wetland: "output_acres_resourcetype_restore_wetland",
                    prairie: "output_acres_resourcetype_restore_prairie",
                    forest: "output_acres_resourcetype_restore_forest",
                    habitat: "output_acres_resourcetype_restore_habitat",
                    total: "output_acres_resourcetype_restore_total",
                    wetland_final: "output_acres_resourcetype_restore_wetland_final",
                    prairie_final: "output_acres_resourcetype_restore_prairie_final",
                    forest_final: "output_acres_resourcetype_restore_forest_final",
                    habitat_final: "output_acres_resourcetype_restore_habitat_final",
                    total_final: "output_acres_resourcetype_restore_total_final"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    wetland: "output_acres_resourcetype_feepilt_wetland",
                    prairie: "output_acres_resourcetype_feepilt_prairie",
                    forest: "output_acres_resourcetype_feepilt_forest",
                    habitat: "output_acres_resourcetype_feepilt_habitat",
                    total: "output_acres_resourcetype_feepilt_total",
                    wetland_final: "output_acres_resourcetype_feepilt_wetland_final",
                    prairie_final: "output_acres_resourcetype_feepilt_prairie_final",
                    forest_final: "output_acres_resourcetype_feepilt_forest_final",
                    habitat_final: "output_acres_resourcetype_feepilt_habitat_final",
                    total_final: "output_acres_resourcetype_feepilt_total_final"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    wetland: "output_acres_resourcetype_feewopilt_wetland",
                    prairie: "output_acres_resourcetype_feewopilt_prairie",
                    forest: "output_acres_resourcetype_feewopilt_forest",
                    habitat: "output_acres_resourcetype_feewopilt_habitat",
                    total: "output_acres_resourcetype_feewopilt_total",
                    wetland_final: "output_acres_resourcetype_feewopilt_wetland_final",
                    prairie_final: "output_acres_resourcetype_feewopilt_prairie_final",
                    forest_final: "output_acres_resourcetype_feewopilt_forest_final",
                    habitat_final: "output_acres_resourcetype_feewopilt_habitat_final",
                    total_final: "output_acres_resourcetype_feewopilt_total_final"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    wetland: "output_acres_resourcetype_easement_wetland",
                    prairie: "output_acres_resourcetype_easement_prairie",
                    forest: "output_acres_resourcetype_easement_forest",
                    habitat: "output_acres_resourcetype_easement_habitat",
                    total: "output_acres_resourcetype_easement_total",
                    wetland_final: "output_acres_resourcetype_easement_wetland_final",
                    prairie_final: "output_acres_resourcetype_easement_prairie_final",
                    forest_final: "output_acres_resourcetype_easement_forest_final",
                    habitat_final: "output_acres_resourcetype_easement_habitat_final",
                    total_final: "output_acres_resourcetype_easement_total_final"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    wetland: "output_acres_resourcetype_enhance_wetland",
                    prairie: "output_acres_resourcetype_enhance_prairie",
                    forest: "output_acres_resourcetype_enhance_forest",
                    habitat: "output_acres_resourcetype_enhance_habitat",
                    total: "output_acres_resourcetype_enhance_total",
                    wetland_final: "output_acres_resourcetype_enhance_wetland_final",
                    prairie_final: "output_acres_resourcetype_enhance_prairie_final",
                    forest_final: "output_acres_resourcetype_enhance_forest_final",
                    habitat_final: "output_acres_resourcetype_enhance_habitat_final",
                    total_final: "output_acres_resourcetype_enhance_total_final"
                });
            }
            this.rows.push({
                type: "Total",
                wetland: "output_acres_resourcetype_total_wetland",
                prairie: "output_acres_resourcetype_total_prairie",
                forest: "output_acres_resourcetype_total_forest",
                habitat: "output_acres_resourcetype_total_habitat",
                total: "output_acres_resourcetype_total_total",
                wetland_final: "output_acres_resourcetype_total_wetland_final",
                prairie_final: "output_acres_resourcetype_total_prairie_final",
                forest_final: "output_acres_resourcetype_total_forest_final",
                habitat_final: "output_acres_resourcetype_total_habitat_final",
                total_final: "output_acres_resourcetype_total_total_final"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {priority_resources_activity.indexOf("Wetlands") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.wetland))}</b>
                                        ) : (
                                            <Form key={n.wetland} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useNumber tableFormat field={n.wetland} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.wetland_final))}</b>
                                        ) : (
                                            <Form key={n.wetland_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.wetland_final} label=""
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.prairie))}</b>
                                        ) : (
                                            <Form key={n.prairie} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useNumber tableFormat field={n.prairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.prairie_final))}</b>
                                        ) : (
                                            <Form key={n.prairie_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_final} label=""
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.forest))}</b>
                                        ) : (
                                            <Form key={n.forest} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useNumber tableFormat field={n.forest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.forest_final))}</b>
                                        ) : (
                                            <Form key={n.forest_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forest_final} label=""
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Habitat") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.habitat))}</b>
                                        ) : (
                                            <Form key={n.habitat} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useNumber tableFormat field={n.habitat} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.habitat_final))}</b>
                                        ) : (
                                            <Form key={n.habitat_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.habitat_final} label=""
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{acresRender(getValue(final_report, n.total))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{acresRender(getValue(final_report, n.total_final))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableNativePrairie extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element) => {
        const { final_report } = this.props;

        value = parseInt(value) || 0;

        // Get Column Total
        var totalCol = 0;
        this.rows.forEach(function(r) {
            if (r.type !== "Total")
                totalCol += (parseInt(r.prairie_final === element ? value : getValue(final_report, r.prairie_final)) || 0)
        });

        return {
            id: final_report.id,
            [element]: value,
            output_acres_resourcetype_total_nativeprairie_final: totalCol,
        };
    }

    updateRecord = (value, element, row) => {
        const { ormFinalReportUpdate, ormFinalReportUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormFinalReportUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormFinalReportUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { classes, final_report, is_read_only } = this.props;
        var columnData = [
            { id: "type", label: "Type", allowSort: false },
            { id: "prairie", label: "Native Prairie (original acres)", numeric: true, allowSort: false },
            { id: "prairie_final", label: "Native Prairie (final acres)", numeric: true, allowSort: false }
        ];
        if (this.rows.length === 0) {
            const activity_types = getValue(final_report, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    prairie: "output_acres_resourcetype_restore_nativeprairie",
                    prairie_final: "output_acres_resourcetype_restore_nativeprairie_final"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    prairie: "output_acres_resourcetype_feepilt_nativeprairie",
                    prairie_final: "output_acres_resourcetype_feepilt_nativeprairie_final"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    prairie: "output_acres_resourcetype_feewopilt_nativeprairie",
                    prairie_final: "output_acres_resourcetype_feewopilt_nativeprairie_final"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    prairie: "output_acres_resourcetype_easement_nativeprairie",
                    prairie_final: "output_acres_resourcetype_easement_nativeprairie_final"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    prairie: "output_acres_resourcetype_enhance_nativeprairie",
                    prairie_final: "output_acres_resourcetype_enhance_nativeprairie_final"
                });
            }
            this.rows.push({
                type: "Total",
                prairie: "output_acres_resourcetype_total_nativeprairie",
                prairie_final: "output_acres_resourcetype_total_nativeprairie_final"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {n.type === "Total" ? (
                                        <b>{acresRender(getValue(final_report, n.prairie))}</b>
                                    ) : (
                                        <Form key={n.prairie} defaultValues={final_report}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField disabled={true} useNumber tableFormat field={n.prairie} label="" />
                                                </form>
                                            )}
                                        </Form>
                                    )}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {n.type === "Total" ? (
                                        <b>{acresRender(getValue(final_report, n.prairie_final))}</b>
                                    ) : (
                                        <Form key={n.prairie_final} defaultValues={final_report}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField 
                                                        disabled={is_read_only} useNumber tableFormat 
                                                        eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                        field={n.prairie_final} label=""
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    )}
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableDollarsByResource extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};
    rows = [];

    getValues = (value, element, row) => {
        const { final_report } = this.props;

        value = (Math.round(parseInt(value) / 100) * 100) || 0;

        // Get Row Total
        var total = (parseInt(row.wetland_final === element ? value : getValue(final_report, row.wetland_final)) || 0) + 
        (parseInt(row.prairie_final === element ? value : getValue(final_report, row.prairie_final)) || 0) + 
        (parseInt(row.forest_final === element ? value : getValue(final_report, row.forest_final)) || 0) + 
        (parseInt(row.habitat_final === element ? value : getValue(final_report, row.habitat_final)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_wetland")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_wetland_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.wetland_final === element ? value : getValue(final_report, r.wetland_final)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_prairie_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_final === element ? value : getValue(final_report, r.prairie_final)) || 0)
            });
        } else if (element.includes("_forest")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_forest_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forest_final === element ? value : getValue(final_report, r.forest_final)) || 0)
            });
        } else if (element.includes("_habitat")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_habitat_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.habitat_final === element ? value : getValue(final_report, r.habitat_final)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_wetland")) {
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_forest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_habitat_final")) || 0);
        }
        if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_wetland_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_forest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_habitat_final")) || 0);
        }
        if (element.includes("_forest")) {
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_wetland_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_habitat_final")) || 0);
        }
        if (element.includes("_habitat")) {
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_wetland_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_resourcetype_total_forest_final")) || 0);
        }

        return {
            id: final_report.id,
            [element]: value,
            [row.total_final]: total,
            [updateField]: totalCol,
            output_dollars_resourcetype_total_total_final: finalTotal
        };
    }

    updateRecord = (value, element, row) => {        
        const { ormFinalReportUpdate, ormFinalReportUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormFinalReportUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormFinalReportUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, final_report, is_read_only } = this.props;
        const priority_resources_activity = getValue(final_report, "priority_resources_activity");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (priority_resources_activity.indexOf("Wetlands") > -1) {
            columnData.push({ id: "wetland", label: "Wetland (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "wetland_final", label: "Wetland (final $)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "prairie_final", label: "Prairie (final $)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Forest") > -1) {
            columnData.push({ id: "forest", label: "Forest (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "forest_final", label: "Forest (final $)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Habitat") > -1) {
            columnData.push({ id: "habitat", label: "Habitat (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "habitat_final", label: "Habitat (final $)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (original $)", type: "numeric", numeric: true, allowSort: false });
        columnData.push({ id: "total_final", label: "Total (final $)", type: "numeric", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(final_report, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    wetland: "output_dollars_resourcetype_restore_wetland",
                    prairie: "output_dollars_resourcetype_restore_prairie",
                    forest: "output_dollars_resourcetype_restore_forest",
                    habitat: "output_dollars_resourcetype_restore_habitat",
                    total: "output_dollars_resourcetype_restore_total",
                    wetland_final: "output_dollars_resourcetype_restore_wetland_final",
                    prairie_final: "output_dollars_resourcetype_restore_prairie_final",
                    forest_final: "output_dollars_resourcetype_restore_forest_final",
                    habitat_final: "output_dollars_resourcetype_restore_habitat_final",
                    total_final: "output_dollars_resourcetype_restore_total_final"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    wetland: "output_dollars_resourcetype_feepilt_wetland",
                    prairie: "output_dollars_resourcetype_feepilt_prairie",
                    forest: "output_dollars_resourcetype_feepilt_forest",
                    habitat: "output_dollars_resourcetype_feepilt_habitat",
                    total: "output_dollars_resourcetype_feepilt_total",
                    wetland_final: "output_dollars_resourcetype_feepilt_wetland_final",
                    prairie_final: "output_dollars_resourcetype_feepilt_prairie_final",
                    forest_final: "output_dollars_resourcetype_feepilt_forest_final",
                    habitat_final: "output_dollars_resourcetype_feepilt_habitat_final",
                    total_final: "output_dollars_resourcetype_feepilt_total_final"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    wetland: "output_dollars_resourcetype_feewopilt_wetland",
                    prairie: "output_dollars_resourcetype_feewopilt_prairie",
                    forest: "output_dollars_resourcetype_feewopilt_forest",
                    habitat: "output_dollars_resourcetype_feewopilt_habitat",
                    total: "output_dollars_resourcetype_feewopilt_total",
                    wetland_final: "output_dollars_resourcetype_feewopilt_wetland_final",
                    prairie_final: "output_dollars_resourcetype_feewopilt_prairie_final",
                    forest_final: "output_dollars_resourcetype_feewopilt_forest_final",
                    habitat_final: "output_dollars_resourcetype_feewopilt_habitat_final",
                    total_final: "output_dollars_resourcetype_feewopilt_total_final"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    wetland: "output_dollars_resourcetype_easement_wetland",
                    prairie: "output_dollars_resourcetype_easement_prairie",
                    forest: "output_dollars_resourcetype_easement_forest",
                    habitat: "output_dollars_resourcetype_easement_habitat",
                    total: "output_dollars_resourcetype_easement_total",
                    wetland_final: "output_dollars_resourcetype_easement_wetland_final",
                    prairie_final: "output_dollars_resourcetype_easement_prairie_final",
                    forest_final: "output_dollars_resourcetype_easement_forest_final",
                    habitat_final: "output_dollars_resourcetype_easement_habitat_final",
                    total_final: "output_dollars_resourcetype_easement_total_final"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    wetland: "output_dollars_resourcetype_enhance_wetland",
                    prairie: "output_dollars_resourcetype_enhance_prairie",
                    forest: "output_dollars_resourcetype_enhance_forest",
                    habitat: "output_dollars_resourcetype_enhance_habitat",
                    total: "output_dollars_resourcetype_enhance_total",
                    wetland_final: "output_dollars_resourcetype_enhance_wetland_final",
                    prairie_final: "output_dollars_resourcetype_enhance_prairie_final",
                    forest_final: "output_dollars_resourcetype_enhance_forest_final",
                    habitat_final: "output_dollars_resourcetype_enhance_habitat_final",
                    total_final: "output_dollars_resourcetype_enhance_total_final"
                });
            }
            this.rows.push({
                type: "Total",
                wetland: "output_dollars_resourcetype_total_wetland",
                prairie: "output_dollars_resourcetype_total_prairie",
                forest: "output_dollars_resourcetype_total_forest",
                habitat: "output_dollars_resourcetype_total_habitat",
                total: "output_dollars_resourcetype_total_total",
                wetland_final: "output_dollars_resourcetype_total_wetland_final",
                prairie_final: "output_dollars_resourcetype_total_prairie_final",
                forest_final: "output_dollars_resourcetype_total_forest_final",
                habitat_final: "output_dollars_resourcetype_total_habitat_final",
                total_final: "output_dollars_resourcetype_total_total_final"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {priority_resources_activity.indexOf("Wetlands") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.wetland))}</b>
                                        ) : (
                                            <Form defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useTextFormat tableFormat field={n.wetland} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.wetland_final))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.wetland_final] ? this.formkeys[n.wetland_final]  : n.wetland_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.wetland_final} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.prairie))}</b>
                                        ) : (
                                            <Form defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useTextFormat tableFormat field={n.prairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.prairie_final))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.prairie_final] ? this.formkeys[n.prairie_final]  : n.prairie_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_final} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.forest))}</b>
                                        ) : (
                                            <Form defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useTextFormat tableFormat field={n.forest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.forest_final))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.forest_final] ? this.formkeys[n.forest_final]  : n.forest_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forest_final} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Habitat") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.habitat))}</b>
                                        ) : (
                                            <Form defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useTextFormat tableFormat field={n.habitat} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.habitat_final))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.habitat_final] ? this.formkeys[n.habitat_final]  : n.habitat_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.habitat_final} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(final_report, n.total))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(final_report, n.total_final))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableAcresBySection extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element, row) => {
        const { final_report } = this.props;

        value = parseInt(value) || 0;

        // Get Row Total
        var total = (parseInt(row.metrourban_final === element ? value : getValue(final_report, row.metrourban_final)) || 0) + 
        (parseInt(row.forestprairie_final === element ? value : getValue(final_report, row.forestprairie_final)) || 0) + 
        (parseInt(row.seforest_final === element ? value : getValue(final_report, row.seforest_final)) || 0) + 
        (parseInt(row.prairie_final === element ? value : getValue(final_report, row.prairie_final)) || 0) + 
        (parseInt(row.northernforest_final === element ? value : getValue(final_report, row.northernforest_final)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_metrourban")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_metrourban_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.metrourban_final === element ? value : getValue(final_report, r.metrourban_final)) || 0)
            });
        } else if (element.includes("_forestprairie")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_forestprairie_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forestprairie_final === element ? value : getValue(final_report, r.forestprairie_final)) || 0)
            });
        } else if (element.includes("_seforest")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_seforest_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.seforest_final === element ? value : getValue(final_report, r.seforest_final)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_prairie_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_final === element ? value : getValue(final_report, r.prairie_final)) || 0)
            });
        } else if (element.includes("_northernforest")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_northernforest_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.northernforest_final === element ? value : getValue(final_report, r.northernforest_final)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_metrourban")) {
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_forestprairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_seforest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_northernforest_final")) || 0);
        } else if (element.includes("_forestprairie")) {
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_metrourban_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_seforest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_northernforest_final")) || 0);
        } else if (element.includes("_seforest")) {
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_metrourban_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_forestprairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_northernforest_final")) || 0);
        } else if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_metrourban_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_forestprairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_seforest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_northernforest_final")) || 0);
        } else if (element.includes("_northernforest")) {
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_metrourban_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_forestprairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_seforest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_acres_ecologicalsection_total_prairie_final")) || 0);
        }

        return {
            id: final_report.id,
            [element]: value,
            [row.total_final]: total,
            [updateField]: totalCol,
            output_acres_ecologicalsection_total_total_final: finalTotal
        };
    };

    updateRecord = (value, element, row) => {        
        const { ormFinalReportUpdate, ormFinalReportUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormFinalReportUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormFinalReportUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { classes, final_report, is_read_only } = this.props;
        const eco_regions = getValue(final_report, "eco_regions");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (eco_regions.indexOf("Metro / Urban") > -1) {
            columnData.push({ id: "metrourban", label: "Metro/Urban (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "metrourban_final", label: "Metro/Urban (final acres)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Forest / Prairie Transition") > -1) {
            columnData.push({ id: "forestprairie", label: "Forest/Prairie (original acres)", numeric: true, allowSort: false  });
            columnData.push({ id: "forestprairie_final", label: "Forest/Prairie (final acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Southeast Forest") > -1) {
            columnData.push({ id: "seforest", label: "SE Forest (original acres)", numeric: true, allowSort: false  });
            columnData.push({ id: "seforest_final", label: "SE Forest (final acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (original acres)", numeric: true, allowSort: false  });
            columnData.push({ id: "prairie_final", label: "Prairie (final acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Northern Forest") > -1) {
            columnData.push({ id: "northernforest", label: "Northern Forest (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "northernforest_final", label: "Northern Forest (final acres)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (original acres)", type: "numeric", numeric: true, allowSort: false  });
        columnData.push({ id: "total_final", label: "Total (final acres)", type: "numeric", numeric: true, allowSort: false  });

        if (this.rows.length === 0) {
            const activity_types = getValue(final_report, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    metrourban: "output_acres_ecologicalsection_restore_metrourban",
                    forestprairie: "output_acres_ecologicalsection_restore_forestprairie",
                    seforest: "output_acres_ecologicalsection_restore_seforest",
                    prairie: "output_acres_ecologicalsection_restore_prairie",
                    northernforest: "output_acres_ecologicalsection_restore_northernforest",
                    total: "output_acres_ecologicalsection_restore_total",
                    metrourban_final: "output_acres_ecologicalsection_restore_metrourban_final",
                    forestprairie_final: "output_acres_ecologicalsection_restore_forestprairie_final",
                    seforest_final: "output_acres_ecologicalsection_restore_seforest_final",
                    prairie_final: "output_acres_ecologicalsection_restore_prairie_final",
                    northernforest_final: "output_acres_ecologicalsection_restore_northernforest_final",
                    total_final: "output_acres_ecologicalsection_restore_total_final"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    metrourban: "output_acres_ecologicalsection_feepilt_metrourban",
                    forestprairie: "output_acres_ecologicalsection_feepilt_forestprairie",
                    seforest: "output_acres_ecologicalsection_feepilt_seforest",
                    prairie: "output_acres_ecologicalsection_feepilt_prairie",
                    northernforest: "output_acres_ecologicalsection_feepilt_northernforest",
                    total: "output_acres_ecologicalsection_feepilt_total",
                    metrourban_final: "output_acres_ecologicalsection_feepilt_metrourban_final",
                    forestprairie_final: "output_acres_ecologicalsection_feepilt_forestprairie_final",
                    seforest_final: "output_acres_ecologicalsection_feepilt_seforest_final",
                    prairie_final: "output_acres_ecologicalsection_feepilt_prairie_final",
                    northernforest_final: "output_acres_ecologicalsection_feepilt_northernforest_final",
                    total_final: "output_acres_ecologicalsection_feepilt_total_final"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    metrourban: "output_acres_ecologicalsection_feewopilt_metrourban",
                    forestprairie: "output_acres_ecologicalsection_feewopilt_forestprairie",
                    seforest: "output_acres_ecologicalsection_feewopilt_seforest",
                    prairie: "output_acres_ecologicalsection_feewopilt_prairie",
                    northernforest: "output_acres_ecologicalsection_feewopilt_northernforest",
                    total: "output_acres_ecologicalsection_feewopilt_total",
                    metrourban_final: "output_acres_ecologicalsection_feewopilt_metrourban_final",
                    forestprairie_final: "output_acres_ecologicalsection_feewopilt_forestprairie_final",
                    seforest_final: "output_acres_ecologicalsection_feewopilt_seforest_final",
                    prairie_final: "output_acres_ecologicalsection_feewopilt_prairie_final",
                    northernforest_final: "output_acres_ecologicalsection_feewopilt_northernforest_final",
                    total_final: "output_acres_ecologicalsection_feewopilt_total_final"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    metrourban: "output_acres_ecologicalsection_easement_metrourban",
                    forestprairie: "output_acres_ecologicalsection_easement_forestprairie",
                    seforest: "output_acres_ecologicalsection_easement_seforest",
                    prairie: "output_acres_ecologicalsection_easement_prairie",
                    northernforest: "output_acres_ecologicalsection_easement_northernforest",
                    total: "output_acres_ecologicalsection_easement_total",
                    metrourban_final: "output_acres_ecologicalsection_easement_metrourban_final",
                    forestprairie_final: "output_acres_ecologicalsection_easement_forestprairie_final",
                    seforest_final: "output_acres_ecologicalsection_easement_seforest_final",
                    prairie_final: "output_acres_ecologicalsection_easement_prairie_final",
                    northernforest_final: "output_acres_ecologicalsection_easement_northernforest_final",
                    total_final: "output_acres_ecologicalsection_easement_total_final"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    metrourban: "output_acres_ecologicalsection_enhance_metrourban",
                    forestprairie: "output_acres_ecologicalsection_enhance_forestprairie",
                    seforest: "output_acres_ecologicalsection_enhance_seforest",
                    prairie: "output_acres_ecologicalsection_enhance_prairie",
                    northernforest: "output_acres_ecologicalsection_enhance_northernforest",
                    total: "output_acres_ecologicalsection_enhance_total",
                    metrourban_final: "output_acres_ecologicalsection_enhance_metrourban_final",
                    forestprairie_final: "output_acres_ecologicalsection_enhance_forestprairie_final",
                    seforest_final: "output_acres_ecologicalsection_enhance_seforest_final",
                    prairie_final: "output_acres_ecologicalsection_enhance_prairie_final",
                    northernforest_final: "output_acres_ecologicalsection_enhance_northernforest_final",
                    total_final: "output_acres_ecologicalsection_enhance_total_final"
                });
            }
            this.rows.push({
                type: "Total",
                metrourban: "output_acres_ecologicalsection_total_metrourban",
                forestprairie: "output_acres_ecologicalsection_total_forestprairie",
                seforest: "output_acres_ecologicalsection_total_seforest",
                prairie: "output_acres_ecologicalsection_total_prairie",
                northernforest: "output_acres_ecologicalsection_total_northernforest",
                total: "output_acres_ecologicalsection_total_total",
                metrourban_final: "output_acres_ecologicalsection_total_metrourban_final",
                forestprairie_final: "output_acres_ecologicalsection_total_forestprairie_final",
                seforest_final: "output_acres_ecologicalsection_total_seforest_final",
                prairie_final: "output_acres_ecologicalsection_total_prairie_final",
                northernforest_final: "output_acres_ecologicalsection_total_northernforest_final",
                total_final: "output_acres_ecologicalsection_total_total_final"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {eco_regions.indexOf("Metro / Urban") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.metrourban))}</b>
                                        ) : (
                                            <Form key={n.metrourban} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useNumber tableFormat field={n.metrourban} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.metrourban_final))}</b>
                                        ) : (
                                            <Form key={n.metrourban_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.metrourban_final} label=""
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Forest / Prairie Transition") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.forestprairie))}</b>
                                        ) : (
                                            <Form key={n.forestprairie} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useNumber tableFormat field={n.forestprairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.forestprairie_final))}</b>
                                        ) : (
                                            <Form key={n.forestprairie_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forestprairie_final} label=""
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Southeast Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.seforest))}</b>
                                        ) : (
                                            <Form key={n.seforest} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useNumber tableFormat field={n.seforest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.seforest_final))}</b>
                                        ) : (
                                            <Form key={n.seforest_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.seforest_final} label=""
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.prairie))}</b>
                                        ) : (
                                            <Form key={n.prairie} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useNumber tableFormat field={n.prairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.prairie_final))}</b>
                                        ) : (
                                            <Form key={n.prairie_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_final} label=""
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Northern Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.northernforest))}</b>
                                        ) : (
                                            <Form key={n.northernforest} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useNumber tableFormat field={n.northernforest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(final_report, n.northernforest_final))}</b>
                                        ) : (
                                            <Form key={n.northernforest_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.northernforest_final} label=""
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{acresRender(getValue(final_report, n.total))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{acresRender(getValue(final_report, n.total_final))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableDollarsBySection extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};
    rows = [];

    getValues = (value, element, row) => {
        const { final_report } = this.props;

        value = (Math.round(parseInt(value) / 100) * 100) || 0;

        // Get Row Total
        var total = (parseInt(row.metrourban_final === element ? value : getValue(final_report, row.metrourban_final)) || 0) + 
        (parseInt(row.forestprairie_final === element ? value : getValue(final_report, row.forestprairie_final)) || 0) + 
        (parseInt(row.seforest_final === element ? value : getValue(final_report, row.seforest_final)) || 0) + 
        (parseInt(row.prairie_final === element ? value : getValue(final_report, row.prairie_final)) || 0) + 
        (parseInt(row.northernforest_final === element ? value : getValue(final_report, row.northernforest_final)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_metrourban")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_metrourban_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.metrourban_final === element ? value : getValue(final_report, r.metrourban_final)) || 0)
            });
        } else if (element.includes("_forestprairie")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_forestprairie_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forestprairie_final === element ? value : getValue(final_report, r.forestprairie_final)) || 0)
            });
        } else if (element.includes("_seforest")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_seforest_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.seforest_final === element ? value : getValue(final_report, r.seforest_final)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_prairie_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_final=== element ? value : getValue(final_report, r.prairie_final)) || 0)
            });
        } else if (element.includes("_northernforest")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_northernforest_final";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.northernforest_final === element ? value : getValue(final_report, r.northernforest_final)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_metrourban")) {
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_forestprairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_seforest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_northernforest_final")) || 0);
        } else if (element.includes("_forestprairie")) {
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_metrourban_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_seforest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_northernforest_final")) || 0);
        } else if (element.includes("_seforest")) {
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_metrourban_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_forestprairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_prairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_northernforest_final")) || 0);
        } else if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_metrourban_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_forestprairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_seforest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_northernforest_final")) || 0);
        } else if (element.includes("_northernforest")) {
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_metrourban_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_forestprairie_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_seforest_final")) || 0);
            finalTotal += (parseInt(getValue(final_report, "output_dollars_ecologicalsection_total_prairie_final")) || 0);
        }

        return {
            id: final_report.id,
            [element]: value,
            [row.total_final]: total,
            [updateField]: totalCol,
            output_dollars_ecologicalsection_total_total_final: finalTotal
        };
    };

    updateRecord = (value, element, row) => {        
        const { ormFinalReportUpdate, ormFinalReportUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormFinalReportUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormFinalReportUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, final_report, is_read_only } = this.props;
        const eco_regions = getValue(final_report, "eco_regions");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (eco_regions.indexOf("Metro / Urban") > -1) {
            columnData.push({ id: "metrourban", label: "Metro/Urban (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "metrourban_final", label: "Metro/Urban (final $)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Forest / Prairie Transition") > -1) {
            columnData.push({ id: "forestprairie", label: "Forest/Prairie (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "forestprairie_final", label: "Forest/Prairie (final $)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Southeast Forest") > -1) {
            columnData.push({ id: "seforest", label: "SE Forest (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "seforest_final", label: "SE Forest (final $)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "prairie_final", label: "Prairie (final $)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Northern Forest") > -1) {
            columnData.push({ id: "northernforest", label: "Northern Forest (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "northernforest_final", label: "Northern Forest (final $)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (original $)", type: "numeric", numeric: true, allowSort: false });
        columnData.push({ id: "total_final", label: "Total (final $)", type: "numeric", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(final_report, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    metrourban: "output_dollars_ecologicalsection_restore_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_restore_forestprairie",
                    seforest: "output_dollars_ecologicalsection_restore_seforest",
                    prairie: "output_dollars_ecologicalsection_restore_prairie",
                    northernforest: "output_dollars_ecologicalsection_restore_northernforest",
                    total: "output_dollars_ecologicalsection_restore_total",
                    metrourban_final: "output_dollars_ecologicalsection_restore_metrourban_final",
                    forestprairie_final: "output_dollars_ecologicalsection_restore_forestprairie_final",
                    seforest_final: "output_dollars_ecologicalsection_restore_seforest_final",
                    prairie_final: "output_dollars_ecologicalsection_restore_prairie_final",
                    northernforest_final: "output_dollars_ecologicalsection_restore_northernforest_final",
                    total_final: "output_dollars_ecologicalsection_restore_total_final"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    metrourban: "output_dollars_ecologicalsection_feepilt_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_feepilt_forestprairie",
                    seforest: "output_dollars_ecologicalsection_feepilt_seforest",
                    prairie: "output_dollars_ecologicalsection_feepilt_prairie",
                    northernforest: "output_dollars_ecologicalsection_feepilt_northernforest",
                    total: "output_dollars_ecologicalsection_feepilt_total",
                    metrourban_final: "output_dollars_ecologicalsection_feepilt_metrourban_final",
                    forestprairie_final: "output_dollars_ecologicalsection_feepilt_forestprairie_final",
                    seforest_final: "output_dollars_ecologicalsection_feepilt_seforest_final",
                    prairie_final: "output_dollars_ecologicalsection_feepilt_prairie_final",
                    northernforest_final: "output_dollars_ecologicalsection_feepilt_northernforest_final",
                    total_final: "output_dollars_ecologicalsection_feepilt_total_final"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    metrourban: "output_dollars_ecologicalsection_feewopilt_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_feewopilt_forestprairie",
                    seforest: "output_dollars_ecologicalsection_feewopilt_seforest",
                    prairie: "output_dollars_ecologicalsection_feewopilt_prairie",
                    northernforest: "output_dollars_ecologicalsection_feewopilt_northernforest",
                    total: "output_dollars_ecologicalsection_feewopilt_total",
                    metrourban_final: "output_dollars_ecologicalsection_feewopilt_metrourban_final",
                    forestprairie_final: "output_dollars_ecologicalsection_feewopilt_forestprairie_final",
                    seforest_final: "output_dollars_ecologicalsection_feewopilt_seforest_final",
                    prairie_final: "output_dollars_ecologicalsection_feewopilt_prairie_final",
                    northernforest_final: "output_dollars_ecologicalsection_feewopilt_northernforest_final",
                    total_final: "output_dollars_ecologicalsection_feewopilt_total_final"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    metrourban: "output_dollars_ecologicalsection_easement_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_easement_forestprairie",
                    seforest: "output_dollars_ecologicalsection_easement_seforest",
                    prairie: "output_dollars_ecologicalsection_easement_prairie",
                    northernforest: "output_dollars_ecologicalsection_easement_northernforest",
                    total: "output_dollars_ecologicalsection_easement_total",
                    metrourban_final: "output_dollars_ecologicalsection_easement_metrourban_final",
                    forestprairie_final: "output_dollars_ecologicalsection_easement_forestprairie_final",
                    seforest_final: "output_dollars_ecologicalsection_easement_seforest_final",
                    prairie_final: "output_dollars_ecologicalsection_easement_prairie_final",
                    northernforest_final: "output_dollars_ecologicalsection_easement_northernforest_final",
                    total_final: "output_dollars_ecologicalsection_easement_total_final"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    metrourban: "output_dollars_ecologicalsection_enhance_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_enhance_forestprairie",
                    seforest: "output_dollars_ecologicalsection_enhance_seforest",
                    prairie: "output_dollars_ecologicalsection_enhance_prairie",
                    northernforest: "output_dollars_ecologicalsection_enhance_northernforest",
                    total: "output_dollars_ecologicalsection_enhance_total",
                    metrourban_final: "output_dollars_ecologicalsection_enhance_metrourban_final",
                    forestprairie_final: "output_dollars_ecologicalsection_enhance_forestprairie_final",
                    seforest_final: "output_dollars_ecologicalsection_enhance_seforest_final",
                    prairie_final: "output_dollars_ecologicalsection_enhance_prairie_final",
                    northernforest_final: "output_dollars_ecologicalsection_enhance_northernforest_final",
                    total_final: "output_dollars_ecologicalsection_enhance_total_final"
                });
            }
            this.rows.push({
                type: "Total",
                metrourban: "output_dollars_ecologicalsection_total_metrourban",
                forestprairie: "output_dollars_ecologicalsection_total_forestprairie",
                seforest: "output_dollars_ecologicalsection_total_seforest",
                prairie: "output_dollars_ecologicalsection_total_prairie",
                northernforest: "output_dollars_ecologicalsection_total_northernforest",
                total: "output_dollars_ecologicalsection_total_total",
                metrourban_final: "output_dollars_ecologicalsection_total_metrourban_final",
                forestprairie_final: "output_dollars_ecologicalsection_total_forestprairie_final",
                seforest_final: "output_dollars_ecologicalsection_total_seforest_final",
                prairie_final: "output_dollars_ecologicalsection_total_prairie_final",
                northernforest_final: "output_dollars_ecologicalsection_total_northernforest_final",
                total_final: "output_dollars_ecologicalsection_total_total_final"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {eco_regions.indexOf("Metro / Urban") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.metrourban))}</b>
                                        ) : (
                                            <Form defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useTextFormat tableFormat field={n.metrourban} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.metrourban_final))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.metrourban_final] ? this.formkeys[n.metrourban_final]  : n.metrourban_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.metrourban_final} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Forest / Prairie Transition") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.forestprairie))}</b>
                                        ) : (
                                            <Form defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useTextFormat tableFormat field={n.forestprairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.forestprairie_final))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.forestprairie_final] ? this.formkeys[n.forestprairie_final]  : n.forestprairie_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forestprairie_final} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Southeast Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.seforest))}</b>
                                        ) : (
                                            <Form defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useTextFormat tableFormat field={n.seforest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.seforest_final))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.seforest_final] ? this.formkeys[n.seforest_final]  : n.seforest_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.seforest_final} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.prairie))}</b>
                                        ) : (
                                            <Form defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useTextFormat tableFormat field={n.prairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.prairie_final))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.prairie_final] ? this.formkeys[n.prairie_final]  : n.prairie_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_final} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Northern Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.northernforest))}</b>
                                        ) : (
                                            <Form defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField disabled={true} useTextFormat tableFormat field={n.northernforest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(final_report, n.northernforest_final))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.northernforest_final] ? this.formkeys[n.northernforest_final]  : n.northernforest_final} defaultValues={final_report}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.northernforest_final} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(final_report, n.total))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(final_report, n.total_final))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

OutputTab = connect(
    (state, ownProps) => ({
        budgets: getBudgets(state, ownProps),
        authState: state.auth
    }),
    {
        ...FinalReport.actions
    }
)(OutputTab);

export default withStyles(styles)(withRouter(OutputTab));
