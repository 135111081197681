import orm, { Model } from "../common/orm";

export class Message extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Message";
    }
}
orm.register(Message);

export class User extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "User";
    }
}
orm.register(User);

export class AdminTable extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "AdminTable";
    }
    static get isUserData() {
        return false;
    }
}
orm.register(AdminTable);