import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { StatusUpdate } from "../models";

import TextField from "../../common/TextField";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});

const numberRender = (rowData) => {
    return rowData ? formatter.format(rowData) : 0
};

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    minWidth: {
        minWidth: 600
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        },
        marginBottom:16
    },
    centerAlign: {
        textAlign: "center"
    },
    totalRow: {
        backgroundColor: "rgb(221, 221, 221) !important",
        borderTop: "3px solid #000",
        height:36
    }
});

class BudgetTab extends Component {
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        this.props.onRef(this);
        document.title = "Status Update: Budget - Lessard Sams Outdoor Heritage Council";
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    };

    componentWillUnmount() {
        this.props.onRef(undefined);
    };

    updateStatusReport(values, fromStepper) {
        const { ormStatusUpdateUpdateLocalOnly, history, handleUnsavedFields, handleNext, status_update } = this.props;
        const { id } = status_update;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        ormStatusUpdateUpdateLocalOnly({
            id: id,
            activeStep: values.activeStep
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    };

    render() {
        const { 
            classes,
            status_update,
            handleUnsavedFields,
            ormStatusUpdateUpdateLocalOnly,
            ormStatusUpdateUpdate,
            ActionMenu, authState
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || status_update.status === "Draft")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        return (
            <Form
                getApi={el => (this.form = el)}
                key={this.formkey}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={status_update.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateStatusReport(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={10} xl={8}>
                               {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={8}>
                                <Typography variant="h3" gutterBottom className={classes.centerAlign}>
                                    For non-state agencies: The budget should reflect the money spent to date on the project, 
                                    which may not necessarily be what has been submitted for reimbursement.
                                </Typography>
                                <BudgetTable
                                    ormStatusUpdateUpdateLocalOnly={ormStatusUpdateUpdateLocalOnly}
                                    ormStatusUpdateUpdate={ormStatusUpdateUpdate}
                                    is_read_only={is_read_only}
                                    status_update={status_update}
                                    classes={classes}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={10} xl={8} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    disabled={is_read_only}
                                    type="submit"
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Output Tables <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

class BudgetTable extends Component {
    columnData = [
        { id: "item", label: "Item", allowSort: false },
        { id: "funding_request", label: "AP Amount", allowSort: false },
        { id: "anticipated_leverage", label: "Spent to Date", allowSort: false },
    ];
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};

    updateRecord = (value, element) => {
        const { ormStatusUpdateUpdateLocalOnly, ormStatusUpdateUpdate, status_update } = this.props;
        const comp = this;

        // Save each individual value locally
        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = {
                id: status_update.id,
                [element]: (Math.round(parseInt(value) / 100) * 100) || 0
            };
            ormStatusUpdateUpdateLocalOnly(vals);
            
            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormStatusUpdateUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, is_read_only, status_update } = this.props;

        var spent = 0;
        spent += status_update.personnel_spent;
        spent += status_update.contracts_spent;
        spent += status_update.fee_with_pilt_spent;
        spent += status_update.fee_without_pilt_spent;
        spent += status_update.easement_acquisition_spent;
        spent += status_update.easement_stewardship_spent;
        spent += status_update.travel_spent;
        spent += status_update.professional_spent;
        spent += status_update.direction_support_spent;
        spent += status_update.land_acquisition_spent;
        spent += status_update.capital_equipment_spent;
        spent += status_update.other_equipment_spent;
        spent += status_update.supplies_spent;
        spent += status_update.dnr_idp_spent;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    <TableRow hover>
                        <CustomTableCell>Personnel</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Personnel").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["personnel_spent"] ? this.formkeys["personnel_spent"]  : "personnel_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="personnel_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Contracts</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Contracts").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["contracts_spent"] ? this.formkeys["contracts_spent"]  : "contracts_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="contracts_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Fee Acquisition w/ PILT</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Fee Acquisition w/ PILT").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["fee_with_pilt_spent"] ? this.formkeys["fee_with_pilt_spent"]  : "fee_with_pilt_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="fee_with_pilt_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Fee Acquisition w/o PILT</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Fee Acquisition w/o PILT").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["fee_without_pilt_spent"] ? this.formkeys["fee_without_pilt_spent"]  : "fee_without_pilt_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="fee_without_pilt_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Easement Acquisition</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Easement Acquisition").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["easement_acquisition_spent"] ? this.formkeys["easement_acquisition_spent"]  : "easement_acquisition_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="easement_acquisition_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Easement Stewardship</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Easement Stewardship").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["easement_stewardship_spent"] ? this.formkeys["easement_stewardship_spent"]  : "easement_stewardship_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="easement_stewardship_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Travel</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Travel").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["travel_spent"] ? this.formkeys["travel_spent"]  : "travel_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="travel_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Professional Services</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Professional Services").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["professional_spent"] ? this.formkeys["professional_spent"]  : "professional_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="professional_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Direct Support Services</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Direct Support Services").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["direction_support_spent"] ? this.formkeys["direction_support_spent"]  : "direction_support_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="direction_support_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>DNR Land Acquisition Costs</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "DNR Land Acquisition Costs").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["land_acquisition_spent"] ? this.formkeys["land_acquisition_spent"]  : "land_acquisition_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="land_acquisition_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Capital Equipment</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Capital Equipment").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["capital_equipment_spent"] ? this.formkeys["capital_equipment_spent"]  : "capital_equipment_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="capital_equipment_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Other Equipment/Tools</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Other Equipment/Tools").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["other_equipment_spent"] ? this.formkeys["other_equipment_spent"]  : "other_equipment_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="other_equipment_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>Supplies/Materials</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "Supplies/Materials").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["supplies_spent"] ? this.formkeys["supplies_spent"]  : "supplies_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="supplies_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow hover>
                        <CustomTableCell>DNR IDP</CustomTableCell>
                        <CustomTableCell>{numberRender(status_update.ap_budgets.find(b => b.item === "DNR IDP").total)}</CustomTableCell>
                        <CustomTableCell>
                            <Form key={this.formkeys["dnr_idp_spent"] ? this.formkeys["dnr_idp_spent"]  : "dnr_idp_spent"} defaultValues={status_update}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={is_read_only} useTextFormat tableFormat 
                                            eventHandle={this.updateRecord} 
                                            field="dnr_idp_spent" label="" onBlur={this.updateForm}
                                        />
                                    </form>
                                )}
                            </Form>
                        </CustomTableCell>
                    </TableRow>
                    <TableRow className={classes.totalRow}>
                        <CustomTableCell><b>Grand Total</b></CustomTableCell>
                        <CustomTableCell><b>{numberRender(status_update.ap_budgets.find(b => b.item === "Grand Total").total)}</b></CustomTableCell>
                        <CustomTableCell><b>{numberRender(spent)}</b></CustomTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
}

BudgetTab = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        ...StatusUpdate.actions,
    }
)(BudgetTab);

export default withStyles(styles)(withRouter(BudgetTab));
