import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { AccomplishmentPlan } from "../models";
import TextField from "../../common/TextField";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import Subquestion from "../../common/Subquestion";
import HelpLabel from "../../common/HelpLabel";
import { getValue } from "../../../api/utils";

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        },
        marginBottom:16
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    totalRow: {
        backgroundColor: "rgb(221, 221, 221) !important",
        borderTop: "3px solid #000",
        height:36
    },
    widthSixty: {
        width: 160
    },
    minWidth: {
        minWidth: 120
    }
});

const dollars_format = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});
const acres_format = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 0
});

const acresRender = (rowData) => {
    return rowData ? acres_format.format(rowData) : 0
};

const dollarsRender = (rowData) => {
    return rowData ? dollars_format.format(rowData) : 0
};

class OutputTab extends Component {
    parentFormKey = window.performance.now();

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Accomplishment Plan: Output Tables - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    };

    componentWillUnmount() {
        this.props.onRef(undefined);
    };

    updateAP(values, fromStepper) {
        const { ormAccomplishmentPlanUpdate, history, handleUnsavedFields, handleNext, accomplishment_plan } = this.props;
        const { id } = accomplishment_plan;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }
        values.federal_funds_confirmation_document = accomplishment_plan.federal_funds_confirmation_document;
        values.modify_date_label = accomplishment_plan.modify_date_label;
        values.signup_criteria = accomplishment_plan.signup_criteria;

        ormAccomplishmentPlanUpdate({
            id: id,
            activeStep: values.activeStep,
            output_target_shoreline: values.output_target_shoreline,
            output_target_shoreline_amend: values.output_target_shoreline_amend
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    };

    render() {
        const { 
            classes,
            accomplishment_plan,
            handleUnsavedFields,
            ormAccomplishmentPlanUpdateLocalOnly,
            ormAccomplishmentPlanUpdate,
            settings,
            ActionMenu, authState
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || (accomplishment_plan.status === "First Draft" || accomplishment_plan.status === "Approved First Draft" || accomplishment_plan.status === "Started Request for Amendment Change"))
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        const SHOW_NATIVE_PRAIRIE_TABLE = (
            getValue(accomplishment_plan, "output_acres_resourcetype_restore_prairie") > 0 ||
            getValue(accomplishment_plan, "output_acres_resourcetype_feepilt_prairie") > 0 ||
            getValue(accomplishment_plan, "output_acres_resourcetype_feewopilt_prairie") > 0 ||
            getValue(accomplishment_plan, "output_acres_resourcetype_easement_prairie") > 0 ||
            getValue(accomplishment_plan, "output_acres_resourcetype_enhance_prairie") > 0
        );

        var temp;
        if (accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") {
            temp = parseFloat(accomplishment_plan.funds_recommended.replace(/,/g, '')) - parseFloat(accomplishment_plan.output_dollars_resourcetype_total_total_amend);
            if (temp !== accomplishment_plan.formData._t2) {
                accomplishment_plan.formData._t2 = temp;
                this.parentFormKey = window.performance.now();
            }
    
            temp = parseFloat(accomplishment_plan.funds_recommended.replace(/,/g, '')) - parseFloat(accomplishment_plan.output_dollars_ecologicalsection_total_total_amend);
            if (temp !== accomplishment_plan.formData._t4) {
                accomplishment_plan.formData._t4 = temp;
                this.parentFormKey = window.performance.now();
            }
        } else {
            temp = parseFloat(accomplishment_plan.funds_recommended.replace(/,/g, '')) - parseFloat(accomplishment_plan.output_dollars_resourcetype_total_total);
            if (temp !== accomplishment_plan.formData._t2) {
                accomplishment_plan.formData._t2 = temp;
                this.parentFormKey = window.performance.now();
            }
    
            temp = parseFloat(accomplishment_plan.funds_recommended.replace(/,/g, '')) - parseFloat(accomplishment_plan.output_dollars_ecologicalsection_total_total);
            if (temp !== accomplishment_plan.formData._t4) {
                accomplishment_plan.formData._t4 = temp;
                this.parentFormKey = window.performance.now();
            }
        }

        return (
            <Form
                getApi={el => (this.form = el)}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={accomplishment_plan.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateAP(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HelpLabel
                                    inputLabel="Output Tables"
                                    showLabel={true}
                                    title={true}
                                    helpText={settings.output_tables_tab_help}
                                />
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3" gutterBottom>Acres by Resource Type (Table 1)</Typography>
                                <div style={{overflowX: "auto"}}>
                                    {(accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") ? (
                                        <OutputTableAcresByResourceAmendment
                                            ormAccomplishmentPlanUpdate={ormAccomplishmentPlanUpdate} 
                                            accomplishment_plan={accomplishment_plan}
                                            is_read_only={is_read_only}
                                            classes={classes}
                                            ormAccomplishmentPlanUpdateLocalOnly={ormAccomplishmentPlanUpdateLocalOnly}
                                        />
                                    ) : (
                                        <OutputTableAcresByResource
                                            ormAccomplishmentPlanUpdate={ormAccomplishmentPlanUpdate} 
                                            accomplishment_plan={accomplishment_plan}
                                            is_read_only={is_read_only}
                                            classes={classes}
                                            ormAccomplishmentPlanUpdateLocalOnly={ormAccomplishmentPlanUpdateLocalOnly}
                                        />
                                    )}
                                </div>
                            </Grid>
                            {SHOW_NATIVE_PRAIRIE_TABLE && (
                                <Subquestion component={
                                    <>
                                    <Typography variant="h3" gutterBottom>How many of these Prairie acres are Native Prairie? (Table 1b)</Typography>
                                    {(accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") ? (
                                        <OutputTableNativePrairieAmendment
                                            ormAccomplishmentPlanUpdate={ormAccomplishmentPlanUpdate} 
                                            accomplishment_plan={accomplishment_plan}
                                            is_read_only={is_read_only}
                                            classes={classes}
                                            ormAccomplishmentPlanUpdateLocalOnly={ormAccomplishmentPlanUpdateLocalOnly}
                                        />
                                    ) : (
                                        <OutputTableNativePrairie
                                            ormAccomplishmentPlanUpdate={ormAccomplishmentPlanUpdate} 
                                            accomplishment_plan={accomplishment_plan}
                                            is_read_only={is_read_only}
                                            classes={classes}
                                            ormAccomplishmentPlanUpdateLocalOnly={ormAccomplishmentPlanUpdateLocalOnly}
                                        />
                                    )}
                                    </>
                                } />
                            )}
                            <Grid item xs={12}>
                                <table style={{width:"100%"}}>
                                    <tr>
                                        <td>
                                            <Typography style={{marginBottom:-20}} variant="h3">Total Request Funding by Resource Type (Table 2)</Typography>
                                        </td>
                                        <td>
                                            <Form
                                                key={this.parentFormKey}
                                                dontValidateOnMount={true}
                                                validateOnSubmit={true}
                                                defaultValues={accomplishment_plan.formData}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <div style={{float:"right",marginTop:-20}}>
                                                            <TextField
                                                                disabled={true}
                                                                field="funds_recommended" useTextFormat
                                                                className={classes.widthSixty}
                                                            >
                                                                <i style={{textAlign:'center',display:"block"}}>Budget Total</i>
                                                            </TextField>
                                                            <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>-</span>
                                                            <TextField
                                                                disabled={true}
                                                                field={(accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") ? "output_dollars_resourcetype_total_total_amend" : "output_dollars_resourcetype_total_total"} 
                                                                useTextFormat
                                                                className={classes.widthSixty}
                                                            >
                                                                <i style={{textAlign:'center',display:"block"}}>Amount allocated in table</i>
                                                            </TextField>
                                                            <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>=</span>
                                                            <TextField
                                                                disabled={true}
                                                                field="_t2" useTextFormat
                                                                color={accomplishment_plan.formData._t2 === 0 ? "green" : accomplishment_plan.formData._t2 > 0 ? "yellow" : "red"}
                                                                className={classes.widthSixty}
                                                            >
                                                                <i style={{textAlign:'center',display:"block"}}>Balance left to allocate</i>
                                                            </TextField>
                                                        </div>
                                                    </form>
                                                )}
                                            </Form>
                                        </td>
                                    </tr>
                                </table>
                                <div style={{overflowX: "auto"}}>
                                    {(accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") ? (
                                        <OutputTableDollarsByResourceAmendment
                                            ormAccomplishmentPlanUpdate={ormAccomplishmentPlanUpdate} 
                                            accomplishment_plan={accomplishment_plan}
                                            is_read_only={is_read_only}
                                            classes={classes}
                                            ormAccomplishmentPlanUpdateLocalOnly={ormAccomplishmentPlanUpdateLocalOnly}
                                        />
                                    ) : (
                                        <OutputTableDollarsByResource
                                            ormAccomplishmentPlanUpdate={ormAccomplishmentPlanUpdate} 
                                            accomplishment_plan={accomplishment_plan}
                                            is_read_only={is_read_only}
                                            classes={classes}
                                            ormAccomplishmentPlanUpdateLocalOnly={ormAccomplishmentPlanUpdateLocalOnly}
                                        />
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3" gutterBottom>Acres within each Ecological Section (Table 3)</Typography>
                                <div style={{overflowX: "auto"}}>
                                    {(accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") ? (
                                        <OutputTableAcresBySectionAmendment
                                            ormAccomplishmentPlanUpdate={ormAccomplishmentPlanUpdate} 
                                            accomplishment_plan={accomplishment_plan}
                                            is_read_only={is_read_only}
                                            classes={classes}
                                            ormAccomplishmentPlanUpdateLocalOnly={ormAccomplishmentPlanUpdateLocalOnly}
                                        />
                                    ) : (
                                        <OutputTableAcresBySection
                                            ormAccomplishmentPlanUpdate={ormAccomplishmentPlanUpdate} 
                                            accomplishment_plan={accomplishment_plan}
                                            is_read_only={is_read_only}
                                            classes={classes}
                                            ormAccomplishmentPlanUpdateLocalOnly={ormAccomplishmentPlanUpdateLocalOnly}
                                        />
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <table style={{width:"100%"}}>
                                    <tr>
                                        <td>
                                            <Typography style={{marginBottom:-20}} variant="h3">Total Requested Funding within each Ecological Section (Table 4)</Typography>
                                        </td>
                                        <td>
                                            <Form
                                                key={this.parentFormKey}
                                                dontValidateOnMount={true}
                                                validateOnSubmit={true}
                                                defaultValues={accomplishment_plan.formData}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <div style={{float:"right",marginTop:-20}}>
                                                            <TextField
                                                                disabled={true}
                                                                field="funds_recommended" useTextFormat
                                                                className={classes.widthSixty}
                                                            >
                                                                <i style={{textAlign:'center',display:"block"}}>Budget Total</i>
                                                            </TextField>
                                                            <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>-</span>
                                                            <TextField
                                                                disabled={true}
                                                                field={(accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") ? "output_dollars_ecologicalsection_total_total_amend" : "output_dollars_ecologicalsection_total_total"} 
                                                                useTextFormat
                                                                className={classes.widthSixty}
                                                            >
                                                                <i style={{textAlign:'center',display:"block"}}>Amount allocated in table</i>
                                                            </TextField>
                                                            <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>=</span>
                                                            <TextField
                                                                disabled={true}
                                                                field="_t4" useTextFormat
                                                                color={accomplishment_plan.formData._t4 === 0 ? "green" : accomplishment_plan.formData._t4 > 0 ? "yellow" : "red"}
                                                                className={classes.widthSixty}
                                                            >
                                                                <i style={{textAlign:'center',display:"block"}}>Balance left to allocate</i>
                                                            </TextField>
                                                        </div>
                                                    </form>
                                                )}
                                            </Form>
                                        </td>
                                    </tr>
                                </table>
                                <div style={{overflowX: "auto"}}>
                                    {(accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") ? (
                                        <OutputTableDollarsBySectionAmendment
                                            ormAccomplishmentPlanUpdate={ormAccomplishmentPlanUpdate} 
                                            accomplishment_plan={accomplishment_plan}
                                            classes={classes}
                                            is_read_only={is_read_only}
                                            ormAccomplishmentPlanUpdateLocalOnly={ormAccomplishmentPlanUpdateLocalOnly}
                                        />
                                    ) : (
                                        <OutputTableDollarsBySection
                                            ormAccomplishmentPlanUpdate={ormAccomplishmentPlanUpdate} 
                                            accomplishment_plan={accomplishment_plan}
                                            classes={classes}
                                            is_read_only={is_read_only}
                                            ormAccomplishmentPlanUpdateLocalOnly={ormAccomplishmentPlanUpdateLocalOnly}
                                        />
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    disabled={accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change" || is_read_only} 
                                    field="output_target_shoreline" 
                                    label="Target Lake/Stream/River (original Feet or Miles)"
                                />
                            </Grid>
                            {(accomplishment_plan.status === "Started Request for Amendment Change" || accomplishment_plan.status === "Submitted Request for Amendment Change") && (
                                <Grid item xs={12}>
                                    <TextField disabled={is_read_only} field="output_target_shoreline_amend" label="Target Lake/Stream/River (new Feet or Miles)" />
                                </Grid>
                            )}
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Outcomes <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

class OutputTableAcresByResource extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element, row) => {
        const { accomplishment_plan } = this.props;

        value = parseInt(value) || 0;

        // Get Row Total
        var total = (parseInt(row.wetland === element ? value : getValue(accomplishment_plan, row.wetland)) || 0) + 
        (parseInt(row.prairie === element ? value : getValue(accomplishment_plan, row.prairie)) || 0) + 
        (parseInt(row.forest === element ? value : getValue(accomplishment_plan, row.forest)) || 0) + 
        (parseInt(row.habitat === element ? value : getValue(accomplishment_plan, row.habitat)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_wetland")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_wetland";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.wetland === element ? value : getValue(accomplishment_plan, r.wetland)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_prairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie === element ? value : getValue(accomplishment_plan, r.prairie)) || 0)
            });
        } else if (element.includes("_forest")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_forest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forest === element ? value : getValue(accomplishment_plan, r.forest)) || 0)
            });
        } else if (element.includes("_habitat")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_habitat";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.habitat === element ? value : getValue(accomplishment_plan, r.habitat)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_wetland")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_forest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_forest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_forest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_habitat")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_forest")) || 0);
        }

        return {
            id: accomplishment_plan.id,
            [element]: value,
            [row.total]: total,
            [updateField]: totalCol,
            output_acres_resourcetype_total_total: finalTotal
        };
    }

    updateRecord = (value, element, row) => {        
        const { ormAccomplishmentPlanUpdate, ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormAccomplishmentPlanUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormAccomplishmentPlanUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { accomplishment_plan, classes, is_read_only } = this.props;
        const priority_resources_activity = getValue(accomplishment_plan, "priority_resources_activity");
        var columnData = [
            { id: "type", title: "Type", allowSort: false }
        ];
        if (priority_resources_activity.indexOf("Wetlands") > -1) {
            columnData.push({ id: "wetland", label: "Wetland (acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Forest") > -1) {
            columnData.push({ id: "forest", label: "Forest (acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Habitat") > -1) {
            columnData.push({ id: "habitat", label: "Habitat (acres)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (acres)", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(accomplishment_plan, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    wetland: "output_acres_resourcetype_restore_wetland",
                    prairie: "output_acres_resourcetype_restore_prairie",
                    forest: "output_acres_resourcetype_restore_forest",
                    habitat: "output_acres_resourcetype_restore_habitat",
                    total: "output_acres_resourcetype_restore_total"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    wetland: "output_acres_resourcetype_feepilt_wetland",
                    prairie: "output_acres_resourcetype_feepilt_prairie",
                    forest: "output_acres_resourcetype_feepilt_forest",
                    habitat: "output_acres_resourcetype_feepilt_habitat",
                    total: "output_acres_resourcetype_feepilt_total"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    wetland: "output_acres_resourcetype_feewopilt_wetland",
                    prairie: "output_acres_resourcetype_feewopilt_prairie",
                    forest: "output_acres_resourcetype_feewopilt_forest",
                    habitat: "output_acres_resourcetype_feewopilt_habitat",
                    total: "output_acres_resourcetype_feewopilt_total"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    wetland: "output_acres_resourcetype_easement_wetland",
                    prairie: "output_acres_resourcetype_easement_prairie",
                    forest: "output_acres_resourcetype_easement_forest",
                    habitat: "output_acres_resourcetype_easement_habitat",
                    total: "output_acres_resourcetype_easement_total"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    wetland: "output_acres_resourcetype_enhance_wetland",
                    prairie: "output_acres_resourcetype_enhance_prairie",
                    forest: "output_acres_resourcetype_enhance_forest",
                    habitat: "output_acres_resourcetype_enhance_habitat",
                    total: "output_acres_resourcetype_enhance_total"
                });
            }
            this.rows.push({
                type: "Total",
                wetland: "output_acres_resourcetype_total_wetland",
                prairie: "output_acres_resourcetype_total_prairie",
                forest: "output_acres_resourcetype_total_forest",
                habitat: "output_acres_resourcetype_total_habitat",
                total: "output_acres_resourcetype_total_total",
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {priority_resources_activity.indexOf("Wetlands") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.wetland))}</b>
                                        ) : (
                                            <Form key={n.wetland} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.wetland} label=""
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Prairie") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.prairie))}</b>
                                        ) : (
                                            <Form key={n.prairie} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie} label=""
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.forest))}</b>
                                        ) : (
                                            <Form key={n.forest} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forest} label=""
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Habitat") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.habitat))}</b>
                                        ) : (
                                            <Form key={n.habitat} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.habitat} label=""
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                <CustomTableCell>
                                    <b>{acresRender(getValue(accomplishment_plan, n.total))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableAcresByResourceAmendment extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element, row) => {
        const { accomplishment_plan } = this.props;

        value = parseInt(value) || 0;

        // Get Row Total
        var total = (parseInt(row.wetland_amend === element ? value : getValue(accomplishment_plan, row.wetland_amend)) || 0) + 
        (parseInt(row.prairie_amend === element ? value : getValue(accomplishment_plan, row.prairie_amend)) || 0) + 
        (parseInt(row.forest_amend === element ? value : getValue(accomplishment_plan, row.forest_amend)) || 0) + 
        (parseInt(row.habitat_amend === element ? value : getValue(accomplishment_plan, row.habitat_amend)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_wetland")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_wetland_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.wetland_amend === element ? value : getValue(accomplishment_plan, r.wetland_amend)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_prairie_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_amend === element ? value : getValue(accomplishment_plan, r.prairie_amend)) || 0)
            });
        } else if (element.includes("_forest")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_forest_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forest_amend === element ? value : getValue(accomplishment_plan, r.forest_amend)) || 0)
            });
        } else if (element.includes("_habitat")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_habitat_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.habitat_amend === element ? value : getValue(accomplishment_plan, r.habitat_amend)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_wetland")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_forest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_habitat_amend")) || 0);
        }
        if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_wetland_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_forest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_habitat_amend")) || 0);
        }
        if (element.includes("_forest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_wetland_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_habitat_amend")) || 0);
        }
        if (element.includes("_habitat")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_wetland_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_resourcetype_total_forest_amend")) || 0);
        }

        return {
            id: accomplishment_plan.id,
            [element]: value,
            [row.total_amend]: total,
            [updateField]: totalCol,
            output_acres_resourcetype_total_total_amend: finalTotal
        };
    }

    updateRecord = (value, element, row) => {        
        const { ormAccomplishmentPlanUpdate, ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormAccomplishmentPlanUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormAccomplishmentPlanUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { accomplishment_plan, classes, is_read_only } = this.props;
        const priority_resources_activity = getValue(accomplishment_plan, "priority_resources_activity");
        var columnData = [
            { id: "type", title: "Type", allowSort: false }
        ];
        if (priority_resources_activity.indexOf("Wetlands") > -1) {
            columnData.push({ id: "wetland", label: "Wetland (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "wetland_amend", label: "Wetland (new acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "prairie_amend", label: "Prairie (new acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Forest") > -1) {
            columnData.push({ id: "forest", label: "Forest (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "forest_amend", label: "Forest (new acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Habitat") > -1) {
            columnData.push({ id: "habitat", label: "Habitat (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "habitat_amend", label: "Habitat (new acres)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (original acres)", numeric: true, allowSort: false });
        columnData.push({ id: "total_amend", label: "Total (new acres)", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(accomplishment_plan, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    wetland: "output_acres_resourcetype_restore_wetland",
                    prairie: "output_acres_resourcetype_restore_prairie",
                    forest: "output_acres_resourcetype_restore_forest",
                    habitat: "output_acres_resourcetype_restore_habitat",
                    total: "output_acres_resourcetype_restore_total",
                    wetland_amend: "output_acres_resourcetype_restore_wetland_amend",
                    prairie_amend: "output_acres_resourcetype_restore_prairie_amend",
                    forest_amend: "output_acres_resourcetype_restore_forest_amend",
                    habitat_amend: "output_acres_resourcetype_restore_habitat_amend",
                    total_amend: "output_acres_resourcetype_restore_total_amend"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    wetland: "output_acres_resourcetype_feepilt_wetland",
                    prairie: "output_acres_resourcetype_feepilt_prairie",
                    forest: "output_acres_resourcetype_feepilt_forest",
                    habitat: "output_acres_resourcetype_feepilt_habitat",
                    total: "output_acres_resourcetype_feepilt_total",
                    wetland_amend: "output_acres_resourcetype_feepilt_wetland_amend",
                    prairie_amend: "output_acres_resourcetype_feepilt_prairie_amend",
                    forest_amend: "output_acres_resourcetype_feepilt_forest_amend",
                    habitat_amend: "output_acres_resourcetype_feepilt_habitat_amend",
                    total_amend: "output_acres_resourcetype_feepilt_total_amend"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    wetland: "output_acres_resourcetype_feewopilt_wetland",
                    prairie: "output_acres_resourcetype_feewopilt_prairie",
                    forest: "output_acres_resourcetype_feewopilt_forest",
                    habitat: "output_acres_resourcetype_feewopilt_habitat",
                    total: "output_acres_resourcetype_feewopilt_total",
                    wetland_amend: "output_acres_resourcetype_feewopilt_wetland_amend",
                    prairie_amend: "output_acres_resourcetype_feewopilt_prairie_amend",
                    forest_amend: "output_acres_resourcetype_feewopilt_forest_amend",
                    habitat_amend: "output_acres_resourcetype_feewopilt_habitat_amend",
                    total_amend: "output_acres_resourcetype_feewopilt_total_amend"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    wetland: "output_acres_resourcetype_easement_wetland",
                    prairie: "output_acres_resourcetype_easement_prairie",
                    forest: "output_acres_resourcetype_easement_forest",
                    habitat: "output_acres_resourcetype_easement_habitat",
                    total: "output_acres_resourcetype_easement_total",
                    wetland_amend: "output_acres_resourcetype_easement_wetland_amend",
                    prairie_amend: "output_acres_resourcetype_easement_prairie_amend",
                    forest_amend: "output_acres_resourcetype_easement_forest_amend",
                    habitat_amend: "output_acres_resourcetype_easement_habitat_amend",
                    total_amend: "output_acres_resourcetype_easement_total_amend"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    wetland: "output_acres_resourcetype_enhance_wetland",
                    prairie: "output_acres_resourcetype_enhance_prairie",
                    forest: "output_acres_resourcetype_enhance_forest",
                    habitat: "output_acres_resourcetype_enhance_habitat",
                    total: "output_acres_resourcetype_enhance_total",
                    wetland_amend: "output_acres_resourcetype_enhance_wetland_amend",
                    prairie_amend: "output_acres_resourcetype_enhance_prairie_amend",
                    forest_amend: "output_acres_resourcetype_enhance_forest_amend",
                    habitat_amend: "output_acres_resourcetype_enhance_habitat_amend",
                    total_amend: "output_acres_resourcetype_enhance_total_amend"
                });
            }
            this.rows.push({
                type: "Total",
                wetland: "output_acres_resourcetype_total_wetland",
                prairie: "output_acres_resourcetype_total_prairie",
                forest: "output_acres_resourcetype_total_forest",
                habitat: "output_acres_resourcetype_total_habitat",
                total: "output_acres_resourcetype_total_total",
                wetland_amend: "output_acres_resourcetype_total_wetland_amend",
                prairie_amend: "output_acres_resourcetype_total_prairie_amend",
                forest_amend: "output_acres_resourcetype_total_forest_amend",
                habitat_amend: "output_acres_resourcetype_total_habitat_amend",
                total_amend: "output_acres_resourcetype_total_total_amend"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {priority_resources_activity.indexOf("Wetlands") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.wetland))}</b>
                                        ) : (
                                            <Form key={n.wetland} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useNumber tableFormat field={n.wetland} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.wetland_amend))}</b>
                                        ) : (
                                            <Form key={n.wetland_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.wetland_amend} label=""
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.prairie))}</b>
                                        ) : (
                                            <Form key={n.prairie} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useNumber tableFormat field={n.prairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.prairie_amend))}</b>
                                        ) : (
                                            <Form key={n.prairie_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_amend} label=""
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.forest))}</b>
                                        ) : (
                                            <Form key={n.forest} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useNumber tableFormat field={n.forest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.forest_amend))}</b>
                                        ) : (
                                            <Form key={n.forest_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forest_amend} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Habitat") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.habitat))}</b>
                                        ) : (
                                            <Form key={n.habitat} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useNumber tableFormat field={n.habitat} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.habitat_amend))}</b>
                                        ) : (
                                            <Form key={n.habitat_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.habitat_amend} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{acresRender(getValue(accomplishment_plan, n.total))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{acresRender(getValue(accomplishment_plan, n.total_amend))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableNativePrairie extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element) => {
        const { accomplishment_plan } = this.props;

        value = parseInt(value) || 0;

        // Get Column Total
        var totalCol = 0;
        this.rows.forEach(function(r) {
            if (r.type !== "Total")
                totalCol += (parseInt(r.prairie === element ? value : getValue(accomplishment_plan, r.prairie)) || 0)
        });

        return {
            id: accomplishment_plan.id,
            [element]: value,
            output_acres_resourcetype_total_nativeprairie: totalCol,
        };
    }

    updateRecord = (value, element, row) => {
        const { ormAccomplishmentPlanUpdate, ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormAccomplishmentPlanUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormAccomplishmentPlanUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { classes, accomplishment_plan, is_read_only } = this.props;
        var columnData = [
            { id: "type", label: "Type", allowSort: false },
            { id: "prairie", label: "Native Prairie (acres)", numeric: true, allowSort: false }
        ];
        if (this.rows.length === 0) {
            const activity_types = getValue(accomplishment_plan, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    prairie: "output_acres_resourcetype_restore_nativeprairie"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    prairie: "output_acres_resourcetype_feepilt_nativeprairie"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    prairie: "output_acres_resourcetype_feewopilt_nativeprairie"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    prairie: "output_acres_resourcetype_easement_nativeprairie"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    prairie: "output_acres_resourcetype_enhance_nativeprairie"
                });
            }
            this.rows.push({
                type: "Total",
                prairie: "output_acres_resourcetype_total_nativeprairie"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {n.type === "Total" ? (
                                        <b>{acresRender(getValue(accomplishment_plan, n.prairie))}</b>
                                    ) : (
                                        <Form key={n.prairie} defaultValues={accomplishment_plan}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField 
                                                        disabled={is_read_only} useNumber tableFormat 
                                                        eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                        field={n.prairie} label="" className={classes.minWidth}
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    )}
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableNativePrairieAmendment extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element) => {
        const { accomplishment_plan } = this.props;

        value = parseInt(value) || 0;

        // Get Column Total
        var totalCol = 0;
        this.rows.forEach(function(r) {
            if (r.type !== "Total")
                totalCol += (parseInt(r.prairie_amend === element ? value : getValue(accomplishment_plan, r.prairie_amend)) || 0)
        });

        return {
            id: accomplishment_plan.id,
            [element]: value,
            output_acres_resourcetype_total_nativeprairie_amend: totalCol,
        };
    }

    updateRecord = (value, element, row) => {
        const { ormAccomplishmentPlanUpdate, ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormAccomplishmentPlanUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormAccomplishmentPlanUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { classes, accomplishment_plan, is_read_only } = this.props;
        var columnData = [
            { id: "type", label: "Type", allowSort: false },
            { id: "prairie", label: "Native Prairie (original acres)", numeric: true, allowSort: false },
            { id: "prairie_amend", label: "Native Prairie (new acres)", numeric: true, allowSort: false }
        ];
        if (this.rows.length === 0) {
            const activity_types = getValue(accomplishment_plan, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    prairie: "output_acres_resourcetype_restore_nativeprairie",
                    prairie_amend: "output_acres_resourcetype_restore_nativeprairie_amend"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    prairie: "output_acres_resourcetype_feepilt_nativeprairie",
                    prairie_amend: "output_acres_resourcetype_feepilt_nativeprairie_amend"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    prairie: "output_acres_resourcetype_feewopilt_nativeprairie",
                    prairie_amend: "output_acres_resourcetype_feewopilt_nativeprairie_amend"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    prairie: "output_acres_resourcetype_easement_nativeprairie",
                    prairie_amend: "output_acres_resourcetype_easement_nativeprairie_amend"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    prairie: "output_acres_resourcetype_enhance_nativeprairie",
                    prairie_amend: "output_acres_resourcetype_enhance_nativeprairie_amend"
                });
            }
            this.rows.push({
                type: "Total",
                prairie: "output_acres_resourcetype_total_nativeprairie",
                prairie_amend: "output_acres_resourcetype_total_nativeprairie_amend"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {n.type === "Total" ? (
                                        <b>{acresRender(getValue(accomplishment_plan, n.prairie))}</b>
                                    ) : (
                                        <Form key={n.prairie} defaultValues={accomplishment_plan}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField className={classes.minWidth} disabled={true} useNumber tableFormat field={n.prairie} label="" />
                                                </form>
                                            )}
                                        </Form>
                                    )}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {n.type === "Total" ? (
                                        <b>{acresRender(getValue(accomplishment_plan, n.prairie_amend))}</b>
                                    ) : (
                                        <Form key={n.prairie_amend} defaultValues={accomplishment_plan}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField 
                                                        disabled={is_read_only} useNumber tableFormat 
                                                        eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                        field={n.prairie_amend} label="" className={classes.minWidth}
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    )}
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableDollarsByResource extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};
    rows = [];

    getValues = (value, element, row) => {
        const { accomplishment_plan } = this.props;

        value = (Math.round(parseInt(value) / 100) * 100) || 0;

        // Get Row Total
        var total = (parseInt(row.wetland === element ? value : getValue(accomplishment_plan, row.wetland)) || 0) + 
        (parseInt(row.prairie === element ? value : getValue(accomplishment_plan, row.prairie)) || 0) + 
        (parseInt(row.forest === element ? value : getValue(accomplishment_plan, row.forest)) || 0) + 
        (parseInt(row.habitat === element ? value : getValue(accomplishment_plan, row.habitat)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_wetland")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_wetland";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.wetland === element ? value : getValue(accomplishment_plan, r.wetland)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_prairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie === element ? value : getValue(accomplishment_plan, r.prairie)) || 0)
            });
        } else if (element.includes("_forest")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_forest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forest === element ? value : getValue(accomplishment_plan, r.forest)) || 0)
            });
        } else if (element.includes("_habitat")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_habitat";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.habitat === element ? value : getValue(accomplishment_plan, r.habitat)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_wetland")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_forest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_forest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_forest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_habitat")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_forest")) || 0);
        }

        return {
            id: accomplishment_plan.id,
            [element]: value,
            [row.total]: total,
            [updateField]: totalCol,
            output_dollars_resourcetype_total_total: finalTotal
        };
    }

    updateRecord = (value, element, row) => {        
        const { ormAccomplishmentPlanUpdate, ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormAccomplishmentPlanUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormAccomplishmentPlanUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, accomplishment_plan, is_read_only } = this.props;
        const priority_resources_activity = getValue(accomplishment_plan, "priority_resources_activity");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (priority_resources_activity.indexOf("Wetlands") > -1) {
            columnData.push({ id: "wetland", label: "Wetland ($)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie ($)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Forest") > -1) {
            columnData.push({ id: "forest", label: "Forest ($)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Habitat") > -1) {
            columnData.push({ id: "habitat", label: "Habitat ($)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total ($)", type: "numeric", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(accomplishment_plan, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    wetland: "output_dollars_resourcetype_restore_wetland",
                    prairie: "output_dollars_resourcetype_restore_prairie",
                    forest: "output_dollars_resourcetype_restore_forest",
                    habitat: "output_dollars_resourcetype_restore_habitat",
                    total: "output_dollars_resourcetype_restore_total"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    wetland: "output_dollars_resourcetype_feepilt_wetland",
                    prairie: "output_dollars_resourcetype_feepilt_prairie",
                    forest: "output_dollars_resourcetype_feepilt_forest",
                    habitat: "output_dollars_resourcetype_feepilt_habitat",
                    total: "output_dollars_resourcetype_feepilt_total"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    wetland: "output_dollars_resourcetype_feewopilt_wetland",
                    prairie: "output_dollars_resourcetype_feewopilt_prairie",
                    forest: "output_dollars_resourcetype_feewopilt_forest",
                    habitat: "output_dollars_resourcetype_feewopilt_habitat",
                    total: "output_dollars_resourcetype_feewopilt_total"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    wetland: "output_dollars_resourcetype_easement_wetland",
                    prairie: "output_dollars_resourcetype_easement_prairie",
                    forest: "output_dollars_resourcetype_easement_forest",
                    habitat: "output_dollars_resourcetype_easement_habitat",
                    total: "output_dollars_resourcetype_easement_total"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    wetland: "output_dollars_resourcetype_enhance_wetland",
                    prairie: "output_dollars_resourcetype_enhance_prairie",
                    forest: "output_dollars_resourcetype_enhance_forest",
                    habitat: "output_dollars_resourcetype_enhance_habitat",
                    total: "output_dollars_resourcetype_enhance_total"
                });
            }
            this.rows.push({
                type: "Total",
                wetland: "output_dollars_resourcetype_total_wetland",
                prairie: "output_dollars_resourcetype_total_prairie",
                forest: "output_dollars_resourcetype_total_forest",
                habitat: "output_dollars_resourcetype_total_habitat",
                total: "output_dollars_resourcetype_total_total"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {priority_resources_activity.indexOf("Wetlands") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.wetland))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.wetland] ? this.formkeys[n.wetland]  : n.wetland} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.wetland} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Prairie") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.prairie))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.prairie] ? this.formkeys[n.prairie]  : n.prairie} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.forest))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.forest] ? this.formkeys[n.forest]  : n.forest} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forest} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Habitat") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.habitat))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.habitat] ? this.formkeys[n.habitat]  : n.habitat} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.habitat} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(accomplishment_plan, n.total))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableDollarsByResourceAmendment extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};
    rows = [];

    getValues = (value, element, row) => {
        const { accomplishment_plan } = this.props;

        value = (Math.round(parseInt(value) / 100) * 100) || 0;

        // Get Row Total
        var total = (parseInt(row.wetland_amend === element ? value : getValue(accomplishment_plan, row.wetland_amend)) || 0) + 
        (parseInt(row.prairie_amend === element ? value : getValue(accomplishment_plan, row.prairie_amend)) || 0) + 
        (parseInt(row.forest_amend === element ? value : getValue(accomplishment_plan, row.forest_amend)) || 0) + 
        (parseInt(row.habitat_amend === element ? value : getValue(accomplishment_plan, row.habitat_amend)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_wetland")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_wetland_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.wetland_amend === element ? value : getValue(accomplishment_plan, r.wetland_amend)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_prairie_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_amend === element ? value : getValue(accomplishment_plan, r.prairie_amend)) || 0)
            });
        } else if (element.includes("_forest")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_forest_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forest_amend === element ? value : getValue(accomplishment_plan, r.forest_amend)) || 0)
            });
        } else if (element.includes("_habitat")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_habitat_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.habitat_amend === element ? value : getValue(accomplishment_plan, r.habitat_amend)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_wetland")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_forest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_habitat_amend")) || 0);
        }
        if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_wetland_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_forest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_habitat_amend")) || 0);
        }
        if (element.includes("_forest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_wetland_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_habitat_amend")) || 0);
        }
        if (element.includes("_habitat")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_wetland_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_resourcetype_total_forest_amend")) || 0);
        }

        return {
            id: accomplishment_plan.id,
            [element]: value,
            [row.total_amend]: total,
            [updateField]: totalCol,
            output_dollars_resourcetype_total_total_amend: finalTotal
        };
    }

    updateRecord = (value, element, row) => {        
        const { ormAccomplishmentPlanUpdate, ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormAccomplishmentPlanUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormAccomplishmentPlanUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, accomplishment_plan, is_read_only } = this.props;
        const priority_resources_activity = getValue(accomplishment_plan, "priority_resources_activity");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (priority_resources_activity.indexOf("Wetlands") > -1) {
            columnData.push({ id: "wetland", label: "Wetland (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "wetland_amend", label: "Wetland (new $)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "prairie_amend", label: "Prairie (new $)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Forest") > -1) {
            columnData.push({ id: "forest", label: "Forest (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "forest_amend", label: "Forest (new $)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Habitat") > -1) {
            columnData.push({ id: "habitat", label: "Habitat (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "habitat_amend", label: "Habitat (new $)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (original $)", type: "numeric", numeric: true, allowSort: false });
        columnData.push({ id: "total_amend", label: "Total (new $)", type: "numeric", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(accomplishment_plan, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    wetland: "output_dollars_resourcetype_restore_wetland",
                    prairie: "output_dollars_resourcetype_restore_prairie",
                    forest: "output_dollars_resourcetype_restore_forest",
                    habitat: "output_dollars_resourcetype_restore_habitat",
                    total: "output_dollars_resourcetype_restore_total",
                    wetland_amend: "output_dollars_resourcetype_restore_wetland_amend",
                    prairie_amend: "output_dollars_resourcetype_restore_prairie_amend",
                    forest_amend: "output_dollars_resourcetype_restore_forest_amend",
                    habitat_amend: "output_dollars_resourcetype_restore_habitat_amend",
                    total_amend: "output_dollars_resourcetype_restore_total_amend"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    wetland: "output_dollars_resourcetype_feepilt_wetland",
                    prairie: "output_dollars_resourcetype_feepilt_prairie",
                    forest: "output_dollars_resourcetype_feepilt_forest",
                    habitat: "output_dollars_resourcetype_feepilt_habitat",
                    total: "output_dollars_resourcetype_feepilt_total",
                    wetland_amend: "output_dollars_resourcetype_feepilt_wetland_amend",
                    prairie_amend: "output_dollars_resourcetype_feepilt_prairie_amend",
                    forest_amend: "output_dollars_resourcetype_feepilt_forest_amend",
                    habitat_amend: "output_dollars_resourcetype_feepilt_habitat_amend",
                    total_amend: "output_dollars_resourcetype_feepilt_total_amend"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    wetland: "output_dollars_resourcetype_feewopilt_wetland",
                    prairie: "output_dollars_resourcetype_feewopilt_prairie",
                    forest: "output_dollars_resourcetype_feewopilt_forest",
                    habitat: "output_dollars_resourcetype_feewopilt_habitat",
                    total: "output_dollars_resourcetype_feewopilt_total",
                    wetland_amend: "output_dollars_resourcetype_feewopilt_wetland_amend",
                    prairie_amend: "output_dollars_resourcetype_feewopilt_prairie_amend",
                    forest_amend: "output_dollars_resourcetype_feewopilt_forest_amend",
                    habitat_amend: "output_dollars_resourcetype_feewopilt_habitat_amend",
                    total_amend: "output_dollars_resourcetype_feewopilt_total_amend"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    wetland: "output_dollars_resourcetype_easement_wetland",
                    prairie: "output_dollars_resourcetype_easement_prairie",
                    forest: "output_dollars_resourcetype_easement_forest",
                    habitat: "output_dollars_resourcetype_easement_habitat",
                    total: "output_dollars_resourcetype_easement_total",
                    wetland_amend: "output_dollars_resourcetype_easement_wetland_amend",
                    prairie_amend: "output_dollars_resourcetype_easement_prairie_amend",
                    forest_amend: "output_dollars_resourcetype_easement_forest_amend",
                    habitat_amend: "output_dollars_resourcetype_easement_habitat_amend",
                    total_amend: "output_dollars_resourcetype_easement_total_amend"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    wetland: "output_dollars_resourcetype_enhance_wetland",
                    prairie: "output_dollars_resourcetype_enhance_prairie",
                    forest: "output_dollars_resourcetype_enhance_forest",
                    habitat: "output_dollars_resourcetype_enhance_habitat",
                    total: "output_dollars_resourcetype_enhance_total",
                    wetland_amend: "output_dollars_resourcetype_enhance_wetland_amend",
                    prairie_amend: "output_dollars_resourcetype_enhance_prairie_amend",
                    forest_amend: "output_dollars_resourcetype_enhance_forest_amend",
                    habitat_amend: "output_dollars_resourcetype_enhance_habitat_amend",
                    total_amend: "output_dollars_resourcetype_enhance_total_amend"
                });
            }
            this.rows.push({
                type: "Total",
                wetland: "output_dollars_resourcetype_total_wetland",
                prairie: "output_dollars_resourcetype_total_prairie",
                forest: "output_dollars_resourcetype_total_forest",
                habitat: "output_dollars_resourcetype_total_habitat",
                total: "output_dollars_resourcetype_total_total",
                wetland_amend: "output_dollars_resourcetype_total_wetland_amend",
                prairie_amend: "output_dollars_resourcetype_total_prairie_amend",
                forest_amend: "output_dollars_resourcetype_total_forest_amend",
                habitat_amend: "output_dollars_resourcetype_total_habitat_amend",
                total_amend: "output_dollars_resourcetype_total_total_amend"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {priority_resources_activity.indexOf("Wetlands") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.wetland))}</b>
                                        ) : (
                                            <Form defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useTextFormat tableFormat field={n.wetland} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.wetland_amend))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.wetland_amend] ? this.formkeys[n.wetland_amend]  : n.wetland_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.wetland_amend} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.prairie))}</b>
                                        ) : (
                                            <Form defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useTextFormat tableFormat field={n.prairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.prairie_amend))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.prairie_amend] ? this.formkeys[n.prairie_amend]  : n.prairie_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_amend} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.forest))}</b>
                                        ) : (
                                            <Form defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useTextFormat tableFormat field={n.forest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.forest_amend))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.forest_amend] ? this.formkeys[n.forest_amend]  : n.forest_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forest_amend} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Habitat") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.habitat))}</b>
                                        ) : (
                                            <Form defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useTextFormat tableFormat field={n.habitat} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.habitat_amend))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.habitat_amend] ? this.formkeys[n.habitat_amend]  : n.habitat_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.habitat_amend} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(accomplishment_plan, n.total))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(accomplishment_plan, n.total_amend))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableAcresBySection extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element, row) => {
        const { accomplishment_plan } = this.props;

        value = parseInt(value) || 0;

        // Get Row Total
        var total = (parseInt(row.metrourban === element ? value : getValue(accomplishment_plan, row.metrourban)) || 0) + 
        (parseInt(row.forestprairie === element ? value : getValue(accomplishment_plan, row.forestprairie)) || 0) + 
        (parseInt(row.seforest === element ? value : getValue(accomplishment_plan, row.seforest)) || 0) + 
        (parseInt(row.prairie === element ? value : getValue(accomplishment_plan, row.prairie)) || 0) + 
        (parseInt(row.northernforest === element ? value : getValue(accomplishment_plan, row.northernforest)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_metrourban")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_metrourban";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.metrourban === element ? value : getValue(accomplishment_plan, r.metrourban)) || 0)
            });
        } else if (element.includes("_forestprairie")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_forestprairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forestprairie === element ? value : getValue(accomplishment_plan, r.forestprairie)) || 0)
            });
        } else if (element.includes("_seforest")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_seforest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.seforest === element ? value : getValue(accomplishment_plan, r.seforest)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_prairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie === element ? value : getValue(accomplishment_plan, r.prairie)) || 0)
            });
        } else if (element.includes("_northernforest")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_northernforest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.northernforest === element ? value : getValue(accomplishment_plan, r.northernforest)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_metrourban")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_forestprairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_seforest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_northernforest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_prairie")) || 0);
        }

        return {
            id: accomplishment_plan.id,
            [element]: value,
            [row.total]: total,
            [updateField]: totalCol,
            output_acres_ecologicalsection_total_total: finalTotal
        };
    };

    updateRecord = (value, element, row) => {        
        const { ormAccomplishmentPlanUpdate, ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormAccomplishmentPlanUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormAccomplishmentPlanUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { classes, accomplishment_plan, is_read_only } = this.props;
        const eco_regions = getValue(accomplishment_plan, "eco_regions");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (eco_regions.indexOf("Metro / Urban") > -1) {
            columnData.push({ id: "metrourban", label: "Metro/Urban (acres)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Forest / Prairie Transition") > -1) {
            columnData.push({ id: "forestprairie", label: "Forest/Prairie (acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Southeast Forest") > -1) {
            columnData.push({ id: "seforest", label: "SE Forest (acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Northern Forest") > -1) {
            columnData.push({ id: "northernforest", label: "Northern Forest (acres)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (acres)", type: "numeric", numeric: true, allowSort: false  });

        if (this.rows.length === 0) {
            const activity_types = getValue(accomplishment_plan, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    metrourban: "output_acres_ecologicalsection_restore_metrourban",
                    forestprairie: "output_acres_ecologicalsection_restore_forestprairie",
                    seforest: "output_acres_ecologicalsection_restore_seforest",
                    prairie: "output_acres_ecologicalsection_restore_prairie",
                    northernforest: "output_acres_ecologicalsection_restore_northernforest",
                    total: "output_acres_ecologicalsection_restore_total"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    metrourban: "output_acres_ecologicalsection_feepilt_metrourban",
                    forestprairie: "output_acres_ecologicalsection_feepilt_forestprairie",
                    seforest: "output_acres_ecologicalsection_feepilt_seforest",
                    prairie: "output_acres_ecologicalsection_feepilt_prairie",
                    northernforest: "output_acres_ecologicalsection_feepilt_northernforest",
                    total: "output_acres_ecologicalsection_feepilt_total"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    metrourban: "output_acres_ecologicalsection_feewopilt_metrourban",
                    forestprairie: "output_acres_ecologicalsection_feewopilt_forestprairie",
                    seforest: "output_acres_ecologicalsection_feewopilt_seforest",
                    prairie: "output_acres_ecologicalsection_feewopilt_prairie",
                    northernforest: "output_acres_ecologicalsection_feewopilt_northernforest",
                    total: "output_acres_ecologicalsection_feewopilt_total"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    metrourban: "output_acres_ecologicalsection_easement_metrourban",
                    forestprairie: "output_acres_ecologicalsection_easement_forestprairie",
                    seforest: "output_acres_ecologicalsection_easement_seforest",
                    prairie: "output_acres_ecologicalsection_easement_prairie",
                    northernforest: "output_acres_ecologicalsection_easement_northernforest",
                    total: "output_acres_ecologicalsection_easement_total"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    metrourban: "output_acres_ecologicalsection_enhance_metrourban",
                    forestprairie: "output_acres_ecologicalsection_enhance_forestprairie",
                    seforest: "output_acres_ecologicalsection_enhance_seforest",
                    prairie: "output_acres_ecologicalsection_enhance_prairie",
                    northernforest: "output_acres_ecologicalsection_enhance_northernforest",
                    total: "output_acres_ecologicalsection_enhance_total"
                });
            }
            this.rows.push({
                type: "Total",
                metrourban: "output_acres_ecologicalsection_total_metrourban",
                forestprairie: "output_acres_ecologicalsection_total_forestprairie",
                seforest: "output_acres_ecologicalsection_total_seforest",
                prairie: "output_acres_ecologicalsection_total_prairie",
                northernforest: "output_acres_ecologicalsection_total_northernforest",
                total: "output_acres_ecologicalsection_total_total"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {eco_regions.indexOf("Metro / Urban") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.metrourban))}</b>
                                        ) : (
                                            <Form key={n.metrourban} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.metrourban} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Forest / Prairie Transition") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.forestprairie))}</b>
                                        ) : (
                                            <Form key={n.forestprairie} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forestprairie} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Southeast Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.seforest))}</b>
                                        ) : (
                                            <Form key={n.seforest} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.seforest} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Prairie") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.prairie))}</b>
                                        ) : (
                                            <Form key={n.prairie} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Northern Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.northernforest))}</b>
                                        ) : (
                                            <Form key={n.northernforest} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.northernforest} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                <CustomTableCell>
                                    <b>{acresRender(getValue(accomplishment_plan, n.total))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableAcresBySectionAmendment extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element, row) => {
        const { accomplishment_plan } = this.props;

        value = parseInt(value) || 0;

        // Get Row Total
        var total = (parseInt(row.metrourban_amend === element ? value : getValue(accomplishment_plan, row.metrourban_amend)) || 0) + 
        (parseInt(row.forestprairie_amend === element ? value : getValue(accomplishment_plan, row.forestprairie_amend)) || 0) + 
        (parseInt(row.seforest_amend === element ? value : getValue(accomplishment_plan, row.seforest_amend)) || 0) + 
        (parseInt(row.prairie_amend === element ? value : getValue(accomplishment_plan, row.prairie_amend)) || 0) + 
        (parseInt(row.northernforest_amend === element ? value : getValue(accomplishment_plan, row.northernforest_amend)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_metrourban")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_metrourban_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.metrourban_amend === element ? value : getValue(accomplishment_plan, r.metrourban_amend)) || 0)
            });
        } else if (element.includes("_forestprairie")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_forestprairie_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forestprairie_amend === element ? value : getValue(accomplishment_plan, r.forestprairie_amend)) || 0)
            });
        } else if (element.includes("_seforest")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_seforest_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.seforest_amend === element ? value : getValue(accomplishment_plan, r.seforest_amend)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_prairie_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_amend === element ? value : getValue(accomplishment_plan, r.prairie_amend)) || 0)
            });
        } else if (element.includes("_northernforest")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_northernforest_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.northernforest_amend === element ? value : getValue(accomplishment_plan, r.northernforest_amend)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_metrourban")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_forestprairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_seforest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_northernforest_amend")) || 0);
        } else if (element.includes("_forestprairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_metrourban_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_seforest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_northernforest_amend")) || 0);
        } else if (element.includes("_seforest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_metrourban_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_forestprairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_northernforest_amend")) || 0);
        } else if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_metrourban_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_forestprairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_seforest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_northernforest_amend")) || 0);
        } else if (element.includes("_northernforest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_metrourban_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_forestprairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_seforest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_acres_ecologicalsection_total_prairie_amend")) || 0);
        }

        return {
            id: accomplishment_plan.id,
            [element]: value,
            [row.total_amend]: total,
            [updateField]: totalCol,
            output_acres_ecologicalsection_total_total_amend: finalTotal
        };
    };

    updateRecord = (value, element, row) => {        
        const { ormAccomplishmentPlanUpdate, ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormAccomplishmentPlanUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormAccomplishmentPlanUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { classes, accomplishment_plan, is_read_only } = this.props;
        const eco_regions = getValue(accomplishment_plan, "eco_regions");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (eco_regions.indexOf("Metro / Urban") > -1) {
            columnData.push({ id: "metrourban", label: "Metro/Urban (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "metrourban_amend", label: "Metro/Urban (new acres)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Forest / Prairie Transition") > -1) {
            columnData.push({ id: "forestprairie", label: "Forest/Prairie (original acres)", numeric: true, allowSort: false  });
            columnData.push({ id: "forestprairie_amend", label: "Forest/Prairie (new acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Southeast Forest") > -1) {
            columnData.push({ id: "seforest", label: "SE Forest (original acres)", numeric: true, allowSort: false  });
            columnData.push({ id: "seforest_amend", label: "SE Forest (new acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (original acres)", numeric: true, allowSort: false  });
            columnData.push({ id: "prairie_amend", label: "Prairie (new acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Northern Forest") > -1) {
            columnData.push({ id: "northernforest", label: "Northern Forest (original acres)", numeric: true, allowSort: false });
            columnData.push({ id: "northernforest_amend", label: "Northern Forest (new acres)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (original acres)", type: "numeric", numeric: true, allowSort: false  });
        columnData.push({ id: "total_amend", label: "Total (new acres)", type: "numeric", numeric: true, allowSort: false  });
        if (this.rows.length === 0) {
            const activity_types = getValue(accomplishment_plan, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    metrourban: "output_acres_ecologicalsection_restore_metrourban",
                    forestprairie: "output_acres_ecologicalsection_restore_forestprairie",
                    seforest: "output_acres_ecologicalsection_restore_seforest",
                    prairie: "output_acres_ecologicalsection_restore_prairie",
                    northernforest: "output_acres_ecologicalsection_restore_northernforest",
                    total: "output_acres_ecologicalsection_restore_total",
                    metrourban_amend: "output_acres_ecologicalsection_restore_metrourban_amend",
                    forestprairie_amend: "output_acres_ecologicalsection_restore_forestprairie_amend",
                    seforest_amend: "output_acres_ecologicalsection_restore_seforest_amend",
                    prairie_amend: "output_acres_ecologicalsection_restore_prairie_amend",
                    northernforest_amend: "output_acres_ecologicalsection_restore_northernforest_amend",
                    total_amend: "output_acres_ecologicalsection_restore_total_amend"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    metrourban: "output_acres_ecologicalsection_feepilt_metrourban",
                    forestprairie: "output_acres_ecologicalsection_feepilt_forestprairie",
                    seforest: "output_acres_ecologicalsection_feepilt_seforest",
                    prairie: "output_acres_ecologicalsection_feepilt_prairie",
                    northernforest: "output_acres_ecologicalsection_feepilt_northernforest",
                    total: "output_acres_ecologicalsection_feepilt_total",
                    metrourban_amend: "output_acres_ecologicalsection_feepilt_metrourban_amend",
                    forestprairie_amend: "output_acres_ecologicalsection_feepilt_forestprairie_amend",
                    seforest_amend: "output_acres_ecologicalsection_feepilt_seforest_amend",
                    prairie_amend: "output_acres_ecologicalsection_feepilt_prairie_amend",
                    northernforest_amend: "output_acres_ecologicalsection_feepilt_northernforest_amend",
                    total_amend: "output_acres_ecologicalsection_feepilt_total_amend"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    metrourban: "output_acres_ecologicalsection_feewopilt_metrourban",
                    forestprairie: "output_acres_ecologicalsection_feewopilt_forestprairie",
                    seforest: "output_acres_ecologicalsection_feewopilt_seforest",
                    prairie: "output_acres_ecologicalsection_feewopilt_prairie",
                    northernforest: "output_acres_ecologicalsection_feewopilt_northernforest",
                    total: "output_acres_ecologicalsection_feewopilt_total",
                    metrourban_amend: "output_acres_ecologicalsection_feewopilt_metrourban_amend",
                    forestprairie_amend: "output_acres_ecologicalsection_feewopilt_forestprairie_amend",
                    seforest_amend: "output_acres_ecologicalsection_feewopilt_seforest_amend",
                    prairie_amend: "output_acres_ecologicalsection_feewopilt_prairie_amend",
                    northernforest_amend: "output_acres_ecologicalsection_feewopilt_northernforest_amend",
                    total_amend: "output_acres_ecologicalsection_feewopilt_total_amend"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    metrourban: "output_acres_ecologicalsection_easement_metrourban",
                    forestprairie: "output_acres_ecologicalsection_easement_forestprairie",
                    seforest: "output_acres_ecologicalsection_easement_seforest",
                    prairie: "output_acres_ecologicalsection_easement_prairie",
                    northernforest: "output_acres_ecologicalsection_easement_northernforest",
                    total: "output_acres_ecologicalsection_easement_total",
                    metrourban_amend: "output_acres_ecologicalsection_easement_metrourban_amend",
                    forestprairie_amend: "output_acres_ecologicalsection_easement_forestprairie_amend",
                    seforest_amend: "output_acres_ecologicalsection_easement_seforest_amend",
                    prairie_amend: "output_acres_ecologicalsection_easement_prairie_amend",
                    northernforest_amend: "output_acres_ecologicalsection_easement_northernforest_amend",
                    total_amend: "output_acres_ecologicalsection_easement_total_amend"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    metrourban: "output_acres_ecologicalsection_enhance_metrourban",
                    forestprairie: "output_acres_ecologicalsection_enhance_forestprairie",
                    seforest: "output_acres_ecologicalsection_enhance_seforest",
                    prairie: "output_acres_ecologicalsection_enhance_prairie",
                    northernforest: "output_acres_ecologicalsection_enhance_northernforest",
                    total: "output_acres_ecologicalsection_enhance_total",
                    metrourban_amend: "output_acres_ecologicalsection_enhance_metrourban_amend",
                    forestprairie_amend: "output_acres_ecologicalsection_enhance_forestprairie_amend",
                    seforest_amend: "output_acres_ecologicalsection_enhance_seforest_amend",
                    prairie_amend: "output_acres_ecologicalsection_enhance_prairie_amend",
                    northernforest_amend: "output_acres_ecologicalsection_enhance_northernforest_amend",
                    total_amend: "output_acres_ecologicalsection_enhance_total_amend"
                });
            }
            this.rows.push({
                type: "Total",
                metrourban: "output_acres_ecologicalsection_total_metrourban",
                forestprairie: "output_acres_ecologicalsection_total_forestprairie",
                seforest: "output_acres_ecologicalsection_total_seforest",
                prairie: "output_acres_ecologicalsection_total_prairie",
                northernforest: "output_acres_ecologicalsection_total_northernforest",
                total: "output_acres_ecologicalsection_total_total",
                metrourban_amend: "output_acres_ecologicalsection_total_metrourban_amend",
                forestprairie_amend: "output_acres_ecologicalsection_total_forestprairie_amend",
                seforest_amend: "output_acres_ecologicalsection_total_seforest_amend",
                prairie_amend: "output_acres_ecologicalsection_total_prairie_amend",
                northernforest_amend: "output_acres_ecologicalsection_total_northernforest_amend",
                total_amend: "output_acres_ecologicalsection_total_total_amend"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {eco_regions.indexOf("Metro / Urban") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.metrourban))}</b>
                                        ) : (
                                            <Form key={n.metrourban} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useNumber tableFormat field={n.metrourban} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.metrourban_amend))}</b>
                                        ) : (
                                            <Form key={n.metrourban_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.metrourban_amend} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Forest / Prairie Transition") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.forestprairie))}</b>
                                        ) : (
                                            <Form key={n.forestprairie} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useNumber tableFormat field={n.forestprairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.forestprairie_amend))}</b>
                                        ) : (
                                            <Form key={n.forestprairie_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forestprairie_amend} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Southeast Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.seforest))}</b>
                                        ) : (
                                            <Form key={n.seforest} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useNumber tableFormat field={n.seforest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.seforest_amend))}</b>
                                        ) : (
                                            <Form key={n.seforest_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.seforest_amend} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.prairie))}</b>
                                        ) : (
                                            <Form key={n.prairie} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useNumber tableFormat field={n.prairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.prairie_amend))}</b>
                                        ) : (
                                            <Form key={n.prairie_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_amend} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Northern Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.northernforest))}</b>
                                        ) : (
                                            <Form key={n.northernforest} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useNumber tableFormat field={n.northernforest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(accomplishment_plan, n.northernforest_amend))}</b>
                                        ) : (
                                            <Form key={n.northernforest_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.northernforest_amend} label="" className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{acresRender(getValue(accomplishment_plan, n.total))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{acresRender(getValue(accomplishment_plan, n.total_amend))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableDollarsBySection extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};
    rows = [];

    getValues = (value, element, row) => {
        const { accomplishment_plan } = this.props;

        value = (Math.round(parseInt(value) / 100) * 100) || 0;

        // Get Row Total
        var total = (parseInt(row.metrourban === element ? value : getValue(accomplishment_plan, row.metrourban)) || 0) + 
        (parseInt(row.forestprairie === element ? value : getValue(accomplishment_plan, row.forestprairie)) || 0) + 
        (parseInt(row.seforest === element ? value : getValue(accomplishment_plan, row.seforest)) || 0) + 
        (parseInt(row.prairie === element ? value : getValue(accomplishment_plan, row.prairie)) || 0) + 
        (parseInt(row.northernforest === element ? value : getValue(accomplishment_plan, row.northernforest)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_metrourban")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_metrourban";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.metrourban === element ? value : getValue(accomplishment_plan, r.metrourban)) || 0)
            });
        } else if (element.includes("_forestprairie")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_forestprairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forestprairie === element ? value : getValue(accomplishment_plan, r.forestprairie)) || 0)
            });
        } else if (element.includes("_seforest")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_seforest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.seforest === element ? value : getValue(accomplishment_plan, r.seforest)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_prairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie === element ? value : getValue(accomplishment_plan, r.prairie)) || 0)
            });
        } else if (element.includes("_northernforest")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_northernforest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.northernforest === element ? value : getValue(accomplishment_plan, r.northernforest)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_metrourban")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_forestprairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_seforest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_northernforest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_prairie")) || 0);
        }

        return {
            id: accomplishment_plan.id,
            [element]: value,
            [row.total]: total,
            [updateField]: totalCol,
            output_dollars_ecologicalsection_total_total: finalTotal
        };
    };

    updateRecord = (value, element, row) => {        
        const { ormAccomplishmentPlanUpdate, ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormAccomplishmentPlanUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormAccomplishmentPlanUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, accomplishment_plan, is_read_only } = this.props;
        const eco_regions = getValue(accomplishment_plan, "eco_regions");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (eco_regions.indexOf("Metro / Urban") > -1) {
            columnData.push({ id: "metrourban", label: "Metro/Urban ($)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Forest / Prairie Transition") > -1) {
            columnData.push({ id: "forestprairie", label: "Forest/Prairie ($)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Southeast Forest") > -1) {
            columnData.push({ id: "seforest", label: "SE Forest ($)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie ($)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Northern Forest") > -1) {
            columnData.push({ id: "northernforest", label: "Northern Forest ($)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total ($)", type: "numeric", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(accomplishment_plan, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    metrourban: "output_dollars_ecologicalsection_restore_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_restore_forestprairie",
                    seforest: "output_dollars_ecologicalsection_restore_seforest",
                    prairie: "output_dollars_ecologicalsection_restore_prairie",
                    northernforest: "output_dollars_ecologicalsection_restore_northernforest",
                    total: "output_dollars_ecologicalsection_restore_total"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    metrourban: "output_dollars_ecologicalsection_feepilt_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_feepilt_forestprairie",
                    seforest: "output_dollars_ecologicalsection_feepilt_seforest",
                    prairie: "output_dollars_ecologicalsection_feepilt_prairie",
                    northernforest: "output_dollars_ecologicalsection_feepilt_northernforest",
                    total: "output_dollars_ecologicalsection_feepilt_total"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    metrourban: "output_dollars_ecologicalsection_feewopilt_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_feewopilt_forestprairie",
                    seforest: "output_dollars_ecologicalsection_feewopilt_seforest",
                    prairie: "output_dollars_ecologicalsection_feewopilt_prairie",
                    northernforest: "output_dollars_ecologicalsection_feewopilt_northernforest",
                    total: "output_dollars_ecologicalsection_feewopilt_total"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    metrourban: "output_dollars_ecologicalsection_easement_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_easement_forestprairie",
                    seforest: "output_dollars_ecologicalsection_easement_seforest",
                    prairie: "output_dollars_ecologicalsection_easement_prairie",
                    northernforest: "output_dollars_ecologicalsection_easement_northernforest",
                    total: "output_dollars_ecologicalsection_easement_total"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    metrourban: "output_dollars_ecologicalsection_enhance_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_enhance_forestprairie",
                    seforest: "output_dollars_ecologicalsection_enhance_seforest",
                    prairie: "output_dollars_ecologicalsection_enhance_prairie",
                    northernforest: "output_dollars_ecologicalsection_enhance_northernforest",
                    total: "output_dollars_ecologicalsection_enhance_total"
                });
            }
            this.rows.push({
                type: "Total",
                metrourban: "output_dollars_ecologicalsection_total_metrourban",
                forestprairie: "output_dollars_ecologicalsection_total_forestprairie",
                seforest: "output_dollars_ecologicalsection_total_seforest",
                prairie: "output_dollars_ecologicalsection_total_prairie",
                northernforest: "output_dollars_ecologicalsection_total_northernforest",
                total: "output_dollars_ecologicalsection_total_total"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {eco_regions.indexOf("Metro / Urban") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.metrourban))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.metrourban] ? this.formkeys[n.metrourban]  : n.metrourban} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.metrourban} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Forest / Prairie Transition") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.forestprairie))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.forestprairie] ? this.formkeys[n.forestprairie]  : n.forestprairie} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forestprairie} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Southeast Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.seforest))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.seforest] ? this.formkeys[n.seforest]  : n.seforest} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.seforest} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Prairie") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.prairie))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.prairie] ? this.formkeys[n.prairie]  : n.prairie} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Northern Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.northernforest))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.northernforest] ? this.formkeys[n.northernforest]  : n.northernforest} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.northernforest} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(accomplishment_plan, n.total))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableDollarsBySectionAmendment extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};
    rows = [];

    getValues = (value, element, row) => {
        const { accomplishment_plan } = this.props;

        value = (Math.round(parseInt(value) / 100) * 100) || 0;

        // Get Row Total
        var total = (parseInt(row.metrourban_amend === element ? value : getValue(accomplishment_plan, row.metrourban_amend)) || 0) + 
        (parseInt(row.forestprairie_amend === element ? value : getValue(accomplishment_plan, row.forestprairie_amend)) || 0) + 
        (parseInt(row.seforest_amend === element ? value : getValue(accomplishment_plan, row.seforest_amend)) || 0) + 
        (parseInt(row.prairie_amend === element ? value : getValue(accomplishment_plan, row.prairie_amend)) || 0) + 
        (parseInt(row.northernforest_amend === element ? value : getValue(accomplishment_plan, row.northernforest_amend)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_metrourban")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_metrourban_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.metrourban_amend === element ? value : getValue(accomplishment_plan, r.metrourban_amend)) || 0)
            });
        } else if (element.includes("_forestprairie")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_forestprairie_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forestprairie_amend === element ? value : getValue(accomplishment_plan, r.forestprairie_amend)) || 0)
            });
        } else if (element.includes("_seforest")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_seforest_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.seforest_amend === element ? value : getValue(accomplishment_plan, r.seforest_amend)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_prairie_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_amend=== element ? value : getValue(accomplishment_plan, r.prairie_amend)) || 0)
            });
        } else if (element.includes("_northernforest")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_northernforest_amend";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.northernforest_amend === element ? value : getValue(accomplishment_plan, r.northernforest_amend)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_metrourban")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_forestprairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_seforest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_northernforest_amend")) || 0);
        } else if (element.includes("_forestprairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_metrourban_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_seforest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_northernforest_amend")) || 0);
        } else if (element.includes("_seforest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_metrourban_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_forestprairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_prairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_northernforest_amend")) || 0);
        } else if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_metrourban_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_forestprairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_seforest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_northernforest_amend")) || 0);
        } else if (element.includes("_northernforest")) {
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_metrourban_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_forestprairie_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_seforest_amend")) || 0);
            finalTotal += (parseInt(getValue(accomplishment_plan, "output_dollars_ecologicalsection_total_prairie_amend")) || 0);
        }

        return {
            id: accomplishment_plan.id,
            [element]: value,
            [row.total_amend]: total,
            [updateField]: totalCol,
            output_dollars_ecologicalsection_total_total_amend: finalTotal
        };
    };

    updateRecord = (value, element, row) => {        
        const { ormAccomplishmentPlanUpdate, ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormAccomplishmentPlanUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormAccomplishmentPlanUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, accomplishment_plan, is_read_only } = this.props;
        const eco_regions = getValue(accomplishment_plan, "eco_regions");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (eco_regions.indexOf("Metro / Urban") > -1) {
            columnData.push({ id: "metrourban", label: "Metro/Urban (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "metrourban_amend", label: "Metro/Urban (new $)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Forest / Prairie Transition") > -1) {
            columnData.push({ id: "forestprairie", label: "Forest/Prairie (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "forestprairie_amend", label: "Forest/Prairie (new $)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Southeast Forest") > -1) {
            columnData.push({ id: "seforest", label: "SE Forest (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "seforest_amend", label: "SE Forest (new $)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "prairie_amend", label: "Prairie (new $)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Northern Forest") > -1) {
            columnData.push({ id: "northernforest", label: "Northern Forest (original $)", numeric: true, allowSort: false });
            columnData.push({ id: "northernforest_amend", label: "Northern Forest (new $)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (original $)", type: "numeric", numeric: true, allowSort: false });
        columnData.push({ id: "total_amend", label: "Total (new $)", type: "numeric", numeric: true, allowSort: false });
        if (this.rows.length === 0) {
            const activity_types = getValue(accomplishment_plan, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    metrourban: "output_dollars_ecologicalsection_restore_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_restore_forestprairie",
                    seforest: "output_dollars_ecologicalsection_restore_seforest",
                    prairie: "output_dollars_ecologicalsection_restore_prairie",
                    northernforest: "output_dollars_ecologicalsection_restore_northernforest",
                    total: "output_dollars_ecologicalsection_restore_total",
                    metrourban_amend: "output_dollars_ecologicalsection_restore_metrourban_amend",
                    forestprairie_amend: "output_dollars_ecologicalsection_restore_forestprairie_amend",
                    seforest_amend: "output_dollars_ecologicalsection_restore_seforest_amend",
                    prairie_amend: "output_dollars_ecologicalsection_restore_prairie_amend",
                    northernforest_amend: "output_dollars_ecologicalsection_restore_northernforest_amend",
                    total_amend: "output_dollars_ecologicalsection_restore_total_amend"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    metrourban: "output_dollars_ecologicalsection_feepilt_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_feepilt_forestprairie",
                    seforest: "output_dollars_ecologicalsection_feepilt_seforest",
                    prairie: "output_dollars_ecologicalsection_feepilt_prairie",
                    northernforest: "output_dollars_ecologicalsection_feepilt_northernforest",
                    total: "output_dollars_ecologicalsection_feepilt_total",
                    metrourban_amend: "output_dollars_ecologicalsection_feepilt_metrourban_amend",
                    forestprairie_amend: "output_dollars_ecologicalsection_feepilt_forestprairie_amend",
                    seforest_amend: "output_dollars_ecologicalsection_feepilt_seforest_amend",
                    prairie_amend: "output_dollars_ecologicalsection_feepilt_prairie_amend",
                    northernforest_amend: "output_dollars_ecologicalsection_feepilt_northernforest_amend",
                    total_amend: "output_dollars_ecologicalsection_feepilt_total_amend"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    metrourban: "output_dollars_ecologicalsection_feewopilt_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_feewopilt_forestprairie",
                    seforest: "output_dollars_ecologicalsection_feewopilt_seforest",
                    prairie: "output_dollars_ecologicalsection_feewopilt_prairie",
                    northernforest: "output_dollars_ecologicalsection_feewopilt_northernforest",
                    total: "output_dollars_ecologicalsection_feewopilt_total",
                    metrourban_amend: "output_dollars_ecologicalsection_feewopilt_metrourban_amend",
                    forestprairie_amend: "output_dollars_ecologicalsection_feewopilt_forestprairie_amend",
                    seforest_amend: "output_dollars_ecologicalsection_feewopilt_seforest_amend",
                    prairie_amend: "output_dollars_ecologicalsection_feewopilt_prairie_amend",
                    northernforest_amend: "output_dollars_ecologicalsection_feewopilt_northernforest_amend",
                    total_amend: "output_dollars_ecologicalsection_feewopilt_total_amend"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    metrourban: "output_dollars_ecologicalsection_easement_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_easement_forestprairie",
                    seforest: "output_dollars_ecologicalsection_easement_seforest",
                    prairie: "output_dollars_ecologicalsection_easement_prairie",
                    northernforest: "output_dollars_ecologicalsection_easement_northernforest",
                    total: "output_dollars_ecologicalsection_easement_total",
                    metrourban_amend: "output_dollars_ecologicalsection_easement_metrourban_amend",
                    forestprairie_amend: "output_dollars_ecologicalsection_easement_forestprairie_amend",
                    seforest_amend: "output_dollars_ecologicalsection_easement_seforest_amend",
                    prairie_amend: "output_dollars_ecologicalsection_easement_prairie_amend",
                    northernforest_amend: "output_dollars_ecologicalsection_easement_northernforest_amend",
                    total_amend: "output_dollars_ecologicalsection_easement_total_amend"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    metrourban: "output_dollars_ecologicalsection_enhance_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_enhance_forestprairie",
                    seforest: "output_dollars_ecologicalsection_enhance_seforest",
                    prairie: "output_dollars_ecologicalsection_enhance_prairie",
                    northernforest: "output_dollars_ecologicalsection_enhance_northernforest",
                    total: "output_dollars_ecologicalsection_enhance_total",
                    metrourban_amend: "output_dollars_ecologicalsection_enhance_metrourban_amend",
                    forestprairie_amend: "output_dollars_ecologicalsection_enhance_forestprairie_amend",
                    seforest_amend: "output_dollars_ecologicalsection_enhance_seforest_amend",
                    prairie_amend: "output_dollars_ecologicalsection_enhance_prairie_amend",
                    northernforest_amend: "output_dollars_ecologicalsection_enhance_northernforest_amend",
                    total_amend: "output_dollars_ecologicalsection_enhance_total_amend"
                });
            }
            this.rows.push({
                type: "Total",
                metrourban: "output_dollars_ecologicalsection_total_metrourban",
                forestprairie: "output_dollars_ecologicalsection_total_forestprairie",
                seforest: "output_dollars_ecologicalsection_total_seforest",
                prairie: "output_dollars_ecologicalsection_total_prairie",
                northernforest: "output_dollars_ecologicalsection_total_northernforest",
                total: "output_dollars_ecologicalsection_total_total",
                metrourban_amend: "output_dollars_ecologicalsection_total_metrourban_amend",
                forestprairie_amend: "output_dollars_ecologicalsection_total_forestprairie_amend",
                seforest_amend: "output_dollars_ecologicalsection_total_seforest_amend",
                prairie_amend: "output_dollars_ecologicalsection_total_prairie_amend",
                northernforest_amend: "output_dollars_ecologicalsection_total_northernforest_amend",
                total_amend: "output_dollars_ecologicalsection_total_total_amend"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {eco_regions.indexOf("Metro / Urban") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.metrourban))}</b>
                                        ) : (
                                            <Form defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useTextFormat tableFormat field={n.metrourban} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.metrourban_amend))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.metrourban_amend] ? this.formkeys[n.metrourban_amend]  : n.metrourban_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.metrourban_amend} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Forest / Prairie Transition") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.forestprairie))}</b>
                                        ) : (
                                            <Form defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useTextFormat tableFormat field={n.forestprairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.forestprairie_amend))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.forestprairie_amend] ? this.formkeys[n.forestprairie_amend]  : n.forestprairie_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forestprairie_amend} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Southeast Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.seforest))}</b>
                                        ) : (
                                            <Form defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useTextFormat tableFormat field={n.seforest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.seforest_amend))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.seforest_amend] ? this.formkeys[n.seforest_amend]  : n.seforest_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.seforest_amend} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.prairie))}</b>
                                        ) : (
                                            <Form defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useTextFormat tableFormat field={n.prairie} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.prairie_amend))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.prairie_amend] ? this.formkeys[n.prairie_amend]  : n.prairie_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_amend} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Northern Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.northernforest))}</b>
                                        ) : (
                                            <Form defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField className={classes.minWidth} disabled={true} useTextFormat tableFormat field={n.northernforest} label="" />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(accomplishment_plan, n.northernforest_amend))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.northernforest_amend] ? this.formkeys[n.northernforest_amend]  : n.northernforest_amend} defaultValues={accomplishment_plan}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.northernforest_amend} label="" onBlur={this.updateForm} className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(accomplishment_plan, n.total))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(accomplishment_plan, n.total_amend))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

OutputTab = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        ...AccomplishmentPlan.actions
    }
)(OutputTab);

export default withStyles(styles)(withRouter(OutputTab));
