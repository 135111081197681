import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    root: {
        display: "flex"
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing()
    },
    tableFormat: {
        marginTop: "4px !important",
        marginBottom: "4px !important",
    },
    group: {
        margin: "0"
    },
    fullWidth: {
        width: "100%"
    },
    horizontal: {
        margin: "0",
        display: "inline"
    },
    groupName: {
        color: "#212529"
    },
    error: {
        "& h3": {
            // No way to access actual text - probably because typography tag is being passed as label
            color: "#be0000"
        },
        "& svg": {
            // Keep color on help icon
            color: "rgba(0, 0, 0, 0.87)"
        }
    }
});

class RadioGroupWrapper extends Component {
    handleChange = (event, value) => {
        const { fieldApi, eventHandle, name, eventHandleProps1 } = this.props;
        const { setValue } = fieldApi;
        if (value === "true") value = true;
        if (value === "false") value = false;

        setValue(value);

        if (eventHandle) eventHandle(value, name, eventHandleProps1);
    };

    render() {
        const {
            fieldApi,
            label,
            children,
            options,
            classes,
            required,
            alignment,
            fullWidth,
            style,
            tableFormat,
            disabled,
            ...rest
        } = this.props;

        const { getValue, getError } = fieldApi;

        var value = getValue(),
            error = getError();

        // FIXME: Material UI requires string values
        // Radio values are returned from API as boolean
        if (value === true) value = "true";
        if (value === false) value = "false";

        return (
            <FormControl
                component="fieldset"
                style={style}
                required={required}
                error={error && true}
                className={classNames(classes.formControl, tableFormat && classes.tableFormat, fullWidth && classes.fullWidth)}>
                <FormLabel component="legend" className={classes.groupName} classes={{ error: classes.error }}>
                    {label || children}
                </FormLabel>
                <RadioGroup
                    className={alignment ? classes.horizontal : classes.group}
                    value={value}
                    onChange={this.handleChange}
                    {...rest}>
                    {options.map(object => (
                        <FormControlLabel
                            disabled={object.disabled || disabled}
                            value={object.value}
                            control={<Radio onClick={e => e.stopPropagation()} color="primary" />}
                            label={object.label}
                            onClick={e => e.stopPropagation()}
                        />
                    ))}
                </RadioGroup>
                {error && <FormHelperText style={{ marginTop: 0 }}>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

const FppRadioGroup = FormField(RadioGroupWrapper);

export default withStyles(styles)(FppRadioGroup);
