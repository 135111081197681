import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";

import { StatusUpdate } from "../models";
import TextField from "../../common/TextField";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import { getValue } from "../../../api/utils";

var tables = {};
export function TEST(table) {
    var key = table.join(':');
    if (!tables[key]) {
        table = table.filter(n => n);
        tables[key] = table.map(row => ({
            label: row,
            value: row
        }));
    }
    return tables[key];
}

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        },
        marginBottom:16
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    totalRow: {
        backgroundColor: "rgb(221, 221, 221) !important",
        borderTop: "3px solid #000",
        height:36
    },
    minWidth: {
        minWidth: 120
    }
});

const dollars_format = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});
const acres_format = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 0
});

const acresRender = (rowData) => {
    return rowData ? acres_format.format(rowData) : 0
};

const dollarsRender = (rowData) => {
    return rowData ? dollars_format.format(rowData) : 0
};

class OutputTab extends Component {
    parentFormKey = window.performance.now();

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Status Update: Output Tables - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    };

    componentWillUnmount() {
        this.props.onRef(undefined);
    };

    updateStatusUpdate(values, fromStepper) {
        const { ormStatusUpdateUpdate, history, handleUnsavedFields, handleNext, status_update } = this.props;
        const { id } = status_update;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }
        values.conservation = status_update.conservation;

        ormStatusUpdateUpdate({
            id: id,
            activeStep: values.activeStep,
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    };

    render() {
        const { 
            classes,
            status_update,
            handleUnsavedFields,
            ormStatusUpdateUpdateLocalOnly,
            ormStatusUpdateUpdate,
            ActionMenu, authState
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || status_update.status === "Draft")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        return (
            <Form
                getApi={el => (this.form = el)}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={status_update.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateStatusUpdate(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3" gutterBottom>Acres by Resource Type (Table 1)</Typography>
                                <OutputTableAcresByResource
                                    ormStatusUpdateUpdate={ormStatusUpdateUpdate} 
                                    status_update={status_update}
                                    is_read_only={is_read_only}
                                    classes={classes}
                                    ormStatusUpdateUpdateLocalOnly={ormStatusUpdateUpdateLocalOnly}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{float:"left",marginTop:12}} variant="h3">Total Request Funding by Resource Type (Table 2)</Typography>
                                <OutputTableDollarsByResource
                                    ormStatusUpdateUpdate={ormStatusUpdateUpdate} 
                                    status_update={status_update}
                                    is_read_only={is_read_only}
                                    classes={classes}
                                    ormStatusUpdateUpdateLocalOnly={ormStatusUpdateUpdateLocalOnly}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3" gutterBottom>Acres within each Ecological Section (Table 3)</Typography>
                                <div style={{overflowX: "auto"}}>
                                    <OutputTableAcresBySection
                                        ormStatusUpdateUpdate={ormStatusUpdateUpdate} 
                                        status_update={status_update}
                                        is_read_only={is_read_only}
                                        classes={classes}
                                        ormStatusUpdateUpdateLocalOnly={ormStatusUpdateUpdateLocalOnly}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{marginBottom:6}} variant="h3">Total Requested Funding within each Ecological Section (Table 4)</Typography>
                                <div style={{overflowX: "auto"}}>
                                    <OutputTableDollarsBySection
                                        ormStatusUpdateUpdate={ormStatusUpdateUpdate} 
                                        status_update={status_update}
                                        classes={classes}
                                        is_read_only={is_read_only}
                                        ormStatusUpdateUpdateLocalOnly={ormStatusUpdateUpdateLocalOnly}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Submit Status Update to LSOHC&nbsp;&nbsp;&nbsp;<CloudUpload />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

class OutputTableAcresByResource extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element, row) => {
        const { status_update } = this.props;

        value = parseInt(value) || 0;

        // Get Row Total
        var total = (parseInt(row.wetland_td === element ? value : getValue(status_update, row.wetland_td)) || 0) + 
        (parseInt(row.prairie_td === element ? value : getValue(status_update, row.prairie_td)) || 0) + 
        (parseInt(row.forest_td === element ? value : getValue(status_update, row.forest_td)) || 0) + 
        (parseInt(row.habitat_td === element ? value : getValue(status_update, row.habitat_td)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_wetland")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_wetland_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.wetland_td === element ? value : getValue(status_update, r.wetland_td)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_prairie_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_td === element ? value : getValue(status_update, r.prairie_td)) || 0)
            });
        } else if (element.includes("_forest")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_forest_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forest_td === element ? value : getValue(status_update, r.forest_td)) || 0)
            });
        } else if (element.includes("_habitat")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_habitat_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.habitat_td === element ? value : getValue(status_update, r.habitat_td)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_wetland")) {
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_forest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_habitat_td")) || 0);
        }
        if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_wetland_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_forest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_habitat_td")) || 0);
        }
        if (element.includes("_forest")) {
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_wetland_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_habitat_td")) || 0);
        }
        if (element.includes("_habitat")) {
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_wetland_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_resourcetype_total_forest_td")) || 0);
        }

        return {
            id: status_update.id,
            [element]: value,
            [row.total_td]: total,
            [updateField]: totalCol,
            output_acres_resourcetype_total_total_td: finalTotal
        };
    }

    updateRecord = (value, element, row) => {     
        const { ormStatusUpdateUpdate, ormStatusUpdateUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormStatusUpdateUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormStatusUpdateUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { status_update, classes, is_read_only } = this.props;
        const priority_resources_activity = getValue(status_update, "accomplishment_info.priority_resources_activity");
        var columnData = [
            { id: "type", title: "Type", allowSort: false }
        ];
        if (priority_resources_activity.indexOf("Wetlands") > -1) {
            columnData.push({ id: "wetland_ap", label: "Wetland (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "wetland_td", label: "Wetland (To Date)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie_ap", label: "Prairie (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "prairie_td", label: "Prairie (To Date)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Forest") > -1) {
            columnData.push({ id: "forest_ap", label: "Forest (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "forest_td", label: "Forest (To Date)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Habitat") > -1) {
            columnData.push({ id: "habitat_ap", label: "Habitat (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "habitat_td", label: "Habitat (To Date)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total_ap", label: "Total (AP)", numeric: true, allowSort: false });
        columnData.push({ id: "total_td", label: "Total (To Date)", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(status_update, "accomplishment_info.activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    wetland_ap: "accomplishment_info.output_acres_resourcetype_restore_wetland",
                    wetland_td: "output_acres_resourcetype_restore_wetland_td",
                    prairie_ap: "accomplishment_info.output_acres_resourcetype_restore_prairie",
                    prairie_td: "output_acres_resourcetype_restore_prairie_td",
                    forest_ap: "accomplishment_info.output_acres_resourcetype_restore_forest",
                    forest_td: "output_acres_resourcetype_restore_forest_td",
                    habitat_ap: "accomplishment_info.output_acres_resourcetype_restore_habitat",
                    habitat_td: "output_acres_resourcetype_restore_habitat_td",
                    total_ap: "accomplishment_info.output_acres_resourcetype_restore_total",
                    total_td: "output_acres_resourcetype_restore_total_td"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    wetland_ap: "accomplishment_info.output_acres_resourcetype_feepilt_wetland",
                    wetland_td: "output_acres_resourcetype_feepilt_wetland_td",
                    prairie_ap: "accomplishment_info.output_acres_resourcetype_feepilt_prairie",
                    prairie_td: "output_acres_resourcetype_feepilt_prairie_td",
                    forest_ap: "accomplishment_info.output_acres_resourcetype_feepilt_forest",
                    forest_td: "output_acres_resourcetype_feepilt_forest_td",
                    habitat_ap: "accomplishment_info.output_acres_resourcetype_feepilt_habitat",
                    habitat_td: "output_acres_resourcetype_feepilt_habitat_td",
                    total_ap: "accomplishment_info.output_acres_resourcetype_feepilt_total",
                    total_td: "output_acres_resourcetype_feepilt_total_td"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    wetland_ap: "accomplishment_info.output_acres_resourcetype_feewopilt_wetland",
                    wetland_td: "output_acres_resourcetype_feewopilt_wetland_td",
                    prairie_ap: "accomplishment_info.output_acres_resourcetype_feewopilt_prairie",
                    prairie_td: "output_acres_resourcetype_feewopilt_prairie_td",
                    forest_ap: "accomplishment_info.output_acres_resourcetype_feewopilt_forest",
                    forest_td: "output_acres_resourcetype_feewopilt_forest_td",
                    habitat_ap: "accomplishment_info.output_acres_resourcetype_feewopilt_habitat",
                    habitat_td: "output_acres_resourcetype_feewopilt_habitat_td",
                    total_ap: "accomplishment_info.output_acres_resourcetype_feewopilt_total",
                    total_td: "output_acres_resourcetype_feewopilt_total_td"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    wetland_ap: "accomplishment_info.output_acres_resourcetype_easement_wetland",
                    wetland_td: "output_acres_resourcetype_easement_wetland_td",
                    prairie_ap: "accomplishment_info.output_acres_resourcetype_easement_prairie",
                    prairie_td: "output_acres_resourcetype_easement_prairie_td",
                    forest_ap: "accomplishment_info.output_acres_resourcetype_easement_forest",
                    forest_td: "output_acres_resourcetype_easement_forest_td",
                    habitat_ap: "accomplishment_info.output_acres_resourcetype_easement_habitat",
                    habitat_td: "output_acres_resourcetype_easement_habitat_td",
                    total_ap: "accomplishment_info.output_acres_resourcetype_easement_total",
                    total_td: "output_acres_resourcetype_easement_total_td"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    wetland_ap: "accomplishment_info.output_acres_resourcetype_enhance_wetland",
                    wetland_td: "output_acres_resourcetype_enhance_wetland_td",
                    prairie_ap: "accomplishment_info.output_acres_resourcetype_enhance_prairie",
                    prairie_td: "output_acres_resourcetype_enhance_prairie_td",
                    forest_ap: "accomplishment_info.output_acres_resourcetype_enhance_forest",
                    forest_td: "output_acres_resourcetype_enhance_forest_td",
                    habitat_ap: "accomplishment_info.output_acres_resourcetype_enhance_habitat",
                    habitat_td: "output_acres_resourcetype_enhance_habitat_td",
                    total_ap: "accomplishment_info.output_acres_resourcetype_enhance_total",
                    total_td: "output_acres_resourcetype_enhance_total_td"
                });
            }
            this.rows.push({
                type: "Total",
                wetland_ap: "accomplishment_info.output_acres_resourcetype_total_wetland",
                wetland_td: "output_acres_resourcetype_total_wetland_td",
                prairie_ap: "accomplishment_info.output_acres_resourcetype_total_prairie",
                prairie_td: "output_acres_resourcetype_total_prairie_td",
                forest_ap: "accomplishment_info.output_acres_resourcetype_total_forest",
                forest_td: "output_acres_resourcetype_total_forest_td",
                habitat_ap: "accomplishment_info.output_acres_resourcetype_total_habitat",
                habitat_td: "output_acres_resourcetype_total_habitat_td",
                total_ap: "accomplishment_info.output_acres_resourcetype_total_total",
                total_td: "output_acres_resourcetype_total_total_td",
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {priority_resources_activity.indexOf("Wetlands") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.wetland_ap))}</b>
                                        ) : (
                                            acresRender(getValue(status_update, n.wetland_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.wetland_td))}</b>
                                        ) : (
                                            <Form key={n.wetland_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.wetland_td} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.prairie_ap))}</b>
                                        ) : (
                                            acresRender(getValue(status_update, n.prairie_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.prairie_td))}</b>
                                        ) : (
                                            <Form key={n.prairie_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_td} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.forest_ap))}</b>
                                        ) : (
                                            acresRender(getValue(status_update, n.forest_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.forest_td))}</b>
                                        ) : (
                                            <Form key={n.forest_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forest_td} label="" 
                                                        />
                                                   </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Habitat") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.habitat_ap))}</b>
                                        ) : (
                                            acresRender(getValue(status_update, n.habitat_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.habitat_td))}</b>
                                        ) : (
                                            <Form key={n.habitat_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.habitat_td} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{acresRender(getValue(status_update, n.total_ap))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{acresRender(getValue(status_update, n.total_td))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableDollarsByResource extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};
    rows = [];

    getValues = (value, element, row) => {
        const { status_update } = this.props;

        value = (Math.round(parseInt(value) / 100) * 100) || 0;

        // Get Row Total
        var total = (parseInt(row.wetland_td === element ? value : getValue(status_update, row.wetland_td)) || 0) + 
        (parseInt(row.prairie_td === element ? value : getValue(status_update, row.prairie_td)) || 0) + 
        (parseInt(row.forest_td === element ? value : getValue(status_update, row.forest_td)) || 0) + 
        (parseInt(row.habitat_td === element ? value : getValue(status_update, row.habitat_td)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_wetland")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_wetland_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.wetland_td === element ? value : getValue(status_update, r.wetland_td)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_prairie_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_td === element ? value : getValue(status_update, r.prairie_td)) || 0)
            });
        } else if (element.includes("_forest")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_forest_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forest_td === element ? value : getValue(status_update, r.forest_td)) || 0)
            });
        } else if (element.includes("_habitat")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_habitat_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.habitat_td === element ? value : getValue(status_update, r.habitat_td)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_wetland")) {
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_forest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_habitat_td")) || 0);
        }
        if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_wetland_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_forest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_habitat_td")) || 0);
        }
        if (element.includes("_forest")) {
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_wetland_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_habitat_td")) || 0);
        }
        if (element.includes("_habitat")) {
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_wetland_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_resourcetype_total_forest_td")) || 0);
        }

        return {
            id: status_update.id,
            [element]: value,
            [row.total_td]: total,
            [updateField]: totalCol,
            output_dollars_resourcetype_total_total_td: finalTotal
        };
    }

    updateRecord = (value, element, row) => {        
        const { ormStatusUpdateUpdate, ormStatusUpdateUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormStatusUpdateUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormStatusUpdateUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, status_update, is_read_only } = this.props;
        const priority_resources_activity = getValue(status_update, "accomplishment_info.priority_resources_activity");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (priority_resources_activity.indexOf("Wetlands") > -1) {
            columnData.push({ id: "wetland_ap", label: "Wetland (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "wetland_td", label: "Wetland (To Date)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie_ap", label: "Prairie (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "prairie_td", label: "Prairie (To Date)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Forest") > -1) {
            columnData.push({ id: "forest_ap", label: "Forest (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "forest_td", label: "Forest (To Date)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Habitat") > -1) {
            columnData.push({ id: "habitat_ap", label: "Habitat (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "habitat_td", label: "Habitat (To Date)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total_ap", label: "Total (AP)", type: "numeric", numeric: true, allowSort: false });
        columnData.push({ id: "total_td", label: "Total (To Date)", type: "numeric", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(status_update, "accomplishment_info.activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    wetland_ap: "accomplishment_info.output_dollars_resourcetype_restore_wetland",
                    wetland_td: "output_dollars_resourcetype_restore_wetland_td",
                    prairie_ap: "accomplishment_info.output_dollars_resourcetype_restore_prairie",
                    prairie_td: "output_dollars_resourcetype_restore_prairie_td",
                    forest_ap: "accomplishment_info.output_dollars_resourcetype_restore_forest",
                    forest_td: "output_dollars_resourcetype_restore_forest_td",
                    habitat_ap: "accomplishment_info.output_dollars_resourcetype_restore_habitat",
                    habitat_td: "output_dollars_resourcetype_restore_habitat_td",
                    total_ap: "accomplishment_info.output_dollars_resourcetype_restore_total",
                    total_td: "output_dollars_resourcetype_restore_total_td"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    wetland_ap: "accomplishment_info.output_dollars_resourcetype_feepilt_wetland",
                    wetland_td: "output_dollars_resourcetype_feepilt_wetland_td",
                    prairie_ap: "accomplishment_info.output_dollars_resourcetype_feepilt_prairie",
                    prairie_td: "output_dollars_resourcetype_feepilt_prairie_td",
                    forest_ap: "accomplishment_info.output_dollars_resourcetype_feepilt_forest",
                    forest_td: "output_dollars_resourcetype_feepilt_forest_td",
                    habitat_ap: "accomplishment_info.output_dollars_resourcetype_feepilt_habitat",
                    habitat_td: "output_dollars_resourcetype_feepilt_habitat_td",
                    total_ap: "accomplishment_info.output_dollars_resourcetype_feepilt_total",
                    total_td: "output_dollars_resourcetype_feepilt_total_td"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    wetland_ap: "accomplishment_info.output_dollars_resourcetype_feewopilt_wetland",
                    wetland_td: "output_dollars_resourcetype_feewopilt_wetland_td",
                    prairie_ap: "accomplishment_info.output_dollars_resourcetype_feewopilt_prairie",
                    prairie_td: "output_dollars_resourcetype_feewopilt_prairie_td",
                    forest_ap: "accomplishment_info.output_dollars_resourcetype_feewopilt_forest",
                    forest_td: "output_dollars_resourcetype_feewopilt_forest_td",
                    habitat_ap: "accomplishment_info.output_dollars_resourcetype_feewopilt_habitat",
                    habitat_td: "output_dollars_resourcetype_feewopilt_habitat_td",
                    total_ap: "accomplishment_info.output_dollars_resourcetype_feewopilt_total",
                    total_td: "output_dollars_resourcetype_feewopilt_total_td"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    wetland_ap: "accomplishment_info.output_dollars_resourcetype_easement_wetland",
                    wetland_td: "output_dollars_resourcetype_easement_wetland_td",
                    prairie_ap: "accomplishment_info.output_dollars_resourcetype_easement_prairie",
                    prairie_td: "output_dollars_resourcetype_easement_prairie_td",
                    forest_ap: "accomplishment_info.output_dollars_resourcetype_easement_forest",
                    forest_td: "output_dollars_resourcetype_easement_forest_td",
                    habitat_ap: "accomplishment_info.output_dollars_resourcetype_easement_habitat",
                    habitat_td: "output_dollars_resourcetype_easement_habitat_td",
                    total_ap: "accomplishment_info.output_dollars_resourcetype_easement_total",
                    total_td: "output_dollars_resourcetype_easement_total_td"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    wetland_ap: "accomplishment_info.output_dollars_resourcetype_enhance_wetland",
                    wetland_td: "output_dollars_resourcetype_enhance_wetland_td",
                    prairie_ap: "accomplishment_info.output_dollars_resourcetype_enhance_prairie",
                    prairie_td: "output_dollars_resourcetype_enhance_prairie_td",
                    forest_ap: "accomplishment_info.output_dollars_resourcetype_enhance_forest",
                    forest_td: "output_dollars_resourcetype_enhance_forest_td",
                    habitat_ap: "accomplishment_info.output_dollars_resourcetype_enhance_habitat",
                    habitat_td: "output_dollars_resourcetype_enhance_habitat_td",
                    total_ap: "accomplishment_info.output_dollars_resourcetype_enhance_total",
                    total_td: "output_dollars_resourcetype_enhance_total_td"
                });
            }
            this.rows.push({
                type: "Total",
                wetland_ap: "accomplishment_info.output_dollars_resourcetype_total_wetland",
                wetland_td: "output_dollars_resourcetype_total_wetland_td",
                prairie_ap: "accomplishment_info.output_dollars_resourcetype_total_prairie",
                prairie_td: "output_dollars_resourcetype_total_prairie_td",
                forest_ap: "accomplishment_info.output_dollars_resourcetype_total_forest",
                forest_td: "output_dollars_resourcetype_total_forest_td",
                habitat_ap: "accomplishment_info.output_dollars_resourcetype_total_habitat",
                habitat_td: "output_dollars_resourcetype_total_habitat_td",
                total_ap: "accomplishment_info.output_dollars_resourcetype_total_total",
                total_td: "output_dollars_resourcetype_total_total_td"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {priority_resources_activity.indexOf("Wetlands") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.wetland_ap))}</b>
                                        ) : (
                                            dollarsRender(getValue(status_update, n.wetland_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.wetland_td))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.wetland_td] ? this.formkeys[n.wetland_td]  : n.wetland_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.wetland_td} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.prairie_ap))}</b>
                                        ) : (
                                            dollarsRender(getValue(status_update, n.prairie_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.prairie_td))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.prairie_td] ? this.formkeys[n.prairie_td]  : n.prairie_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_td} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.forest_ap))}</b>
                                        ) : (
                                            dollarsRender(getValue(status_update, n.forest_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.forest_td))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.forest_td] ? this.formkeys[n.forest_td]  : n.forest_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forest_td} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {priority_resources_activity.indexOf("Habitat") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.habitat_ap))}</b>
                                        ) : (
                                            dollarsRender(getValue(status_update, n.habitat_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.habitat_td))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.habitat_td] ? this.formkeys[n.habitat_td]  : n.habitat_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.habitat_td} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(status_update, n.total_ap))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(status_update, n.total_td))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableAcresBySection extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element, row) => {
        const { status_update } = this.props;

        value = parseInt(value) || 0;

        // Get Row Total
        var total = (parseInt(row.metrourban_td === element ? value : getValue(status_update, row.metrourban_td)) || 0) + 
        (parseInt(row.forestprairie_td === element ? value : getValue(status_update, row.forestprairie_td)) || 0) + 
        (parseInt(row.seforest_td === element ? value : getValue(status_update, row.seforest_td)) || 0) + 
        (parseInt(row.prairie_td === element ? value : getValue(status_update, row.prairie_td)) || 0) + 
        (parseInt(row.northernforest_td === element ? value : getValue(status_update, row.northernforest_td)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_metrourban")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_metrourban_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.metrourban_td === element ? value : getValue(status_update, r.metrourban_td)) || 0)
            });
        } else if (element.includes("_forestprairie")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_forestprairie_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forestprairie_td === element ? value : getValue(status_update, r.forestprairie_td)) || 0)
            });
        } else if (element.includes("_seforest")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_seforest_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.seforest_td === element ? value : getValue(status_update, r.seforest_td)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_prairie_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_td === element ? value : getValue(status_update, r.prairie_td)) || 0)
            });
        } else if (element.includes("_northernforest")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_northernforest_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.northernforest_td === element ? value : getValue(status_update, r.northernforest_td)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_metrourban")) {
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_forestprairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_seforest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_northernforest_td")) || 0);
        } else if (element.includes("_forestprairie")) {
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_metrourban_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_seforest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_northernforest_td")) || 0);
        } else if (element.includes("_seforest")) {
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_metrourban_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_forestprairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_northernforest_td")) || 0);
        } else if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_metrourban_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_forestprairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_seforest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_northernforest_td")) || 0);
        } else if (element.includes("_northernforest")) {
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_metrourban_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_forestprairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_seforest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_acres_ecologicalsection_total_prairie_td")) || 0);
        }

        return {
            id: status_update.id,
            [element]: value,
            [row.total_td]: total,
            [updateField]: totalCol,
            output_acres_ecologicalsection_total_total_td: finalTotal
        };
    };

    updateRecord = (value, element, row) => {        
        const { ormStatusUpdateUpdate, ormStatusUpdateUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormStatusUpdateUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormStatusUpdateUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { classes, status_update, is_read_only } = this.props;
        const eco_regions = getValue(status_update, "accomplishment_info.eco_regions");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (eco_regions.indexOf("Metro / Urban") > -1) {
            columnData.push({ id: "metrourban_ap", label: "Metro/Urban (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "metrourban_td", label: "Metro/Urban (To Date)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Forest / Prairie Transition") > -1) {
            columnData.push({ id: "forestprairie_ap", label: "Forest/Prairie (AP)", numeric: true, allowSort: false  });
            columnData.push({ id: "forestprairie_td", label: "Forest/Prairie (To Date)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Southeast Forest") > -1) {
            columnData.push({ id: "seforest_ap", label: "SE Forest (AP)", numeric: true, allowSort: false  });
            columnData.push({ id: "seforest_td", label: "SE Forest (To Date)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie_ap", label: "Prairie (AP)", numeric: true, allowSort: false  });
            columnData.push({ id: "prairie_td", label: "Prairie (To Date)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Northern Forest") > -1) {
            columnData.push({ id: "northernforest_ap", label: "Northern Forest (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "northernforest_td", label: "Northern Forest (To Date)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total_ap", label: "Total (AP)", type: "numeric", numeric: true, allowSort: false  });
        columnData.push({ id: "total_td", label: "Total (To Date)", type: "numeric", numeric: true, allowSort: false  });

        if (this.rows.length === 0) {
            const activity_types = getValue(status_update, "accomplishment_info.activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    metrourban_ap: "accomplishment_info.output_acres_ecologicalsection_restore_metrourban",
                    metrourban_td: "output_acres_ecologicalsection_restore_metrourban_td",
                    forestprairie_ap: "accomplishment_info.output_acres_ecologicalsection_restore_forestprairie",
                    forestprairie_td: "output_acres_ecologicalsection_restore_forestprairie_td",
                    seforest_ap: "accomplishment_info.output_acres_ecologicalsection_restore_seforest",
                    seforest_td: "output_acres_ecologicalsection_restore_seforest_td",
                    prairie_ap: "accomplishment_info.output_acres_ecologicalsection_restore_prairie",
                    prairie_td: "output_acres_ecologicalsection_restore_prairie_td",
                    northernforest_ap: "accomplishment_info.output_acres_ecologicalsection_restore_northernforest",
                    northernforest_td: "output_acres_ecologicalsection_restore_northernforest_td",
                    total_ap: "accomplishment_info.output_acres_ecologicalsection_restore_total",
                    total_td: "output_acres_ecologicalsection_restore_total_td"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    metrourban_ap: "accomplishment_info.output_acres_ecologicalsection_feepilt_metrourban",
                    metrourban_td: "output_acres_ecologicalsection_feepilt_metrourban_td",
                    forestprairie_ap: "accomplishment_info.output_acres_ecologicalsection_feepilt_forestprairie",
                    forestprairie_td: "output_acres_ecologicalsection_feepilt_forestprairie_td",
                    seforest_ap: "accomplishment_info.output_acres_ecologicalsection_feepilt_seforest",
                    seforest_td: "output_acres_ecologicalsection_feepilt_seforest_td",
                    prairie_ap: "accomplishment_info.output_acres_ecologicalsection_feepilt_prairie",
                    prairie_td: "output_acres_ecologicalsection_feepilt_prairie_td",
                    northernforest_ap: "accomplishment_info.output_acres_ecologicalsection_feepilt_northernforest",
                    northernforest_td: "output_acres_ecologicalsection_feepilt_northernforest_td",
                    total_ap: "accomplishment_info.output_acres_ecologicalsection_feepilt_total",
                    total_td: "output_acres_ecologicalsection_feepilt_total_td"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    metrourban_ap: "accomplishment_info.output_acres_ecologicalsection_feewopilt_metrourban",
                    metrourban_td: "output_acres_ecologicalsection_feewopilt_metrourban_td",
                    forestprairie_ap: "accomplishment_info.output_acres_ecologicalsection_feewopilt_forestprairie",
                    forestprairie_td: "output_acres_ecologicalsection_feewopilt_forestprairie_td",
                    seforest_ap: "accomplishment_info.output_acres_ecologicalsection_feewopilt_seforest",
                    seforest_td: "output_acres_ecologicalsection_feewopilt_seforest_td",
                    prairie_ap: "accomplishment_info.output_acres_ecologicalsection_feewopilt_prairie",
                    prairie_td: "output_acres_ecologicalsection_feewopilt_prairie_td",
                    northernforest_ap: "accomplishment_info.output_acres_ecologicalsection_feewopilt_northernforest",
                    northernforest_td: "output_acres_ecologicalsection_feewopilt_northernforest_td",
                    total_ap: "accomplishment_info.output_acres_ecologicalsection_feewopilt_total",
                    total_td: "output_acres_ecologicalsection_feewopilt_total_td"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    metrourban_ap: "accomplishment_info.output_acres_ecologicalsection_easement_metrourban",
                    metrourban_td: "output_acres_ecologicalsection_easement_metrourban_td",
                    forestprairie_ap: "accomplishment_info.output_acres_ecologicalsection_easement_forestprairie",
                    forestprairie_td: "output_acres_ecologicalsection_easement_forestprairie_td",
                    seforest_ap: "accomplishment_info.output_acres_ecologicalsection_easement_seforest",
                    seforest_td: "output_acres_ecologicalsection_easement_seforest_td",
                    prairie_ap: "accomplishment_info.output_acres_ecologicalsection_easement_prairie",
                    prairie_td: "output_acres_ecologicalsection_easement_prairie_td",
                    northernforest_ap: "accomplishment_info.output_acres_ecologicalsection_easement_northernforest",
                    northernforest_td: "output_acres_ecologicalsection_easement_northernforest_td",
                    total_ap: "accomplishment_info.output_acres_ecologicalsection_easement_total",
                    total_td: "output_acres_ecologicalsection_easement_total_td"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    metrourban_ap: "accomplishment_info.output_acres_ecologicalsection_enhance_metrourban",
                    metrourban_td: "output_acres_ecologicalsection_enhance_metrourban_td",
                    forestprairie_ap: "accomplishment_info.output_acres_ecologicalsection_enhance_forestprairie",
                    forestprairie_td: "output_acres_ecologicalsection_enhance_forestprairie_td",
                    seforest_ap: "accomplishment_info.output_acres_ecologicalsection_enhance_seforest",
                    seforest_td: "output_acres_ecologicalsection_enhance_seforest_td",
                    prairie_ap: "accomplishment_info.output_acres_ecologicalsection_enhance_prairie",
                    prairie_td: "output_acres_ecologicalsection_enhance_prairie_td",
                    northernforest_ap: "accomplishment_info.output_acres_ecologicalsection_enhance_northernforest",
                    northernforest_td: "output_acres_ecologicalsection_enhance_northernforest_td",
                    total_ap: "accomplishment_info.output_acres_ecologicalsection_enhance_total",
                    total_td: "output_acres_ecologicalsection_enhance_total_td"
                });
            }
            this.rows.push({
                type: "Total",
                metrourban_ap: "accomplishment_info.output_acres_ecologicalsection_total_metrourban",
                metrourban_td: "output_acres_ecologicalsection_total_metrourban_td",
                forestprairie_ap: "accomplishment_info.output_acres_ecologicalsection_total_forestprairie",
                forestprairie_td: "output_acres_ecologicalsection_total_forestprairie_td",
                seforest_ap: "accomplishment_info.output_acres_ecologicalsection_total_seforest",
                seforest_td: "output_acres_ecologicalsection_total_seforest_td",
                prairie_ap: "accomplishment_info.output_acres_ecologicalsection_total_prairie",
                prairie_td: "output_acres_ecologicalsection_total_prairie_td",
                northernforest_ap: "accomplishment_info.output_acres_ecologicalsection_total_northernforest",
                northernforest_td: "output_acres_ecologicalsection_total_northernforest_td",
                total_ap: "accomplishment_info.output_acres_ecologicalsection_total_total",
                total_td: "output_acres_ecologicalsection_total_total_td"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {eco_regions.indexOf("Metro / Urban") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.metrourban_ap))}</b>
                                        ) : (
                                            acresRender(getValue(status_update, n.metrourban_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.metrourban_td))}</b>
                                        ) : (
                                            <Form key={n.metrourban_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.metrourban_td} label=""
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Forest / Prairie Transition") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.forestprairie_ap))}</b>
                                        ) : (
                                            acresRender(getValue(status_update, n.forestprairie_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.forestprairie_td))}</b>
                                        ) : (
                                            <Form key={n.forestprairie_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forestprairie_td} label=""
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Southeast Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.seforest_ap))}</b>
                                        ) : (
                                            acresRender(getValue(status_update, n.seforest_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.seforest_td))}</b>
                                        ) : (
                                            <Form key={n.seforest_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.seforest_td} label=""
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.prairie_ap))}</b>
                                        ) : (
                                            acresRender(getValue(status_update, n.prairie_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.prairie_td))}</b>
                                        ) : (
                                            <Form key={n.prairie_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_td} label=""
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Northern Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.northernforest_ap))}</b>
                                        ) : (
                                            acresRender(getValue(status_update, n.northernforest_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(status_update, n.northernforest_td))}</b>
                                        ) : (
                                            <Form key={n.northernforest_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.northernforest_td} label=""
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{acresRender(getValue(status_update, n.total_ap))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{acresRender(getValue(status_update, n.total_td))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableDollarsBySection extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};
    rows = [];

    getValues = (value, element, row) => {
        const { status_update } = this.props;

        value = (Math.round(parseInt(value) / 100) * 100) || 0;

        // Get Row Total
        var total = (parseInt(row.metrourban_td === element ? value : getValue(status_update, row.metrourban_td)) || 0) + 
        (parseInt(row.forestprairie_td === element ? value : getValue(status_update, row.forestprairie_td)) || 0) + 
        (parseInt(row.seforest_td === element ? value : getValue(status_update, row.seforest_td)) || 0) + 
        (parseInt(row.prairie_td === element ? value : getValue(status_update, row.prairie_td)) || 0) + 
        (parseInt(row.northernforest_td === element ? value : getValue(status_update, row.northernforest_td)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_metrourban")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_metrourban_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.metrourban_td === element ? value : getValue(status_update, r.metrourban_td)) || 0)
            });
        } else if (element.includes("_forestprairie")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_forestprairie_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forestprairie_td === element ? value : getValue(status_update, r.forestprairie_td)) || 0)
            });
        } else if (element.includes("_seforest")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_seforest_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.seforest_td === element ? value : getValue(status_update, r.seforest_td)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_prairie_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie_td === element ? value : getValue(status_update, r.prairie_td)) || 0)
            });
        } else if (element.includes("_northernforest")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_northernforest_td";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.northernforest_td === element ? value : getValue(status_update, r.northernforest_td)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_metrourban")) {
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_forestprairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_seforest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_northernforest_td")) || 0);
        } else if (element.includes("_forestprairie")) {
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_metrourban_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_seforest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_northernforest_td")) || 0);
        } else if (element.includes("_seforest")) {
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_metrourban_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_forestprairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_prairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_northernforest_td")) || 0);
        } else if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_metrourban_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_forestprairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_seforest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_northernforest_td")) || 0);
        } else if (element.includes("_northernforest")) {
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_metrourban_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_forestprairie_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_seforest_td")) || 0);
            finalTotal += (parseInt(getValue(status_update, "output_dollars_ecologicalsection_total_prairie_td")) || 0);
        }

        return {
            id: status_update.id,
            [element]: value,
            [row.total_td]: total,
            [updateField]: totalCol,
            output_dollars_ecologicalsection_total_total_td: finalTotal
        };
    };

    updateRecord = (value, element, row) => {        
        const { ormStatusUpdateUpdate, ormStatusUpdateUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormStatusUpdateUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormStatusUpdateUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, status_update, is_read_only } = this.props;
        const eco_regions = getValue(status_update, "accomplishment_info.eco_regions");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (eco_regions.indexOf("Metro / Urban") > -1) {
            columnData.push({ id: "metrourban_ap", label: "Metro/Urban (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "metrourban_td", label: "Metro/Urban (To Date)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Forest / Prairie Transition") > -1) {
            columnData.push({ id: "forestprairie_ap", label: "Forest/Prairie (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "forestprairie_td", label: "Forest/Prairie (To Date)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Southeast Forest") > -1) {
            columnData.push({ id: "seforest_ap", label: "SE Forest (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "seforest_td", label: "SE Forest (To Date)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie_ap", label: "Prairie (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "prairie_td", label: "Prairie (To Date)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Northern Forest") > -1) {
            columnData.push({ id: "northernforest_ap", label: "Northern Forest (AP)", numeric: true, allowSort: false });
            columnData.push({ id: "northernforest_td", label: "Northern Forest (To Date)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total_ap", label: "Total (AP)", type: "numeric", numeric: true, allowSort: false });
        columnData.push({ id: "total_td", label: "Total (To Date)", type: "numeric", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(status_update, "accomplishment_info.activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    metrourban_ap: "accomplishment_info.output_dollars_ecologicalsection_restore_metrourban",
                    metrourban_td: "output_dollars_ecologicalsection_restore_metrourban_td",
                    forestprairie_ap: "accomplishment_info.output_dollars_ecologicalsection_restore_forestprairie",
                    forestprairie_td: "output_dollars_ecologicalsection_restore_forestprairie_td",
                    seforest_ap: "accomplishment_info.output_dollars_ecologicalsection_restore_seforest",
                    seforest_td: "output_dollars_ecologicalsection_restore_seforest_td",
                    prairie_ap: "accomplishment_info.output_dollars_ecologicalsection_restore_prairie",
                    prairie_td: "output_dollars_ecologicalsection_restore_prairie_td",
                    northernforest_ap: "accomplishment_info.output_dollars_ecologicalsection_restore_northernforest",
                    northernforest_td: "output_dollars_ecologicalsection_restore_northernforest_td",
                    total_ap: "accomplishment_info.output_dollars_ecologicalsection_restore_total",
                    total_td: "output_dollars_ecologicalsection_restore_total_td"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    metrourban_ap: "accomplishment_info.output_dollars_ecologicalsection_feepilt_metrourban",
                    metrourban_td: "output_dollars_ecologicalsection_feepilt_metrourban_td",
                    forestprairie_ap: "accomplishment_info.output_dollars_ecologicalsection_feepilt_forestprairie",
                    forestprairie_td: "output_dollars_ecologicalsection_feepilt_forestprairie_td",
                    seforest_ap: "accomplishment_info.output_dollars_ecologicalsection_feepilt_seforest",
                    seforest_td: "output_dollars_ecologicalsection_feepilt_seforest_td",
                    prairie_ap: "accomplishment_info.output_dollars_ecologicalsection_feepilt_prairie",
                    prairie_td: "output_dollars_ecologicalsection_feepilt_prairie_td",
                    northernforest_ap: "accomplishment_info.output_dollars_ecologicalsection_feepilt_northernforest",
                    northernforest_td: "output_dollars_ecologicalsection_feepilt_northernforest_td",
                    total_ap: "accomplishment_info.output_dollars_ecologicalsection_feepilt_total",
                    total_td: "output_dollars_ecologicalsection_feepilt_total_td"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    metrourban_ap: "accomplishment_info.output_dollars_ecologicalsection_feewopilt_metrourban",
                    metrourban_td: "output_dollars_ecologicalsection_feewopilt_metrourban_td",
                    forestprairie_ap: "accomplishment_info.output_dollars_ecologicalsection_feewopilt_forestprairie",
                    forestprairie_td: "output_dollars_ecologicalsection_feewopilt_forestprairie_td",
                    seforest_ap: "accomplishment_info.output_dollars_ecologicalsection_feewopilt_seforest",
                    seforest_td: "output_dollars_ecologicalsection_feewopilt_seforest_td",
                    prairie_ap: "accomplishment_info.output_dollars_ecologicalsection_feewopilt_prairie",
                    prairie_td: "output_dollars_ecologicalsection_feewopilt_prairie_td",
                    northernforest_ap: "accomplishment_info.output_dollars_ecologicalsection_feewopilt_northernforest",
                    northernforest_td: "output_dollars_ecologicalsection_feewopilt_northernforest_td",
                    total_ap: "accomplishment_info.output_dollars_ecologicalsection_feewopilt_total",
                    total_td: "output_dollars_ecologicalsection_feewopilt_total_td"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    metrourban_ap: "accomplishment_info.output_dollars_ecologicalsection_easement_metrourban",
                    metrourban_td: "output_dollars_ecologicalsection_easement_metrourban_td",
                    forestprairie_ap: "accomplishment_info.output_dollars_ecologicalsection_easement_forestprairie",
                    forestprairie_td: "output_dollars_ecologicalsection_easement_forestprairie_td",
                    seforest_ap: "accomplishment_info.output_dollars_ecologicalsection_easement_seforest",
                    seforest_td: "output_dollars_ecologicalsection_easement_seforest_td",
                    prairie_ap: "accomplishment_info.output_dollars_ecologicalsection_easement_prairie",
                    prairie_td: "output_dollars_ecologicalsection_easement_prairie_td",
                    northernforest_ap: "accomplishment_info.output_dollars_ecologicalsection_easement_northernforest",
                    northernforest_td: "output_dollars_ecologicalsection_easement_northernforest_td",
                    total_ap: "accomplishment_info.output_dollars_ecologicalsection_easement_total",
                    total_td: "output_dollars_ecologicalsection_easement_total_td"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    metrourban_ap: "accomplishment_info.output_dollars_ecologicalsection_enhance_metrourban",
                    metrourban_td: "output_dollars_ecologicalsection_enhance_metrourban_td",
                    forestprairie_ap: "accomplishment_info.output_dollars_ecologicalsection_enhance_forestprairie",
                    forestprairie_td: "output_dollars_ecologicalsection_enhance_forestprairie_td",
                    seforest_ap: "accomplishment_info.output_dollars_ecologicalsection_enhance_seforest",
                    seforest_td: "output_dollars_ecologicalsection_enhance_seforest_td",
                    prairie_ap: "accomplishment_info.output_dollars_ecologicalsection_enhance_prairie",
                    prairie_td: "output_dollars_ecologicalsection_enhance_prairie_td",
                    northernforest_ap: "accomplishment_info.output_dollars_ecologicalsection_enhance_northernforest",
                    northernforest_td: "output_dollars_ecologicalsection_enhance_northernforest_td",
                    total_ap: "accomplishment_info.output_dollars_ecologicalsection_enhance_total",
                    total_td: "output_dollars_ecologicalsection_enhance_total_td"
                });
            }
            this.rows.push({
                type: "Total",
                metrourban_ap: "accomplishment_info.output_dollars_ecologicalsection_total_metrourban",
                metrourban_td: "output_dollars_ecologicalsection_total_metrourban_td",
                forestprairie_ap: "accomplishment_info.output_dollars_ecologicalsection_total_forestprairie",
                forestprairie_td: "output_dollars_ecologicalsection_total_forestprairie_td",
                seforest_ap: "accomplishment_info.output_dollars_ecologicalsection_total_seforest",
                seforest_td: "output_dollars_ecologicalsection_total_seforest_td",
                prairie_ap: "accomplishment_info.output_dollars_ecologicalsection_total_prairie",
                prairie_td: "output_dollars_ecologicalsection_total_prairie_td",
                northernforest_ap: "accomplishment_info.output_dollars_ecologicalsection_total_northernforest",
                northernforest_td: "output_dollars_ecologicalsection_total_northernforest_td",
                total_ap: "accomplishment_info.output_dollars_ecologicalsection_total_total",
                total_td: "output_dollars_ecologicalsection_total_total_td"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {eco_regions.indexOf("Metro / Urban") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.metrourban_ap))}</b>
                                        ) : (
                                            dollarsRender(getValue(status_update, n.metrourban_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.metrourban_td))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.metrourban_td] ? this.formkeys[n.metrourban_td]  : n.metrourban_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.metrourban_td} label="" onBlur={this.updateForm}
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Forest / Prairie Transition") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.forestprairie_ap))}</b>
                                        ) : (
                                            dollarsRender(getValue(status_update, n.forestprairie_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.forestprairie_td))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.forestprairie_td] ? this.formkeys[n.forestprairie_td]  : n.forestprairie_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forestprairie_td} label="" onBlur={this.updateForm}
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Southeast Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.seforest_ap))}</b>
                                        ) : (
                                            dollarsRender(getValue(status_update, n.seforest_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.seforest_td))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.seforest_td] ? this.formkeys[n.seforest_td]  : n.seforest_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.seforest_td} label="" onBlur={this.updateForm}
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Prairie") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.prairie_ap))}</b>
                                        ) : (
                                            dollarsRender(getValue(status_update, n.prairie_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.prairie_td))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.prairie_td] ? this.formkeys[n.prairie_td]  : n.prairie_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie_td} label="" onBlur={this.updateForm}
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                {eco_regions.indexOf("Northern Forest") > -1 && (
                                    <>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.northernforest_ap))}</b>
                                        ) : (
                                            dollarsRender(getValue(status_update, n.northernforest_ap))
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(status_update, n.northernforest_td))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.northernforest_td] ? this.formkeys[n.northernforest_td]  : n.northernforest_td} defaultValues={status_update}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.northernforest_td} label="" onBlur={this.updateForm}
                                                            className={classes.minWidth}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                    </>
                                )}
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(status_update, n.total_ap))}</b>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(status_update, n.total_td))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

OutputTab = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        ...StatusUpdate.actions
    }
)(OutputTab);

export default withStyles(styles)(withRouter(OutputTab));
