import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "./index.css";
import "whatwg-fetch";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import JssProvider from "react-jss/lib/JssProvider";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { Provider as ReduxProvider } from "react-redux";
import { createReducer } from "redux-orm";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import localForage from "localforage";

import { MuiThemeProvider, createMuiTheme, createGenerateClassName } from "@material-ui/core/styles";

import AdmHome from "./components/admin/Home";
import SystemSettings from "./components/admin/SystemSettings";
import AdminTable from "./components/admin/Table";
import AdminSearch from "./components/admin/Search";
import AdmUser from "./components/admin/Users";
import AdmUserEdit from "./components/admin/EditUser";
import Messages from "./components/admin/Messages";
import HomePage from "./components/home/HomePage";
import MapPage from "./components/home/Map";
import PasswordPage from "./components/auth/PasswordPage";
import PasswordRecoverPage from "./components/auth/PasswordRecoverPage";
import UpdatePage from "./components/auth/UpdatePage";
import Dashboard from "./components/home/Dashboard";
import RegisterPage from "./components/auth/RegisterPage";
import Proposal from "./components/proposal/List";
import AccomplishmentPlan from "./components/accomplishment/List";
import FinalReport from "./components/final/List";
import InitialLandReporting from "./components/land_reporting/Form";
import RealProperty from "./components/real_property/Form";
import RealPropertyBatch from "./components/real_property/Batch";
import StatusUpdate from "./components/status_update/List";

import registerServiceWorker from "./registerServiceWorker";
import ErrorBoundary from "./ErrorBoundary";

import navReducer from "./components/common/reducers";
import { storeReady } from "./components/common/actions";
import authReducer from "./components/auth/reducers";
import orm, { reloadAll, syncReducer } from "./components/common/orm";
import WarningDialogWrapper from "./components/common/WarningDialogWrapper";

const theme = createMuiTheme({
    props: {
        MuiButton: {
            disableRipple: true
        }
    },
    typography: {
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif"
    },
    focusVisible: {
        boxShadow: "0 0 0 0.2rem rgba(153,0,51,.50) !important",
        opacity: ".9"
    },
    overrides: {
        MuiInputLabel: {
            root: {
                fontSize: 14,
                color: "#212529 !important"
            },
            shrink: {
                transform: "translate(0, 0px) scale(1)"
            }
        },
        MuiTableSortLabel: {
            root: {
              color: '#fff',
              '&:hover': {
                color: '#bbdefb',
              },
              '&:focus': {
                color: '#bbdefb',
              },
            },
            active: {
              color: '#bbdefb',
            },
        },
        MuiListItem: {
            root: {
                fontSize: 16,
                fontWeight: 400,
                color: "#212529"
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: 14,
                fontWeight: 400,
                color: "#212529"
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: 14,
            },
            error: {
                color: "#be0000 !important"
            }
        },
        MuiTableCell: {
            head: {
                backgroundColor: "#333333 !important",
                fontWeight: 500,
                color: "#ffffff"
            },
            body: {
                "& button": {
                    color: "#990033"
                }
            }
        },
        MuiTypography: {
            h2: {
                // Title of pages
                color: "#495057",
                fontSize: "1.55rem",
                lineHeight: 1.17
            },
            h3: {
                // Section titles
                fontWeight: 500,
                fontSize: "1.15rem",
                color: "#495057",
                lineHeight: 1.2
            },
            body1: {
                // Regular text
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 1.5,
                color: "#212529"
            },
        },
        MuiButton: {
            root: {
                textTransform: "none",
                color: "#707070",
                "&:hover": {
                    backgroundColor: "#E0E0E0",
                    textDecoration: "none"
                },
                border: "1px solid",
            },
            contained: {
                border: "none",
            },
            focusVisible: {
                boxShadow: "0 0 0 0.2rem rgba(153,0,51,.50) !important",
                opacity: ".9"
            },
        }
    },
    palette: {
        primary: {
            light: "#cf445c",
            main: "#990033",
            dark: "#64000d",
            contrastText: "#fff"
        }
    }
});

const generateClassName = createGenerateClassName({
    productionPrefix: "lsohc"
});

const logger = createLogger();
const reducer = combineReducers({
    auth: authReducer,
    orm: createReducer(orm),
    sync: syncReducer,
    nav: navReducer
});

const initialState = {
    nav: {
        sw: { checking: true },
        proposalExpand: true,
        apExpand: false,
        finalExpand: false,
        parcelExpand: false,
        statusExpand: false
    }
};

const middleware = process.env.NODE_ENV === "production" ? applyMiddleware(thunk) : applyMiddleware(thunk, logger);

const store = createStore(
    reducer,
    initialState,
    compose(
        middleware,
        offline({
            ...offlineConfig,
            retry: (action, retries) => (retries > 3 ? null : retries * 1000),
            persistOptions: {
                storage: localForage,
                serialize: false
            },
            persistAutoRehydrate: () =>
                offlineConfig.persistAutoRehydrate({
                    stateReconciler: (state, inboundState) => {
                        // Don't carry over pending from previous session
                        // Loading icon becomes stuck
                        if (inboundState.auth && inboundState.auth.pending) {
                            inboundState.auth.pending = null;
                        }
                        // Don't wipe out sw notification if it happened during init
                        if (state.nav && state.nav.sw) {
                            inboundState.nav = {
                                ...inboundState.nav,
                                sw: state.nav.sw
                            };
                        }
                        inboundState.offline = {
                            ...state.offline,
                            ...inboundState.offline,
                            online: state.offline.online,
                            netInfo: state.offline.netInfo,
                            busy: state.offline.busy
                        };

                        // When registering a brand new model (ex: FarmSurvey)
                        // the new model is not present in inboundState.orm
                        // but it is in state.orm.
                        // Add the empty orm models through state.orm so the new model
                        // is present but overwrite any existing models through inboundState.orm
                        inboundState.orm = {
                            ...state.orm,
                            ...inboundState.orm
                        };
                        return inboundState;
                    }
                }),
            persistCallback: () => {
                store.dispatch(storeReady());
                store.dispatch(reloadAll(true));
            }
        })
    )
);

const getConfirmation = (message, callback) => {
    ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <WarningDialogWrapper message={message} callback={callback} />
        </MuiThemeProvider>,
        document.getElementById("pp")
    );
};

ReactDOM.render(
    <ErrorBoundary>
        <ReduxProvider store={store}>
            <JssProvider generateClassName={generateClassName}>
                <MuiThemeProvider theme={theme}>
                    <HashRouter getUserConfirmation={getConfirmation}>
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route path="/password/reset" component={PasswordPage} />
                            <Route path="/reset/confirm/:uid/:token" component={PasswordRecoverPage} />
                            <Route path="/register" component={RegisterPage} />
                            <Route path="/updateuser" component={UpdatePage} />
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/proposal/:id" component={Proposal} />
                            <Route path="/accomplishment/:id" component={AccomplishmentPlan} />
                            <Route path="/final/:id" component={FinalReport} />
                            <Route path="/land_reporting/:id" component={InitialLandReporting} />
                            <Route path="/real_property/:id" component={RealProperty} />
                            <Route path="/real_property_batch/:id" component={RealPropertyBatch} />
                            <Route path="/status_update/:id" component={StatusUpdate} />
                            <Route path="/map" component={MapPage} />
                            <Route path="/adm/messages" component={Messages} />
                            <Route path="/adm/home" component={AdmHome} />
                            <Route path="/adm/users/:id" component={AdmUserEdit} />
                            <Route path="/adm/users" component={AdmUser} />
                            <Route path="/adm/settings" component={SystemSettings} />
                            <Route path="/adm/table" component={AdminTable} />
                            <Route path="/adm/search" component={AdminSearch} />
                        </Switch>
                    </HashRouter>
                </MuiThemeProvider>
            </JssProvider>
        </ReduxProvider>
    </ErrorBoundary>,
    document.getElementById("root")
);

registerServiceWorker(store.dispatch);
