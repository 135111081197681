import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core";

import { getValue } from "../../api/utils";
import TextField from "../common/TextField";
import RadioGroup from "../common/RadioGroup";
import FileInput from "../common/FileInput";
import Subquestion from "../common/Subquestion";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import Snackbar from "../common/Snackbar";
import { RealPropertyInterest } from "./models";
import { InitialLandReporting, InitialLandReportingView } from "../land_reporting/models"

const getRealProperty = RealPropertyInterest.selectByUrlId();

const styles = theme => ({
    minWidth: {
        width: "100%",
        height:"100%"
    },
    rightAlign: {
        textAlign: "center"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    gutterTop: {
        textAlign: "center",
        marginTop: ".6em"
    }
});

class RealPropertyForm extends Component {
    state = {
        snackbarOpen: false,
    };

    componentDidMount() {
        const { 
            ormRealPropertyInterestLoadDetail, match, authState
        } = this.props;
        if (authState && authState.user) {
            ormRealPropertyInterestLoadDetail(match.params.id);
        }
    }

    componentDidUpdate() {
        const { 
            real_property, ormRealPropertyInterestLoadDetail, match, authState
        } = this.props;
        if ((!real_property) && authState && authState.user) {
            ormRealPropertyInterestLoadDetail(match.params.id);
        }
    }

    applyOther = (value, element) => {
        const { ormRealPropertyInterestUpdateLocalOnly, real_property } = this.props;
        const { id } = real_property;
        ormRealPropertyInterestUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    updateLandingReport = (values) => {
        const { ormRealPropertyInterestUpdate, real_property, ormInitialLandReportingReload } = this.props;
        const { id } = real_property;

        ormRealPropertyInterestUpdate({
            id: id,
            ...values
        });
        // In case the status has changed
        setTimeout(function() {
            ormInitialLandReportingReload();
            //ormInitialLandReportingViewReload();
        }, 500)
    };

    render() {
        const { classes, real_property, history, authState } = this.props;
        const { snackbarOpen } = this.state;

        if (!real_property) {
            return (
                <AppContainer authenticated synced={true} />
            );
        }

        const question1_NO = getValue(real_property, "question1") === "false" || getValue(real_property, "question1") === false;
        const question2_NO = getValue(real_property, "question2") === "false" || getValue(real_property, "question2") === false;
        const question3_NO = getValue(real_property, "question3") === "false" || getValue(real_property, "question3") === false;
        const question4_YES = getValue(real_property, "question4") === "true" || getValue(real_property, "question4") === true;        
        const is_fee = getValue(real_property, "parcel_type") === "Protect in Fee with PILT" || getValue(real_property, "parcel_type") === "Protect in Fee w/o PILT";
        const is_easement = getValue(real_property, "parcel_type") === "Protect in Easement";
        
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || real_property.status === "Draft")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        // Numbering system here
        var i = 5;
        if (is_easement) {
            var a = i;
            i++;
        }
        if (is_fee && !real_property.parcel_pin) {
            var b = i;
            i++;
        }
        
        var d = i;
        i++;
        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <PageHeader title={real_property.parcel_name} />
                    <Grid item xs={12}>
                        <Form
                            key={real_property.id}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={real_property}
                            onSubmit={this.updateLandingReport}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={10} lg={8} xl={6}>
                                            <Typography variant="h3" className={classes.gutterTop}>
                                                Project Manager Contact Information
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <TextField label="Organization Name" disabled={true} field="organization" fullWidth />
                                                    <TextField label="Manager's Name" disabled={true} field="manager_name" fullWidth />
                                                    <TextField label="Address" disabled={true} field="address_1" fullWidth />
                                                    <TextField label="City" disabled={true} field="city" fullWidth />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField label="State" disabled={true} field="state" fullWidth />
                                                    <TextField label="Zip" disabled={true} field="zip" fullWidth />
                                                    <TextField label="Email" disabled={true} field="email" fullWidth />
                                                    <TextField label="Office Number" disabled={true} field="phone_office" fullWidth />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField label="Legal Citation" rows={2} multiline disabled={true} field="citation" fullWidth />
                                                    <TextField label="Appropriation" rows={7} multiline disabled={true} field="appropriation" fullWidth />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={10} lg={8} xl={6}>
                                            <Typography variant="h3" className={classes.gutterTop}>
                                                Real Property Interest Report
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="question1"
                                                        name="question1"
                                                        disabled={is_read_only}
                                                        eventHandle={this.applyOther}
                                                        fullWidth
                                                        label="1) Do you (or your organization/agency), currently own interest in this parcel?"
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No (Explain)", value: "false" }
                                                        ]}
                                                        alignment={true}
                                                    />
                                                    {question1_NO && (
                                                        <Subquestion component={
                                                            <TextField
                                                                field="question1explain"
                                                                label="Explain"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                                rowsMax={20}
                                                            />
                                                        } />
                                                    )}
                                                    <RadioGroup
                                                        field="question2"
                                                        name="question2"
                                                        disabled={is_read_only}
                                                        eventHandle={this.applyOther}
                                                        fullWidth
                                                        label="2) Is the contact information listed above for this parcel correct?"
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No (Edit)", value: "false" }
                                                        ]}
                                                        alignment={true}
                                                    />
                                                    {question2_NO && (
                                                        <Subquestion component={
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <TextField disabled={is_read_only} label="Organization Name" field="organization" fullWidth />
                                                                    <TextField disabled={is_read_only} label="Manager's Name" field="manager_name" fullWidth />
                                                                    <TextField disabled={is_read_only} label="Address" field="address_1" fullWidth />
                                                                    <TextField disabled={is_read_only} label="City" field="city" fullWidth />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField disabled={is_read_only} label="State" field="state" fullWidth />
                                                                    <TextField disabled={is_read_only} label="Zip" field="zip" fullWidth />
                                                                    <TextField disabled={is_read_only} label="Email" field="email" fullWidth />
                                                                    <TextField disabled={is_read_only} label="Office Number" field="phone_office" fullWidth />
                                                                </Grid>
                                                            </Grid>
                                                        } />
                                                    )}
                                                    <RadioGroup
                                                        field="question3"
                                                        name="question3"
                                                        disabled={is_read_only}
                                                        eventHandle={this.applyOther}
                                                        fullWidth
                                                        label="3) Is the parcel currently being used for the purposes stated in the appropriation?"
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No (Explain and Give Instructions)", value: "false" }
                                                        ]}
                                                        alignment={true}
                                                    />
                                                    {question3_NO && (
                                                        <Subquestion component={
                                                            <TextField
                                                                field="question3explain"
                                                                label="Explain and Give Instructions"
                                                                fullWidth
                                                                disabled={is_read_only}
                                                                multiline
                                                                rows={4}
                                                                rowsMax={20}
                                                            />
                                                        } />
                                                    )}
                                                </Grid>
                                                <Grid item xs={6}> 
                                                    <RadioGroup
                                                        field="question4"
                                                        name="question4"
                                                        disabled={is_read_only}
                                                        eventHandle={this.applyOther}
                                                        fullWidth
                                                        label="4) Has the status of hunting & fishing as described in the A.P. changed?"
                                                        options={[
                                                            { label: "Yes (Explain)", value: "true" },
                                                            { label: "No", value: "false" }
                                                        ]}
                                                        alignment={true}
                                                    />
                                                    {question4_YES && (
                                                        <Subquestion component={
                                                            <TextField
                                                                field="question4explainedit"
                                                                label="Explain"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                                rowsMax={20}
                                                            />
                                                        } />
                                                    )}
                                                    {is_easement && (
                                                        <FileInput
                                                            field="easement_monitoring"
                                                            id="easement_monitoring"
                                                            disabled={is_read_only}
                                                            marginStyle={true}
                                                            fullWidth
                                                        >
                                                            <div>
                                                                {a}) Attach the annual financial easement monitoring & enforcement report&nbsp;
                                                                <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.17" target="_blank">per MS97A.056, subd 17</a>
                                                            </div>
                                                        </FileInput>
                                                    )}
                                                    {is_fee && !real_property.parcel_pin && (
                                                        <TextField field="parcel_pin" fullWidth disabled={is_read_only}>
                                                            <div>
                                                                {b}) Parcel PIN Number&nbsp;
                                                                <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.17" target="_blank">per MS 97A.056 subd 17</a>
                                                            </div>
                                                        </TextField>
                                                    )}
                                                    <RadioGroup
                                                        field="status"
                                                        name="status"
                                                        fullWidth 
                                                        disabled={is_read_only}
                                                        options={[
                                                            { label: "Draft", value: "Draft" },
                                                            { label: "Completed", value: "Completed" }
                                                        ]}
                                                        alignment={true}
                                                    >
                                                        <div>
                                                            {d}) Status
                                                            <br/>
                                                            <i>Mark as completed when you are finished</i>
                                                        </div>
                                                    </RadioGroup>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={10} lg={8} xl={12} className={classes.rightAlign}>
                                            <Button
                                                variant="contained"
                                                type="button"
                                                onClick={() => history.goBack()}
                                                className={classes.button}>
                                                Return
                                            </Button>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                disabled={is_read_only}
                                                onClick={() => history.goBack()}
                                                className={classes.button}>
                                                Save and Return
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
                <Snackbar
                    handleSnackbarClose={() => this.setState({ snackbarOpen: false })}
                    isSuccess
                    snackbarOpen={snackbarOpen}
                />
            </AppContainer>
        );
    }
}

RealPropertyForm = connect(
    (state, ownProps) => ({
        real_property: getRealProperty(state, ownProps),
        authState: state.auth
    }),
    {
        ...RealPropertyInterest.actions,
        ...InitialLandReporting.actions,
        ...InitialLandReportingView.actions,
    }
)(RealPropertyForm);

export default withStyles(styles)(withRouter(RealPropertyForm));
