import React, { Component } from "react";
import { FormField } from "react-form";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";

import { withStyles } from "@material-ui/core";

const styles = theme => ({
    input: {
        display: "none"
    },
    inline: {
        display: "inline",
        marginLeft: 16
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8"
        }
    },

    progress: {
        top: "50%",
        left: "50%",
        color: "#4CAF50",
        position: "absolute",
        "margin-top": -12,
        "margin-left": -12
    },

    redBorder: {
        border: "1px solid red"
    },

    marginTop: {
        marginTop: 14
    }
});

// See https://react-form.js.org/#/custom-input
class FileInputWrapper extends Component {
    constructor(props, context) {
        super(props, context);

        var displayLabel = null;
        var val = this.props.fieldApi.getValue();
        if (val) displayLabel = val.replace("/api/db/proposals/", "/media/lsohc/");
        this.state = {
            displayLabel: displayLabel,
            useUrl: val ? true : false
        };
    }

    getBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    handleChange = e => {
        let value = e.target.files[0];
        if (!value) return;

        const { accept } = this.props;

        if (accept) {
            var ext = value.name.match(/\.([^.]+)$/)[1];
            if (!accept.includes(ext)) {
                alert("Unsupported file type");
                return;
            }
        }

        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;

        this.setState({ displayLabel: value.name, useUrl: false });

        this.getBase64(value).then(data => {
            setValue(data);
            if (eventHandle) {
                eventHandle(data);
            }
        });
    };

    removeFile = () => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;

        this.setState({ displayLabel: null, useUrl: false });
        setValue(null);
        if (eventHandle) {
            eventHandle(null);
        }
    };

    render() {
        const { classes, label, children, id, aria, disabled, showLoading, fieldApi, marginStyle, ...rest } = this.props;
        const { displayLabel, useUrl } = this.state;

        const { getError, getWarning, getSuccess } = fieldApi;

        var error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success;

        return (
            <div className={marginStyle && classes.marginTop}>
                <Typography gutterBottom>{children || label}</Typography>
                <input
                    disabled={disabled}
                    className={classes.input}
                    id={id}
                    type="file"
                    onChange={this.handleChange}
                    {...rest}
                />
                <label htmlFor={id}>
                    <Button disabled={disabled} contained className={hasError && classes.redBorder} component="span">
                        {useUrl ? "Reupload New File" : "Select File"}
                    </Button>
                </label>
                <Typography className={classes.inline}>
                    {useUrl && (
                        <>
                            <a
                                className={classes.linkColor}
                                href={displayLabel}
                                target="_blank"
                                rel="noopener noreferrer">
                                View File
                            </a>
                            <Button disabled={disabled} onClick={this.removeFile} style={{ marginLeft: 24 }}>
                                Delete File
                            </Button>
                        </>
                    )}
                    {!useUrl && displayLabel && (
                        <>
                            <span>{displayLabel}</span> {showLoading && <CircularProgress size={12} />}
                        </>
                    )}
                </Typography>
                {hasError && <FormHelperText style={{ color: "#be0000" }}>{errorText}</FormHelperText>}
            </div>
        );
    }
}

const FileInput = FormField(FileInputWrapper);

export default withStyles(styles)(FileInput);
