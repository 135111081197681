import React, { Component } from "react";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import { budget_items } from "../../api/constants";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import { 
    Proposal, ProposalAmountNonOHF, ProposalFutureMaintain, ProposalPastOHF, 
    ProposalTimeline, ProposalBudget, ProposalBudgetPersonnel, ProposalBudgetCapitalEquipment,
    ProposalParcel
} from "./models";
import Select from "../common/Select";
import TextField from "../common/TextField";
import HelpLabel from "../common/HelpLabel";
import Switch from "../common/Switch";
import { createSelector } from "../common/orm";

const allProposals = createSelector(schema => {
    return schema.Proposal.all()
        .orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray();
});

const getAmountNonOHFs = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalAmountNonOHF.all().orderBy("id").toModelArray();
    }
);

const getFutureMaintains = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalFutureMaintain.all().orderBy("id").toModelArray();
    }
);

const getPastOHFs = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalPastOHF.all().orderBy("id").toModelArray();
    }
);

const getTimelines = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalTimeline.all().orderBy("id").toModelArray();
    }
);

const getBudgets = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalBudget.all().orderBy("order").toModelArray()
        .map(budget => ({
            _personnel: budget.budgetpersonnel.all().toRefArray(),
            _capital: budget.budgetcapital.all().toRefArray(),
            ...budget.ref
        }));;
    }
);

const getParcels = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalParcel.all().orderBy("name", "asc").toModelArray();
    }
);


const styles = {
    dialogMin: {
        minWidth: "400px"
    },
    flex: {
        flex: 1
    },
    button: {
        float: "right"
    },
    gutterTop: {
        marginTop: ".6em"
    },
    minWidth: {
        minWidth: 600
    }
};

class NewProposal extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            copyProposal: null,
            showCopyFields: false
        };
    }

    addProposal(values) {
        const { 
            proposals, amountNonOHFs, futureMaintains, pastOHFs, timelines, budgets, parcels,
            returnDialog, ormProposalCreate, history, 
            ormProposalAmountNonOHFCreate, ormProposalFutureMaintainCreate, ormProposalPastOHFCreate,
            ormProposalTimelineCreate, ormProposalBudgetCreate, ormProposalBudgetPersonnelCreate, 
            ormProposalBudgetCapitalEquipmentCreate, ormProposalParcelCreate
        } = this.props;
        const { copyProposal } = this.state;

        var new_proposal = {
            name: values.name,
            ml_year: (new Date().getFullYear() + 1).toString()
        };

        // Use values from a previous year
        if (copyProposal) {
            var previous_proposal = proposals.find(cY => cY.id === copyProposal);
            // Deep copy
            previous_proposal = JSON.parse(JSON.stringify(previous_proposal));
            delete previous_proposal.id;
            delete previous_proposal.create_date;

            // Later properties overwrite earlier properties with the same name.
            new_proposal = { ...previous_proposal, ...new_proposal };
        }

        new_proposal["status"] = "New";

        ormProposalCreate(new_proposal).then(id => {
            if (copyProposal) {
                amountNonOHFs.filter(a => a.proposal.id === copyProposal).forEach(function(a) {
                    var previous_amount = JSON.parse(JSON.stringify(a));
                    previous_amount["proposal_id"] = id;
                    delete previous_amount.id;
                    ormProposalAmountNonOHFCreate(previous_amount);
                });
                futureMaintains.filter(a => a.proposal.id === copyProposal).forEach(function(a) {
                    var previous_future = JSON.parse(JSON.stringify(a));
                    previous_future["proposal_id"] = id;
                    delete previous_future.id;
                    ormProposalFutureMaintainCreate(previous_future);
                });
                pastOHFs.filter(a => a.proposal.id  === copyProposal).forEach(function(a) {
                    var previous_fast = JSON.parse(JSON.stringify(a));
                    previous_fast["proposal_id"] = id;
                    delete previous_fast.id;
                    ormProposalPastOHFCreate(previous_fast);
                });
                timelines.filter(a => a.proposal.id  === copyProposal).forEach(function(a) {
                    var previous_timeline = JSON.parse(JSON.stringify(a));
                    previous_timeline["proposal_id"] = id;
                    delete previous_timeline.id;
                    ormProposalTimelineCreate(previous_timeline);
                });
                parcels.filter(a => a.proposal.id  === copyProposal).forEach(function(a) {
                    var previous_parcel = JSON.parse(JSON.stringify(a));
                    previous_parcel["proposal_id"] = id;
                    delete previous_parcel.id;
                    ormProposalParcelCreate(previous_parcel);
                });
                budgets.filter(a => a.proposal === copyProposal).forEach(function(a) {
                    var previous_budget = JSON.parse(JSON.stringify(a));
                    previous_budget["proposal_id"] = id;
                    previous_budget["proposal"] = id;
                    delete previous_budget.id;
                    ormProposalBudgetCreate(previous_budget).then(budget_id => {
                        if (previous_budget["item"] === "Personnel") {
                            a._personnel.forEach(function(personnel) {
                                var previous_personnel = JSON.parse(JSON.stringify(personnel));
                                previous_personnel["budget_id"] = budget_id;
                                previous_personnel["budget"] = budget_id;
                                delete previous_personnel.id;
                                ormProposalBudgetPersonnelCreate(previous_personnel)
                            })
                        }
                        if (previous_budget["item"] === "Capital Equipment") {
                            a._capital.forEach(function(capital) {
                                var previous_capital = JSON.parse(JSON.stringify(capital));
                                previous_capital["budget_id"] = budget_id;
                                previous_capital["budget"] = budget_id;
                                delete previous_capital.id;
                                ormProposalBudgetCapitalEquipmentCreate(previous_capital)
                            })
                        }
                    });
                });
            } else {
                budget_items.forEach(function(item, i) {
                    var readonly = false;
                    if (item === "Personnel" || item === "Capital Equipment" || item === "Grand Total")
                        readonly = true;
                    ormProposalBudgetCreate({
                        proposal: id,
                        order: i,
                        item: item,
                        readonly: readonly
                    })
                });
            }
            returnDialog();
            history.push("/proposal/" + id);
        });
    }

    proposalDropDown = value => {
        this.setState({ copyProposal: value });
    };

    showCopyFields = value => {
        this.setState({ showCopyFields: value, copyProposal: null });
    };

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, proposals } = this.props;

        const { previousFarm, showCopyFields } = this.state;

        function makeOptionsProposals(table) {
            return table.map(row => ({
                label: row.ml_year + " " + row.name,
                value: row.id
            }));
        }

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        Add New Proposal
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            validateError={this.errorValidator}
                            onSubmit={values => this.addProposal(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField field="name" fullWidth>
                                                <HelpLabel
                                                    inputLabel="Project or Program Title"
                                                    showLabel={true}
                                                    helpText="Project or Program Title should be descriptive and include the correct program phase, if applicable."
                                                />
                                            </TextField>
                                            {proposals.length > 0 && (
                                                <div>
                                                    <Switch
                                                        field="switch"
                                                        name="switch"
                                                        eventHandle={value => this.showCopyFields(value)}
                                                        label="Would you like to copy from a previous year?"
                                                    />
                                                    {showCopyFields && (
                                                        <Select
                                                            field="fa"
                                                            label="Your Previous Proposals"
                                                            eventHandle={value => this.proposalDropDown(value)}
                                                            options={makeOptionsProposals(proposals)}
                                                            fullWidth
                                                            value={previousFarm}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}>
                                                Add and Proceed to Edit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

NewProposal = connect(
    (state, ownProps) => ({
        proposals: allProposals(state),
        amountNonOHFs: getAmountNonOHFs(state, ownProps),
        futureMaintains: getFutureMaintains(state, ownProps),
        pastOHFs: getPastOHFs(state, ownProps),
        timelines: getTimelines(state, ownProps),
        budgets: getBudgets(state, ownProps),
        parcels: getParcels(state, ownProps),
    }),
    {
        ...Proposal.actions,
        ...ProposalAmountNonOHF.actions,
        ...ProposalFutureMaintain.actions,
        ...ProposalPastOHF.actions,
        ...ProposalTimeline.actions,
        ...ProposalBudget.actions,
        ...ProposalBudgetPersonnel.actions,
        ...ProposalBudgetCapitalEquipment.actions,
        ...ProposalParcel.actions
    }
)(NewProposal);

export default withStyles(styles)(withRouter(NewProposal));
