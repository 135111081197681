import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { AccomplishmentPlan } from "../models";
import TextField from "../../common/TextField";
import Checkbox from "../../common/Checkbox";
import CheckboxGroup from "../../common/CheckboxGroup";
import RadioGroup from "../../common/RadioGroup";
import Subquestion from "../../common/Subquestion";
import FileInput from "../../common/FileInput";
import HelpLabel from "../../common/HelpLabel";
import { MAKE_OPTIONS } from "../../../api/constants";
import { getValue } from "../../../api/utils";


const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    }
});

class FederalTab extends Component {
    state = {
        uploadEdit: false
    }
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Accomplishment Plan: Federal Funds - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateAP(values, fromStepper) {
        const { ormAccomplishmentPlanUpdate, history, handleUnsavedFields, handleNext, accomplishment_plan } = this.props;
        const { id } = accomplishment_plan;
        const { uploadEdit } = this.state;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        // FIXME: Values synced from the server are not updated on the form
        // The orm reducer is called twice (_UPDATE and _UPDATE_PUSHED) and the 2nd update call does seem to have the correct payload
        // The render functions are called multiple times and later on the ap prop value does have the correct information from the server
        // I suspect it is a react-forms bug that is using the defaultValues of the first time it was rendered?
        // This fix is on every tab, but a special check is here to make sure the user didn't actually upload a new document
        if (!uploadEdit && accomplishment_plan.federal_funds_confirmation_document !== "") {
            values.federal_funds_confirmation_document = accomplishment_plan.federal_funds_confirmation_document;
        }
        values.modify_date_label = accomplishment_plan.modify_date_label;
        values.signup_criteria = accomplishment_plan.signup_criteria;

        ormAccomplishmentPlanUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    }

    applyOther = (value, element) => {
        const { ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const { id } = this.props.accomplishment_plan;
        ormAccomplishmentPlanUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    fileChange = () => {
        this.setState({uploadEdit: true});
    };

    render() {
        const { 
            classes,
            accomplishment_plan,
            handleUnsavedFields,
            settings,
            ActionMenu, authState
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || (accomplishment_plan.status === "First Draft" || accomplishment_plan.status === "Approved First Draft" || accomplishment_plan.status === "Started Request for Amendment Change"))
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;
        
        const federal_funds_YES = getValue(accomplishment_plan, "federal_funds") === "true" || getValue(accomplishment_plan, "federal_funds") === true;
        const federal_funds_confirmed_YES = getValue(accomplishment_plan, "federal_funds_confirmed") === "true" || getValue(accomplishment_plan, "federal_funds_confirmed") === true;
        const federal_funds_confirmed_NO = getValue(accomplishment_plan, "federal_funds_confirmed") === "false" || getValue(accomplishment_plan, "federal_funds_confirmed") === false;
        const federal_funds_type_CASH = getValue(accomplishment_plan, "federal_funds_type").indexOf("Cash Match") > -1;
        const federal_funds_type_KIND = getValue(accomplishment_plan, "federal_funds_type").indexOf("In-Kind Match") > -1;
        const federal_funds_type_OTHER = getValue(accomplishment_plan, "federal_funds_type").indexOf("Other") > -1;
        
        const showCheckbox = getValue(accomplishment_plan, "proposal_acknowledgement_federal") === false;

        return (
            <Form
                getApi={el => (this.form = el)}
                key={accomplishment_plan.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={accomplishment_plan.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateAP(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HelpLabel
                                    inputLabel="Federal Funds"
                                    title={true}
                                    showLabel={true}
                                    helpText={settings.federal_funds_tab_help}
                                />
                                {ActionMenu}
                            </Grid>
                                <Grid item xs={12} lg={10} xl={7}>
                                    {showCheckbox && (
                                        <div className={classes.checkbox}>
                                            <Checkbox
                                                field="proposal_acknowledgement_federal"
                                                eventHandle={this.applyOther}
                                                disabled={is_read_only} 
                                                label="Please check this box to acknowledge that the information in this tab was brought over from your proposal and is still valid."
                                            />
                                        </div>
                                    )}
                                </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <RadioGroup
                                    field="federal_funds"
                                    name="federal_funds"
                                    disabled={is_read_only}
                                    fullWidth
                                    eventHandle={this.applyOther}
                                    options={[
                                        { label: "Yes", value: "true" },
                                        { label: "No", value: "false" }
                                    ]}
                                    alignment={true}
                                    label="Do you anticipate federal funds as a match for this program?"
                                />
                                {federal_funds_YES && (
                                    <Subquestion component={
                                        <div>
                                            <RadioGroup
                                                field="federal_funds_confirmed"
                                                name="federal_funds_confirmed"
                                                disabled={is_read_only}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                                options={[
                                                    { label: "Yes", value: "true" },
                                                    { label: "No", value: "false" }
                                                ]}
                                                alignment={true}
                                                label="Are the funds confirmed?"
                                            />
                                            {federal_funds_confirmed_YES && (
                                                <Subquestion component={
                                                    <div>
                                                        <FileInput
                                                            field="federal_funds_confirmation_document"
                                                            id="federal_funds_confirmation_document"
                                                            disabled={is_read_only}
                                                            label="Attach documentation confirming the use of federal funds."
                                                            fullWidth
                                                            eventHandle={this.fileChange}
                                                        />
                                                        <CheckboxGroup
                                                            field="federal_funds_type"
                                                            label="What are the types of funds?"
                                                            row={true}
                                                            disabled={is_read_only}
                                                            eventHandle={this.applyOther}
                                                            options={MAKE_OPTIONS([
                                                                "Cash Match",
                                                                "In-Kind Match",
                                                                'Other'
                                                            ])}
                                                        />
                                                        {federal_funds_type_CASH && (
                                                            <Subquestion component={
                                                                <TextField
                                                                    field="federal_funds_type_cash"
                                                                    disabled={is_read_only}
                                                                    label="Cash Match: Enter Amount ($)"
                                                                    useTextFormat
                                                                />
                                                            } />
                                                        )}
                                                        {federal_funds_type_KIND && (
                                                            <Subquestion component={
                                                                <TextField
                                                                    field="federal_funds_type_inkind"
                                                                    disabled={is_read_only}
                                                                    label="In-Kind Match: Enter Amount ($)"
                                                                    useTextFormat
                                                                />
                                                            } />
                                                        )}
                                                        {federal_funds_type_OTHER && (
                                                            <Subquestion component={
                                                                <TextField
                                                                    field="federal_funds_type_other"
                                                                    disabled={is_read_only}
                                                                    label="Other: Explain"
                                                                    fullWidth
                                                                />
                                                            } />
                                                        )}
                                                    </div>
                                                } />
                                            )}
                                            {federal_funds_confirmed_NO && (
                                                <Subquestion component={
                                                    <TextField
                                                        field="federal_funding_anticipated"
                                                        disabled={is_read_only}
                                                        label="What is the approximate date you anticipate receiving confirmation of the federal funds?"
                                                        fullWidth
                                                    />
                                                } />
                                            )}
                                        </div>
                                    } />
                                )}
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Output Tables <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

FederalTab = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        ...AccomplishmentPlan.actions
    }
)(FederalTab);

export default withStyles(styles)(withRouter(FederalTab));
