import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { Proposal } from "../models";
import TextField from "../../common/TextField";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import Subquestion from "../../common/Subquestion";
import HelpLabel from "../../common/HelpLabel";
import { getValue } from "../../../api/utils";

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        },
        marginBottom:16
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    totalRow: {
        backgroundColor: "rgb(221, 221, 221) !important",
        borderTop: "3px solid #000",
        height:36
    },
    widthSixty: {
        width: 160
    }
});

const dollars_format = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
});
const acres_format = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 0
});

const acresRender = (rowData) => {
    return rowData ? acres_format.format(rowData) : 0
};

const dollarsRender = (rowData) => {
    return rowData ? dollars_format.format(rowData) : 0
};

class OutputTab extends Component {
    parentFormKey = window.performance.now();

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Output Tables - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    };

    componentWillUnmount() {
        this.props.onRef(undefined);
    };

    updateProposal(values, fromStepper) {
        const { ormProposalUpdate, history, handleUnsavedFields, handleNext, proposal } = this.props;
        const { id } = proposal;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        ormProposalUpdate({
            id: id,
            activeStep: values.activeStep,
            output_target_shoreline: values.output_target_shoreline
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    };

    render() {
        const { 
            classes,
            proposal,
            handleUnsavedFields,
            ormProposalUpdateLocalOnly,
            ormProposalUpdate,
            settings,
            ActionMenu, authState
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || proposal.status === "New")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        const SHOW_NATIVE_PRAIRIE_TABLE = (
            getValue(proposal, "output_acres_resourcetype_restore_prairie") > 0 ||
            getValue(proposal, "output_acres_resourcetype_feepilt_prairie") > 0 ||
            getValue(proposal, "output_acres_resourcetype_feewopilt_prairie") > 0 ||
            getValue(proposal, "output_acres_resourcetype_easement_prairie") > 0 ||
            getValue(proposal, "output_acres_resourcetype_enhance_prairie") > 0
        );
        var requested = proposal.funds_requested;
        if (!proposal.funds_requested)
            requested = "0";
        var temp = parseFloat(requested.replace(/,/g, '')) - parseFloat(proposal.output_dollars_resourcetype_total_total);
        if (temp !== proposal.formData._t2) {
            proposal.formData._t2 = temp;
            this.parentFormKey = window.performance.now();
        }

        temp = parseFloat(requested.replace(/,/g, '')) - parseFloat(proposal.output_dollars_ecologicalsection_total_total);
        if (temp !== proposal.formData._t4) {
            proposal.formData._t4 = temp;
            this.parentFormKey = window.performance.now();
        }

        return (
            <Form
                getApi={el => (this.form = el)}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={proposal.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HelpLabel
                                    inputLabel="Output Tables"
                                    showLabel={true}
                                    title={true}
                                    helpText={settings.output_tables_tab_help}
                                />
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3" gutterBottom>Acres by Resource Type (Table 1)</Typography>
                                <OutputTableAcresByResource
                                    ormProposalUpdate={ormProposalUpdate} 
                                    proposal={proposal}
                                    is_read_only={is_read_only}
                                    classes={classes}
                                    ormProposalUpdateLocalOnly={ormProposalUpdateLocalOnly}
                                />
                            </Grid>
                            {SHOW_NATIVE_PRAIRIE_TABLE && (
                                <Subquestion component={
                                    <>
                                    <Typography variant="h3" gutterBottom>How many of these Prairie acres are Native Prairie? (Table 1b)</Typography>
                                    <OutputTableNativePrairie
                                        ormProposalUpdate={ormProposalUpdate} 
                                        proposal={proposal}
                                        is_read_only={is_read_only}
                                        classes={classes}
                                        ormProposalUpdateLocalOnly={ormProposalUpdateLocalOnly}
                                    />
                                    </>
                                } />
                            )}
                            <Grid item xs={12}>
                                <Typography style={{float:"left",marginTop:12}} variant="h3">Total Request Funding by Resource Type (Table 2)</Typography>
                                <Form
                                    key={this.parentFormKey}
                                    dontValidateOnMount={true}
                                    validateOnSubmit={true}
                                    defaultValues={proposal.formData}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <div style={{float:"right",marginTop:-20}}>
                                                <TextField
                                                    disabled={true}
                                                    field="funds_requested" useTextFormat
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Budget Total</i>
                                                </TextField>
                                                <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>-</span>
                                                <TextField
                                                    disabled={true}
                                                    field="output_dollars_resourcetype_total_total" useTextFormat
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Amount allocated in table</i>
                                                </TextField>
                                                <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>=</span>
                                                <TextField
                                                    disabled={true}
                                                    field="_t2" useTextFormat
                                                    color={proposal.formData._t2 === 0 ? "green" : proposal.formData._t2 > 0 ? "yellow" : "red"}
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Balance left to allocate</i>
                                                </TextField>
                                            </div>
                                        </form>
                                    )}
                                </Form>
                                <OutputTableDollarsByResource
                                    ormProposalUpdate={ormProposalUpdate} 
                                    proposal={proposal}
                                    is_read_only={is_read_only}
                                    classes={classes}
                                    ormProposalUpdateLocalOnly={ormProposalUpdateLocalOnly}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3" gutterBottom>Acres within each Ecological Section (Table 3)</Typography>
                                <OutputTableAcresBySection
                                    ormProposalUpdate={ormProposalUpdate} 
                                    proposal={proposal}
                                    is_read_only={is_read_only}
                                    classes={classes}
                                    ormProposalUpdateLocalOnly={ormProposalUpdateLocalOnly}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{float:"left",marginTop:12}} variant="h3">Total Requested Funding within each Ecological Section (Table 4)</Typography>
                                <Form
                                    key={this.parentFormKey}
                                    dontValidateOnMount={true}
                                    validateOnSubmit={true}
                                    defaultValues={proposal.formData}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <div style={{float:"right",marginTop:-20}}>
                                                <TextField
                                                    disabled={true}
                                                    field="funds_requested" useTextFormat
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Budget Total</i>
                                                </TextField>
                                                <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>-</span>
                                                <TextField
                                                    disabled={true}
                                                    field="output_dollars_ecologicalsection_total_total" useTextFormat
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Amount allocated in table</i>
                                                </TextField>
                                                <span style={{marginLeft:5,marginRight:5,marginTop:45,display:"inline-block"}}>=</span>
                                                <TextField
                                                    disabled={true}
                                                    field="_t4" useTextFormat
                                                    color={proposal.formData._t4 === 0 ? "green" : proposal.formData._t4 > 0 ? "yellow" : "red"}
                                                    className={classes.widthSixty}
                                                >
                                                    <i style={{textAlign:'center',display:"block"}}>Balance left to allocate</i>
                                                </TextField>
                                            </div>
                                        </form>
                                    )}
                                </Form>
                                <OutputTableDollarsBySection
                                    ormProposalUpdate={ormProposalUpdate} 
                                    proposal={proposal}
                                    classes={classes}
                                    is_read_only={is_read_only}
                                    ormProposalUpdateLocalOnly={ormProposalUpdateLocalOnly}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField disabled={is_read_only} field="output_target_shoreline" label="Target Lake/Stream/River (Feet or Miles)" />
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Outcomes <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

class OutputTableAcresByResource extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element, row) => {
        const { proposal } = this.props;

        value = parseInt(value) || 0;

        // Get Row Total
        var total = (parseInt(row.wetland === element ? value : getValue(proposal, row.wetland)) || 0) + 
        (parseInt(row.prairie === element ? value : getValue(proposal, row.prairie)) || 0) + 
        (parseInt(row.forest === element ? value : getValue(proposal, row.forest)) || 0) + 
        (parseInt(row.habitat === element ? value : getValue(proposal, row.habitat)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_wetland")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_wetland";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.wetland === element ? value : getValue(proposal, r.wetland)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_prairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie === element ? value : getValue(proposal, r.prairie)) || 0)
            });
        } else if (element.includes("_forest")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_forest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forest === element ? value : getValue(proposal, r.forest)) || 0)
            });
        } else if (element.includes("_habitat")) {
            totalCol = 0;
            updateField = "output_acres_resourcetype_total_habitat";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.habitat === element ? value : getValue(proposal, r.habitat)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_wetland")) {
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_forest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_forest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_forest")) {
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_habitat")) {
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_resourcetype_total_forest")) || 0);
        }

        return {
            id: proposal.id,
            [element]: value,
            [row.total]: total,
            [updateField]: totalCol,
            output_acres_resourcetype_total_total: finalTotal
        };
    }

    updateRecord = (value, element, row) => {        
        const { ormProposalUpdate, ormProposalUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormProposalUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormProposalUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { proposal, classes, is_read_only } = this.props;
        const priority_resources_activity = getValue(proposal, "priority_resources_activity");
        var columnData = [
            { id: "type", title: "Type", allowSort: false }
        ];
        if (priority_resources_activity.indexOf("Wetlands") > -1) {
            columnData.push({ id: "wetland", label: "Wetland (acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Forest") > -1) {
            columnData.push({ id: "forest", label: "Forest (acres)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Habitat") > -1) {
            columnData.push({ id: "habitat", label: "Habitat (acres)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (acres)", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(proposal, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    wetland: "output_acres_resourcetype_restore_wetland",
                    prairie: "output_acres_resourcetype_restore_prairie",
                    forest: "output_acres_resourcetype_restore_forest",
                    habitat: "output_acres_resourcetype_restore_habitat",
                    total: "output_acres_resourcetype_restore_total"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    wetland: "output_acres_resourcetype_feepilt_wetland",
                    prairie: "output_acres_resourcetype_feepilt_prairie",
                    forest: "output_acres_resourcetype_feepilt_forest",
                    habitat: "output_acres_resourcetype_feepilt_habitat",
                    total: "output_acres_resourcetype_feepilt_total"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    wetland: "output_acres_resourcetype_feewopilt_wetland",
                    prairie: "output_acres_resourcetype_feewopilt_prairie",
                    forest: "output_acres_resourcetype_feewopilt_forest",
                    habitat: "output_acres_resourcetype_feewopilt_habitat",
                    total: "output_acres_resourcetype_feewopilt_total"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    wetland: "output_acres_resourcetype_easement_wetland",
                    prairie: "output_acres_resourcetype_easement_prairie",
                    forest: "output_acres_resourcetype_easement_forest",
                    habitat: "output_acres_resourcetype_easement_habitat",
                    total: "output_acres_resourcetype_easement_total"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    wetland: "output_acres_resourcetype_enhance_wetland",
                    prairie: "output_acres_resourcetype_enhance_prairie",
                    forest: "output_acres_resourcetype_enhance_forest",
                    habitat: "output_acres_resourcetype_enhance_habitat",
                    total: "output_acres_resourcetype_enhance_total"
                });
            }
            this.rows.push({
                type: "Total",
                wetland: "output_acres_resourcetype_total_wetland",
                prairie: "output_acres_resourcetype_total_prairie",
                forest: "output_acres_resourcetype_total_forest",
                habitat: "output_acres_resourcetype_total_habitat",
                total: "output_acres_resourcetype_total_total",
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {priority_resources_activity.indexOf("Wetlands") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(proposal, n.wetland))}</b>
                                        ) : (
                                            <Form key={n.wetland} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.wetland} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Prairie") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(proposal, n.prairie))}</b>
                                        ) : (
                                            <Form key={n.prairie} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(proposal, n.forest))}</b>
                                        ) : (
                                            <Form key={n.forest} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forest} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Habitat") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(proposal, n.habitat))}</b>
                                        ) : (
                                            <Form key={n.habitat} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.habitat} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                <CustomTableCell>
                                    <b>{acresRender(getValue(proposal, n.total))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableNativePrairie extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element) => {
        const { proposal } = this.props;

        value = parseInt(value) || 0;

        // Get Column Total
        var totalCol = 0;
        this.rows.forEach(function(r) {
            if (r.type !== "Total")
                totalCol += (parseInt(r.prairie === element ? value : getValue(proposal, r.prairie)) || 0)
        });

        return {
            id: proposal.id,
            [element]: value,
            output_acres_resourcetype_total_nativeprairie: totalCol,
        };
    }

    updateRecord = (value, element) => {
        const { ormProposalUpdate, ormProposalUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element);
            ormProposalUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormProposalUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { classes, proposal, is_read_only } = this.props;
        var columnData = [
            { id: "type", label: "Type", allowSort: false },
            { id: "prairie", label: "Native Prairie (acres)", numeric: true, allowSort: false }
        ];
        if (this.rows.length === 0) {
            const activity_types = getValue(proposal, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    prairie: "output_acres_resourcetype_restore_nativeprairie"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    prairie: "output_acres_resourcetype_feepilt_nativeprairie"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    prairie: "output_acres_resourcetype_feewopilt_nativeprairie"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    prairie: "output_acres_resourcetype_easement_nativeprairie"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    prairie: "output_acres_resourcetype_enhance_nativeprairie"
                });
            }
            this.rows.push({
                type: "Total",
                prairie: "output_acres_resourcetype_total_nativeprairie"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {n.type === "Total" ? (
                                        <b>{acresRender(getValue(proposal, n.prairie))}</b>
                                    ) : (
                                        <Form key={n.prairie} defaultValues={proposal}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField 
                                                        disabled={is_read_only} useNumber tableFormat 
                                                        eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                        field={n.prairie} label="" 
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    )}
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableDollarsByResource extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};
    rows = [];

    getValues = (value, element, row) => {
        const { proposal } = this.props;

        value = (Math.round(parseInt(value) / 100) * 100) || 0;

        // Get Row Total
        var total = (parseInt(row.wetland === element ? value : getValue(proposal, row.wetland)) || 0) + 
        (parseInt(row.prairie === element ? value : getValue(proposal, row.prairie)) || 0) + 
        (parseInt(row.forest === element ? value : getValue(proposal, row.forest)) || 0) + 
        (parseInt(row.habitat === element ? value : getValue(proposal, row.habitat)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_wetland")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_wetland";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.wetland === element ? value : getValue(proposal, r.wetland)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_prairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie === element ? value : getValue(proposal, r.prairie)) || 0)
            });
        } else if (element.includes("_forest")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_forest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forest === element ? value : getValue(proposal, r.forest)) || 0)
            });
        } else if (element.includes("_habitat")) {
            totalCol = 0;
            updateField = "output_dollars_resourcetype_total_habitat";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.habitat === element ? value : getValue(proposal, r.habitat)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_wetland")) {
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_forest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_forest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_forest")) {
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_habitat")) || 0);
        }
        if (element.includes("_habitat")) {
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_wetland")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_resourcetype_total_forest")) || 0);
        }

        return {
            id: proposal.id,
            [element]: value,
            [row.total]: total,
            [updateField]: totalCol,
            output_dollars_resourcetype_total_total: finalTotal
        };
    }

    updateRecord = (value, element, row) => {        
        const { ormProposalUpdate, ormProposalUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormProposalUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormProposalUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, proposal, is_read_only } = this.props;
        const priority_resources_activity = getValue(proposal, "priority_resources_activity");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (priority_resources_activity.indexOf("Wetlands") > -1) {
            columnData.push({ id: "wetland", label: "Wetland ($)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie ($)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Forest") > -1) {
            columnData.push({ id: "forest", label: "Forest ($)", numeric: true, allowSort: false });
        }
        if (priority_resources_activity.indexOf("Habitat") > -1) {
            columnData.push({ id: "habitat", label: "Habitat ($)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total ($)", type: "numeric", numeric: true, allowSort: false });
        
        if (this.rows.length === 0) {
            const activity_types = getValue(proposal, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    wetland: "output_dollars_resourcetype_restore_wetland",
                    prairie: "output_dollars_resourcetype_restore_prairie",
                    forest: "output_dollars_resourcetype_restore_forest",
                    habitat: "output_dollars_resourcetype_restore_habitat",
                    total: "output_dollars_resourcetype_restore_total"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    wetland: "output_dollars_resourcetype_feepilt_wetland",
                    prairie: "output_dollars_resourcetype_feepilt_prairie",
                    forest: "output_dollars_resourcetype_feepilt_forest",
                    habitat: "output_dollars_resourcetype_feepilt_habitat",
                    total: "output_dollars_resourcetype_feepilt_total"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    wetland: "output_dollars_resourcetype_feewopilt_wetland",
                    prairie: "output_dollars_resourcetype_feewopilt_prairie",
                    forest: "output_dollars_resourcetype_feewopilt_forest",
                    habitat: "output_dollars_resourcetype_feewopilt_habitat",
                    total: "output_dollars_resourcetype_feewopilt_total"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    wetland: "output_dollars_resourcetype_easement_wetland",
                    prairie: "output_dollars_resourcetype_easement_prairie",
                    forest: "output_dollars_resourcetype_easement_forest",
                    habitat: "output_dollars_resourcetype_easement_habitat",
                    total: "output_dollars_resourcetype_easement_total"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    wetland: "output_dollars_resourcetype_enhance_wetland",
                    prairie: "output_dollars_resourcetype_enhance_prairie",
                    forest: "output_dollars_resourcetype_enhance_forest",
                    habitat: "output_dollars_resourcetype_enhance_habitat",
                    total: "output_dollars_resourcetype_enhance_total"
                });
            }
            this.rows.push({
                type: "Total",
                wetland: "output_dollars_resourcetype_total_wetland",
                prairie: "output_dollars_resourcetype_total_prairie",
                forest: "output_dollars_resourcetype_total_forest",
                habitat: "output_dollars_resourcetype_total_habitat",
                total: "output_dollars_resourcetype_total_total"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {priority_resources_activity.indexOf("Wetlands") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(proposal, n.wetland))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.wetland] ? this.formkeys[n.wetland]  : n.wetland} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.wetland} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Prairie") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(proposal, n.prairie))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.prairie] ? this.formkeys[n.prairie]  : n.prairie} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(proposal, n.forest))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.forest] ? this.formkeys[n.forest]  : n.forest} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forest} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {priority_resources_activity.indexOf("Habitat") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(proposal, n.habitat))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.habitat] ? this.formkeys[n.habitat]  : n.habitat} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.habitat} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(proposal, n.total))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableAcresBySection extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    rows = [];

    getValues = (value, element, row) => {
        const { proposal } = this.props;

        value = parseInt(value) || 0;

        // Get Row Total
        var total = (parseInt(row.metrourban === element ? value : getValue(proposal, row.metrourban)) || 0) + 
        (parseInt(row.forestprairie === element ? value : getValue(proposal, row.forestprairie)) || 0) + 
        (parseInt(row.seforest === element ? value : getValue(proposal, row.seforest)) || 0) + 
        (parseInt(row.prairie === element ? value : getValue(proposal, row.prairie)) || 0) + 
        (parseInt(row.northernforest === element ? value : getValue(proposal, row.northernforest)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_metrourban")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_metrourban";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.metrourban === element ? value : getValue(proposal, r.metrourban)) || 0)
            });
        } else if (element.includes("_forestprairie")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_forestprairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forestprairie === element ? value : getValue(proposal, r.forestprairie)) || 0)
            });
        } else if (element.includes("_seforest")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_seforest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.seforest === element ? value : getValue(proposal, r.seforest)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_prairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie === element ? value : getValue(proposal, r.prairie)) || 0)
            });
        } else if (element.includes("_northernforest")) {
            totalCol = 0;
            updateField = "output_acres_ecologicalsection_total_northernforest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.northernforest === element ? value : getValue(proposal, r.northernforest)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_metrourban")) {
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_forestprairie")) {
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_seforest")) {
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_northernforest")) {
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_acres_ecologicalsection_total_prairie")) || 0);
        }

        return {
            id: proposal.id,
            [element]: value,
            [row.total]: total,
            [updateField]: totalCol,
            output_acres_ecologicalsection_total_total: finalTotal
        };
    };

    updateRecord = (value, element, row) => {        
        const { ormProposalUpdate, ormProposalUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormProposalUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 250);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormProposalUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    render() {
        const { classes, proposal, is_read_only } = this.props;
        const eco_regions = getValue(proposal, "eco_regions");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (eco_regions.indexOf("Metro / Urban") > -1) {
            columnData.push({ id: "metrourban", label: "Metro/Urban (acres)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Forest / Prairie Transition") > -1) {
            columnData.push({ id: "forestprairie", label: "Forest/Prairie (acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Southeast Forest") > -1) {
            columnData.push({ id: "seforest", label: "SE Forest (acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie (acres)", numeric: true, allowSort: false  });
        }
        if (eco_regions.indexOf("Northern Forest") > -1) {
            columnData.push({ id: "northernforest", label: "Northern Forest (acres)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total (acres)", type: "numeric", numeric: true, allowSort: false  });

        if (this.rows.length === 0) {
            const activity_types = getValue(proposal, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    metrourban: "output_acres_ecologicalsection_restore_metrourban",
                    forestprairie: "output_acres_ecologicalsection_restore_forestprairie",
                    seforest: "output_acres_ecologicalsection_restore_seforest",
                    prairie: "output_acres_ecologicalsection_restore_prairie",
                    northernforest: "output_acres_ecologicalsection_restore_northernforest",
                    total: "output_acres_ecologicalsection_restore_total"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    metrourban: "output_acres_ecologicalsection_feepilt_metrourban",
                    forestprairie: "output_acres_ecologicalsection_feepilt_forestprairie",
                    seforest: "output_acres_ecologicalsection_feepilt_seforest",
                    prairie: "output_acres_ecologicalsection_feepilt_prairie",
                    northernforest: "output_acres_ecologicalsection_feepilt_northernforest",
                    total: "output_acres_ecologicalsection_feepilt_total"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    metrourban: "output_acres_ecologicalsection_feewopilt_metrourban",
                    forestprairie: "output_acres_ecologicalsection_feewopilt_forestprairie",
                    seforest: "output_acres_ecologicalsection_feewopilt_seforest",
                    prairie: "output_acres_ecologicalsection_feewopilt_prairie",
                    northernforest: "output_acres_ecologicalsection_feewopilt_northernforest",
                    total: "output_acres_ecologicalsection_feewopilt_total"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    metrourban: "output_acres_ecologicalsection_easement_metrourban",
                    forestprairie: "output_acres_ecologicalsection_easement_forestprairie",
                    seforest: "output_acres_ecologicalsection_easement_seforest",
                    prairie: "output_acres_ecologicalsection_easement_prairie",
                    northernforest: "output_acres_ecologicalsection_easement_northernforest",
                    total: "output_acres_ecologicalsection_easement_total"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    metrourban: "output_acres_ecologicalsection_enhance_metrourban",
                    forestprairie: "output_acres_ecologicalsection_enhance_forestprairie",
                    seforest: "output_acres_ecologicalsection_enhance_seforest",
                    prairie: "output_acres_ecologicalsection_enhance_prairie",
                    northernforest: "output_acres_ecologicalsection_enhance_northernforest",
                    total: "output_acres_ecologicalsection_enhance_total"
                });
            }
            this.rows.push({
                type: "Total",
                metrourban: "output_acres_ecologicalsection_total_metrourban",
                forestprairie: "output_acres_ecologicalsection_total_forestprairie",
                seforest: "output_acres_ecologicalsection_total_seforest",
                prairie: "output_acres_ecologicalsection_total_prairie",
                northernforest: "output_acres_ecologicalsection_total_northernforest",
                total: "output_acres_ecologicalsection_total_total"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {eco_regions.indexOf("Metro / Urban") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(proposal, n.metrourban))}</b>
                                        ) : (
                                            <Form key={n.metrourban} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.metrourban} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Forest / Prairie Transition") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(proposal, n.forestprairie))}</b>
                                        ) : (
                                            <Form key={n.forestprairie} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forestprairie} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Southeast Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(proposal, n.seforest))}</b>
                                        ) : (
                                            <Form key={n.seforest} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.seforest} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Prairie") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(proposal, n.prairie))}</b>
                                        ) : (
                                            <Form key={n.prairie} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Northern Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{acresRender(getValue(proposal, n.northernforest))}</b>
                                        ) : (
                                            <Form key={n.northernforest} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useNumber tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.northernforest} label="" 
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                <CustomTableCell>
                                    <b>{acresRender(getValue(proposal, n.total))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

class OutputTableDollarsBySection extends Component {
    typingTimer = [];
    typingTimer2 = [];
    objectsToUpdate = {};
    formkeys = {};
    rows = [];

    getValues = (value, element, row) => {
        const { proposal } = this.props;

        value = (Math.round(parseInt(value) / 100) * 100) || 0;

        // Get Row Total
        var total = (parseInt(row.metrourban === element ? value : getValue(proposal, row.metrourban)) || 0) + 
        (parseInt(row.forestprairie === element ? value : getValue(proposal, row.forestprairie)) || 0) + 
        (parseInt(row.seforest === element ? value : getValue(proposal, row.seforest)) || 0) + 
        (parseInt(row.prairie === element ? value : getValue(proposal, row.prairie)) || 0) + 
        (parseInt(row.northernforest === element ? value : getValue(proposal, row.northernforest)) || 0);

        // Get Column Total
        var totalCol, updateField, finalTotal;
        if (element.includes("_metrourban")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_metrourban";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.metrourban === element ? value : getValue(proposal, r.metrourban)) || 0)
            });
        } else if (element.includes("_forestprairie")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_forestprairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.forestprairie === element ? value : getValue(proposal, r.forestprairie)) || 0)
            });
        } else if (element.includes("_seforest")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_seforest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.seforest === element ? value : getValue(proposal, r.seforest)) || 0)
            });
        } else if (element.includes("_prairie")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_prairie";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.prairie === element ? value : getValue(proposal, r.prairie)) || 0)
            });
        } else if (element.includes("_northernforest")) {
            totalCol = 0;
            updateField = "output_dollars_ecologicalsection_total_northernforest";
            this.rows.forEach(function(r) {
                if (r.type !== "Total")
                    totalCol += (parseInt(r.northernforest === element ? value : getValue(proposal, r.northernforest)) || 0)
            });
        }

        // Get final total
        finalTotal = totalCol;
        if (element.includes("_metrourban")) {
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_forestprairie")) {
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_seforest")) {
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_prairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_prairie")) {
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_northernforest")) || 0);
        } else if (element.includes("_northernforest")) {
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_metrourban")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_forestprairie")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_seforest")) || 0);
            finalTotal += (parseInt(getValue(proposal, "output_dollars_ecologicalsection_total_prairie")) || 0);
        }

        return {
            id: proposal.id,
            [element]: value,
            [row.total]: total,
            [updateField]: totalCol,
            output_dollars_ecologicalsection_total_total: finalTotal
        };
    };

    updateRecord = (value, element, row) => {        
        const { ormProposalUpdate, ormProposalUpdateLocalOnly } = this.props;
        const comp = this;

        clearTimeout(this.typingTimer2[element]);
        this.typingTimer2[element] = setTimeout(function() {
            var vals = comp.getValues(value, element, row);
            ormProposalUpdateLocalOnly(vals);

            // Later properties overwrite earlier properties with the same name.
            comp.objectsToUpdate = { ...comp.objectsToUpdate, ...vals };
        }, 50);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormProposalUpdate(comp.objectsToUpdate);
        }, 2500);
    };

    updateForm = (field) => {
        this.formkeys[field] = window.performance.now();
        this.setState({foo:"bar"});
    };

    render() {
        const { classes, proposal, is_read_only } = this.props;
        const eco_regions = getValue(proposal, "eco_regions");
        var columnData = [
            { id: "type", label: "Type", allowSort: false }
        ];
        if (eco_regions.indexOf("Metro / Urban") > -1) {
            columnData.push({ id: "metrourban", label: "Metro/Urban ($)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Forest / Prairie Transition") > -1) {
            columnData.push({ id: "forestprairie", label: "Forest/Prairie ($)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Southeast Forest") > -1) {
            columnData.push({ id: "seforest", label: "SE Forest ($)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Prairie") > -1) {
            columnData.push({ id: "prairie", label: "Prairie ($)", numeric: true, allowSort: false });
        }
        if (eco_regions.indexOf("Northern Forest") > -1) {
            columnData.push({ id: "northernforest", label: "Northern Forest ($)", numeric: true, allowSort: false });
        }
        columnData.push({ id: "total", label: "Total ($)", type: "numeric", numeric: true, allowSort: false });

        if (this.rows.length === 0) {
            const activity_types = getValue(proposal, "activity_types");
            if (activity_types.indexOf("Restore") > -1) {
                this.rows.push({
                    type: "Restore",
                    metrourban: "output_dollars_ecologicalsection_restore_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_restore_forestprairie",
                    seforest: "output_dollars_ecologicalsection_restore_seforest",
                    prairie: "output_dollars_ecologicalsection_restore_prairie",
                    northernforest: "output_dollars_ecologicalsection_restore_northernforest",
                    total: "output_dollars_ecologicalsection_restore_total"
                });
            }
            if (activity_types.indexOf("Protect in Fee") > -1) {
                this.rows.push({
                    type: "Protect in Fee with State PILT Liability",
                    metrourban: "output_dollars_ecologicalsection_feepilt_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_feepilt_forestprairie",
                    seforest: "output_dollars_ecologicalsection_feepilt_seforest",
                    prairie: "output_dollars_ecologicalsection_feepilt_prairie",
                    northernforest: "output_dollars_ecologicalsection_feepilt_northernforest",
                    total: "output_dollars_ecologicalsection_feepilt_total"
                });
                this.rows.push({
                    type: "Protect in Fee W/O State PILT Liability",
                    metrourban: "output_dollars_ecologicalsection_feewopilt_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_feewopilt_forestprairie",
                    seforest: "output_dollars_ecologicalsection_feewopilt_seforest",
                    prairie: "output_dollars_ecologicalsection_feewopilt_prairie",
                    northernforest: "output_dollars_ecologicalsection_feewopilt_northernforest",
                    total: "output_dollars_ecologicalsection_feewopilt_total"
                });
            }
            if (activity_types.indexOf("Protect in Easement") > -1) {
                this.rows.push({
                    type: "Protect in Easement",
                    metrourban: "output_dollars_ecologicalsection_easement_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_easement_forestprairie",
                    seforest: "output_dollars_ecologicalsection_easement_seforest",
                    prairie: "output_dollars_ecologicalsection_easement_prairie",
                    northernforest: "output_dollars_ecologicalsection_easement_northernforest",
                    total: "output_dollars_ecologicalsection_easement_total"
                });
            }
            if (activity_types.indexOf("Enhance") > -1) {
                this.rows.push({
                    type: "Enhance",
                    metrourban: "output_dollars_ecologicalsection_enhance_metrourban",
                    forestprairie: "output_dollars_ecologicalsection_enhance_forestprairie",
                    seforest: "output_dollars_ecologicalsection_enhance_seforest",
                    prairie: "output_dollars_ecologicalsection_enhance_prairie",
                    northernforest: "output_dollars_ecologicalsection_enhance_northernforest",
                    total: "output_dollars_ecologicalsection_enhance_total"
                });
            }
            this.rows.push({
                type: "Total",
                metrourban: "output_dollars_ecologicalsection_total_metrourban",
                forestprairie: "output_dollars_ecologicalsection_total_forestprairie",
                seforest: "output_dollars_ecologicalsection_total_seforest",
                prairie: "output_dollars_ecologicalsection_total_prairie",
                northernforest: "output_dollars_ecologicalsection_total_northernforest",
                total: "output_dollars_ecologicalsection_total_total"
            });
        }

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={columnData} />
                <TableBody>
                    {this.rows.map(n => {
                        return (
                            <TableRow hover className={n.type === "Total" && classes.totalRow}>
                                <CustomTableCell>
                                    {n.type === "Total" ? (<b>{n.type}</b>) : n.type}
                                </CustomTableCell>
                                {eco_regions.indexOf("Metro / Urban") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(proposal, n.metrourban))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.metrourban] ? this.formkeys[n.metrourban]  : n.metrourban} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.metrourban} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Forest / Prairie Transition") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(proposal, n.forestprairie))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.forestprairie] ? this.formkeys[n.forestprairie]  : n.forestprairie} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.forestprairie} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Southeast Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(proposal, n.seforest))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.seforest] ? this.formkeys[n.seforest]  : n.seforest} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.seforest} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Prairie") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(proposal, n.prairie))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.prairie] ? this.formkeys[n.prairie]  : n.prairie} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.prairie} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                {eco_regions.indexOf("Northern Forest") > -1 && (
                                    <CustomTableCell>
                                        {n.type === "Total" ? (
                                            <b>{dollarsRender(getValue(proposal, n.northernforest))}</b>
                                        ) : (
                                            <Form key={this.formkeys[n.northernforest] ? this.formkeys[n.northernforest]  : n.northernforest} defaultValues={proposal}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField 
                                                            disabled={is_read_only} useTextFormat tableFormat 
                                                            eventHandleProps1={n} eventHandle={this.updateRecord} 
                                                            field={n.northernforest} label="" onBlur={this.updateForm}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        )}
                                    </CustomTableCell>
                                )}
                                <CustomTableCell>
                                    <b>{dollarsRender(getValue(proposal, n.total))}</b>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

OutputTab = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        ...Proposal.actions
    }
)(OutputTab);

export default withStyles(styles)(withRouter(OutputTab));
