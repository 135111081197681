import React, { Component } from "react";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

import HelpDialog from "./HelpDialog";

const styles = theme => ({
    helpGrid: {
        width: 20,
        height: 20,
        opacity: ".4",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.dark
        }
    },
    absolute: {
        height: theme.spacing(2)
    },
    inline: {
        display: "inline"
    },
    special: {
        display: "inline-block",
        marginBottom:20
    },
    titleIcon: {
        width: 24,
        height: 24,
        opacity: ".8",
        color: theme.palette.primary.main,
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.dark
        }
    },
    helpButton: {
        marginLeft: 8,
        padding: 0
    },
    floatButton: {
        float: "right",
        marginBottom: -40,
        marginTop: 5,
        zIndex: 9
    }
});

class HelpLabel extends Component {
    state = {
        helpOpen: false
    };

    iconClick = e => {
        this.setState({ helpOpen: true });
        //FIXME: How to stop the input from being focused
        e.stopPropagation();
    };

    render() {
        const { helpText, classes, inputLabel, question, title, showLabel, special, style } = this.props;

        const { helpOpen } = this.state;

        return (
            <Typography variant={title ? "h3" : "body1"} className={!inputLabel ? classes.absolute : special ? classes.special : classes.inline}>
                {showLabel && inputLabel}
                <IconButton style={style} onClick={this.iconClick} className={classNames(classes.helpButton, !showLabel && classes.floatButton)} aria-label={"Open Help Dialog for " + inputLabel}>
                    <HelpIcon className={classNames(classes.helpGrid, title ? classes.titleIcon : null)} />
                </IconButton>
                <HelpDialog
                    question={inputLabel && inputLabel !== true ? inputLabel : question}
                    text={helpText}
                    open={helpOpen}
                    title={title}
                    showHelp
                    onClose={() => this.setState({ helpOpen: false })}
                />
            </Typography>
        );
    }
}

export default withStyles(styles)(HelpLabel);
