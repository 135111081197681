import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import TextField from "../common/TextField";
import Checkbox from "../common/Checkbox";
import { SystemSetting } from "../proposal/models";

const getSystemSettings = SystemSetting.selectMessage();

class SystemSettings extends Component {
    componentDidMount() {
        document.title = "Configure System Settings Tabs - LSOHC Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "admin") {
            history.push("/dashboard");
        }
    };

    submitForm = values => {
        const { ormSystemSettingUpdate, history } = this.props;

        ormSystemSettingUpdate({
            id: values.id,
            ...values
        });

        history.push("/adm/home");
    };

    render() {
        const { setting } = this.props;

        const v = new Date().getFullYear();

        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <PageHeader title="Configure System Settings" />
                    <Grid item xs={11} md={8} lg={6}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={setting}
                            onSubmit={this.submitForm}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} style={{ marginBottom: -16 }}>
                                            <Checkbox field="funding_open" label={"ML " + (v + 1) + " Funding is Now Open"} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="welcome" multiline rows={8} label="Welcome Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="instructions" multiline rows={8} label="Instructions" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="general_tab_help" multiline rows={8} label="General Help Tab Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="narrative_tab_help" multiline rows={8} label="Narrative Help Tab Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="activity_tab_help" multiline rows={8} label="Activity Detail Help Tab Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="timeline_tab_help" multiline rows={8} label="Timeline Help Tab Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="budget_tab_help" multiline rows={8} label="Budget Help Tab Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="federal_funds_tab_help" multiline rows={8} label="Federal Funds Help Tab Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="output_tables_tab_help" multiline rows={8} label="Output Tables Help Tab Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="outcomes_tab_help" multiline rows={8} label="Outcomes Help Tab Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="parcels_tab_help" multiline rows={8} label="Parcels Help Tab Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="attachments_tab_help" multiline rows={8} label="Attachments Help Tab Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="confirmation_message" multiline rows={8} label="Proposal Confirmation Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="confirmation_message_ap" multiline rows={8} label="Accomplishment Plan Confirmation Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="confirmation_message_final" multiline rows={8} label="Final Report Confirmation Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="confirmation_message_status" multiline rows={8} label="Status Update Confirmation Message" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth component={Link} to="/adm/home">
                                                Return to Admin Home
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

SystemSettings = connect(
    (state, ownProps) => ({
        authState: state.auth,
        setting: getSystemSettings(state, ownProps)
    }),
    {
        ...SystemSetting.actions
    }
)(SystemSettings);

export default withRouter(SystemSettings);
