import orm, { Model } from "../common/orm";
import { fk } from "redux-orm";

export class FinalReport extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "FinalReport";
    }
}
orm.register(FinalReport);

export class FinalFutureMaintain extends Model {
    static get fields() {
        return {
            final: fk("FinalReport", "futuremaintain")
        };
    }
    static get modelName() {
        return "FinalFutureMaintain";
    }
}
orm.register(FinalFutureMaintain);

export class FinalBudget extends Model {
    static get fields() {
        return {
            final: fk("FinalReport", "budget")
        };
    }
    static get modelName() {
        return "FinalBudget";
    }
}
orm.register(FinalBudget);

export class FinalBudgetPersonnel extends Model {
    static get fields() {
        return {
            budget: fk("FinalBudget", "budgetpersonnel")
        };
    }
    static get modelName() {
        return "FinalBudgetPersonnel";
    }
}
orm.register(FinalBudgetPersonnel);

export class FinalBudgetCapitalEquipment extends Model {
    static get fields() {
        return {
            budget: fk("FinalBudget", "budgetcapital")
        };
    }
    static get modelName() {
        return "FinalBudgetCapitalEquipment";
    }
}
orm.register(FinalBudgetCapitalEquipment);

export class FinalBudgetPersonnelOriginal extends Model {
    static get fields() {
        return {
            budget: fk("FinalBudget", "budgetpersonneloriginal")
        };
    }
    static get modelName() {
        return "FinalBudgetPersonnelOriginal";
    }
}
orm.register(FinalBudgetPersonnelOriginal);

export class FinalBudgetCapitalEquipmentOriginal extends Model {
    static get fields() {
        return {
            budget: fk("FinalBudget", "budgetcapitaloriginal")
        };
    }
    static get modelName() {
        return "FinalBudgetCapitalEquipmentOriginal";
    }
}
orm.register(FinalBudgetCapitalEquipmentOriginal);

export class FinalAttachment extends Model {
    static get fields() {
        return {
            final: fk("FinalReport", "attachment")
        };
    }
    static get modelName() {
        return "FinalAttachment";
    }
}
orm.register(FinalAttachment);

export class FinalReportView extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "FinalReportView";
    }
    static get isUserData() {
        return false;
    }
}
orm.register(FinalReportView);
