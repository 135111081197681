import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Scrollbars } from "react-custom-scrollbars";

import { withStyles } from "@material-ui/core";

import Header from "./Header";
import NotReady from "./NotReady";
import Footer from "./Footer";

const styles = theme => ({
    appContainer: {
        // The parent container of the header, sidebar, content
        width: "100%",
        paddingTop: "12rem",
        height: "calc(100% - 12rem - 56px)" // Height 100% - padding; This is set so the dashboard map can be 100%
    },
    contentContainer: {
        // The spacing on Material UI's grid works great when spacing between grid items, however the container itself is not spaced
        "& > div:first-child, & > form:first-child": {
            //, & > form div:first-child
            width: "calc(100% - 40px)",
            //marginLeft: 20,
            //marginRight: 20,
            margin: 20,
            marginBottom: 40
        }
    },
    contentContainerWithFooter: {
        // The spacing on Material UI's grid works great when spacing between grid items, however the container itself is not spaced
        "& > div:first-child, & > form:first-child": {
            //, & > form div:first-child
            marginBottom: 76 // The regular 20 margin plus 56 sized footer
        }
    },
    height: {
        //height: "100%"
    },
    overflowX: {
        "& > div:first-child": {
            //overflowX: "hidden !important"
        }
    }
});

class AppContainer extends Component {
    constructor(props) {
        super(props);
        this.scrollbar = React.createRef();
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#64000d",
            zIndex: "9999",
            cursor: "pointer",
            marginLeft: -8,
            width: 14
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    renderThumb2({ style, ...props }) {
        const thumbStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    scrollTop = () => {
        // THIS IS STUPID
        const _this = this;
        setTimeout(function() {
            _this.scrollbar.current.scrollToTop();
        }, 1);
    };

    scrollBottom = () => {
        // THIS IS STUPID
        const _this = this;
        setTimeout(function() {
            _this.scrollbar.current.scrollToBottom();
        }, 1);
    };

    render() {
        const { children, classes, authenticated, navState, authState, synced, syncState } = this.props;

        //Object.keys(syncState.pending).length is a hack
        // We want to know the first time the application is loading and everything is being fetched
        // syncState.ready is called upon every save so it cant be used

        return (
            <Scrollbars
                ref={this.scrollbar}
                renderThumbVertical={this.renderThumb}
                renderThumbHorizontal={this.renderThumb2}
                thumbSize={600}
                className={classes.overflowX}>
                <div className={classes.appContainer}>
                    <Header authenticated={authenticated} />
                    <div
                        className={classNames(
                            classes.contentContainer,
                            classes.height,
                            !authenticated && classes.contentContainerWithFooter
                        )}>
                        {!authenticated && children}
                        {authenticated &&
                            authState.user &&
                            syncState.pending &&
                            Object.keys(syncState.pending).length >= 5 && <NotReady storeReady={false} />}
                        {authenticated &&
                            (!authState || !authState.user) &&
                            syncState.pending &&
                            Object.keys(syncState.pending).length < 5 && <NotReady storeReady={navState.storeReady} />}
                        {authenticated &&
                            authState.user &&
                            !synced &&
                            syncState.pending &&
                            Object.keys(syncState.pending).length < 5 &&
                            children}
                        {authenticated &&
                            authState.user &&
                            synced &&
                            syncState.pending &&
                            Object.keys(syncState.pending).length < 5 && <NotReady message="Loading..." />}
                    </div>
                    {!authenticated && <Footer />}
                </div>
            </Scrollbars>
        );
    }
}

AppContainer = connect(
    state => ({
        navState: state.nav,
        authState: state.auth,
        syncState: state.sync
    }),
    null,
    null,
    { forwardRef: true }
)(AppContainer);

export default withStyles(styles)(AppContainer);
