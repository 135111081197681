import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import AppContainer from "../common/AppContainer";
import Checkbox from "../common/Checkbox";
import TextField from "../common/TextField";
import states from "../common/states.json";
import Select from "../common/Select";
import PageHeader from "../common/PageHeader";
import { User } from "../home/models";
import { Typography } from "@material-ui/core";

const getUser = User.selectByUrlId();

class EditUser extends Component {
    componentDidMount() {
        document.title = "Edit User - LSOHC Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "admin") {
            history.push("/dashboard");
        }
    }

    errorValidator = values => {
        const validateRequired = name => {
            return !name ? "Required" : null;
        };
        return {
            name: validateRequired(values.name)
        };
    };

    submitForm = values => {
        const { ormUserUpdate, history } = this.props;

        ormUserUpdate({
            id: values.id,
            ...values
        });

        history.push("/adm/users");
    };

    render() {
        const { user } = this.props;
        if (!user) return "";

        return (
            <AppContainer authenticated>
                <Grid container justify="center" spacing={5}>
                    <PageHeader title={"Edit User: " + user.name} />
                    <Grid item xs={11} md={8} lg={6}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={user}
                            validateError={this.errorValidator}
                            onSubmit={this.submitForm}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} style={{ marginBottom: -16 }}>
                                            <Checkbox field="is_staff" label="Is Staff Member?" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ marginBottom: -16 }}>
                                            <Checkbox
                                                field="is_read_only"
                                                label="Read Only Access?"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="name" label="Name" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="organization" label="Organization" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="address" label="Address" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="address2" label="Address 2" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="city" label="City" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Select field="state" label="State" options={states} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="zip" label="Zip Code" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                field="phone"
                                                label="Telephone"
                                                phoneNumber
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="email" label="Email (username)" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography>User ID: {user.id}</Typography>
                                        </Grid>
                                        <Grid item xs={12}></Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                component={Link}
                                                to="/adm/users">
                                                Return to Manage Users
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

EditUser = connect(
    (state, ownProps) => ({
        authState: state.auth,
        user: getUser(state, ownProps),
    }),
    {
        ...User.actions,
    }
)(EditUser);

export default withRouter(EditUser);
