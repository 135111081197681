import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { withStyles, Typography } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";


import { Proposal } from "../models";

import TextField from "../../common/TextField";
import CheckboxGroup from "../../common/CheckboxGroup";
import Subquestion from "../../common/Subquestion";
import HelpLabel from "../../common/HelpLabel";
import { MAKE_OPTIONS } from "../../../api/constants";
import { getValue } from "../../../api/utils";

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    gutterTop: {
        textAlign: "center",
        marginTop: ".6em"
    },
});

class OutcomesTab extends Component {
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Outcomes - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const { id } = this.props.proposal;
        const { ormProposalUpdate, history, handleUnsavedFields, handleNext, proposal } = this.props;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }
        values.federal_funds_confirmation_document = proposal.federal_funds_confirmation_document;
        values.modify_date_label = proposal.modify_date_label;
        values.signup_criteria = proposal.signup_criteria;
        values.illustration = proposal.illustration;
        values.financial_audit = proposal.financial_audit;
        values.board_members = proposal.board_members;

        ormProposalUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    }

    maxChecked = (value, field, element) => {
        if (value.length >= 1) {
            var dis = [];
            element.props.options.forEach(function(row) {
                if (!value.includes(row.value)) dis[row.value] = true;
            });

            element.setState({ disabled: dis });
        } else {
            element.setState({ disabled: [] });
        }
        this.applyOther(value, field);
    };

    applyOther = (value, element) => {
        const { ormProposalUpdateLocalOnly } = this.props;
        const { id } = this.props.proposal;
        ormProposalUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    render() {
        const { 
            classes,
            proposal,
            handleUnsavedFields,
            settings,
            ActionMenu, authState
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || proposal.status === "New")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        const year = parseInt(proposal.ml_year);

        const eco_regions = getValue(proposal, "eco_regions");
        const outcomes_forest_prairie_transition = eco_regions.indexOf("Forest / Prairie Transition") > -1;
        const outcomes_metro_urban = eco_regions.indexOf("Metro / Urban") > -1;
        const outcomes_northern_forest = eco_regions.indexOf("Northern Forest") > -1;
        const outcomes_prairie = eco_regions.indexOf("Prairie") > -1;
        const outcomes_southeast_forest = eco_regions.indexOf("Southeast Forest") > -1;

        const outcomes_forest_prairie_transition_explain = getValue(proposal, "outcomes_forest_prairie_transition").length > 0;
        const outcomes_metro_urban_explain = getValue(proposal, "outcomes_metro_urban").length > 0;
        const outcomes_northern_forest_explain = getValue(proposal, "outcomes_northern_forest").length > 0;
        const outcomes_prairie_explain = getValue(proposal, "outcomes_prairie").length > 0;
        const outcomes_southeast_forest_explain = getValue(proposal, "outcomes_southeast_forest").length > 0;

        return (
            <Form
                getApi={el => (this.form = el)}
                key={proposal.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={proposal.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HelpLabel
                                    inputLabel="Outcomes"
                                    showLabel={true}
                                    title={true}
                                    helpText={settings.outcomes_tab_help}
                                />
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12}>
                                {(!outcomes_forest_prairie_transition && !outcomes_metro_urban && !outcomes_northern_forest && !outcomes_prairie && !outcomes_southeast_forest) && (
                                    <Typography variant="h3">You must select at least one eco region (General Step 1) in order to select the project outcomes.</Typography>
                                )}
                                {outcomes_forest_prairie_transition && (
                                    <CheckboxGroup
                                        field="outcomes_forest_prairie_transition"
                                        row={true}
                                        disabled={is_read_only}
                                        eventHandle={this.maxChecked}
                                        options={MAKE_OPTIONS([
                                            year < 2014 && 'Improved aquatic habitat indicators',
                                            year >= 2014 && 'Improved aquatic habitat vegetation',
                                            year < 2014 && 'Increased availability and improved condition of riparian forests and other habitat corridors',
                                            year >= 2014 && 'Increased waterfowl and upland bird migratory and breeding success',
                                            'Protected, restored, and enhanced aspen parklands and riparian areas',
                                            'Protected, restored, and enhanced nesting and migratory habitat for waterfowl, upland birds, and species of greatest conservation need',
                                            year >= 2014 && 'Remnant native prairies are part of large complexes of restored prairies, grasslands, and large and small wetlands',
                                            year >= 2014 && 'Rivers and streams provide corridors of habitat including intact areas of forest cover in the east and large wetland/upland complexes in the west',
                                            'Water is kept on the land',
                                            'Wetland and upland complexes will consist of native prairies, restored prairies, quality grasslands, and restored shallow lakes and wetlands',
                                            'Other'
                                        ])}
                                    >
                                        <HelpLabel
                                            inputLabel="Program outcomes in forest-prairie transition region"
                                            showLabel={true}
                                            helpText="Check the top ONE applicable outcome"
                                        />
                                    </CheckboxGroup>
                                )}
                                {outcomes_forest_prairie_transition && outcomes_forest_prairie_transition_explain && year >= 2014 && (
                                    <Subquestion component={
                                        <TextField
                                            field="outcomes_forest_prairie_transition_explain"
                                            maxWords={!is_read_only && "100"}
                                            disabled={is_read_only}
                                            multiline
                                            rows={4}
                                            rowsMax={20}
                                            fullWidth
                                        >
                                            <HelpLabel
                                                inputLabel="Please explain how this outcome for the forest-prairie transition region is measured and evaluated"
                                                showLabel={true}
                                                helpText="Up to 100 words"
                                            />
                                        </TextField>
                                    } />
                                )}
                                {outcomes_metro_urban && (
                                    <CheckboxGroup
                                        field="outcomes_metro_urban"
                                        row={true}
                                        disabled={is_read_only}
                                        eventHandle={this.maxChecked}
                                        options={MAKE_OPTIONS([
                                            year >= 2014 && 'A forest land base that contributes to the habitat picture',
                                            'A network of natural land and riparian habitats will connect corridors for wildlife and species in greatest conservation need',
                                            'Core areas protected with highly biologically diverse wetlands and plant communities, including native prairie, Big Woods, and oak savanna',
                                            year >= 2014 && 'Game lakes are significant contributors of waterfowl, due to efforts to protect uplands adjacent to game lakes',
                                            'Improved aquatic habitat indicators',
                                            'Protected habitats will hold wetlands and shallow lakes open to public recreation and hunting',
                                            'Other'
                                        ])}
                                    >
                                        <HelpLabel
                                            inputLabel="Program outcomes in metropolitan urbanizing region"
                                            showLabel={true}
                                            helpText="Check the top ONE applicable outcome"
                                        />
                                    </CheckboxGroup>
                                )}
                                {outcomes_metro_urban && outcomes_metro_urban_explain && year >= 2014 && (
                                    <Subquestion component={
                                        <TextField
                                            field="outcomes_metro_urban_explain"
                                            maxWords={!is_read_only && "100"}
                                            disabled={is_read_only}
                                            multiline
                                            rows={4}
                                            rowsMax={20}
                                            fullWidth
                                        >
                                            <HelpLabel
                                                inputLabel="Please explain how this outcome for the metropolitan urbanizing region is measured and evaluated"
                                                showLabel={true}
                                                helpText="Up to 100 words"
                                            />
                                        </TextField>
                                    } />
                                )}
                                {outcomes_northern_forest && (
                                    <CheckboxGroup
                                        field="outcomes_northern_forest"
                                        row={true}
                                        disabled={is_read_only}
                                        eventHandle={this.maxChecked}
                                        options={MAKE_OPTIONS([
                                            'Forestlands are protected from development and fragmentation',
                                            year >= 2014 && 'Greater public access for wildlife and outdoors-related recreation',
                                            'Healthy populations of endangered, threatened, and special concern species as well as more common species',
                                            'Improved aquatic habitat indicators',
                                            year >= 2014 && 'Improved availability and improved condition of habitats that have experienced substantial decline',
                                            'Increased availability and improved condition of riparian forests and other habitat corridors',
                                            'Landlocked public properties are accessible with have increased access for land managers',
                                            'Other'
                                        ])}
                                    >
                                        <HelpLabel
                                            inputLabel="Program outcomes in the northern forest region"
                                            showLabel={true}
                                            helpText="Check the top ONE applicable outcome"
                                        />
                                    </CheckboxGroup>
                                )}
                                {outcomes_northern_forest && outcomes_northern_forest_explain && year >= 2014 && (
                                    <Subquestion component={
                                        <TextField
                                            field="outcomes_northern_forest_explain"
                                            maxWords={!is_read_only && "100"}
                                            multiline
                                            disabled={is_read_only}
                                            rows={4}
                                            rowsMax={20}
                                            fullWidth
                                        >
                                            <HelpLabel
                                                inputLabel="Please explain how this outcome for the northern forest region is measured and evaluated"
                                                showLabel={true}
                                                helpText="Up to 100 words"
                                            />
                                        </TextField>
                                    } />
                                )}
                                {outcomes_prairie && (
                                    <CheckboxGroup
                                        field="outcomes_prairie"
                                        row={true}
                                        disabled={is_read_only}
                                        eventHandle={this.maxChecked}
                                        options={MAKE_OPTIONS([
                                            year >= 2014 && 'Agriculture lands are converted to grasslands to sustain functioning prairie systems',
                                            year < 2014 && 'Core areas protected with highly biologically diverse wetlands and plant communities, including native prairie, Big Woods, and oak savanna',
                                             year >= 2014 && 'Enhanced shallow lake productivity',
                                            'Expiring CRP lands are permanently protected',
                                            year >= 2014 && 'Improved access to public lands',
                                            year >= 2014 && 'Improve aquatic vegetation',
                                            'Improved condition of habitat on public lands',
                                            'Increased participation of private landowners in habitat projects',
                                            year >= 2014 && 'Increased wildlife productivity',
                                            year >= 2014 && 'Key core parcels are protected for fish, game and other wildlife',
                                            year >= 2014 && 'Protected, enhanced and restored remnants of big woods and oak savanna',
                                            year >= 2014 && 'Protected, restored, and enhanced habitat for migratory and unique Minnesota species',
                                            year < 2014 && 'Protected, restored, and enhanced habitat for waterfowl, upland birds, and species of greatest conservation need',
                                            'Protected, restored, and enhanced shallow lakes and wetlands',
                                            year < 2014 && 'Remnant native prairies and wetlands are permanently protected and are part of large complexes of restored prairie, grasslands, and large and small wetlands',
                                            year >= 2014 && 'Remnant native prairies and wetlands are perpetually protected and adequately buffered',
                                            year >= 2014 && 'Remnant native prairies are part of large complexes of restored prairies, grasslands, and large and small wetlands',
                                            year >= 2014 && 'Restored and enhanced upland habitats',
                                            year < 2014 && 'Water is kept on the land',
                                            year >= 2014 && 'Water is kept on the land to reduce flood potential and degradation of aquatic habitat',
                                            'Other'
                                        ])}
                                    >
                                        <HelpLabel
                                            inputLabel="Program outcomes in prairie region"
                                            showLabel={true}
                                            helpText="Check the top ONE applicable outcome"
                                        />
                                    </CheckboxGroup>
                                )}
                                {outcomes_prairie && outcomes_prairie_explain && year >= 2014 && (
                                    <Subquestion component={
                                        <TextField
                                            field="outcomes_prairie_explain"
                                            maxWords={!is_read_only && "100"}
                                            disabled={is_read_only}
                                            multiline
                                            rows={4}
                                            rowsMax={20}
                                            fullWidth
                                        >
                                            <HelpLabel
                                                inputLabel="Please explain how this outcome for the prairie region is measured and evaluated"
                                                showLabel={true}
                                                helpText="Up to 100 words"
                                            />
                                        </TextField>
                                    } />
                                )}
                                {outcomes_southeast_forest && (
                                    <CheckboxGroup
                                        field="outcomes_southeast_forest"
                                        row={true}
                                        disabled={is_read_only}
                                        eventHandle={this.maxChecked}
                                        options={MAKE_OPTIONS([  
                                            year >= 2014 && 'Forestlands and savannas are protected from parceliazation and fragmentation and accessible for resource management purposes',
                                            'Healthier populations of endangered, threatened, and special concern species as well as more common species',
                                            'High priority riparian lands, forestlands, and savannas are protected from parcelization and fragmentation',
                                            year < 2014 && 'Improved aquatic habitat indicators',
                                            'Landlocked public properties have increased access for land managers',
                                            year >= 2014 && 'Large corridors and complexes of biologically diverse wildlife habitat typical of the unglaciated region are restored and protected',
                                            'Remnant goat prairies are perpetually protected',
                                            'Rivers, streams, and surrounding vegetation provide corridors of habitat',
                                            'Stream to bluff habitat restoration and enhancement will keep water on the land to slow runoff and degradation of aquatic habitat',
                                            'Other'
                                        ])}
                                    >
                                        <HelpLabel
                                            inputLabel="Program outcomes in southeast forest region"
                                            showLabel={true}
                                            helpText="Check the top ONE applicable outcome"
                                        />
                                    </CheckboxGroup>
                                )}
                                {outcomes_southeast_forest && outcomes_southeast_forest_explain && year >= 2014 && (
                                    <Subquestion component={
                                        <TextField
                                            field="outcomes_southeast_forest_explain"
                                            maxWords={!is_read_only && "100"}
                                            disabled={is_read_only}
                                            multiline
                                            rows={4}
                                            rowsMax={20}
                                            fullWidth
                                        >
                                            <HelpLabel
                                                inputLabel="Please explain how this outcome for the southeast forest region is measured and evaluated"
                                                showLabel={true}
                                                helpText="Up to 100 words"
                                            />
                                        </TextField>
                                    } />
                                )}
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Parcels <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

OutcomesTab = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        ...Proposal.actions
    }
)(OutcomesTab);

export default withStyles(styles)(withRouter(OutcomesTab));
