import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import * as authActions from "../auth/actions";
import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import TextField from "../common/TextField";
import legacy_logo from "../../images/legacy_logo.png";
import map_img from "../../images/map.jpg";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import { SystemSetting } from "../proposal/models";

const getSystemSettings = SystemSetting.selectMessage();

const styles = {
    marginBottom: {
        marginBottom: 40
    },
    leftDivide: {
        paddingRight: 32,
        borderRight: "2px solid #666666"
    },
    rightDivide: {
        paddingLeft: 32
    },
    introText: {
        marginBottom: 40,
        fontStyle: "italic"
    },
    loginError: {
        color: "#be0000",
        textAlign: "center"
    },
    marginBottomButton: {
        marginBottom: 24
    },
    imageSide: {
        width: "100%",
        cursor: "pointer"
    },
    forgot: {
        float: "right",
        marginTop: 8
    }
};

class HomePage extends Component {
    inputs = [];

    continueRegister = () => {
        this.props.history.push("/register");
    };

    componentDidMount(){
        document.title = "LSOHC Proposal and Grant Management System - Welcome/Login Page";
    }

    logOut = () => {
        this.props.authLogout();
    };

    continueDashboard = () => {
        this.props.history.push("/dashboard");
    };

    errorValidator = values => {
        const validateUsername = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid email address" : null;
        };
        const validatePassword = value => {
            return !value ? "Password is required." : null;
        };

        const objErrors = {
            email: validateUsername(values.email),
            password: validatePassword(values.password)
        };
        const entries = Object.entries(objErrors).reverse();
        for (const [k, v] of entries) {
            if (v && this.inputs[k]) this.inputs[k].focus();
        }
        return objErrors;
    };

    loginSubmit = (values, e, formApi) => {
        this.props.authLogin(values).then(() => {
            if (this.props.authState.error) {
                formApi.setValue("password", "");
            } else if (this.props.authState.user) {
                if (this.props.authState.user.redirect) {
                    this.props.history.push(this.props.authState.user.redirect.replace("#", "") + "?old=True");
                } else {
                    this.continueDashboard();
                }
            }
        });
    };

    render() {
        const { classes, authState, settings } = this.props;
        if (!authState) return "...";

        return (
            <AppContainer>
                <Grid container spacing={5} justify="center">
                    <Grid item xs={11} md={8} lg={6} xl={4}>
                        <Typography variant="h2" className={classes.introText}>
                            ". . . restore, protect, and enhance Minnesota's wetlands, prairies, forests, and habitat
                            for fish, game, and wildlife. . . "
                        </Typography>
                        <Grid container>
                            <Grid item xs={6} className={classes.leftDivide}>
                                <Typography variant="h2" gutterBottom>
                                    New Users
                                </Typography>
                                <Typography className={classes.marginBottom}>
                                    <Button variant="contained" color="primary" fullWidth onClick={this.continueRegister}>
                                        Register Account
                                    </Button>
                                </Typography>
                                {authState.user && (
                                    <div>
                                        <Typography variant="h2" gutterBottom>
                                            Welcome, {authState.user.name}
                                        </Typography>
                                        <Button
                                            onClick={this.continueDashboard}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={classes.marginBottomButton}>
                                            Continue to Dashboard
                                        </Button>
                                        <Button variant="contained" onClick={this.logOut} fullWidth>
                                            Logout
                                        </Button>
                                    </div>
                                )}
                                {!authState.user && (
                                    <Form
                                        dontValidateOnMount="true"
                                        validateOnSubmit="true"
                                        validateError={this.errorValidator}
                                        onSubmit={this.loginSubmit}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <Typography variant="h2" gutterBottom autoFocus>
                                                    Existing Users
                                                </Typography>
                                                <TextField field="email" label="Email" fullWidth login
                                                    inputRef={input => {
                                                        this.inputs["email"] = input;
                                                    }} />
                                                <TextField
                                                    field="password"
                                                    login
                                                    label="Password"
                                                    type="password"
                                                    inputRef={input => {
                                                        this.inputs["password"] = input;
                                                    }}
                                                    fullWidth
                                                />
                                                <Typography className={classes.loginError} gutterBottom>
                                                    {authState.error}
                                                </Typography>
                                                <Submit label="Login" gridSize={24} loading={authState.pending} />
                                                <Button
                                                    variant="contained"
                                                    component={Link}
                                                    to="/password/reset"
                                                    className={classes.forgot}>
                                                    Forgot your password?
                                                </Button>
                                            </form>
                                        )}
                                    </Form>
                                )}
                            </Grid>
                            <Grid item xs={6} className={classes.rightDivide}>
                                <Typography variant="h2" gutterBottom>
                                    Welcome
                                </Typography>
                                <Typography className={classes.marginBottom}>
                                    <div dangerouslySetInnerHTML={{ __html: settings && settings.welcome }} />
                                </Typography>
                                <Typography variant="h2" gutterBottom>
                                    Instructions
                                </Typography>
                                <Typography className={classes.marginBottom}>
                                    <div dangerouslySetInnerHTML={{ __html: settings && settings.instructions }} />
                                </Typography>
                                <table>
                                    <tr>
                                        <td style={{ paddingRight: 40 }}>
                                            <img
                                                onClick={() => window.open("https://www.legacy.mn.gov/")}
                                                className={classes.imageSide}
                                                alt="Clean Water Land and Legacy Amendment"
                                                src={legacy_logo}
                                            />
                                        </td>
                                        <td style={{ width: "50%", verticalAlign: "top" }}>
                                            <img
                                                onClick={() => window.open("https://www.lsohcprojectmgmt.leg.mn/map/")}
                                                className={classes.imageSide}
                                                alt="Legacy Map"
                                                src={map_img}
                                            />
                                            <Typography style={{ textAlign: "center" }}>
                                                Your Legacy at Work: Completed Programs
                                            </Typography>
                                        </td>
                                    </tr>
                                </table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

HomePage = connect(
    state => ({ 
        authState: state.auth, 
        settings: getSystemSettings(state) 
    }),
    {
        ...authActions,
    }
)(HomePage);

export default withStyles(styles)(withRouter(HomePage));
