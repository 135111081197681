import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";

const styles = theme => ({
    height: {
        height: "100%",
        margin: "0 !important",
        width: "100% !important"
    }
});

class MapPage extends Component {
    state = {
        lat: 46.27917,
        lng: -93.444666,
        zoom: 7
    };
    render() {
        const { classes } = this.props;
        const position = [this.state.lat, this.state.lng];
        return (
            <AppContainer authenticated>
                <Map center={position} zoom={this.state.zoom} className={classes.height}>
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position}>
                        <Popup>
                            A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                    </Marker>
                </Map>
            </AppContainer>
        );
    }
}

export default withStyles(styles)(withRouter(MapPage));
