import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Visibility from "@material-ui/icons/Visibility";
import NewReleases from "@material-ui/icons/NewReleases";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import CreateIcon from "@material-ui/icons/Create";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Warning from "@material-ui/icons/Warning";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import DialogActions from "@material-ui/core/DialogActions";

import Messages from "./Messages";
import AppContainer from "../common/AppContainer";
import RadioGroup from "../common/RadioGroup";
import PageHeader from "../common/PageHeader";
import TextField from "../common/TextField";
import Checkbox from "../common/Checkbox";
import HelpLabel from "../common/HelpLabel";
import * as navActions from "../common/actions";
import { MAKE_OPTIONS } from "../../api/constants";
import Select from "../common/Select";
import NewProposal from "../proposal/NewProposal";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import TablePaginationActionsWrapped from "../common/Paginator";
import { createSelector } from "../common/orm";
import { SystemSetting, Proposal, ProposalView } from "../proposal/models";
import { AccomplishmentPlan, AccomplishmentPlanView } from "../accomplishment/models";
import { FinalReport, FinalReportView } from "../final/models";
import { InitialLandReporting, InitialLandReportingView } from "../land_reporting/models"
import { RealPropertyInterest } from "../real_property/models";
import { StatusUpdate, StatusUpdateView } from "../status_update/models";

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    attachmentFontSize: {
        fontSize: 12
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
        "& div:first-child": {
            margin: 0,
            marginTop: 24
        }
    },
    expansionSummaryRoot2: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    flex: {
        flex: 1
    },
    progress: {
        width: "100%",
        textAlign: "center"
    },
    marginLeft: {
        marginLeft: 16
    },
    selectWidth: {
        width: 200
    },
    minWidth: {
        minWidth: 400
    },
    titleContainer: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "center"
    },
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 32
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
    inline: {
        display: "inline-block",
        width: 30,
        height: 44
    },
    inline2: {
        display: "inline-block",
        width: 30,
        height: 0
    },
    progress2: {
        position: "absolute",
        marginLeft: -120,
        marginTop: 3
    }
});

function getCookieValue(a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : null;
}

const getSystemSettings = SystemSetting.selectMessage();
const getProposals = createSelector(schema => {
    return schema.Proposal.all()
        //.orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray();
});
const getAllProposals = createSelector(schema => {
    return schema.ProposalView.all()
        //.orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray();
});
const getAps = createSelector(schema => {
    return schema.AccomplishmentPlan.all()
        //.orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray();
});
const getAllAps = createSelector(schema => {
    return schema.AccomplishmentPlanView.all()
        //.orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray();
});
const getFinals = createSelector(schema => {
    return schema.FinalReport.all()
        //.orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray();
});
const getAllFinals = createSelector(schema => {
    return schema.FinalReportView.all()
        //.orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray();
});
const getLandReporting = createSelector(schema => {
    return schema.InitialLandReporting.all()
        //.orderBy(["ml_year", "project_name"], ["desc", "asc"])
        .toModelArray();
});
const getAllLandReporting = createSelector(schema => {
    return schema.InitialLandReportingView.all()
        //.orderBy(["ml_year", "project_name"], ["desc", "asc"])
        .toModelArray();
});
const getStatusUpdates = createSelector(schema => {
    return schema.StatusUpdate.all()
        //.orderBy(["statusdate", "ml_year"], ["desc", "desc"])
        .toModelArray();
});
const getAllStatusUpdates = createSelector(schema => {
    return schema.StatusUpdateView.all()
        //.orderBy(["statusdate", "ml_year"], ["desc", "desc"])
        .toModelArray();
});

class Dashboard extends Component {
    state = {
        proposalFilter: getCookieValue("proposalFilter"),
        proposalFilter2: getCookieValue("proposalFilter2"),
        proposalFilterYear: getCookieValue("proposalFilterYear") ? getCookieValue("proposalFilterYear") : "All",
        statusFilterYear: getCookieValue("statusFilterYear") ? getCookieValue("statusFilterYear") : "All",
        apFilter: getCookieValue("apFilter"),
        apFilter2: getCookieValue("apFilter2"),
        apFilterYear: getCookieValue("apFilterYear") ? getCookieValue("apFilterYear") : "All",
        finalFilter: getCookieValue("finalFilter"),
        finalFilter2: getCookieValue("finalFilter2"),
        reportingFilter: getCookieValue("reportingFilter"),
        reportingFilter2: getCookieValue("reportingFilter2"),
        statusFilter: getCookieValue("statusFilter"),
        statusFilter2: getCookieValue("statusFilter2"),
        order: getCookieValue("order") ? getCookieValue("order") : "desc",
        orderBy: getCookieValue("orderBy") ? getCookieValue("orderBy") : "ml_year",
        page: getCookieValue("page") ? parseInt(getCookieValue("page")) : 0,
        rowsPerPage: getCookieValue("rowsPerPage") ? parseInt(getCookieValue("rowsPerPage")) : 10,
        apOrder: getCookieValue("apOrder") ? getCookieValue("apOrder") : "desc",
        apOrderBy: getCookieValue("apOrderBy") ? getCookieValue("apOrderBy") : "ml_year",
        apPage: getCookieValue("apPage") ? parseInt(getCookieValue("apPage")) : 0,
        apRowsPerPage: getCookieValue("apRowsPerPage") ? parseInt(getCookieValue("apRowsPerPage")) : 10,
        finalOrder: getCookieValue("finalOrder") ? getCookieValue("finalOrder") : "desc",
        finalOrderBy: getCookieValue("finalOrderBy") ? getCookieValue("finalOrderBy") : "ml_year",
        finalPage: getCookieValue("finalPage") ? parseInt(getCookieValue("finalPage")) : 0,
        finalRowsPerPage: getCookieValue("finalRowsPerPage") ? parseInt(getCookieValue("finalRowsPerPage")) : 10,
        reportingOrder: getCookieValue("reportingOrder") ? getCookieValue("reportingOrder") : "desc",
        reportingOrderBy: getCookieValue("reportingOrderBy") ? getCookieValue("reportingOrderBy") : "ml_year",
        reportingPage: getCookieValue("reportingPage") ? parseInt(getCookieValue("reportingPage")) : 0,
        reportingRowsPerPage: getCookieValue("reportingRowsPerPage") ? parseInt(getCookieValue("reportingRowsPerPage")) : 10,
        statusOrder: getCookieValue("statusOrder") ? getCookieValue("statusOrder") : "desc",
        statusOrderBy: getCookieValue("statusOrderBy") ? getCookieValue("statusOrderBy") : "ml_year",
        statusPage: getCookieValue("statusPage") ? parseInt(getCookieValue("statusPage")) : 0,
        statusRowsPerPage: getCookieValue("statusRowsPerPage") ? parseInt(getCookieValue("statusRowsPerPage")) : 10,
        totalCount: 0,
        tableLoading: false,
        totalCount2: 0,
        tableLoading2: false,
        totalCount3: 0,
        tableLoading3: false,
        totalCount4: 0,
        tableLoading4: false,
        totalCount5: 0,
        tableLoading5: false,
        deleteWarningOpen: false,
        deleteId: null,
        batchDialogOpen: false,
        batchMessage: null,
        batchParcels: [],
        loadingAp: null,
        loadingProp: null,
        loadingFinal: null,
        loadingStatus: null,
    };
    year_options = [
        { label: "All", value: "All" },
        { label: "Current Year", value: "Current Year" },
        { label: "Past", value: "Past" }
    ];
    year_options2 = [
        { label: "All", value: "All" },
        { label: "Past Year", value: "Past Year" },
    ];
    typingTimer;

    componentDidMount(){
        document.title = "Dashboard - LSOHC Proposal and Grant Management System";
        this.dynamicLoading();
        this.dynamicLoading2();
        this.dynamicLoading3();
        this.dynamicLoading4();
        this.dynamicLoading5();
    };

    componentDidUpdate(prevProps){
        const { authState } = this.props;
        if (authState !== prevProps.authState) {
            this.dynamicLoading();
            this.dynamicLoading2();
            this.dynamicLoading3();
            this.dynamicLoading4();
            this.dynamicLoading5();
        }
    };

    loadTotalRecords = (f, urllink, state_element) => {
        const { authState, history } = this.props;
        var component = this;

        fetch("/count/" + urllink + "/" + f, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        }).then(e => {
            if (e.status === 401) {
                history.push("/");
            } else if (e.status !== 500) {
                e.json().then(data => {
                    component.setState({ [state_element]: data.totalCount });
                });
            }
        });
    };

    dynamicLoading = (_page, _rowsPerPage, _order, _orderBy, _filter1, _filter2) => {
        const { ormInitialLandReportingViewReloadDynamic, authState } = this.props;
        var { reportingOrder, reportingOrderBy, reportingRowsPerPage, reportingPage, reportingFilter, reportingFilter2 } = this.state;
        var component = this;

        if (!authState || !authState.user || (authState.user.role !== "admin" && authState.user.role !== "readonly"))
            return;

        var p, r, o, ob, f;
        if (_page !== undefined) p = _page;
        else p = reportingPage;
        if (_rowsPerPage !== undefined) r = _rowsPerPage;
        else r = reportingRowsPerPage;
        if (_order !== undefined) o = _order;
        else o = reportingOrder;
        if (_orderBy !== undefined) ob = _orderBy;
        else ob = reportingOrderBy;
        if (_filter1 !== undefined) f = _filter1 + "|" + _filter2;
        else f = reportingFilter + "|" + reportingFilter2;

        this.loadTotalRecords(f, "InitialLandReportingView", "totalCount");

        var str = "";

        this.setState({ tableLoading: true });
        str += "&filters=" + encodeURIComponent(f) + "&count=" + r + "&offset=" + r * p + "&order=" + o + "&order_by=" + encodeURIComponent(ob);
        ormInitialLandReportingViewReloadDynamic(str).then(() => {
            component.setState({ tableLoading: false });
        });
    };

    dynamicLoading2 = (_page, _rowsPerPage, _order, _orderBy, _filter1, _filter2, _proposalFilterYear) => {
        const { ormProposalViewReloadDynamic, authState } = this.props;
        var { order, orderBy, rowsPerPage, page, proposalFilter, proposalFilter2, proposalFilterYear } = this.state;
        var component = this;

        if (!authState || !authState.user || (authState.user.role !== "admin" && authState.user.role !== "readonly"))
            return;

        var p, r, o, ob, f;
        if (_page !== undefined) p = _page;
        else p = page;
        if (_rowsPerPage !== undefined) r = _rowsPerPage;
        else r = rowsPerPage;
        if (_order !== undefined) o = _order;
        else o = order;
        if (_orderBy !== undefined) ob = _orderBy;
        else ob = orderBy;
        if (_filter1 !== undefined) f = _filter1 + "|" + _filter2;
        else f = proposalFilter + "|" + proposalFilter2;
        if (_proposalFilterYear !== undefined) f += "|" + _proposalFilterYear;
        else f += "|" + proposalFilterYear;

        this.loadTotalRecords(f, "ProposalView", "totalCount2");

        var str = "";

        this.setState({ tableLoading2: true });
        str += "&filters2=" + encodeURIComponent(f) + "&count=" + r + "&offset=" + r * p + "&order=" + o + "&order_by=" + encodeURIComponent(ob);
        ormProposalViewReloadDynamic(str).then(() => {
            component.setState({ tableLoading2: false });
        });
    };

    dynamicLoading3 = (_page, _rowsPerPage, _order, _orderBy, _filter1, _filter2, _apFilterYear) => {
        const { ormAccomplishmentPlanViewReloadDynamic, authState } = this.props;
        var { apOrder, apOrderBy, apRowsPerPage, apPage, apFilter, apFilter2, apFilterYear } = this.state;
        var component = this;

        if (!authState || !authState.user || (authState.user.role !== "admin" && authState.user.role !== "readonly"))
            return;

        var p, r, o, ob, f;
        if (_page !== undefined) p = _page;
        else p = apPage;
        if (_rowsPerPage !== undefined) r = _rowsPerPage;
        else r = apRowsPerPage;
        if (_order !== undefined) o = _order;
        else o = apOrder;
        if (_orderBy !== undefined) ob = _orderBy;
        else ob = apOrderBy;
        if (_filter1 !== undefined) f = _filter1 + "|" + _filter2;
        else f = apFilter + "|" + apFilter2;
        if (_apFilterYear !== undefined) f += "|" + _apFilterYear;
        else f += "|" + apFilterYear;

        this.loadTotalRecords(f, "AccomplishmentPlanView", "totalCount3");

        var str = "";

        this.setState({ tableLoading3: true });
        str += "&filters3=" + encodeURIComponent(f) + "&count=" + r + "&offset=" + r * p + "&order=" + o + "&order_by=" + encodeURIComponent(ob);
        ormAccomplishmentPlanViewReloadDynamic(str).then(() => {
            component.setState({ tableLoading3: false });
        });
    };

    dynamicLoading4 = (_page, _rowsPerPage, _order, _orderBy, _filter1, _filter2) => {
        const { ormFinalReportViewReloadDynamic, authState } = this.props;
        var { finalOrder, finalOrderBy, finalRowsPerPage, finalPage, finalFilter, finalFilter2 } = this.state;
        var component = this;

        if (!authState || !authState.user || (authState.user.role !== "admin" && authState.user.role !== "readonly"))
            return;

        var p, r, o, ob, f;
        if (_page !== undefined) p = _page;
        else p = finalPage;
        if (_rowsPerPage !== undefined) r = _rowsPerPage;
        else r = finalRowsPerPage;
        if (_order !== undefined) o = _order;
        else o = finalOrder;
        if (_orderBy !== undefined) ob = _orderBy;
        else ob = finalOrderBy;
        if (_filter1 !== undefined) f = _filter1 + "|" + _filter2;
        else f = finalFilter + "|" + finalFilter2;

        this.loadTotalRecords(f, "FinalReportView", "totalCount4");

        var str = "";

        this.setState({ tableLoading4: true });
        str += "&filters4=" + encodeURIComponent(f) + "&count=" + r + "&offset=" + r * p + "&order=" + o + "&order_by=" + encodeURIComponent(ob);
        ormFinalReportViewReloadDynamic(str).then(() => {
            component.setState({ tableLoading4: false });
        });
    };

    dynamicLoading5 = (_page, _rowsPerPage, _order, _orderBy, _filter1, _filter2, _statusFilterYear) => {
        const { ormStatusUpdateViewReloadDynamic, authState } = this.props;
        var { statusOrder, statusOrderBy, statusRowsPerPage, statusPage, statusFilter, statusFilter2, statusFilterYear } = this.state;
        var component = this;

        if (!authState || !authState.user || (authState.user.role !== "admin" && authState.user.role !== "readonly"))
            return;

        var p, r, o, ob, f;
        if (_page !== undefined) p = _page;
        else p = statusPage;
        if (_rowsPerPage !== undefined) r = _rowsPerPage;
        else r = statusRowsPerPage;
        if (_order !== undefined) o = _order;
        else o = statusOrder;
        if (_orderBy !== undefined) ob = _orderBy;
        else ob = statusOrderBy;
        if (_filter1 !== undefined) f = _filter1 + "|" + _filter2;
        else f = statusFilter + "|" + statusFilter2;
        if (_statusFilterYear !== undefined) f += "|" + _statusFilterYear;
        else f += "|" + statusFilterYear;

        this.loadTotalRecords(f, "StatusUpdateView", "totalCount5");

        var str = "";

        this.setState({ tableLoading5: true });
        str += "&filters5=" + encodeURIComponent(f) + "&count=" + r + "&offset=" + r * p + "&order=" + o + "&order_by=" + encodeURIComponent(ob);
        ormStatusUpdateViewReloadDynamic(str).then(() => {
            component.setState({ tableLoading5: false });
        });
    };

    fields_to_search = ["ml_year", "name", "status", "user_label", "subd", "project_name", "parcel_name", "parcel_type", "user_name", "user_id"];
    filterByValue = (array, string) => {
        return array.filter(o =>
            Object.keys(o).some(k => this.fields_to_search.includes(k) && o[k] && o[k].toString().toLowerCase().includes(string.toLowerCase())));
    }

    sortFunction = (sort, columnData, order, orderBy) => {
        sort.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
    }

    handleRequestSort = (event, property, columnData, sort) => {
        const { authState } = this.props;
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        document.cookie = "order=" + order;
        document.cookie = "orderBy=" + orderBy;

        if ( authState && authState.user && (authState.user.role === "admin" || authState.user.role === "readonly")) {
            document.cookie = "page=0";
            document.cookie = "rowsPerPage=10";
            this.setState({ order: order, orderBy: orderBy, page: 0, rowsPerPage: 10 });
            this.dynamicLoading2(0, 10, order, orderBy);
            return;
        }

        this.sortFunction(sort, columnData, order, orderBy);
        this.setState({ order: order, orderBy: orderBy });
    };

    handleRequestSortAp = (event, property, columnData, sort) => {
        const { authState } = this.props;
        const orderBy = property;
        let order = "desc";

        if (this.state.apOrderBy === property && this.state.apOrder === "desc") {
            order = "asc";
        }

        document.cookie = "apOrder=" + order;
        document.cookie = "apOrderBy=" + orderBy;

        if ( authState && authState.user && (authState.user.role === "admin" || authState.user.role === "readonly")) {
            document.cookie = "apPage=0";
            document.cookie = "apRowsPerPage=10";
            this.setState({ apOrder: order, apOrderBy: orderBy, apPage: 0, apRowsPerPage: 10 });
            this.dynamicLoading3(0, 10, order, orderBy);
            return;
        }

        this.sortFunction(sort, columnData, order, orderBy);
        this.setState({ apOrder: order, apOrderBy: orderBy });
    };

    handleRequestSortStatus = (event, property, columnData, sort) => {
        const { authState } = this.props;
        const orderBy = property;
        let order = "desc";

        if (this.state.statusOrderBy === property && this.state.statusOrder === "desc") {
            order = "asc";
        }

        document.cookie = "statusOrder=" + order;
        document.cookie = "statusOrderBy=" + orderBy;

        if ( authState && authState.user && (authState.user.role === "admin" || authState.user.role === "readonly")) {
            document.cookie = "statusPage=0";
            document.cookie = "statusRowsPerPage=10";
            this.setState({ statusOrder: order, statusOrderBy: orderBy, statusPage: 0, statusRowsPerPage: 10 });
            this.dynamicLoading5(0, 10, order, orderBy);
            return;
        }

        this.sortFunction(sort, columnData, order, orderBy);
        this.setState({ statusOrder: order, statusOrderBy: orderBy });
    };

    handleRequestSortFinal = (event, property, columnData, sort) => {
        const { authState } = this.props;
        const orderBy = property;
        let order = "desc";

        if (this.state.finalOrderBy === property && this.state.finalOrder === "desc") {
            order = "asc";
        }

        document.cookie = "finalOrder=" + order;
        document.cookie = "finalOrderBy=" + orderBy;

        if ( authState && authState.user && (authState.user.role === "admin" || authState.user.role === "readonly")) {
            document.cookie = "finalPage=0";
            document.cookie = "finalRowsPerPage=10";
            this.setState({ finalOrder: order, finalOrderBy: orderBy, finalPage: 0, finalRowsPerPage: 10 });
            this.dynamicLoading4(0, 10, order, orderBy);
            return;
        }

        this.sortFunction(sort, columnData, order, orderBy);
        this.setState({ finalOrder: order, finalOrderBy: orderBy });
    };

    handleRequestSortReporting = (event, property, columnData, sort) => {
        const { authState } = this.props;
        const orderBy = property;
        let order = "desc";

        if (this.state.reportingOrderBy === property && this.state.reportingOrder === "desc") {
            order = "asc";
        }

        document.cookie = "reportingOrder=" + order;
        document.cookie = "reportingOrderBy=" + orderBy;

        if ( authState && authState.user && (authState.user.role === "admin" || authState.user.role === "readonly")) {
            document.cookie = "reportingPage=0";
            document.cookie = "reportingRowsPerPage=10";
            this.setState({ reportingOrder: order, reportingOrderBy: orderBy, reportingPage: 0, reportingRowsPerPage: 10 });
            this.dynamicLoading(0, 10, order, orderBy);
            return;
        }

        this.sortFunction(sort, columnData, order, orderBy);
        this.setState({ reportingOrder: order, reportingOrderBy: orderBy });
    };

    updateStatus = (new_status, e, id) => {
        const { ormProposalUpdate2 } = this.props;
        const compon = this;
        compon.setState({loadingProp: id}, function() {
            ormProposalUpdate2({id: id, status: new_status}).then(function() { 
                compon.setState({loadingProp: null});
            });
        });
    };

    updateStatusAp = (new_status, e, id) => {
        const { ormAccomplishmentPlanUpdate2 } = this.props;
        const compon = this;
        compon.setState({loadingAp: id}, function() {
            ormAccomplishmentPlanUpdate2({id: id, status: new_status}).then(function() { 
                compon.setState({loadingAp: null});
            });
        });
    };

    updateStatusFinal = (new_status, e, id) => {
        const { ormFinalReportUpdate2 } = this.props;
        const compon = this;
        compon.setState({loadingFinal: id}, function() {
            ormFinalReportUpdate2({id: id, status: new_status}).then(function() { 
                compon.setState({loadingFinal: null});
            });
        });
    };

    updateStatusStatus = (new_status, e, id) => {
        const { ormStatusUpdateUpdate2 } = this.props;
        const compon = this;
        compon.setState({loadingStatus: id}, function() {
            ormStatusUpdateUpdate2({id: id, status: new_status}).then(function() { 
                compon.setState({loadingStatus: null});
            });
        });
    };

    showFinalReport = (a, b, c, id) => {
        const { ormFinalReportUpdate/*, ormFinalReportViewReload*/ } = this.props;
        ormFinalReportUpdate({id: id, visible: true});
        /*setTimeout(function() {
            ormFinalReportViewReload();
        }, 500);*/
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
        this.dynamicLoading2(page);
        document.cookie = "page=" + page;
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
        this.dynamicLoading2(0, event.target.value);
        document.cookie = "rowsPerPage=" + event.target.value;
        document.cookie = "page=0";
    };

    handleChangePageAp = (event, apPage) => {
        this.setState({ apPage });
        this.dynamicLoading3(apPage);
        document.cookie = "apPage=" + apPage;
    };

    handleChangeRowsPerPageAp = event => {
        this.setState({ apRowsPerPage: event.target.value, apPage: 0 });
        this.dynamicLoading3(0, event.target.value);
        document.cookie = "apRowsPerPage=" + event.target.value;
        document.cookie = "apPage=0";
    };

    handleChangePageFinal = (event, finalPage) => {
        this.setState({ finalPage });
        this.dynamicLoading4(finalPage);
        document.cookie = "finalPage=" + finalPage;
    };

    handleChangeRowsPerPageFinal = event => {
        this.setState({ finalRowsPerPage: event.target.value, finalPage: 0 });
        this.dynamicLoading4(0, event.target.value);
        document.cookie = "finalRowsPerPage=" + event.target.value;
        document.cookie = "finalPage=0";
    };

    handleChangePageReporting = (event, reportingPage) => {
        this.setState({ reportingPage });
        this.dynamicLoading(reportingPage);
        document.cookie = "reportingPage=" + reportingPage;
    };

    handleChangeRowsPerPageReporting = event => {
        this.setState({ reportingRowsPerPage: event.target.value, reportingPage: 0 });
        this.dynamicLoading(0, event.target.value);
        document.cookie = "reportingRowsPerPage=" + event.target.value;
        document.cookie = "reportingPage=0";
    };

    handleChangePageStatus = (event, statusPage) => {
        this.setState({ statusPage });
        this.dynamicLoading5(statusPage);
        document.cookie = "statusPage=" + statusPage;
    };

    handleChangeRowsPerPageStatus = event => {
        this.setState({ statusRowsPerPage: event.target.value, statusPage: 0 });
        this.dynamicLoading5(0, event.target.value);
        document.cookie = "statusRowsPerPage=" + event.target.value;
        document.cookie = "statusPage=0";
    };

    newRealProperty = (id) => {
        const { ormRealPropertyInterestCreate, history, ormInitialLandReportingReload/*, ormInitialLandReportingViewReload*/ } = this.props;
        ormRealPropertyInterestCreate({
            accomplishment_parcel_id: id,
            year: new Date().getFullYear()
        }).then((data) => {
            history.push("/real_property/" + data);
            // Refresh dashboard table since real_properties are from a serializer not the model
            ormInitialLandReportingReload();
            //ormInitialLandReportingViewReload();
        });
    }

    handleFilterReportingProposal = (val, e, element) => {
        var { order, orderBy, rowsPerPage, proposalFilter2 } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            document.cookie = "proposalFilter=" + (val ? val : "");
            comp.setState({[element]: val, page: 0});
            comp.dynamicLoading2(0, rowsPerPage, order, orderBy, val, proposalFilter2);
        }, 500);
    };

    handleFilterReporting2Proposal = (val, e, element) => {
        var { order, orderBy, rowsPerPage, proposalFilter } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            document.cookie = "proposalFilter2=" + (val ? val : "");
            comp.setState({[element]: val, page: 0});
            comp.dynamicLoading2(0, rowsPerPage, order, orderBy, proposalFilter, val);
        }, 500);
    };

    handleFilterReportingAp = (val, e, element) => {
        var { apOrder, apOrderBy, apRowsPerPage, apFilter2 } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            document.cookie = "apFilter=" + (val ? val : "");
            comp.setState({[element]: val, apPage: 0});
            comp.dynamicLoading3(0, apRowsPerPage, apOrder, apOrderBy, val, apFilter2);
        }, 500);
    };

    handleFilterReporting2Ap = (val, e, element) => {
        var { apOrder, apOrderBy, apRowsPerPage, apFilter } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            document.cookie = "apFilter2=" + (val ? val : "");
            comp.setState({[element]: val, apPage: 0});
            comp.dynamicLoading3(0, apRowsPerPage, apOrder, apOrderBy, apFilter, val);
        }, 500);
    };

    handleFilterReportingFinal = (val, e, element) => {
        var { finalOrder, finalOrderBy, finalRowsPerPage, finalFilter2 } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            document.cookie = "finalFilter=" + (val ? val : "");
            comp.setState({[element]: val, finalPage: 0});
            comp.dynamicLoading4(0, finalRowsPerPage, finalOrder, finalOrderBy, val, finalFilter2);
        }, 500);
    };

    handleFilterReporting2Final = (val, e, element) => {
        var { finalOrder, finalOrderBy, finalRowsPerPage, finalFilter } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            document.cookie = "finalFilter2=" + (val ? val : "");
            comp.setState({[element]: val, finalPage: 0});
            comp.dynamicLoading4(0, finalRowsPerPage, finalOrder, finalOrderBy, finalFilter, val);
        }, 500);
    };

    handleFilterReportingStatus = (val, e, element) => {
        var { statusOrder, statusOrderBy, statusRowsPerPage, statusFilter2 } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            document.cookie = "statusFilter=" + (val ? val : "");
            comp.setState({[element]: val, statusPage: 0});
            comp.dynamicLoading5(0, statusRowsPerPage, statusOrder, statusOrderBy, val, statusFilter2);
        }, 500);
    };

    handleFilterReporting2Status = (val, e, element) => {
        var { statusOrder, statusOrderBy, statusRowsPerPage, statusFilter } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            document.cookie = "statusFilter2=" + (val ? val : "");
            comp.setState({[element]: val, statusPage: 0});
            comp.dynamicLoading5(0, statusRowsPerPage, statusOrder, statusOrderBy, statusFilter, val);
        }, 500);
    };

    handleFilterReporting = (val, e, element) => {
        var { reportingOrder, reportingOrderBy, reportingRowsPerPage, reportingFilter2 } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            document.cookie = "reportingFilter=" + (val ? val : "");
            comp.setState({[element]: val, reportingPage: 0});
            comp.dynamicLoading(0, reportingRowsPerPage, reportingOrder, reportingOrderBy, val, reportingFilter2);
        }, 500);
    };

    handleFilterReporting2 = (val, e, element) => {
        var { reportingOrder, reportingOrderBy, reportingRowsPerPage, reportingFilter } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            document.cookie = "reportingFilter2=" + (val ? val : "");
            comp.setState({[element]: val, reportingPage: 0});
            comp.dynamicLoading(0, reportingRowsPerPage, reportingOrder, reportingOrderBy, reportingFilter, val);
        }, 500);
    };

    handleFilterProposalYear = (val, e, element) => {
        var { order, orderBy, rowsPerPage, proposalFilter, proposalFilter2 } = this.state;
        document.cookie = "proposalFilterYear=" + (val ? val : "");
        this.setState({[element]: val, page: 0});
        this.dynamicLoading2(0, rowsPerPage, order, orderBy, proposalFilter, proposalFilter2, val);
    };

    handleFilterApYear = (val, e, element) => {
        var { apOrder, apOrderBy, apRowsPerPage, apFilter, apFilter2 } = this.state;
        document.cookie = "apFilterYear=" + (val ? val : "");
        this.setState({[element]: val, apPage: 0});
        this.dynamicLoading3(0, apRowsPerPage, apOrder, apOrderBy, apFilter, apFilter2, val);
    };

    handleStatusFilterYear = (val, e, element) => {
        var { statusOrder, statusOrderBy, statusRowsPerPage, statusFilter, statusFilter2 } = this.state;
        document.cookie = "statusFilterYear=" + (val ? val : "");
        this.setState({[element]: val, statusPage: 0});
        this.dynamicLoading5(0, statusRowsPerPage, statusOrder, statusOrderBy, statusFilter, statusFilter2, val);
    };

    startBatchRealProperty = (values) => {
        const { allLand_reportings, authState } = this.props;
        var { land_reportings } = this.props;   
        
        if (authState && authState.user && (authState.user.role === "admin" || authState.user.role === "readonly")) {
            land_reportings = allLand_reportings;
        }

        var parcels = [];
        for (const [key, value] of Object.entries(values)) {
            if (value) {
                var name_obj = land_reportings.find( x => x.accomplishment_parcel_id === parseInt(key.replace("b_", "")));
                if (name_obj)
                    name_obj = name_obj.parcel_name;
                else
                    name_obj = key.replace("b_", "");
                parcels.push({
                    "id":key.replace("b_", ""), 
                    "name": name_obj
                });
            }
        }
        if (parcels.length === 0) {
            this.setState({batchDialogOpen: true, batchParcels: parcels, batchMessage:"You have not selected any parcels. Use the checkboxes in the table below to specifiy which parcels you wish to start."});
        } else {
            this.setState({batchDialogOpen: true, batchParcels: parcels, batchMessage:"IMPORTANT NOTICE: Once this batch report has been saved you will not be able to edit it again. If you need to edit a value you will have to enter each real property interest report individually. You are about to start a real property interest report for the following parcels:"});
        }
    };

    render() {
        const { 
            authState, classes, settings, 
            allProposals, allAccomplishment_plans, allFinal_reports, allLand_reportings, all_status_updates,
            navState, navToggleProposal, navToggleAp, navToggleFinal, navToggleParcel, navToggleStatus, ormStatusUpdateDelete, ormStatusUpdateViewDelete, history
        } = this.props;
        var { proposals, accomplishment_plans, final_reports, land_reportings, status_updates } = this.props;
        const { 
            page, rowsPerPage, proposalFilter, proposalFilter2, newDialogOpen, orderBy, order,
            apPage, apRowsPerPage, apFilter, apFilter2, apOrderBy, apOrder,
            finalPage, finalRowsPerPage, finalFilter, finalFilter2, finalOrderBy, finalOrder,
            reportingPage, reportingRowsPerPage, reportingFilter, reportingFilter2, reportingOrderBy, reportingOrder,
            statusPage, statusRowsPerPage, statusFilter, statusFilter2, statusOrderBy, statusOrder, totalCount, tableLoading,
            totalCount2, tableLoading2, totalCount3, tableLoading3, totalCount4, tableLoading4, totalCount5, tableLoading5, statusFilterYear,
            deleteWarningOpen, deleteId, batchDialogOpen, batchMessage, batchParcels, loadingAp, loadingProp, loadingStatus, loadingFinal
         } = this.state;

        const proposals_open = settings && settings.funding_open;

        var is_admin = false;
        var columnDataProposal = [
            { id: "actions", numeric: false, label: "Actions", allowSort: false, width:"20px" },
            { id: "ml_year", numeric: false, label: "ML Year", allowSort: true, width:"20px"  },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "status", numeric: false, label: "Status", allowSort: true },
            { id: "reports", numeric: false, label: "Reports", allowSort: false },
            { id: "attachments", numeric: false, label: "Attachments", allowSort: false },
        ];
        var columnDataAp = [
            { id: "actions", numeric: false, label: "Actions", allowSort: false, width:"20px" },
            { id: "ml_year", numeric: false, label: "ML Year", allowSort: true, width:"20px"  },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "status", numeric: false, label: "Status", allowSort: true },
            { id: "reports", numeric: false, label: "Reports", allowSort: false },
        ];
        var columnDataFinal = [
            { id: "actions", numeric: false, label: "Actions", allowSort: false, width:"20px" },
            { id: "ml_year", numeric: false, label: "ML Year", allowSort: true, width:"20px"  },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "status", numeric: false, label: "Status", allowSort: true },
            { id: "reports", numeric: false, label: "Reports", allowSort: false },
            { id: "attachments", numeric: false, label: "Attachments", allowSort: false },
        ];
        var columnDataReporting = [
            { id: "actions", numeric: false, label: "Actions", allowSort: false, width:"20px" },
            { id: "ml_year", numeric: false, label: "ML Year", allowSort: true, width:"20px"  },
            { id: "project_name", numeric: false, label: "Project Name", allowSort: true },
            { id: "parcel_name", numeric: false, label: "Parcel Name", allowSort: true },
            { id: "parcel_type", numeric: false, label: "Type", allowSort: true },
            { id: "reports", numeric: false, label: "Reports", allowSort: false },
            { id: "real_property_interest", numeric: false, label: "Real Property Interest", allowSort: false },
        ];
        var columnDataStatus = [
            { id: "actions", numeric: false, label: "Actions", allowSort: false, width:"20px" },
            { id: "ml_year", numeric: false, label: "ML Year", allowSort: true, width:"20px"  },
            { id: "project_name", numeric: false, label: "Project Name", allowSort: true },
            { id: "status", numeric: false, label: "Status", allowSort: true },
            { id: "statusdate_label", numeric: false, label: "Date", allowSort: false },
            { id: "reports", numeric: false, label: "Reports", allowSort: false },
        ];
        var is_edit_admin = false;
        if (authState && authState.user && (authState.user.role === "admin" || authState.user.role === "readonly")) {
            columnDataProposal = [
                { id: "actions", numeric: false, label: "Actions", allowSort: false, width:"20px" },
                { id: "ml_year", numeric: false, label: "ML Year", allowSort: true, width:"20px"  },
                { id: "name", numeric: false, label: "Name", allowSort: true },
                { id: "status", numeric: false, label: "Status", allowSort: true },
                { id: "user_id", numeric: false, label: "User", allowSort: true },
                { id: "modify_date", numeric: false, label: "Last Modified", date: true, allowSort: true },
                { id: "reports", numeric: false, label: "Reports", allowSort: false },
                { id: "attachments", numeric: false, label: "Attachments", allowSort: false },
            ];
            columnDataAp = [
                { id: "actions", numeric: false, label: "Actions", allowSort: false, width:"20px" },
                { id: "ml_year", numeric: false, label: "ML Year", allowSort: true, width:"20px"  },
                { id: "subd", numeric: false, label: "Subd", allowSort: true, width:"20px"  },
                { id: "name", numeric: false, label: "Name", allowSort: true },
                { id: "status", numeric: false, label: "Status", allowSort: true },
                { id: "user_id", numeric: false, label: "User", allowSort: true },
                { id: "modify_date", numeric: false, label: "Last Modified", date: true, allowSort: true },
                { id: "reports", numeric: false, label: "Reports", allowSort: false },
                { id: "attachments", numeric: false, label: "Attachments", allowSort: false },
                { id: "final_report", numeric: false, label: "Final Report?", allowSort: false, width:"20px"  },
            ]; 
            columnDataFinal = [
                { id: "actions", numeric: false, label: "Actions", allowSort: false, width:"20px" },
                { id: "ml_year", numeric: false, label: "ML Year", allowSort: true, width:"20px"  },
                { id: "subd", numeric: false, label: "Subd", allowSort: true, width:"20px"  },
                { id: "name", numeric: false, label: "Name", allowSort: true },
                { id: "status", numeric: false, label: "Status", allowSort: true },
                { id: "user_id", numeric: false, label: "User", allowSort: true },
                { id: "modify_date", numeric: false, label: "Last Modified", date: true, allowSort: true },
                { id: "reports", numeric: false, label: "Reports", allowSort: false },
                { id: "attachments", numeric: false, label: "Attachments", allowSort: false },
            ];
            columnDataReporting = [
                { id: "actions", numeric: false, label: "Actions", allowSort: false, width:"20px" },
                { id: "ml_year", numeric: false, label: "ML Year", allowSort: true, width:"20px"  },
                { id: "subd", numeric: false, label: "Subd", allowSort: true, width:"20px"  },
                { id: "project_name", numeric: false, label: "Project Name", allowSort: true },
                { id: "parcel_name", numeric: false, label: "Parcel Name", allowSort: true },
                { id: "parcel_type", numeric: false, label: "Type", allowSort: true },
                { id: "user_name", numeric: false, label: "User", allowSort: true },
                { id: "reports", numeric: false, label: "Reports", allowSort: false },
                { id: "real_property_interest", numeric: false, label: "Real Property Interest", allowSort: false },
            ];
            columnDataStatus = [
                { id: "actions", numeric: false, label: "Actions", allowSort: false, width:"20px" },
                { id: "ml_year", numeric: false, label: "ML Year", allowSort: true, width:"20px"  },
                { id: "subd", numeric: false, label: "Subd", allowSort: true, width:"20px"  },
                { id: "project_name", numeric: false, label: "Project Name", allowSort: true },
                { id: "status", numeric: false, label: "Status", allowSort: true },
                { id: "user_name", numeric: false, label: "User", allowSort: true },
                { id: "statusdate", numeric: false, label: "Date", allowSort: true },
                { id: "reports", numeric: false, label: "Reports", allowSort: false },
                { id: "delete", numeric: false, label: "Delete", allowSort: false, width:"20px"  },
            ];
            is_admin = true;
            if (authState.user.role === "admin")
                is_edit_admin = true;
        }

        if (is_admin) {
            proposals = allProposals;
            accomplishment_plans = allAccomplishment_plans;
            final_reports = allFinal_reports;
            land_reportings = allLand_reportings;
            status_updates = all_status_updates;
        }

        // Text Filter
        var filtered_proposals;
        var filtered_proposals_sliced;
        if (!is_admin) {
            if (proposalFilter) {
                filtered_proposals = this.filterByValue(proposals, proposalFilter);
            } else {
                filtered_proposals = proposals;
            }
            if (proposalFilter2) {
                filtered_proposals = this.filterByValue(filtered_proposals, proposalFilter2);
            }
        } else {
            filtered_proposals = proposals;
            filtered_proposals_sliced = filtered_proposals;
        }
        var filtered_aps;
        var filtered_aps_sliced;
        if (!is_admin) {
            if (apFilter) {
                filtered_aps = this.filterByValue(accomplishment_plans, apFilter);
            } else {
                filtered_aps = accomplishment_plans;
            }
            if (apFilter2) {
                filtered_aps = this.filterByValue(filtered_aps, apFilter2);
            }
        } else {
            filtered_aps = accomplishment_plans;
            filtered_aps_sliced = filtered_aps;
        }
        var filtered_finals;
        var filtered_finals_sliced;
        if (!is_admin) {
            if (finalFilter) {
                filtered_finals = this.filterByValue(final_reports, finalFilter);
            } else {
                filtered_finals = final_reports;
            }
            if (finalFilter2) {
                filtered_finals = this.filterByValue(filtered_finals, finalFilter2);
            }
        } else {
            filtered_finals = final_reports;
            filtered_finals_sliced = filtered_finals;
        }
        var filtered_lands;
        var filtered_lands_sliced;
        if (!is_admin) {
            if (reportingFilter) {
                filtered_lands = this.filterByValue(land_reportings, reportingFilter);
            } else {
                filtered_lands = land_reportings;
            }
            if (reportingFilter2) {
                filtered_lands = this.filterByValue(filtered_lands, reportingFilter2);
            }
        } else {
            filtered_lands = land_reportings;
            filtered_lands_sliced = filtered_lands;
        }
        var filtered_status;
        var filtered_status_sliced;
        if (!is_admin) {
            if (statusFilter) {
                filtered_status = this.filterByValue(status_updates, statusFilter);
            } else {
                filtered_status = status_updates;
            }
            if (statusFilter2) {
                filtered_status = this.filterByValue(filtered_status, statusFilter2);
            }
        } else {
            filtered_status = status_updates;
            filtered_status_sliced = filtered_status;
        }

        // Year Filter
        if (!is_admin) {
            if (statusFilterYear && statusFilterYear !== "All") {
                filtered_status = filtered_status.filter(p => {
                    var year = new Date(p.statusdate_label).getFullYear();
                    var current_year = new Date().getFullYear();
                    if (statusFilterYear === "Past Year") {
                        return (year === current_year || year === (current_year - 1))
                    }
                    return true;
                });
            }
            filtered_proposals_sliced = filtered_proposals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            filtered_aps_sliced = filtered_aps.slice(apPage * apRowsPerPage, apPage * apRowsPerPage + apRowsPerPage);
            filtered_finals_sliced = filtered_finals.slice(finalPage * finalRowsPerPage, finalPage * finalRowsPerPage + finalRowsPerPage);
            filtered_lands_sliced = filtered_lands.slice(reportingPage * reportingRowsPerPage, reportingPage * reportingRowsPerPage + reportingRowsPerPage);
            filtered_status_sliced = filtered_status.slice(statusPage * statusRowsPerPage, statusPage * statusRowsPerPage + statusRowsPerPage);
        }

        var is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly") {
            is_read_only = true;
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <PageHeader title="Dashboard" />
                    {!is_admin && <Messages />}
                    {!is_admin && proposals_open && (
                        <Grid item xs={12} style={{ marginTop: 16 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.setState({ newDialogOpen: true })}>
                                <AddCircleIcon />
                                &nbsp;&nbsp;&nbsp;Create New Proposal
                            </Button>
                        </Grid>
                    )}
                   <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.proposalExpand} onChange={navToggleProposal}>
                            <ExpansionPanelSummary
                                className={is_admin ? classes.expansionSummaryRoot : classes.expansionSummaryRoot2}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <BookmarksIcon className={classes.expansionIcon} /> {!is_admin ? "My" : "All"}{" "}
                                    Proposals
                                    {is_admin && (
                                        <Form
                                            dontValidateOnMount={true}
                                            validateOnSubmit={true}
                                            defaultValues={this.state}>
                                            {formApi => (
                                                <form
                                                    style={{ float: "right", marginTop: -24 }}
                                                    onSubmit={formApi.submitForm}>
                                                    <RadioGroup
                                                        field="proposalFilterYear"
                                                        name="proposalFilterYear"
                                                        eventHandle={this.handleFilterProposalYear}
                                                        eventHandleProps1="proposalFilterYear"
                                                        fullWidth
                                                        options={this.year_options}
                                                        alignment={true}
                                                        label="Filter by Year"
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    )}
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12} style={{marginTop:-20}}>
                                        <Form defaultValues={this.state} dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form style={{ float: "right" }} onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        eventHandle={this.handleFilterReportingProposal}
                                                        eventHandleProps1="proposalFilter"
                                                        field="proposalFilter"
                                                        label="Filter"
                                                        filterInput
                                                    />
                                                    <TextField
                                                        eventHandle={this.handleFilterReporting2Proposal}
                                                        eventHandleProps1="proposalFilter2"
                                                        field="proposalFilter2"
                                                        label="and"
                                                        filterInput
                                                        className={classes.marginLeft}
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                columnData={columnDataProposal}
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={(a, b) => this.handleRequestSort(a, b, columnDataProposal, proposals)}
                                            />
                                            <TableBody>
                                                {tableLoading2 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataProposal.length} className={classes.centerAlign} style={{ paddingBottom: 16 }}>
                                                            Loading Table Records...
                                                            <CircularProgress size={30} className={classes.progress} />
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                                {(() => {
                                                    if (tableLoading2) return null;

                                                    return filtered_proposals_sliced.map(n => {
                                                        return (
                                                            <TableRow hover key={n.id}>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="Open Proposal">
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="Open Proposal"
                                                                            className={classes.deleteWidth}
                                                                            component={Link}
                                                                            to={"/proposal/" + n.id}>
                                                                            {!is_admin &&
                                                                                n.status === "New" && (
                                                                                    <CreateIcon color="primary" />
                                                                                )}
                                                                            {(is_admin ||
                                                                                n.status !== "New") && (
                                                                                <Visibility color="primary" />
                                                                            )}
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.ml_year}</CustomTableCell>
                                                                <CustomTableCell>{n.name}</CustomTableCell>
                                                                <CustomTableCell>{is_edit_admin && (
                                                                    <Form key={n.id + "status"} defaultValues={n}>
                                                                        {formApi => (
                                                                            <form onSubmit={formApi.submitForm}>
                                                                                <Select 
                                                                                    className={classes.selectWidth} tableFormat eventHandleProps1={n.id}
                                                                                    options={MAKE_OPTIONS(["New", "Submitted", "Reviewed", "Hearing", "No Hearing", "Funding Selected", "Funding Rejected"])} 
                                                                                    eventHandle={this.updateStatus} field="status" label="" fullWidth disabled={loadingProp === n.id}
                                                                                />
                                                                                {loadingProp === n.id && (
                                                                                    <CircularProgress size={30} className={classes.progress2} />
                                                                                )}
                                                                            </form>
                                                                        )}
                                                                    </Form>
                                                                )}{!is_edit_admin && n.status}</CustomTableCell>
                                                                {is_admin && (<CustomTableCell>{n.user_id}</CustomTableCell>)}
                                                                {is_admin && (<CustomTableCell>{n.modify_date_label}</CustomTableCell>)}
                                                                <CustomTableCell><div className={classes.attachmentFontSize} dangerouslySetInnerHTML={{ __html: n.reports }} /></CustomTableCell>
                                                                <CustomTableCell><div className={classes.attachmentFontSize} dangerouslySetInnerHTML={{ __html: n.attachments }} /></CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                })()}
                                                {(is_admin ? (tableLoading2 ? 1 : totalCount2) : filtered_proposals.length) < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataProposal.length} className={classes.centerAlign}>
                                                            No Proposals Found
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                            {(is_admin ? totalCount2 : filtered_proposals.length) > 10 && (
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            colSpan={columnDataProposal.length}
                                                            count={(is_admin ? totalCount2 : filtered_proposals.length)}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onChangePage={this.handleChangePage}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActionsWrapped}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            )}
                                        </Table>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                   <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.apExpand} onChange={navToggleAp}>
                            <ExpansionPanelSummary
                                className={is_admin ? classes.expansionSummaryRoot : classes.expansionSummaryRoot2}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <BookmarksIcon className={classes.expansionIcon} /> {!is_admin ? "My" : "All"}{" "}
                                    Accomplishment Plans
                                    {is_admin && (
                                        <Form
                                            dontValidateOnMount={true}
                                            validateOnSubmit={true}
                                            defaultValues={this.state}>
                                            {formApi => (
                                                <form
                                                    style={{ float: "right", marginTop: -24 }}
                                                    onSubmit={formApi.submitForm}>
                                                    <RadioGroup
                                                        field="apFilterYear"
                                                        name="apFilterYear"
                                                        eventHandle={this.handleFilterApYear}
                                                        eventHandleProps1="apFilterYear"
                                                        fullWidth
                                                        options={this.year_options}
                                                        alignment={true}
                                                        label="Filter by Year"
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    )}
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12} style={{marginTop:-20}}>
                                        <Form defaultValues={this.state} dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form style={{ float: "right" }} onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        eventHandle={this.handleFilterReportingAp}
                                                        eventHandleProps1="apFilter"
                                                        field="apFilter"
                                                        label="Filter"
                                                        filterInput
                                                    />
                                                    <TextField
                                                        eventHandle={this.handleFilterReporting2Ap}
                                                        eventHandleProps1="apFilter2"
                                                        field="apFilter2"
                                                        label="and"
                                                        filterInput
                                                        className={classes.marginLeft}
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                columnData={columnDataAp}
                                                order={apOrder}
                                                orderBy={apOrderBy}
                                                onRequestSort={(a, b) => this.handleRequestSortAp(a, b, columnDataAp, accomplishment_plans)}
                                            />
                                            <TableBody>
                                                {tableLoading3 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataAp.length} className={classes.centerAlign} style={{ paddingBottom: 16 }}>
                                                            Loading Table Records...
                                                            <CircularProgress size={30} className={classes.progress} />
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                                {(() => {
                                                    if (tableLoading3) return null;

                                                    return filtered_aps_sliced.map(n => {
                                                        return (
                                                            <TableRow hover key={n.id}>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="Open Accomplishment Plan">
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="Open Accomplishment Plan"
                                                                            className={classes.deleteWidth}
                                                                            component={Link}
                                                                            to={"/accomplishment/" + n.id}>
                                                                            {!is_admin && (n.status === "First Draft" || n.status === "Approved First Draft" || n.status === "Started Request for Amendment Change") && (
                                                                                    <CreateIcon color="primary" />
                                                                                )}
                                                                            {(is_admin || (n.status !== "First Draft" && n.status !== "Approved First Draft" && n.status !== "Started Request for Amendment Change")) && (
                                                                                <Visibility color="primary" />
                                                                            )}
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.ml_year}</CustomTableCell>
                                                                {is_admin && (<CustomTableCell>{n.subd}</CustomTableCell>)}
                                                                <CustomTableCell>{n.name}</CustomTableCell>
                                                                <CustomTableCell>{is_edit_admin && (
                                                                    <Form key={n.id + "status"} defaultValues={n}>
                                                                        {formApi => (
                                                                            <form onSubmit={formApi.submitForm}>
                                                                                <Select 
                                                                                    className={classes.selectWidth} tableFormat eventHandleProps1={n.id}
                                                                                    options={MAKE_OPTIONS(["First Draft", "Submitted First Draft", "Approved First Draft", "Submitted Final Draft", "Approved Final Draft", "Started Request for Amendment Change", "Submitted Request for Amendment Change", "Flag for Deletion"])} 
                                                                                    eventHandle={this.updateStatusAp} field="status" label="" fullWidth disabled={loadingAp === n.id}
                                                                                />
                                                                                {loadingAp === n.id && (
                                                                                    <CircularProgress size={30} className={classes.progress2} />
                                                                                )}
                                                                            </form>
                                                                        )}
                                                                    </Form>
                                                                )}{!is_edit_admin && n.status}</CustomTableCell>
                                                                {is_admin && (<CustomTableCell>{n.user_id}</CustomTableCell>)}
                                                                {is_admin && (<CustomTableCell>{n.modify_date_label}</CustomTableCell>)}
                                                                <CustomTableCell><div className={classes.attachmentFontSize} dangerouslySetInnerHTML={{ __html: n.reports }} /></CustomTableCell>
                                                                {is_admin && (<CustomTableCell><div className={classes.attachmentFontSize} dangerouslySetInnerHTML={{ __html: n.attachments }} /></CustomTableCell>)}
                                                                {is_edit_admin && (
                                                                    <CustomTableCell style={{textAlign:"center"}}>
                                                                        <Form key={n.id + "final"} defaultValues={n}>
                                                                            {formApi => (
                                                                                <form onSubmit={formApi.submitForm}>
                                                                                    <Checkbox
                                                                                        field="visible"
                                                                                        disabled={n.status !== "Approved Final Draft" || n.visible}
                                                                                        eventHandleProps1={n.final_id}
                                                                                        eventHandle={this.showFinalReport}
                                                                                    />
                                                                                </form>
                                                                            )}
                                                                        </Form>
                                                                    </CustomTableCell>
                                                                )}
                                                            </TableRow>
                                                        );
                                                    })
                                                })()}
                                                {(is_admin ? (tableLoading3 ? 1 : totalCount3) : filtered_aps.length) < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataAp.length} className={classes.centerAlign}>
                                                            No Accomplishment Plans Found
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                            {(is_admin ? totalCount3 : filtered_aps.length) > 10 && (
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            colSpan={columnDataAp.length}
                                                            count={(is_admin ? totalCount3 : filtered_aps.length)}
                                                            rowsPerPage={apRowsPerPage}
                                                            page={apPage}
                                                            onChangePage={this.handleChangePageAp}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPageAp}
                                                            ActionsComponent={TablePaginationActionsWrapped}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            )}
                                        </Table>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.statusExpand} onChange={navToggleStatus}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <BookmarksIcon className={classes.expansionIcon} /> {!is_admin ? "My" : "All"}{" "}
                                    Status Updates
                                    <Form
                                        dontValidateOnMount={true}
                                        validateOnSubmit={true}
                                        defaultValues={this.state}>
                                        {formApi => (
                                            <form
                                                style={{ float: "right", marginTop: -24 }}
                                                onSubmit={formApi.submitForm}>
                                                <RadioGroup
                                                    field="statusFilterYear"
                                                    name="statusFilterYear"
                                                    eventHandle={this.handleStatusFilterYear}
                                                    eventHandleProps1="statusFilterYear"
                                                    fullWidth
                                                    options={this.year_options2}
                                                    alignment={true}
                                                    label="Filter by Year"
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12} style={{marginTop:-20}}>
                                        <Form defaultValues={this.state} dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form style={{ float: "right" }} onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        eventHandle={this.handleFilterReportingStatus}
                                                        eventHandleProps1="statusFilter"
                                                        field="statusFilter"
                                                        label="Filter"
                                                        filterInput
                                                    />
                                                    <TextField
                                                        eventHandle={this.handleFilterReporting2Status}
                                                        eventHandleProps1="statusFilter2"
                                                        field="statusFilter2"
                                                        label="and"
                                                        filterInput
                                                        className={classes.marginLeft}
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                columnData={columnDataStatus}
                                                order={statusOrder}
                                                orderBy={statusOrderBy}
                                                onRequestSort={(a, b) => this.handleRequestSortStatus(a, b, columnDataStatus, status_updates)}
                                            />
                                            <TableBody>
                                                {tableLoading5 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataStatus.length} className={classes.centerAlign} style={{ paddingBottom: 16 }}>
                                                            Loading Table Records...
                                                            <CircularProgress size={30} className={classes.progress} />
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                                {(() => {
                                                    if (tableLoading5) return null;

                                                    return filtered_status_sliced.map(n => {
                                                        return (
                                                            <TableRow hover key={n.id}>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="Open Status Update">
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="Open Status Update"
                                                                            className={classes.deleteWidth}
                                                                            component={Link}
                                                                            to={"/status_update/" + n.id}>
                                                                            {!is_admin &&
                                                                                n.status === "Draft" && (
                                                                                    <CreateIcon color="primary" />
                                                                                )}
                                                                            {(is_admin ||
                                                                                n.status !== "Draft") && (
                                                                                <Visibility color="primary" />
                                                                            )}
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.ml_year}</CustomTableCell>
                                                                {is_admin && (<CustomTableCell>{n.subd}</CustomTableCell>)}
                                                                <CustomTableCell>{n.project_name}</CustomTableCell>
                                                                <CustomTableCell>{is_edit_admin && (
                                                                    <Form key={n.id + "status"} defaultValues={n}>
                                                                        {formApi => (
                                                                            <form onSubmit={formApi.submitForm}>
                                                                                <Select 
                                                                                    className={classes.selectWidth} tableFormat 
                                                                                    options={MAKE_OPTIONS(["Draft", "Submitted", "Accepted"])} eventHandleProps1={n.id}
                                                                                    eventHandle={this.updateStatusStatus} field="status" label="" disabled={loadingStatus === n.id}
                                                                                />
                                                                                {loadingStatus === n.id && (
                                                                                    <CircularProgress size={30} className={classes.progress2} />
                                                                                )}
                                                                            </form>
                                                                        )}
                                                                    </Form>
                                                                )}{!is_edit_admin && n.status}</CustomTableCell>
                                                                {is_admin && (<CustomTableCell>{n.user_name}</CustomTableCell>)}
                                                                <CustomTableCell>{n.statusdate_label}</CustomTableCell>
                                                                <CustomTableCell><div dangerouslySetInnerHTML={{ __html: n.reports }} /></CustomTableCell>
                                                                {is_admin && !is_read_only && n.status !== "Accepted" && (<CustomTableCell>
                                                                    <Tooltip title="Delete Status Update">
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="Delete Status Update"
                                                                            className={classes.deleteWidth}
                                                                            onClick={() => this.setState({deleteWarningOpen: true, deleteId: n.id})}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>)}
                                                            </TableRow>
                                                        );
                                                    })
                                                })()}
                                                {(is_admin ? (tableLoading5 ? 1 : totalCount5) : filtered_status.length) < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataStatus.length} className={classes.centerAlign}>
                                                            No Status Updates Found
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                            {(is_admin ? totalCount5 : filtered_status.length) > 10 && (
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            colSpan={columnDataStatus.length}
                                                            count={(is_admin ? totalCount5 : filtered_status.length)}
                                                            rowsPerPage={statusRowsPerPage}
                                                            page={statusPage}
                                                            onChangePage={this.handleChangePageStatus}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPageStatus}
                                                            ActionsComponent={TablePaginationActionsWrapped}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            )}
                                        </Table>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.finalExpand} onChange={navToggleFinal}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot2}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <BookmarksIcon className={classes.expansionIcon} /> {!is_admin ? "My" : "All"}{" "}
                                    Final Reports
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12} style={{marginTop:-20}}>
                                        <Form defaultValues={this.state} dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form style={{ float: "right" }} onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        eventHandle={this.handleFilterReportingFinal}
                                                        eventHandleProps1="finalFilter"
                                                        field="finalFilter"
                                                        label="Filter"
                                                        filterInput
                                                    />
                                                    <TextField
                                                        eventHandle={this.handleFilterReporting2Final}
                                                        eventHandleProps1="finalFilter2"
                                                        field="finalFilter2"
                                                        label="and"
                                                        filterInput
                                                        className={classes.marginLeft}
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                columnData={columnDataFinal}
                                                order={finalOrder}
                                                orderBy={finalOrderBy}
                                                onRequestSort={(a, b) => this.handleRequestSortFinal(a, b, columnDataFinal, final_reports)}
                                            />
                                            <TableBody>
                                                {tableLoading4 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataFinal.length} className={classes.centerAlign} style={{ paddingBottom: 16 }}>
                                                            Loading Table Records...
                                                            <CircularProgress size={30} className={classes.progress} />
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                                {(() => {
                                                    if (tableLoading4) return null;

                                                    return filtered_finals_sliced.map(n => {
                                                        return (
                                                            <TableRow hover key={n.id}>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="Open Final Report">
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="Open Final Report"
                                                                            className={classes.deleteWidth}
                                                                            component={Link}
                                                                            to={"/final/" + n.id}>
                                                                            {!is_admin &&
                                                                                n.status === "Draft" && (
                                                                                    <CreateIcon color="primary" />
                                                                                )}
                                                                            {(is_admin ||
                                                                                n.status !== "Draft") && (
                                                                                <Visibility color="primary" />
                                                                            )}
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.ml_year}</CustomTableCell>
                                                                {is_admin && (<CustomTableCell>{n.subd}</CustomTableCell>)}
                                                                <CustomTableCell>{n.name}</CustomTableCell>
                                                                <CustomTableCell>{is_edit_admin && (
                                                                    <Form key={n.id + "status"} defaultValues={n}>
                                                                        {formApi => (
                                                                            <form onSubmit={formApi.submitForm}>
                                                                                <Select 
                                                                                    className={classes.selectWidth} tableFormat 
                                                                                    options={MAKE_OPTIONS(["Draft", "Submitted", "Accepted"])} eventHandleProps1={n.id}
                                                                                    eventHandle={this.updateStatusFinal} field="status" label="" fullWidth disabled={loadingFinal === n.id}
                                                                                />
                                                                                {loadingFinal === n.id && (
                                                                                    <CircularProgress size={30} className={classes.progress2} />
                                                                                )}
                                                                            </form>
                                                                        )}
                                                                    </Form>
                                                                )}{!is_edit_admin && n.status}</CustomTableCell>
                                                                {is_admin && (<CustomTableCell>{n.user_id}</CustomTableCell>)}
                                                                {is_admin && (<CustomTableCell>{n.modify_date_label}</CustomTableCell>)}
                                                                <CustomTableCell><div className={classes.attachmentFontSize} dangerouslySetInnerHTML={{ __html: n.reports }} /></CustomTableCell>
                                                                <CustomTableCell><div className={classes.attachmentFontSize} dangerouslySetInnerHTML={{ __html: n.attachments }} /></CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                })()}
                                                {(is_admin ? (tableLoading4 ? 1 : totalCount4) : filtered_finals.length) < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataFinal.length} className={classes.centerAlign}>
                                                            No Final Reports Found
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                            {(is_admin ? totalCount4 : filtered_finals.length) > 10 && (
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            colSpan={columnDataFinal.length}
                                                            count={(is_admin ? totalCount4 : filtered_finals.length)}
                                                            rowsPerPage={finalRowsPerPage}
                                                            page={finalPage}
                                                            onChangePage={this.handleChangePageFinal}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPageFinal}
                                                            ActionsComponent={TablePaginationActionsWrapped}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            )}
                                        </Table>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.parcelExpand} onChange={navToggleParcel}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot2}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <BookmarksIcon className={classes.expansionIcon} /> {!is_admin ? "My" : "All"}{" "}
                                    Completed Parcels
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12} style={{marginTop:-20}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={is_read_only}
                                            onClick={() => this.completedForm.submitForm()}
                                            style={{marginTop:30}}
                                        >
                                            Start Batch Annual Real Property Interest Report
                                        </Button>
                                        <HelpLabel
                                            inputLabel=" "
                                            title={true}
                                            showLabel={true}
                                            helpText="You can start multiple real property interest reports at the same time for the current year by selecting the parcels below."
                                            style={{marginTop:30}}
                                        />
                                        <Form defaultValues={this.state} dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form style={{ float: "right" }} onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        eventHandle={this.handleFilterReporting}
                                                        eventHandleProps1="reportingFilter"
                                                        field="reportingFilter"
                                                        label="Filter"
                                                        filterInput
                                                    />
                                                    <TextField
                                                        eventHandle={this.handleFilterReporting2}
                                                        eventHandleProps1="reportingFilter2"
                                                        field="reportingFilter2"
                                                        label="and"
                                                        filterInput
                                                        className={classes.marginLeft}
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Form key="completed_form" onSubmit={(values) => this.startBatchRealProperty(values)} getApi={el => (this.completedForm = el)}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <Table className={classes.table}>
                                                        <EnhancedTableHead
                                                            columnData={columnDataReporting}
                                                            order={reportingOrder}
                                                            orderBy={reportingOrderBy}
                                                            onRequestSort={(a, b) => this.handleRequestSortReporting(a, b, columnDataReporting, land_reportings)}
                                                        />
                                                        <TableBody>
                                                            {tableLoading && (
                                                                <TableRow>
                                                                    <CustomTableCell colSpan={columnDataReporting.length} className={classes.centerAlign} style={{ paddingBottom: 16 }}>
                                                                        Loading Table Records...
                                                                        <CircularProgress size={30} className={classes.progress} />
                                                                    </CustomTableCell>
                                                                </TableRow>
                                                            )}
                                                            {(() => {
                                                                if (tableLoading) return null;

                                                                return filtered_lands_sliced.map(n => {
                                                                    const show_real = (n.parcel_type === "Protect in Easement" || n.parcel_type === "Protect in Fee w/o PILT" || n.parcel_type === "Protect in Fee with PILT");
                                                                    return (
                                                                        <TableRow hover key={n.id}>
                                                                            <CustomTableCell className={classes.nowrap}>
                                                                                <Tooltip title="Open Initial Land Reporting Form">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        aria-label="Open Initial Land Reporting Form"
                                                                                        className={classes.deleteWidth}
                                                                                        component={Link}
                                                                                        to={"/land_reporting/" + n.id}>
                                                                                        <CreateIcon color="primary" />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                                {n.highlight && (
                                                                                    <Tooltip title={is_admin ? "This user has not completed their real property interest report for the current year" : "You are responsible for completion of a real property interest report this year"}>
                                                                                        <Button
                                                                                            color="primary"
                                                                                            className={classes.deleteWidth}>
                                                                                            <NewReleases color="primary" />
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                )}
                                                                                {n.highlight && (
                                                                                    <Checkbox value={n.accomplishment_parcel_id} field={"b_" + n.accomplishment_parcel_id} align={classes.inline} />
                                                                                )}
                                                                            </CustomTableCell>
                                                                            <CustomTableCell>{n.ml_year}</CustomTableCell>
                                                                            {is_admin && (<CustomTableCell>{n.subd}</CustomTableCell>)}
                                                                            <CustomTableCell>{n.project_name}</CustomTableCell>
                                                                            <CustomTableCell>{n.parcel_name}</CustomTableCell>
                                                                            <CustomTableCell>{n.parcel_type}</CustomTableCell>
                                                                            {is_admin && (<CustomTableCell>{n.user_name}</CustomTableCell>)}
                                                                            <CustomTableCell><div className={classes.attachmentFontSize} dangerouslySetInnerHTML={{ __html: n.reports }} /></CustomTableCell>
                                                                            <CustomTableCell>
                                                                                {show_real && !n.real_property_interest.some(real_prop => real_prop.year === new Date().getFullYear()) && (
                                                                                    // eslint-disable-next-line
                                                                                    <a className={classes.attachmentFontSize} style={{cursor: "pointer"}} onClick={() => this.newRealProperty(n.accomplishment_parcel_id)} rel="noopener noreferrer">
                                                                                        New Report
                                                                                    </a> 
                                                                                )}
                                                                                {show_real && n.real_property_interest.map(real_prop => {
                                                                                    if (real_prop.year === new Date().getFullYear()) {
                                                                                        return (
                                                                                            <a className={classes.attachmentFontSize} href={"#/real_property/" + real_prop.id} rel="noopener noreferrer">
                                                                                                {real_prop.year} ({real_prop.status})
                                                                                            </a> 
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <>
                                                                                            <br/>
                                                                                            <a className={classes.attachmentFontSize} href={"#/real_property/" + real_prop.id} rel="noopener noreferrer">
                                                                                                {real_prop.year} ({real_prop.status})
                                                                                            </a> 
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </CustomTableCell>
                                                                        </TableRow>
                                                                    );
                                                                })
                                                            })()}
                                                            {(is_admin ? (tableLoading ? 1 : totalCount) : filtered_lands.length) < 1 && (
                                                                <TableRow>
                                                                    <CustomTableCell colSpan={columnDataReporting.length} className={classes.centerAlign}>
                                                                        No Initial Land Reports Found
                                                                    </CustomTableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                        {(is_admin ? totalCount : filtered_lands.length) > 10 && (
                                                            <TableFooter>
                                                                <TableRow>
                                                                    <TablePagination
                                                                        colSpan={columnDataReporting.length}
                                                                        count={(is_admin ? totalCount : filtered_lands.length)}
                                                                        rowsPerPage={reportingRowsPerPage}
                                                                        page={reportingPage}
                                                                        onChangePage={this.handleChangePageReporting}
                                                                        onChangeRowsPerPage={this.handleChangeRowsPerPageReporting}
                                                                        ActionsComponent={TablePaginationActionsWrapped}
                                                                    />
                                                                </TableRow>
                                                            </TableFooter>
                                                        )}
                                                    </Table>
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Grid>
                <NewProposal returnDialog={() => this.setState({ newDialogOpen: false })} open={newDialogOpen} />
                <Dialog open={deleteWarningOpen} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer}>
                        <Warning className={classes.supportIcon} />
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({deleteWarningOpen: false})} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{marginTop:24}}>
                            <Typography>
                                Are you sure you wish to delete this status update?
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => {
                                ormStatusUpdateDelete(deleteId);
                                ormStatusUpdateViewDelete(deleteId);
                                this.setState({deleteWarningOpen: false});
                            }} color="primary" variant="contained">
                                Delete
                            </Button>
                            <Button onClick={() => this.setState({deleteWarningOpen: false})} color="primary" variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog open={batchDialogOpen} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer}>
                        <Warning className={classes.supportIcon} />
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({batchDialogOpen: false})} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{marginTop:24}}>
                            <Typography>
                                {batchMessage}
                                <ul>
                                    {batchParcels && batchParcels.map(m => (
                                        <li>{m.name}</li>
                                    ))}
                                </ul>
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => {
                                if (batchParcels.length > 0) {
                                    history.push("/real_property_batch/" + batchParcels.map(x => x.id).toString());
                                } else {
                                    this.setState({batchDialogOpen: false})
                                }
                            }} color="primary" variant="contained">
                                Ok
                            </Button>
                            <Button onClick={() => this.setState({batchDialogOpen: false})} color="primary" variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </AppContainer>
        );
    }
}

Dashboard = connect(
    (state, ownProps) => ({
        authState: state.auth,
        proposals: getProposals(state, ownProps),
        accomplishment_plans: getAps(state, ownProps),
        final_reports: getFinals(state, ownProps),
        land_reportings: getLandReporting(state, ownProps),
        status_updates: getStatusUpdates(state, ownProps),
        allProposals: getAllProposals(state, ownProps),
        allAccomplishment_plans: getAllAps(state, ownProps),
        allFinal_reports: getAllFinals(state, ownProps),
        allLand_reportings: getAllLandReporting(state, ownProps),
        all_status_updates: getAllStatusUpdates(state, ownProps),
        settings: getSystemSettings(state),
        navState: state.nav,
    }),
    {
        ...Proposal.actions,
        ...ProposalView.actions,
        ...AccomplishmentPlan.actions,
        ...AccomplishmentPlanView.actions,
        ...FinalReport.actions,
        ...FinalReportView.actions,
        ...RealPropertyInterest.actions,
        ...InitialLandReporting.actions,
        ...InitialLandReportingView.actions,
        ...StatusUpdate.actions,
        ...StatusUpdateView.actions,
        ...navActions
    }
)(Dashboard);

export default withStyles(styles)(withRouter(Dashboard));