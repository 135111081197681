import orm, { Model } from "../common/orm";

export class InitialLandReporting extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "InitialLandReporting";
    }
}
orm.register(InitialLandReporting);

export class InitialLandReportingView extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "InitialLandReportingView";
    }
    static get isUserData() {
        return false;
    }
}
orm.register(InitialLandReportingView);
