import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CloudUpload from "@material-ui/icons/CloudUpload";
import TablePagination from "@material-ui/core/TablePagination";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import Select from "../common/Select";
import DatePicker from "../common/DatePicker";
import TextField from "../common/TextField";
import FileInput from "../common/FileInput";
import TablePaginationActionsWrapped from "../common/Paginator";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createSelector } from "../common/orm";
import { AdminTable } from "../home/models";
import { MAKE_OPTIONS } from "../../api/constants";

const styles = theme => ({
    table: {
        width: "auto",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    buttonFloat: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "table"
    },
    flex: {
        flex: 1
    },
    minWidth: {
        minWidth: 400
    },
    widthTwoHundred: {
        width:200
    },
    widthEightHundred: {
        width:800
    },
    widthThreeHundred: {
        width:300
    },
    widthFifty: {
        width:50
    },
    widthOneFifty: {
        width:150
    },
    widthOneHundred: {
        width:100
    },
    widthTwoFifty: {
        width:250
    },
    container: {
      maxHeight: 540,
    },
    marginLeft: {
        marginLeft: 16
    },
    progress: {
        width: "100%",
        textAlign: "center"
    },
});

const getTableRows = createSelector(schema => {
    return schema.AdminTable.all()
        .orderBy("name", "asc")
        .toRefArray();
});

const columnData = [
    { id: "name", numeric: false, label: "Name", allowSort: true },
    { id: "appropriation_language", numeric: false, label: "Appropriation Language", allowSort: true },
    { id: "abbreviated_appropriation_language", numeric: false, label: "Abbreviated Appropriation Language", allowSort: true },
    { id: "subd", numeric: false, label: "Subd", allowSort: true },
    { id: "appropriation_year", numeric: false, label: "Appropriation Year", allowSort: true },
    { id: "legal_citation", numeric: false, label: "Legal Citation", allowSort: true },
    { id: "project_number", numeric: false, label: "Project #", allowSort: true },
    { id: "selected_for_hearing", numeric: false, label: "Selected for hearing?", allowSort: true },
    { id: "fiscal_year", numeric: false, label: "Fiscal Year", allowSort: true },
    { id: "project_status", numeric: false, label: "Project Status", allowSort: true },
    { id: "administering_agency", numeric: false, label: "Administering Agency", allowSort: true },
    { id: "activity_type", numeric: false, label: "Activity Type", allowSort: true },
    { id: "recipient", numeric: false, label: "Recipient", allowSort: true },
    { id: "recipient_type", numeric: false, label: "Recipient Type", allowSort: true },
    { id: "subject", numeric: false, label: "Subject", allowSort: true },
    { id: "fund", numeric: false, label: "Fund", allowSort: true },
    { id: "start_date", numeric: false, label: "Start Date", allowSort: true, date: true },
    { id: "end_date", numeric: false, label: "End Date", allowSort: true, date: true },
    { id: "recommended", numeric: true, label: "Recommended Appropriation", allowSort: true },
    { id: "link_to_accomplishment_plan", numeric: false, label: "Link to Accomplishment Plan", allowSort: true },
    { id: "node_id", numeric: false, label: "Node ID", allowSort: true },
    { id: "program", numeric: false, label: "Program", allowSort: true },
];

class AdminTableComp extends Component {
    state = {
        page: 0,
        rowsPerPage: 25,
        order: "asc",
        orderBy: "name",
        filter: null,
        filter2: null,
        open: false,
        open2: false,
        totalCount6: 0,
        tableLoading6: false,
    };
    typingTimer = [];
    typingTimer2;
    objectsToUpdate = [];
    key = window.performance.now()

    componentDidMount() {
        document.title = "Admin Table - LSOHC Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "admin") {
            history.push("/dashboard");
        }
        this.dynamicLoading6();
    }

    componentDidUpdate(prevProps){
        const { authState } = this.props;
        if (authState !== prevProps.authState) {
            this.dynamicLoading6();
        }
    };

    loadTotalRecords = (f, urllink, state_element) => {
        const { authState, history } = this.props;
        var component = this;

        fetch("/count/" + urllink + "/" + f, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        }).then(e => {
            if (e.status === 401) {
                history.push("/");
            } else if (e.status !== 500) {
                e.json().then(data => {
                    component.setState({ [state_element]: data.totalCount });
                });
            }
        });
    };

    dynamicLoading6 = (_page, _rowsPerPage, _order, _orderBy, _filter1, _filter2) => {
        const { ormAdminTableReloadDynamic, authState } = this.props;
        var { order, orderBy, rowsPerPage, page, filter, filter2 } = this.state;
        var component = this;

        if (!authState || !authState.user || authState.user.role !== "admin")
            return;

        var p, r, o, ob, f;
        if (_page !== undefined) p = _page;
        else p = page;
        if (_rowsPerPage !== undefined) r = _rowsPerPage;
        else r = rowsPerPage;
        if (_order !== undefined) o = _order;
        else o = order;
        if (_orderBy !== undefined) ob = _orderBy;
        else ob = orderBy;
        if (_filter1 !== undefined) f = _filter1 + "|";
        else f = filter + "|";
        if (_filter2 !== undefined) f += _filter2;
        else f += filter2;

        this.loadTotalRecords(f, "AdminTable", "totalCount6");

        var str = "";

        this.setState({ tableLoading6: true });
        str += "&filters6=" + encodeURIComponent(f) + "&count=" + r + "&offset=" + r * p + "&order=" + o + "&order_by=" + encodeURIComponent(ob);
        ormAdminTableReloadDynamic(str).then(() => {
            component.setState({ tableLoading6: false });
        });
    };

    handleRequestSort = (event, property) => {
        const { authState } = this.props;
        const orderBy = property;
        let order = "desc";

        if (this.state.statusOrderBy === property && this.state.statusOrder === "desc") {
            order = "asc";
        }

        if ( authState && authState.user && (authState.user.role === "admin" || authState.user.role === "readonly")) {
            this.setState({ order: order, orderBy: orderBy, page: 0, rowsPerPage: 10 });
            this.dynamicLoading6(0, 10, order, orderBy);
            return;
        }
    };


    handleChangePage = (event, page) => {
        this.setState({ page });
        this.dynamicLoading6(page);
    };

    handleChangeRowsPerPageStatus = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
        this.dynamicLoading6(0, event.target.value);
    };

    updateRecord = (value, element, id) => {
        const { ormAdminTableUpdate } = this.props;
        const comp = this;

        const val = {
            id: id,
            [element]: value
        }

        comp.objectsToUpdate[id] = { ...comp.objectsToUpdate[id], ...val };

        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormAdminTableUpdate(comp.objectsToUpdate[id]);
        }, 1000);
    };

    filterByValue = (array, string) => {
        return array.filter(o =>
            Object.keys(o).some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };

    setFilter = (val, element) => {
        var { order, orderBy, rowsPerPage, filter2 } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            comp.setState({[element]: val, page: 0});
            comp.dynamicLoading6(0, rowsPerPage, order, orderBy, val, filter2);
        }, 500);
    };

    setFilter2 = (val, element) => {
        var { order, orderBy, rowsPerPage, filter } = this.state;
        var comp = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            comp.setState({[element]: val, statusPage: 0});
            comp.dynamicLoading6(0, rowsPerPage, order, orderBy, filter, val);
        }, 500);
    };

    render() {
        const { rows, classes, authState } = this.props;
        const { page, rowsPerPage, order, orderBy, open, open2, totalCount6, tableLoading6 } = this.state;

        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <PageHeader title="Admin Table" />
                    <Grid item xs={12}>
                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                            {formApi => (
                                <form style={{ float: "right" }} onSubmit={formApi.submitForm}>
                                    <TextField
                                        eventHandle={this.setFilter}
                                        field="filter"
                                        label="Filter"
                                        filterInput
                                    />
                                    <TextField
                                        eventHandle={this.setFilter2}
                                        field="filter2"
                                        label="and"
                                        filterInput
                                        className={classes.marginLeft}
                                    />
                                </form>
                            )}
                        </Form>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer className={classes.container}>
                            <Table className={classes.table} stickyHeader >
                                <EnhancedTableHead
                                    columnData={columnData}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                />
                                <TableBody>
                                    {tableLoading6 && (
                                        <TableRow>
                                            <CustomTableCell colSpan={columnData.length} className={classes.centerAlign} style={{ paddingBottom: 16 }}>
                                                Loading Table Records...
                                                <CircularProgress size={30} className={classes.progress} />
                                            </CustomTableCell>
                                        </TableRow>
                                    )}
                                    {(() => {
                                        if (tableLoading6) return null; //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                                        return rows.map(n => {
                                            return (
                                                <TableRow hover key={n.id}>
                                                    <CustomTableCell><Typography className={classes.widthTwoHundred}>{n.name}</Typography></CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "appropriation_language"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthEightHundred} multiline tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="appropriation_language" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "abbreviated_appropriation_language"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthThreeHundred} multiline tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="abbreviated_appropriation_language" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "subd"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthFifty} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="subd" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "appropriation_year"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthOneFifty} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="appropriation_year" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "legal_citation"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthTwoHundred} multiline tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="legal_citation" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "project_number"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthOneHundred} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="project_number" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "selected_for_hearing"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <Select className={classes.widthTwoHundred} tableFormat options={[
                                                                        {label: "Yes", value: "Yes"},
                                                                        {label: "No", value: "No"},
                                                                    ]} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="selected_for_hearing" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "fiscal_year"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthOneHundred} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="fiscal_year" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "project_status"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthOneFifty} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="project_status" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "administering_agency"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <Select className={classes.widthThreeHundred} tableFormat options={[
                                                                        {label: "Department of Natural Resources - 168", value: "Department of Natural Resources - 168"},
                                                                        {label: "Board of Water and Soil Resources - 162", value: "Board of Water and Soil Resources - 162"},
                                                                        {label: "Legislative Coordinating Commission - 159", value: "Legislative Coordinating Commission - 159"},
                                                                    ]} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="administering_agency" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "activity_type"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <Select className={classes.widthTwoFifty} tableFormat options={[
                                                                        {label: "Assessment/Evaluation - 131", value: "Assessment/Evaluation - 131"},
                                                                        {label: "Fund Administration - 135", value: "Fund Administration - 135"},
                                                                        {label: "Land Acquisition - 138", value: "Land Acquisition - 138"},
                                                                        {label: "Restoration/Enhancement - 146", value: "Restoration/Enhancement - 146"},
                                                                        {label: "Technical Assistance - 147", value: "Technical Assistance - 147"},
                                                                        {label: "Grants/Contracts - 136", value: "Grants/Contracts - 136"},
                                                                    ]} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="activity_type" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "recipient"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthTwoFifty} multiline tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="recipient" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "recipient_type"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>    
                                                                    <Select className={classes.widthTwoFifty} tableFormat options={[
                                                                        {label: "Federal Government - 148", value: "Federal Government - 148"},
                                                                        {label: "Local/Regional Government - 153", value: "Local/Regional Government - 153"},
                                                                        {label: "Non-Profit Business/Entity - 154", value: "Non-Profit Business/Entity - 154"},
                                                                        {label: "State Government - 157", value: "State Government - 157"},
                                                                    ]} eventHandleProps1={n.id} eventHandle={this.updateRecord} field="recipient_type" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "subject"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthTwoFifty} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="subject" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "fund"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthTwoHundred} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="fund" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "start_date"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                <DatePicker className={classes.widthOneFifty} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord}  field="start_date" label="" />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "end_date"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <DatePicker className={classes.widthOneFifty} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord}  field="end_date" label="" />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "recommended"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthTwoHundred} useTextFormat tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="recommended" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "link_to_accomplishment_plan"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthTwoFifty} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="link_to_accomplishment_plan" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "node_id"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthOneFifty} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="node_id" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Form key={n.id + "program"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <TextField className={classes.widthTwoFifty} tableFormat eventHandleProps1={n.id} eventHandle={this.updateRecord} field="program" label="" fullWidth />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })
                                    })()}
                                    {(tableLoading6 ? 1 : totalCount6) < 1 && (
                                        <TableRow>
                                            <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                                No Records Found
                                            </CustomTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {totalCount6 && (
                            <TablePagination
                                colSpan={columnData.length}
                                count={totalCount6}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                                style={{float:"right"}}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} className={classes.centerAlign}>
                        <Button variant="contained" type="button" className={classes.button} onClick={() => this.setState({ open2: true })}>
                            Download Excel Admin Table&nbsp;&nbsp;&nbsp;<CloudDownload />
                        </Button>
                        <Button variant="contained" type="button" onClick={() => this.setState({ open: true })}>
                            Upload Excel Admin Table&nbsp;&nbsp;&nbsp;<CloudUpload />
                        </Button>
                    </Grid>
                </Grid>
                <TableDownload authState={authState} classes={classes} open2={open2} returnDialog={() => {this.setState({open2: false});this.key = window.performance.now()}} />
                <TableUpload dynamicLoading6={this.dynamicLoading6} authState={authState} classes={classes} open={open} returnDialog={() => {this.setState({open: false});this.key = window.performance.now()}} />
            </AppContainer>
        );
    }
}

class TableDownload extends Component {
    uploadDocument = values => {
        var { returnDialog } = this.props;
        window.open("/reports/admin/table/download/" + values.year);
        returnDialog();
    };

    render() {
        const { classes, open2, returnDialog } = this.props;

        var date = new Date().getFullYear() + 1;
        var i = 2009;
        var options = [];
        while (i <= date) {
            options.push(i.toString());
            i++;
        }
        options.push("All");

        return (
            <Dialog open={open2} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        Download Admin Table
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => {returnDialog();}}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={{year: "All"}}
                            onSubmit={values => this.uploadDocument(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Select style={{marginTop:0, marginBottom:16}} options={MAKE_OPTIONS(options)} field="year" label="Appropriation Year" fullWidth />
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.buttonFloat}>
                                                Download
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

class TableUpload extends Component {
    state = {
        loading: false,
        results: null
    };

    uploadDocument = values => {
        var { authState, history, authLogout, dynamicLoading6 } = this.props;
        this.setState({loading: true, results: null});
        fetch("/reports/admin/table/upload", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify(values)
        }).then(e => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else {
                e.json().then(data => {
                    var msg = "<b>Upload Results:</b><br/>";
                    data.message.forEach(function(m) {
                        msg += m.name + ": " + m.message + "<br/>";
                    })
                    this.setState({loading: false, results: msg});
                    dynamicLoading6();
                });
            }
        });
    };

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog } = this.props;
        const { loading, results } = this.state;

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        Upload Admin Table
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => {this.setState({results:null});returnDialog();}}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            onSubmit={values => this.uploadDocument(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FileInput field="file" id="file_upl" label="Spreadsheet" fullWidth />
                                            <div dangerouslySetInnerHTML={{ __html: results }} />
                                            {loading && (
                                                <CircularProgress size={24} className={classes.buttonFloat} />
                                            )}
                                            {!loading && (
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.buttonFloat}>
                                                    Upload
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

AdminTableComp = connect(
    (state, ownProps) => ({
        authState: state.auth,
        rows: getTableRows(state, ownProps)
    }),
    {
        ...AdminTable.actions
    }
)(AdminTableComp);

export default withStyles(styles)(withRouter(AdminTableComp));
