// Convert API name/id into react label/value

// Cache array object so react-form's shouldComponentUpdate sees it as constant
var tables = {};
export function MAKE_OPTIONS(table) {
    var key = table.join(':');
    if (!tables[key]) {
        table = table.filter(n => n);
        tables[key] = table.map(row => ({
            label: row,
            value: row
        }));
    }
    return tables[key];
}

export const budget_items = ["Personnel", "Contracts", "Fee Acquisition w/ PILT", "Fee Acquisition w/o PILT", "Easement Acquisition",
    "Easement Stewardship", "Travel", "Professional Services", "Direct Support Services", "DNR Land Acquisition Costs", "Capital Equipment",
    "Other Equipment/Tools", "Supplies/Materials", "DNR IDP", "Grand Total"
];

export const COUNTIES = [
    {label: "Aitkin", value: "Aitkin"},
    {label: "Anoka", value: "Anoka"},
    {label: "Becker", value: "Becker"},
    {label: "Beltrami", value: "Beltrami"},
    {label: "Benton", value: "Benton"},
    {label: "Big Stone", value: "Big Stone"},
    {label: "Blue Earth", value: "Blue Earth"},
    {label: "Brown", value: "Brown"},
    {label: "Carlton", value: "Carlton"},
    {label: "Carver", value: "Carver"},
    {label: "Cass", value: "Cass"},
    {label: "Chippewa", value: "Chippewa"},
    {label: "Chisago", value: "Chisago"},
    {label: "Clay", value: "Clay"},
    {label: "Clearwater", value: "Clearwater"},
    {label: "Cook", value: "Cook"},
    {label: "Cottonwood", value: "Cottonwood"},
    {label: "Crow Wing", value: "Crow Wing"},
    {label: "Dakota", value: "Dakota"},
    {label: "Dodge", value: "Dodge"},
    {label: "Douglas", value: "Douglas"},
    {label: "Faribault", value: "Faribault"},
    {label: "Fillmore", value: "Fillmore"},
    {label: "Freeborn", value: "Freeborn"},
    {label: "Goodhue", value: "Goodhue"},
    {label: "Grant", value: "Grant"},
    {label: "Hennepin", value: "Hennepin"},
    {label: "Houston", value: "Houston"},
    {label: "Hubbard", value: "Hubbard"},
    {label: "Isanti", value: "Isanti"},
    {label: "Itasca", value: "Itasca"},
    {label: "Jackson", value: "Jackson"},
    {label: "Kanabec", value: "Kanabec"},
    {label: "Kandiyohi", value: "Kandiyohi"},
    {label: "Kittson", value: "Kittson"},
    {label: "Koochiching", value: "Koochiching"},
    {label: "Lac qui Parle", value: "Lac qui Parle"},
    {label: "Lake", value: "Lake"},
    {label: "Lake of the Woods", value: "Lake of the Woods"},
    {label: "Le Sueur", value: "Le Sueur"},
    {label: "Lincoln", value: "Lincoln"},
    {label: "Lyon", value: "Lyon"},
    {label: "McLeod", value: "McLeod"},
    {label: "Mahnomen", value: "Mahnomen"},
    {label: "Marshall", value: "Marshall"},
    {label: "Martin", value: "Martin"},
    {label: "Meeker", value: "Meeker"},
    {label: "Mille Lacs", value: "Mille Lacs"},
    {label: "Morrison", value: "Morrison"},
    {label: "Mower", value: "Mower"},
    {label: "Murray", value: "Murray"},
    {label: "Nicollet", value: "Nicollet"},
    {label: "Nobles", value: "Nobles"},
    {label: "Norman", value: "Norman"},
    {label: "Olmsted", value: "Olmsted"},
    {label: "Otter Tail", value: "Otter Tail"},
    {label: "Pennington", value: "Pennington"},
    {label: "Pine", value: "Pine"},
    {label: "Pipestone", value: "Pipestone"},
    {label: "Polk", value: "Polk"},
    {label: "Pope", value: "Pope"},
    {label: "Ramsey", value: "Ramsey"},
    {label: "Red Lake", value: "Red Lake"},
    {label: "Redwood", value: "Redwood"},
    {label: "Renville", value: "Renville"},
    {label: "Rice", value: "Rice"},
    {label: "Rock", value: "Rock"},
    {label: "Roseau", value: "Roseau"},
    {label: "Scott", value: "Scott"},
    {label: "Sherburne", value: "Sherburne"},
    {label: "Sibley", value: "Sibley"},
    {label: "St. Louis", value: "St. Louis"},
    {label: "Stearns", value: "Stearns"},
    {label: "Steele", value: "Steele"},
    {label: "Stevens", value: "Stevens"},
    {label: "Swift", value: "Swift"},
    {label: "Todd", value: "Todd"},
    {label: "Traverse", value: "Traverse"},
    {label: "Wabasha", value: "Wabasha"},
    {label: "Wadena", value: "Wadena"},
    {label: "Waseca", value: "Waseca"},
    {label: "Washington", value: "Washington"},
    {label: "Watonwan", value: "Watonwan"},
    {label: "Wilkin", value: "Wilkin"},
    {label: "Winona", value: "Winona"},
    {label: "Wright", value: "Wright"},
    {label: "Yellow Medicine", value: "Yellow Medicine"}
];

export const TOWNSHIPS = [
    {label: "026", value: "026"},
    {label: "027", value: "027"},
    {label: "028", value: "028"},
    {label: "029", value: "029"},
    {label: "030", value: "030"},
    {label: "031", value: "031"},
    {label: "032", value: "032"},
    {label: "033", value: "033"},
    {label: "034", value: "034"},
    {label: "035", value: "035"},
    {label: "036", value: "036"},
    {label: "037", value: "037"},
    {label: "038", value: "038"},
    {label: "039", value: "039"},
    {label: "040", value: "040"},
    {label: "041", value: "041"},
    {label: "042", value: "042"},
    {label: "043", value: "043"},
    {label: "044", value: "044"},
    {label: "045", value: "045"},
    {label: "046", value: "046"},
    {label: "047", value: "047"},
    {label: "048", value: "048"},
    {label: "049", value: "049"},
    {label: "050", value: "050"},
    {label: "051", value: "051"},
    {label: "052", value: "052"},
    {label: "053", value: "053"},
    {label: "054", value: "054"},
    {label: "055", value: "055"},
    {label: "056", value: "056"},
    {label: "057", value: "057"},
    {label: "058", value: "058"},
    {label: "059", value: "059"},
    {label: "060", value: "060"},
    {label: "061", value: "061"},
    {label: "062", value: "062"},
    {label: "063", value: "063"},
    {label: "064", value: "064"},
    {label: "065", value: "065"},
    {label: "066", value: "066"},
    {label: "067", value: "067"},
    {label: "068", value: "068"},
    {label: "069", value: "069"},
    {label: "070", value: "070"},
    {label: "071", value: "071"},
    {label: "072", value: "072"},
    {label: "073", value: "073"},
    {label: "074", value: "074"},
    {label: "075", value: "075"},
    {label: "076", value: "076"},
    {label: "077", value: "077"},
    {label: "078", value: "078"},
    {label: "079", value: "079"},
    {label: "080", value: "080"},
    {label: "081", value: "081"},
    {label: "082", value: "082"},
    {label: "083", value: "083"},
    {label: "084", value: "084"},
    {label: "085", value: "085"},
    {label: "086", value: "086"},
    {label: "087", value: "087"},
    {label: "088", value: "088"},
    {label: "089", value: "089"},
    {label: "090", value: "090"},
    {label: "091", value: "091"},
    {label: "092", value: "092"},
    {label: "093", value: "093"},
    {label: "094", value: "094"},
    {label: "095", value: "095"},
    {label: "096", value: "096"},
    {label: "097", value: "097"},
    {label: "098", value: "098"},
    {label: "099", value: "099"},
    {label: "100", value: "100"},
    {label: "101", value: "101"},
    {label: "102", value: "102"},
    {label: "103", value: "103"},
    {label: "104", value: "104"},
    {label: "105", value: "105"},
    {label: "106", value: "106"},
    {label: "107", value: "107"},
    {label: "108", value: "108"},
    {label: "109", value: "109"},
    {label: "110", value: "110"},
    {label: "111", value: "111"},
    {label: "112", value: "112"},
    {label: "113", value: "113"},
    {label: "114", value: "114"},
    {label: "115", value: "115"},
    {label: "116", value: "116"},
    {label: "117", value: "117"},
    {label: "118", value: "118"},
    {label: "119", value: "119"},
    {label: "120", value: "120"},
    {label: "121", value: "121"},
    {label: "122", value: "122"},
    {label: "123", value: "123"},
    {label: "124", value: "124"},
    {label: "125", value: "125"},
    {label: "126", value: "126"},
    {label: "127", value: "127"},
    {label: "128", value: "128"},
    {label: "129", value: "129"},
    {label: "130", value: "130"},
    {label: "131", value: "131"},
    {label: "132", value: "132"},
    {label: "133", value: "133"},
    {label: "134", value: "134"},
    {label: "135", value: "135"},
    {label: "136", value: "136"},
    {label: "137", value: "137"},
    {label: "138", value: "138"},
    {label: "139", value: "139"},
    {label: "140", value: "140"},
    {label: "141", value: "141"},
    {label: "142", value: "142"},
    {label: "143", value: "143"},
    {label: "144", value: "144"},
    {label: "145", value: "145"},
    {label: "146", value: "146"},
    {label: "147", value: "147"},
    {label: "148", value: "148"},
    {label: "149", value: "149"},
    {label: "150", value: "150"},
    {label: "151", value: "151"},
    {label: "152", value: "152"},
    {label: "153", value: "153"},
    {label: "154", value: "154"},
    {label: "155", value: "155"},
    {label: "156", value: "156"},
    {label: "157", value: "157"},
    {label: "158", value: "158"},
    {label: "159", value: "159"},
    {label: "160", value: "160"},
    {label: "161", value: "161"},
    {label: "162", value: "162"},
    {label: "163", value: "163"},
    {label: "164", value: "164"},
    {label: "165", value: "165"},
    {label: "166", value: "166"},
    {label: "167", value: "167"},
    {label: "168", value: "168"}
];

export const RANGES = [
    {label: "01", value: "01"},
    {label: "02", value: "02"},
    {label: "03", value: "03"},
    {label: "04", value: "04"},
    {label: "05", value: "05"},
    {label: "06", value: "06"},
    {label: "07", value: "07"},
    {label: "08", value: "08"},
    {label: "09", value: "09"},
    {label: "10", value: "10"},
    {label: "11", value: "11"},
    {label: "12", value: "12"},
    {label: "13", value: "13"},
    {label: "14", value: "14"},
    {label: "15", value: "15"},
    {label: "16", value: "16"},
    {label: "17", value: "17"},
    {label: "18", value: "18"},
    {label: "19", value: "19"},
    {label: "20", value: "20"},
    {label: "21", value: "21"},
    {label: "22", value: "22"},
    {label: "23", value: "23"},
    {label: "24", value: "24"},
    {label: "25", value: "25"},
    {label: "26", value: "26"},
    {label: "27", value: "27"},
    {label: "28", value: "28"},
    {label: "29", value: "29"},
    {label: "30", value: "30"},
    {label: "31", value: "31"},
    {label: "32", value: "32"},
    {label: "33", value: "33"},
    {label: "34", value: "34"},
    {label: "35", value: "35"},
    {label: "36", value: "36"},
    {label: "37", value: "37"},
    {label: "38", value: "38"},
    {label: "39", value: "39"},
    {label: "40", value: "40"},
    {label: "41", value: "41"},
    {label: "42", value: "42"},
    {label: "43", value: "43"},
    {label: "44", value: "44"},
    {label: "45", value: "45"},
    {label: "46", value: "46"},
    {label: "47", value: "47"},
    {label: "48", value: "48"},
    {label: "49", value: "49"},
    {label: "50", value: "50"},
    {label: "51", value: "51"}
]

export const SECTIONS = [
    {label: "01", value: "01"},
    {label: "02", value: "02"},
    {label: "03", value: "03"},
    {label: "04", value: "04"},
    {label: "05", value: "05"},
    {label: "06", value: "06"},
    {label: "07", value: "07"},
    {label: "08", value: "08"},
    {label: "09", value: "09"},
    {label: "10", value: "10"},
    {label: "11", value: "11"},
    {label: "12", value: "12"},
    {label: "13", value: "13"},
    {label: "14", value: "14"},
    {label: "15", value: "15"},
    {label: "16", value: "16"},
    {label: "17", value: "17"},
    {label: "18", value: "18"},
    {label: "19", value: "19"},
    {label: "20", value: "20"},
    {label: "21", value: "21"},
    {label: "22", value: "22"},
    {label: "23", value: "23"},
    {label: "24", value: "24"},
    {label: "25", value: "25"},
    {label: "26", value: "26"},
    {label: "27", value: "27"},
    {label: "28", value: "28"},
    {label: "29", value: "29"},
    {label: "30", value: "30"},
    {label: "31", value: "31"},
    {label: "32", value: "32"},
    {label: "33", value: "33"},
    {label: "34", value: "34"},
    {label: "35", value: "35"},
    {label: "36", value: "36"}
];

export const ACTIVITIES = [
    {label: "Protect in Fee with PILT", value: "Protect in Fee with PILT"},
    {label: "Protect in Fee w/o PILT", value: "Protect in Fee w/o PILT"},
    {label: "Protect in Easement", value: "Protect in Easement"},
    {label: "Restore", value: "Restore"},
    {label: "Enhance", value: "Enhance"},
    {label: "Other", value: "Other"},
    {label: "Not Applicable", value: "Not Applicable"}
];

export const OPENS = [
    {label: "Full", value: "Full"},
    {label: "Limited", value: "Limited"},
    {label: "No", value: "No"},
    {label: "Not Applicable", value: "Not Applicable"}
];

export const PSTATUS = [
    {label: "Proposed", value: "Proposed"},
    {label: "Completed", value: "Completed"},
];