import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Map, TileLayer, WMSTileLayer, ZoomControl } from "react-leaflet";
import EditControl from "./LeafletDraw";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditLocation from "@material-ui/icons/Room";
import ExitIcon from "@material-ui/icons/ExitToApp";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";

import { withStyles, Typography } from "@material-ui/core";
import PolyGon from "../icons/shape-polygon-plus.png";
import CollapsibleLayersControl from "./LegendControl";
import * as fullscreen from "leaflet-fullscreen"; // eslint-disable-line
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";

var myIcon = new L.icon({
    iconUrl:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=",
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41]
});

const styles = theme => ({
    height: {
        height: "100%",
        margin: "0 !important",
        width: "100% !important",
    },
    root: { width: "208px", marginTop:16, marginLeft:16, backgroundColor: "#fff" },
    root2: { zIndex: "999" },
    flex: {
        flex: 1
    },
    centerButtons: {
        justifyContent: "center"
    },
});

class BaseMapControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lat: 46.47917,
            lng: -93.444666,
            zoom: 7,
            drawActive: false,
            editActive: false,
            directionsDialog: true
        };

        this.featureGroup = new L.featureGroup();
    };

    // Drawing Created
    updateRecordField = (event) => {
        event.layer.addTo(this.featureGroup);
        this.setState({drawActive: false})
    };

    _onMounted = () => {
        var leafToolBar = document.getElementsByClassName("leaflet-draw-toolbar");
        for (var i = 0; i < leafToolBar.length; i++) {
            leafToolBar[i].style.marginTop = "-1500px";
        }
        
        this.featureGroup.addTo(this.leafletMap.leafletElement);

        const { parcel_geometry, township, range, section } = this.props;
        var _this = this;
    
        // Add existing geometry to map
        if (parcel_geometry) {
            var geometry;
            parcel_geometry.geometries.forEach(function(geom) {
                var coord = geom.coordinates;

                if (geom.type === "Point") {
                    coord = L.GeoJSON.coordsToLatLng(coord);
                    geometry = new L.Marker(coord, { icon: myIcon });
                    _this.featureGroup.addLayer(geometry);
                }

                if (geom.type === "Polygon") {
                    coord = L.GeoJSON.coordsToLatLngs(coord, true);
                    geometry = new L.polygon(coord);
                    _this.featureGroup.addLayer(geometry);
                }

                if (geom.type === "MultiPolygon") {
                    coord.forEach(function(c) {
                        c = L.GeoJSON.coordsToLatLngs(c, true);
                        geometry = new L.polygon(c);
                        _this.featureGroup.addLayer(geometry);
                    })
                }

            });
            
            /*
                // A fancier way of doing the above but editing doesnt work then
                var test = new L.geoJSON(parcel_geometry, {
                    pointToLayer: function(feature, latlng) {
                        return L.marker(latlng, {icon: myIcon});
                    }
                })
                var test = new L.geoJSON(parcel_geometry).getLayers()[0]
                _this.featureGroup.addLayer(test);
            */

            // Zoom to existing geometry
            setTimeout(function() {
                if (_this.leafletMap.leafletElement._mapPane) {
                    _this.leafletMap.leafletElement.fitBounds(_this.featureGroup.getBounds());
                }
            }, 200);
        } else if (township && range && section) {
            fetch("/trs_search/" + township + "/" + range + "/" + section, {
                method: "GET"
            }).then(function(response) {
                if (response.status >= 200 && response.status < 300) {
                    return response.json().then(data => {
                        if (!data.error)
                            _this.leafletMap.leafletElement.setView([data.lat, data.long], 15);
                    });
                }
            });
        }
    };

    ref = React.createRef();

    render() {
        const { classes, setRef, reporting_id, ormInitialLandReportingUpdate, is_restore, closeMap } = this.props;
        const { lat, lng, zoom, drawActive, editActive, directionsDialog } = this.state;
        const position = [lat, lng];
        
        var has_geom = this.featureGroup.getLayers().length > 0

        return (
            <Map
                center={position}
                touch={false}
                zoom={zoom}
                minZoom={6}
                maxZoom={18}
                doubleClickZoom={false}
                className={classes.height}
                fullscreenControl={{ position: "bottomleft" }}
                ref={(ref) => {
                    if (ref) {
                        this.leafletMap = ref;
                        setRef(ref);
                    }
                }}
                zoomControl={false}>
                <ZoomControl position="bottomleft" />
                <CollapsibleLayersControl position="topright" collapsed={false}>
                    <CollapsibleLayersControl.BaseLayer checked={true} name="Topographic">
                        <TileLayer
                            url="https://server.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                        />
                    </CollapsibleLayersControl.BaseLayer>
                    <CollapsibleLayersControl.BaseLayer name="Street">
                        <TileLayer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                        />
                    </CollapsibleLayersControl.BaseLayer>
                    <CollapsibleLayersControl.BaseLayer name="Aerial">
                        <TileLayer
                            url="https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        />
                    </CollapsibleLayersControl.BaseLayer>
                    <CollapsibleLayersControl.Overlay checked={true} name="Counties">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="county_borders"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="Cities">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="city_poly"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="State Parks">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="StateParks"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="State Forests">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="StateForests"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="Wildlife Management Areas">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="WildlifeManagementAreas"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="Scientific and Natural Areas">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="ScientificNaturalArea"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="Wetland Management Districts">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="WetlandManagementDistricts"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="Boundary Water Canoe Area">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="BoundaryWaterCanoeArea"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="National Forests">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="NationalForest"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="House Districts">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="House"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="Senate Districts">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="Senate"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="LSOHC Sections">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="Sections"
                            map='/var/www/lsohc/conf/map/mapserver.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                    <CollapsibleLayersControl.Overlay name="TRS">
                        <WMSTileLayer
                            url="https://dev.lsohcprojectmgmt.leg.mn/cgi-bin/mapserv?"
                            subdomains="abc"
                            layers="trs"
                            map='/var/www/lsohc/conf/maps/trs.map'
                            format="image/png"
                            transparent="true"
                            tileSize={2000}
                        />
                    </CollapsibleLayersControl.Overlay>
                </CollapsibleLayersControl>
                <EditControl
                    layerControl={this.featureGroup}
                    onCreated={event => {
                        this.updateRecordField(event);
                    }}
                    onMounted={event => {
                        this._onMounted();
                    }}
                    draw={{
                        polyline: false,
                        polygon: {
                            allowIntersection: false,
                            shapeOptions: { color: "#1d78c9", opacity: 1, weight: 3 },
                            edit: false,
                            showLength: true,
                            metric: false,
                            feet: false,
                            showArea: true
                        },
                        rectangle: false,
                        circle: false,
                        circlemarker: false,
                        marker: { edit: true, icon: myIcon }
                    }}
                    edit={{
                        delete: false
                    }}
                /> 
                <Grid container className={classes.root}>
                    <Grid item xs={12} className={classes.root2} id="leaflet-control-noclick">
                        {!drawActive && !editActive && is_restore &&
                            <Button
                                variant="contained"
                                type="button"
                                color="primary"
                                style={{marginBottom:16}}
                                fullWidth
                                onClick={() => {
                                    this.setState({ drawActive: true });
                                    var markerbtn = document.getElementsByClassName(`leaflet-draw-draw-marker`)[0];
                                    if (markerbtn) {
                                        markerbtn.click();
                                    }
                                }}>
                                Place Parcel Point&nbsp;&nbsp;<EditLocation />
                            </Button>
                        }
                        {!drawActive && !editActive &&
                            <Button
                                variant="contained"
                                type="button"
                                color="primary"
                                style={{marginBottom:16}}
                                fullWidth
                                onClick={() => {
                                    this.setState({ drawActive: true });
                                    var markerbtn = document.getElementsByClassName(`leaflet-draw-draw-polygon`)[0];
                                    if (markerbtn) {
                                        markerbtn.click();
                                    }
                                }}>
                                Draw Parcel Boundary&nbsp;&nbsp; <img src={PolyGon} alt={"polygon"} />
                            </Button>
                        }
                        {!drawActive && has_geom && !editActive && 
                            <Button
                                variant="contained"
                                type="button"
                                color="primary"
                                style={{marginBottom:16}}
                                fullWidth
                                onClick={() => {
                                    var editBtn = document.getElementsByClassName("leaflet-draw leaflet-control");
                                    editBtn = editBtn[0].querySelector('a[title="Edit layers"]');
                                    if (editBtn) {
                                        editBtn.click();
                                        this.setState({editActive: true});
                                    }
                                }}>
                                Edit Parcels&nbsp;&nbsp;<EditIcon />
                            </Button>
                        }
                        {editActive && 
                            <Button
                                variant="contained"
                                type="button"
                                color="primary"
                                style={{marginBottom:16}}
                                fullWidth
                                onClick={() => {
                                    var saveBtn = document.getElementsByClassName("leaflet-draw leaflet-control");
                                    saveBtn = saveBtn[0].querySelector('a[title="Save changes"]');
                                    if (saveBtn) {
                                        saveBtn.click();
                                        this.setState({editActive: false});
                                    }
                                }}>
                                Save Edits&nbsp;&nbsp;<Save />
                            </Button>
                        }
                        {editActive && 
                            <Button
                                variant="contained"
                                type="button"
                                color="primary"
                                style={{marginBottom:16}}
                                fullWidth
                                onClick={() => {
                                    var cancelBtn = document.getElementsByClassName("leaflet-draw leaflet-control");
                                    cancelBtn = cancelBtn[0].querySelector(
                                        'a[title="Cancel editing, discards all changes"]'
                                    );
                                    if (cancelBtn) {
                                        cancelBtn.click();
                                        this.setState({editActive: false});
                                    }
                                }}>
                                Cancel Edits&nbsp;&nbsp;<Cancel />
                            </Button>
                        }
                        {!drawActive && has_geom && !editActive && 
                            <Button
                                variant="contained"
                                type="button"
                                color="primary"
                                style={{marginBottom:16}}
                                fullWidth
                                onClick={() => {
                                    // Clear single
                                    /*var deleteBtn = document.getElementsByClassName("leaflet-draw leaflet-control");
                                    deleteBtn = deleteBtn[0].querySelector('a[title="Delete layers"]');
                                    if (deleteBtn) {
                                        deleteBtn.click();
                                    }*/
                                    // Clear all
                                    this.featureGroup.clearLayers();
                                    this.setState({foo: "bar"}); //re-render
                                }}>
                                Delete Parcels&nbsp;&nbsp;<DeleteIcon />
                            </Button>
                        }
                        {!drawActive && !editActive && 
                            <Button
                                variant="contained"
                                type="button"
                                color="primary"
                                style={{marginBottom:16}}
                                fullWidth
                                onClick={() => {
                                    var layers = this.featureGroup.getLayers();
                                    var collection = null;

                                    if (layers.length > 0) {
                                        collection = {
                                            "type": "GeometryCollection",
                                            "geometries": []
                                        };

                                        layers.forEach(function (layer) {
                                            var geojson = layer.toGeoJSON();
                                            collection.geometries.push(geojson.geometry);
                                        });
                                    }
                                    var updates = {
                                        id: reporting_id, 
                                        parcel_geometry: collection
                                    };
                                    if (!collection) {
                                        updates["shapefile"] = null;
                                    }
                                    ormInitialLandReportingUpdate(updates);
                                    closeMap();
                                }}>
                                Save & Exit&nbsp;&nbsp;<ExitIcon />
                            </Button>
                        }
                    </Grid>
                </Grid>
                <Dialog open={directionsDialog}>
                    <Toolbar>
                        <Typography variant="h3" className={classes.flex}>
                            Directions
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({directionsDialog: false})}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <Typography>
                            Please zoom in and draw this parcel as closely as possible. The draw tools are in the upper left corner and the zoom tools are in the bottom left corner.
                            On the right side are tools to position and a list of helpful layers to orient yourself. 
                            When finished press the Save & Exit button to permanently save your parcel and return back to the form.
                        </Typography>
                    </DialogContent>
                    <DialogActions className={classes.centerButtons}>
                        <Button onClick={() => this.setState({directionsDialog: false})} color="primary" variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Map>
        );
    }
}

export default withStyles(styles)(withRouter(BaseMapControl));
