import orm, { Model, createSelector } from "../common/orm";
import { fk } from "redux-orm";

export class SystemSetting extends Model {
    static get fields() {
        return { };
    }

    static get modelName() {
        return "SystemSetting";
    }

    static get isSettings() {
        return true;
    }
    static selectMessage() {
        return createSelector(session => {
            return session.SystemSetting.withId(1);
        });
    }
}
orm.register(SystemSetting);

export class Proposal extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Proposal";
    }
}
orm.register(Proposal);

export class ProposalAmountNonOHF extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "amountnonohf")
        };
    }
    static get modelName() {
        return "ProposalAmountNonOHF";
    }
}

orm.register(ProposalAmountNonOHF);

export class ProposalFutureMaintain extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "futuremaintain")
        };
    }
    static get modelName() {
        return "ProposalFutureMaintain";
    }
}
orm.register(ProposalFutureMaintain);

export class ProposalPastOHF extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "pastohf")
        };
    }
    static get modelName() {
        return "ProposalPastOHF";
    }
}
orm.register(ProposalPastOHF);

export class ProposalTimeline extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "timeline")
        };
    }
    static get modelName() {
        return "ProposalTimeline";
    }
}
orm.register(ProposalTimeline);

export class ProposalBudget extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "budget")
        };
    }
    static get modelName() {
        return "ProposalBudget";
    }
}
orm.register(ProposalBudget);

export class ProposalBudgetPersonnel extends Model {
    static get fields() {
        return {
            budget: fk("ProposalBudget", "budgetpersonnel")
        };
    }
    static get modelName() {
        return "ProposalBudgetPersonnel";
    }
}
orm.register(ProposalBudgetPersonnel);

export class ProposalBudgetCapitalEquipment extends Model {
    static get fields() {
        return {
            budget: fk("ProposalBudget", "budgetcapital")
        };
    }
    static get modelName() {
        return "ProposalBudgetCapitalEquipment";
    }
}
orm.register(ProposalBudgetCapitalEquipment);

export class ProposalParcel extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "parcel")
        };
    }
    static get modelName() {
        return "ProposalParcel";
    }
}
orm.register(ProposalParcel);

export class ProposalAttachment extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "attachment")
        };
    }
    static get modelName() {
        return "ProposalAttachment";
    }
}
orm.register(ProposalAttachment);

export class ProposalView extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "ProposalView";
    }
    static get isUserData() {
        return false;
    }
}
orm.register(ProposalView);
