import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { AccomplishmentPlan } from "../models";
import { AdminTable } from "../../home/models";

import TextField from "../../common/TextField";
import Checkbox from "../../common/Checkbox";
import CheckboxGroup from "../../common/CheckboxGroup";
import HelpLabel from "../../common/HelpLabel";
import Select from "../../common/Select";
import states from "../../common/states.json";
import { MAKE_OPTIONS } from "../../../api/constants";
import { getValue } from "../../../api/utils";

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    gutterTop: {
        textAlign: "center",
        marginTop: ".6em"
    },
    checkbox: {
        border: "1px solid",
        padding: 10,
        paddingBottom: 0,
        "&:hover": {
            backgroundColor: "#eee"
        }
    }
});

class GeneralTab extends Component {
    typingTimer = [];

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Accomplishment Plan: General - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name),
            ml_year: isRequired(values.ml_year)
        };

        return valObj;
    };

    updateAP(values, fromStepper) {
        const { ormAccomplishmentPlanUpdate, history, handleUnsavedFields, handleNext, accomplishment_plan } = this.props;
        const { id } = accomplishment_plan;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        values.federal_funds_confirmation_document = accomplishment_plan.federal_funds_confirmation_document;
        values.modify_date_label = accomplishment_plan.modify_date_label;
        values.signup_criteria = accomplishment_plan.signup_criteria;

        // Clear out fields that may no longer be shown
        if (values.activity_types.indexOf("Other") === -1) {
            values.activity_types_other = null;
            values.activity_other_desc = null;
        }
        if (values.activity_types.indexOf("Protect in Fee") === -1) {
            values.fee_governement_approval = null;
            values.fee_governement_approval_desc = null;
            values.fee_free_of_protection = null;
            values.fee_free_of_protection_desc = null;
            values.fee_hunting_fishing = null;
            values.fee_hunting_fishing_desc = null;
            values.fee_hunting_fishing_after = null;
            values.fee_hunting_fishing_after_desc = null;
            values.fee_owntitle = null;
            values.fee_title_ownership = [];
            values.fee_title_ownership_other = null;
            values.fee_land_designated = [];
            values.fee_land_designated_city = null;
            values.accomplish_closed_acquisitions = null;
        }
        if (values.activity_types.indexOf("Protect in Easement") === -1) {
            values.easement_free_of_protection = null;
            values.easement_free_of_protection_desc = null;
            values.easement_manager = null;
            values.easement_holder = null;
            values.accomplish_easements = null;
            values.easement_public_use = null;
            values.easement_public_use_desc = null;
        }
        if (values.activity_types.indexOf("Protect in Easement") === -1 && values.activity_types.indexOf("Protect in Fee") === -1) {
            values.feeeasement_trailroads = null;
            values.trail_types = null;
            values.trails_continued_use = null;
            values.trails_continued_use_desc = null;
            values.feeeasement_trailroads_new = null;
            values.trail_types_new = null;
            values.trail_types_new_maintenance = null;
            values.acquired_parcels_restore_enhance = null;
            values.acquired_parcels_restore_enhance_comment = null;
            values.land_restored = null;
            values.land_restored_how = null;
        }
        if (values.activity_types.indexOf("Restore") === -1 && values.activity_types.indexOf("Enhance") === -1) {
            values.restoration_follow_bmp = null;
            values.restoration_follow_bmp_desc = null;
            values.restoration_on_protected_land = null;
            values.rep_protected_desc = null;
            values.restoration_on_protected_land_yes = [];
            values.restoration_on_protected_land_yes_other = null;
        }
        if (values.eco_regions.indexOf("Forest / Prairie Transition") === -1) {
            values.lsohc_section_priorities = values.lsohc_section_priorities.filter(
                item => (
                    item !== "Forest / Prairie Transition - Protect, enhance, and restore migratory habitat for waterfowl and related species, so as to increase migratory and breeding success" &&
                    item !== "Forest / Prairie Transition - Protect, enhance, and restore rare native remnant prairie" &&
                    item !== "Forest / Prairie Transition - Protect, enhance, and restore wild rice wetlands, shallow lakes, wetland/grassland complexes, aspen parklands, and shoreland that provide critical habitat for game and nongame wildlife" &&
                    item !== "Forest / Prairie Transition - Protect from long-term or permanent endangerment from invasive species" &&
                    item !== "Forest / Prairie Transition - Protect, restore, and enhance habitat for waterfowl, upland birds, and species of greatest conservation need"
                )
            )
            values.outcomes_forest_prairie_transition = [];
            values.outcomes_forest_prairie_transition_explain = null;
        }
        if (values.eco_regions.indexOf("Metro / Urban") === -1) {
            values.lsohc_section_priorities = values.lsohc_section_priorities.filter(
                item => (
                    item !== "Metro / Urban - Enhance and restore coldwater fisheries systems" &&
                    item !== "Metro / Urban - Protect, enhance, and restore remnant native prairie, Big Woods forests, and oak savanna with an emphasis on areas with high biological diversity" &&
                    item !== "Metro / Urban - Protect, enhance, and restore riparian and littoral habitats on lakes to benefit game and nongame fish species" &&
                    item !== "Metro / Urban - Protect from long-term or permanent endangerment from invasive species" &&
                    item !== "Metro / Urban - Protect habitat corridors, with emphasis on the Minnesota, Mississippi, and St. Croix rivers (bluff to floodplain)"
                )
            )
            values.outcomes_metro_urban = [];
            values.outcomes_metro_urban_explain = null;
        }
        if (values.eco_regions.indexOf("Northern Forest") === -1) {
            values.lsohc_section_priorities = values.lsohc_section_priorities.filter(
                item => (
                    item !== "Northern Forest - Protect shoreland and restore or enhance critical habitat on wild rice lakes, shallow lakes, cold water lakes, streams and rivers, and spawning areas" &&
                    item !== "Northern Forest - Protect forestland though acquisition or easement to prevent parcelization and fragmentation and to provide the ability to access and manage landlocked public properties" &&
                    item !== "Northern Forest - Provide access to manage habitat on landlocked public properties or protect forest land from parcelization and fragmentation through fee acquisition, conservation or access easement" &&
                    item !== "Northern Forest - Restore and enhance habitat on existing protected properties, with preference to habitat for rare, endangered, or threatened species identified by the Minnesota County Biological Survey" &&
                    item !== "Northern Forest - Restore forest-based wildlife habitat that has experienced substantial decline in area in recent decades" &&
                    item !== "Northern Forest - Protect from long-term or permanent endangerment from invasive species"
                )
            )
            values.outcomes_northern_forest = [];
            values.outcomes_northern_forest_explain = null;
        }
        if (values.eco_regions.indexOf("Prairie") === -1) {
            values.lsohc_section_priorities = values.lsohc_section_priorities.filter(
                item => (
                    item !== "Prairie - Protect, enhance, and restore remnant native prairie, Big Woods forests, and oak savanna" &&
                    item !== "Prairie - Protect, enhance, and restore migratory habitat for waterfowl and related species, so as to increase migratory and breeding success" &&
                    item !== "Prairie - Protect, enhance, or restore existing wetland/upland complexes, or convert agricultural lands to new wetland/upland habitat complexes" &&
                    item !== "Prairie - Protect expiring CRP lands" &&
                    item !== "Prairie - Convert agricultural land to wetland/upland to protect, enhance, or restore existing habitat complexes, such as WMAs" &&
                    item !== "Prairie - Protect from long-term or permanent endangerment from invasive species" &&
                    item !== "Prairie - Protect, restore, and enhance shallow lakes" &&
                    item !== "Prairie - Restore or enhance habitat on public lands"
                )
            )
            values.outcomes_prairie = [];
            values.outcomes_prairie_explain = null;
        }
        if (values.eco_regions.indexOf("Southeast Forest") === -1) {
            values.lsohc_section_priorities = values.lsohc_section_priorities.filter(
                item => (
                    item !== "Southeast Forest - Protect, enhance, and restore habitat for fish, game, and nongame wildlife in rivers, cold-water streams, and associated upland habitat" &&
                    item !== "Southeast Forest - Protect, enhance, and restore remnant goat prairies" &&
                    item !== "Southeast Forest - Protect from long-term or permanent endangerment from invasive species" &&
                    item !== "Southeast Forest - Protect forest habitat though acquisition in fee or easement to prevent parcelization and fragmentation and to provide the ability to access and manage landlocked public properties" &&
                    item !== "Southeast Forest - Restore forest-based wildlife habitat that has experienced substantial decline in area in recent decades"
                )
            )
            values.outcomes_southeast_forest = [];
            values.outcomes_southeast_forest_explain = null;
        }

        ormAccomplishmentPlanUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    };

    editAppropriation = (value, fieldname) => {
        const { ormAdminTableUpdate } = this.props;
        const { admin_id } = this.props.accomplishment_plan;        
        
        clearTimeout(this.typingTimer[fieldname]);
        this.typingTimer[fieldname] = setTimeout(function() {
            ormAdminTableUpdate({
                id: admin_id,
                [fieldname]: value
            });
        }, 1000);
    };

    applyActivityOther = (value, fieldname) => {
        const { ormAccomplishmentPlanUpdateLocalOnly } = this.props;
        const { id } = this.props.accomplishment_plan;
        ormAccomplishmentPlanUpdateLocalOnly({
            id: id,
            [fieldname]: value
        });
        this.setState({ foo: "bar" });
    };

    render() {
        const { classes, accomplishment_plan, handleUnsavedFields, settings, ActionMenu, authState } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || (accomplishment_plan.status === "First Draft" || accomplishment_plan.status === "Approved First Draft" || accomplishment_plan.status === "Started Request for Amendment Change"))
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        const showOther = getValue(accomplishment_plan, "activity_types").indexOf("Other") > -1;
        const showCheckbox = getValue(accomplishment_plan, "proposal_acknowledgement_general") === false;

        return (
            <Form
                getApi={el => (this.form = el)}
                key={accomplishment_plan.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={accomplishment_plan.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                validateError={values => this.errorValidator(values)}
                onSubmit={(values, fromStepper) => this.updateAP(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={10} lg={12} xl={10}>
                                <HelpLabel
                                    inputLabel="Management Information"
                                    title={true}
                                    showLabel={true}
                                    helpText={settings.general_tab_help}
                                />
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12} md={10} lg={8} xl={6}>
                                {showCheckbox && (
                                    <div className={classes.checkbox}>
                                        <Checkbox
                                            field="proposal_acknowledgement_general"
                                            eventHandle={this.applyActivityOther}
                                            disabled={is_read_only} 
                                            label="Please check this box to acknowledge that the information in this tab was brought over from your proposal and is still valid."
                                        />
                                    </div>
                                )}
                                <TextField disabled={is_read_only} field="name" fullWidth>
                                    <HelpLabel
                                        inputLabel="Project or Program Title"
                                        showLabel={true}
                                        helpText="Project or Program Title should be descriptive and include the correct program phase, if applicable."
                                    />
                                </TextField>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField field="ml_year" label="Session Year" readOnly fullWidth />
                                        <TextField
                                            field="funds_recommended" useTextFormat
                                            fullWidth readOnly>
                                                <HelpLabel
                                                    inputLabel="Funds Recommended ($)"
                                                    showLabel={true}
                                                    helpText="The funds appropriated to this program."
                                                />
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField field="create_date_label" label="Created Date" fullWidth readOnly />
                                        <TextField field="modify_date_label" label="Modified Date" readOnly fullWidth />
                                    </Grid>
                                </Grid>
                                <Typography variant="h3" className={classes.gutterTop}>
                                    Organization
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField disabled={is_read_only} field="manager_name" label="Manager's Name" fullWidth />
                                        <TextField disabled={is_read_only} field="manager_title" label="Title" fullWidth />
                                        <TextField disabled={is_read_only} field="manager_organization" label="Organization Name" fullWidth />
                                        <TextField disabled={is_read_only}field="manager_address" label="Address" fullWidth />
                                        <TextField disabled={is_read_only} field="manager_address2" label="Address 2" fullWidth />
                                        <TextField disabled={is_read_only} field="manager_city" label="City" fullWidth />
                                        <Select disabled={is_read_only} field="manager_state" label="State" options={states} fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField disabled={is_read_only} field="manager_zip" label="Zip" fullWidth />
                                        <TextField disabled={is_read_only} field="manager_email" label="Email" fullWidth />
                                        <TextField disabled={is_read_only} field="manager_office_number" label="Office Number" fullWidth />
                                        <TextField disabled={is_read_only} field="manager_mobile_number" label="Mobile Number" fullWidth />
                                        <TextField disabled={is_read_only} field="manager_fax_number" label="Fax Number" fullWidth />
                                        <TextField disabled={is_read_only} field="manager_website" label="Website" fullWidth />
                                    </Grid>
                                    {authState && authState.user && authState.user.role === "admin" && (
                                        <Grid item xs={6}>
                                            <TextField disabled={is_read_only} field="dnr_emails" label="DNR Email Notifications" fullWidth />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            
                            <Grid item xs={12} md={8} lg={4} xl={4}>
                                <TextField multiline eventHandle={this.editAppropriation} disabled={!(authState && authState.user && authState.user.role === "admin")} field="appropriation_language" label="Appropriation Language" fullWidth />
                                <TextField multiline disabled={!(authState && authState.user && authState.user.role === "admin")} field="additional_legislative_changes" label="Additional Legislative Changes" fullWidth />
                                <TextField multiline disabled={true} field="legal_citation" label="Legal Citation" fullWidth />
                                <CheckboxGroup
                                    field="eco_regions"
                                    row={true}
                                    width={205}
                                    disabled={is_read_only}
                                    options={MAKE_OPTIONS([
                                        "Forest / Prairie Transition",
                                        "Metro / Urban",
                                        "Northern Forest",
                                        "Prairie",
                                        "Southeast Forest"
                                    ])}>
                                        <HelpLabel
                                            showLabel={true}
                                            inputLabel="Eco regions in which work will take place"
                                            helpText={
                                                <div>
                                                    Check all that apply.
                                                    <br />
                                                    <br />
                                                    Selections made for Regions will trigger unique questions throughout
                                                    the application, including the control fields in the Output Tables
                                                    Tab.
                                                    <br />
                                                    <br />
                                                    <a
                                                        href="https://www.lsohc.leg.mn/materials/resource_doc_plan/lsohc_county_city.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        Region Map
                                                    </a>
                                                </div>
                                            }
                                        />
                                </CheckboxGroup>
                                <CheckboxGroup
                                    field="activity_types"
                                    row={true}
                                    width={205}
                                    disabled={is_read_only}
                                    eventHandle={this.applyActivityOther}
                                    options={MAKE_OPTIONS([
                                        "Enhance",
                                        "Protect in Easement",
                                        "Protect in Fee",
                                        "Restore",
                                        "Other"
                                    ])}
                                    >
                                        <HelpLabel
                                            inputLabel="Activity types"
                                            showLabel={true}
                                            helpText={
                                                <div>
                                                    Check all that apply.
                                                    <br />
                                                    <br />
                                                    Selections made for Activity type will trigger unique questions
                                                    throughout the application, including the control fields in the
                                                    Output Tables Tab.
                                                </div>
                                            }
                                        />
                                </CheckboxGroup>
                                {showOther && (
                                    <TextField
                                        field="activity_types_other"
                                        disabled={is_read_only}
                                        label="What is your other activity type?"
                                        fullWidth
                                    />
                                )}
                                <CheckboxGroup
                                    field="priority_resources_activity"
                                    row={true}
                                    disabled={is_read_only}
                                    width={205}
                                    options={MAKE_OPTIONS(["Forest", "Habitat", "Prairie", "Wetlands"])}
                                >
                                        <HelpLabel
                                            inputLabel="Priority resources addressed by activity"
                                            showLabel={true}
                                            helpText={
                                                <div>
                                                    Check all that apply.
                                                    <br />
                                                    <br />
                                                    Selections made for Priority resources will trigger unique questions
                                                    throughout the application, including the control fields in the
                                                    Output Tables Tab.
                                                </div>
                                            }
                                        />
                                    </CheckboxGroup>
                            </Grid>
                            <Grid item xs={12} xl={10} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Narrative <ChevronRightIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

GeneralTab = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        ...AccomplishmentPlan.actions,
        ...AdminTable.actions
    }
)(GeneralTab);

export default withStyles(styles)(withRouter(GeneralTab));
