import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { withStyles } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";

import { AccomplishmentPlan, AmendmentLog } from "../models";
import { getValue } from "../../../api/utils";
import TextField from "../../common/TextField";
import Subquestion from "../../common/Subquestion";
import RadioGroup from "../../common/RadioGroup";
import DatePicker from "../../common/DatePicker";
import { createSelector } from "../../common/orm";


const getAmendments = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.AmendmentLog.filter({ accomplishment: id }).orderBy("date_accepted", "desc").toModelArray();
    }
);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
});

class AmendmentsTab extends Component {
    typingTimer = [];

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Accomplishment Plan: Amendments - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateAP(values, fromStepper) {
        const { ormAccomplishmentPlanUpdate, history, handleUnsavedFields, handleNext, accomplishment_plan } = this.props;
        const { id } = accomplishment_plan;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        values.federal_funds_confirmation_document = accomplishment_plan.federal_funds_confirmation_document;
        values.modify_date_label = accomplishment_plan.modify_date_label;
        values.signup_criteria = accomplishment_plan.signup_criteria;

        ormAccomplishmentPlanUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    }    
    
    updateRecord = (value, id) => {
        const { ormAmendmentLogUpdate } = this.props;

        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormAmendmentLogUpdate({
                id: id,
                description: value,
            });
        }, 1000);
    };
    
    updateRecord2 = (value, id) => {
        const { ormAmendmentLogUpdate } = this.props;

        if (value === false) {
            ormAmendmentLogUpdate({
                id: id,
                retroactive: value,
                retroactive_date: null, 
                retroactive_explanation: null
            });
        } else {
            ormAmendmentLogUpdate({
                id: id,
                retroactive: value,
            });
        }
    };
    
    updateRecord3 = (value, id) => {
        const { ormAmendmentLogUpdate } = this.props;

        ormAmendmentLogUpdate({
            id: id,
            retroactive_date: value,
        });
    };
    
    updateRecord4 = (value, id) => {
        const { ormAmendmentLogUpdate } = this.props;

        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormAmendmentLogUpdate({
                id: id,
                retroactive_explanation: value,
            });
        }, 1000);
    };
    
    updateRecord5 = (value, id) => {
        const { ormAmendmentLogUpdate } = this.props;

        if (value === "No" || value === "N/A (Not Adding Parcels)") {
            ormAmendmentLogUpdate({
                id: id,
                conservation_fund: value,
                conservation_fund_parcels: null, 
            });
        } else {
            ormAmendmentLogUpdate({
                id: id,
                conservation_fund: value,
            });
        }
    };
    
    updateRecord6 = (value, id) => {
        const { ormAmendmentLogUpdate } = this.props;

        clearTimeout(this.typingTimer[id]);
        this.typingTimer[id] = setTimeout(function() {
            ormAmendmentLogUpdate({
                id: id,
                conservation_fund_parcels: value,
            });
        }, 1000);
    };

    render() {
        const { classes, accomplishment_plan, handleUnsavedFields, amendments, ActionMenu, authState } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || (accomplishment_plan.status === "First Draft" || accomplishment_plan.status === "Approved First Draft" || accomplishment_plan.status === "Started Request for Amendment Change"))
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        var is_admin = false;
        if (authState && authState.user && authState.user.role === "admin")
            is_admin = true;

        return (
            <>
            <Form
                getApi={el => (this.form = el)}
                key={accomplishment_plan.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={accomplishment_plan.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateAP(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={10} lg={8} xl={7}>
                                <Typography variant="h3" gutterBottom>Amendments</Typography>
                                {ActionMenu}
                            </Grid>
                            <Grid xs={12} md={10} lg={8} xl={7}> 
                                {amendments.length === 0 && (
                                    <Typography>No Amendments Found</Typography>
                                )}
                                {amendments.map(n => {
                                    const retroactive_YES = getValue(n, "retroactive") === "true" || getValue(n, "retroactive") === true;
                                    const conservation_fund_YES = getValue(n, "conservation_fund") === "Yes";
                                    return (
                                        <Form
                                            dontValidateOnMount={true}
                                            validateOnSubmit={true}
                                            defaultValues={n}>
                                            {formApi2 => (
                                                <form onSubmit={formApi2.submitForm}>
                                                    <Typography>Amendment Date: <b>{n.date_accepted_label}</b></Typography>
                                                    {n.link && (
                                                        <Button variant="contained" onClick={() => window.open(n.link)} style={{marginRight:8}}>Open Amendment PDF</Button>
                                                    )}
                                                    {is_admin && (<Button variant="contained" onClick={() => {
                                                        fetch("/reports/amendment/" + accomplishment_plan.id + "/"  + n.id, {
                                                            method: "POST",
                                                            headers: {
                                                                "Content-Type": "application/json",
                                                                Authorization: "Token " + authState.user.auth_token
                                                            }
                                                        })
                                                            .then(res => {
                                                                if (res.status === 403) {
                                                                    alert("Unauthorized Access");
                                                                    return;
                                                                }
                                                                res.blob().then(blob => {
                                                                    var url = window.URL.createObjectURL(blob);
                                                                    var a = document.createElement("a");
                                                                    a.href = url;
                                                                    a.download = "Amendment Letter " + n.date_accepted + ".docx";
                                                                    document.body.appendChild(a);
                                                                    a.click();
                                                                    a.remove();
                                                                });
                                                            });
                                                    }}>Generate Letter Template</Button>)}
                                                    <TextField
                                                        field="description"
                                                        label="Description"
                                                        multiline
                                                        disabled={is_read_only}
                                                        rows={8}
                                                        rowsMax={20}
                                                        fullWidth
                                                        eventHandle={(v, e) => this.updateRecord(v, n.id)}
                                                    />
                                                    <RadioGroup
                                                        field="retroactive"
                                                        name="retroactive"
                                                        disabled={is_read_only}
                                                        fullWidth
                                                        eventHandle={(v, e) => this.updateRecord2(v, n.id)}
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No", value: "false" }
                                                        ]}
                                                        alignment={true}
                                                        label="Is the amendment retroactive?"
                                                    />
                                                    {retroactive_YES && (
                                                        <Subquestion component={
                                                            <>
                                                            <DatePicker field="retroactive_date" eventHandle={(v, e) => this.updateRecord3(v, n.id)} fullWidth label="Retroactive back to what date?" style={{marginTop:14,marginBottom:8}} />
                                                            <TextField
                                                                field="retroactive_explanation"
                                                                label="Explain the need for retroactivity"
                                                                multiline
                                                                disabled={is_read_only}
                                                                rows={8}
                                                                rowsMax={20}
                                                                fullWidth
                                                                eventHandle={(v, e) => this.updateRecord4(v, n.id)}
                                                            />
                                                            </>
                                                        } />  
                                                    )}
                                                    <RadioGroup
                                                        field="conservation_fund"
                                                        name="conservation_fund"
                                                        disabled={is_read_only}
                                                        fullWidth
                                                        eventHandle={(v, e) => this.updateRecord5(v, n.id)}
                                                        options={[
                                                            { label: "Yes", value: "Yes" },
                                                            { label: "No", value: "No" },
                                                            { label: "N/A (Not Adding Parcels)", value: "N/A (Not Adding Parcels)" }
                                                        ]}
                                                        alignment={true}
                                                        label={
                                                            <div>
                                                                If you are adding parcels, are any of the parcels anticipated for acquisition from The Conservation Fund (Potlach) as part of the <a rel="noopener noreferrer" href="https://www.conservationfund.org/impact/press-releases/2354-72-440-acres-of-working-forests-purchased-in-minnesota" target="_blank">MN Heritage Forest Project</a>?
                                                            </div>
                                                        }
                                                    />
                                                    {conservation_fund_YES && (
                                                        <Subquestion component={
                                                            <>
                                                            <TextField
                                                                field="conservation_fund_parcels"
                                                                label="What is the name of the parcel(s)"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                eventHandle={(v, e) => this.updateRecord6(v, n.id)}
                                                            />
                                                            </>
                                                        } />  
                                                    )}
                                                    <Divider style={{marginBottom:8}} />
                                                </form>
                                            )}
                                        </Form>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={12} md={10} lg={8} xl={7} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Submit Accomplishment Plan to LSOHC&nbsp;&nbsp;&nbsp;<CloudUpload />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            </>
        );
    }
};

AmendmentsTab = connect(
    (state, ownProps) => ({
        amendments: getAmendments(state, ownProps),
        authState: state.auth
    }),
    {
        ...AccomplishmentPlan.actions,
        ...AmendmentLog.actions
    }
)(AmendmentsTab);

export default withStyles(styles)(withRouter(AmendmentsTab));
