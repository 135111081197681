import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { Proposal, ProposalTimeline } from "../models";

import { createSelector } from "../../common/orm";
import HelpLabel from "../../common/HelpLabel";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import TextField from "../../common/TextField";

const getTimelines = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalTimeline.filter({ proposal: id }).orderBy("id").toModelArray();
    }
);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        }
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
});

class TimelineTab extends Component {
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Timeline - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const { id } = this.props.proposal;
        const { ormProposalUpdate, history, handleUnsavedFields, handleNext, proposal } = this.props;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }
        values.federal_funds_confirmation_document = proposal.federal_funds_confirmation_document;
        values.modify_date_label = proposal.modify_date_label;
        values.signup_criteria = proposal.signup_criteria;
        values.illustration = proposal.illustration;
        values.financial_audit = proposal.financial_audit;
        values.board_members = proposal.board_members;

        ormProposalUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    }

    render() {
        const { 
            classes,
            proposal,
            handleUnsavedFields,
            timelines,
            ormProposalTimelineCreate,
            ormProposalTimelineDelete,
            ormProposalTimelineUpdate,
            settings,
            ActionMenu, authState
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || proposal.status === "New")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        return (
            <Form
                getApi={el => (this.form = el)}
                key={proposal.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={proposal.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={9} xl={7}>
                                <HelpLabel
                                    inputLabel="Timeline"
                                    title={true}
                                    showLabel={true}
                                    helpText={settings.timeline_tab_help}
                                />
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={9} xl={7}>
                                <Button  disabled={is_read_only} variant="contained" color="primary" onClick={() => ormProposalTimelineCreate({proposal: proposal.id})}>
                                    <AddCircleIcon />
                                    &nbsp;&nbsp;&nbsp;Add New Activity
                                </Button>
                            </Grid>
                            <Grid item xs={12} lg={9} xl={7}>
                                <TimelineTable
                                    ormProposalTimelineUpdate={ormProposalTimelineUpdate}
                                    ormProposalTimelineDelete={ormProposalTimelineDelete}
                                    rows={timelines}
                                    is_read_only={is_read_only}
                                    classes={classes}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign} lg={9} xl={7}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Budget <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

class TimelineTable extends Component {
    columnData = [
        { id: "activity", numeric: false, label: "Activity", allowSort: false },
        { id: "date", numeric: false, label: "Estimated Completion Date", allowSort: false },
        { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" },
    ];
    typingTimer = []

    updateTimeline = (value, element, id) => {
        const { ormProposalTimelineUpdate } = this.props;

        clearTimeout(this.typingTimer[element]);
        this.typingTimer[element] = setTimeout(function() {
            ormProposalTimelineUpdate({
                id: id,
                [element]: value,
            });
        }, 1000);
    }

    render() {
        const { rows, classes, ormProposalTimelineDelete, is_read_only } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {rows.map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    <Form key={n.id + "activity"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateTimeline(v, e, n.id)} field="activity" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "date"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateTimeline(v, e, n.id)} field="date" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title="Delete Timeline Activity">
                                        <Button
                                            color="primary"
                                            disabled={is_read_only}
                                            className={classes.deleteWidth}
                                            onClick={() => ormProposalTimelineDelete(n.id)}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {rows.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={3} className={classes.centerAlign}>
                                No Timeline Activities Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

TimelineTab = connect(
    (state, ownProps) => ({
        timelines: getTimelines(state, ownProps),
        authState: state.auth
    }),
    {
        ...Proposal.actions,
        ...ProposalTimeline.actions
    }
)(TimelineTab);

export default withStyles(styles)(withRouter(TimelineTab));
