import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Visibility from "@material-ui/icons/Visibility";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import TextField from "../common/TextField";
import HelpLabel from "../common/HelpLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "../common/Select";
import TablePaginationActionsWrapped from "../common/Paginator";

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    button: {
        marginTop: 32,
        marginLeft: "auto",
        marginRight: "auto",
        display: "table"
    }
});

const columnDataUsers = [
    { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" },
    { id: "name", numeric: false, label: "Name", allowSort: false },
    { id: "year", numeric: false, label: "ML Year", allowSort: false, width: "20px" },
    { id: "results", numeric: false, label: "Found Text", allowSort: false },
];

class Search extends Component {
    state = {
        page: 0,
        rowsPerPage: 25,
        order: "asc",
        orderBy: "name",
        results: [],
        loading: false
    };

    componentDidMount() {
        document.title = "Search - LSOHC Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "admin") {
            history.push("/dashboard");
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    searchForm(values) {
        var { authState, history, authLogout } = this.props;

        this.setState({loading: true, results: []});
        fetch("/reports/admin/search", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify(values)
        }).then(e => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else {
                e.json().then(data => {
                    this.setState({loading: false, results: data.results});
                });
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { page, rowsPerPage, order, orderBy, results, loading } = this.state;

        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <PageHeader title="Advanced Search" />
                    <Grid item xs={12}>
                        <Form defaultValues={{"on":"Accomplishment Plan", "type": "AND"}} dontValidateOnMount={true} validateOnSubmit={true} 
                            onSubmit={(values) => this.searchForm(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} md={4} lg={3} xl={2}>
                                            <Select options={[
                                                {label: "Accomplishment Plan", value: "Accomplishment Plan"},
                                                {label: "Proposal", value: "Proposal"},
                                                {label: "Final Report", value: "Final Report"},
                                            ]} field="on" label="Search On" fullWidth />
                                            <Select options={[
                                                {label: "AND", value: "AND"},
                                                {label: "OR", value: "OR"},
                                            ]} field="type" fullWidth> 
                                                <HelpLabel
                                                    inputLabel="Search Type"
                                                    showLabel={true}
                                                    helpText={
                                                        <div>
                                                            An 'AND' search will only return programs that match <b>all</b> of the search terms.<br/>
                                                            An 'OR' search will return all programs that match <b>at least one</b> of the search terms.<br/><br/>
                                                            Ex: Return results with... [Search Term 1] <b>AND</b> [Search Term 2]<br/>
                                                            [Search Term 1] <b>OR</b> [Search Term 2]
                                                        </div>
                                                    }
                                                />
                                            </Select>
                                            {loading && (
                                                <CircularProgress size={24} className={classes.button} />
                                            )}
                                            {!loading && (
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    color="primary"
                                                    className={classes.button}>
                                                    Search
                                                </Button>
                                            )}
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={3} xl={2}>
                                            <TextField
                                                field="filter1"
                                                label="Search Term 1"
                                                filterInput fullWidth
                                            />
                                            <TextField
                                                field="filter2"
                                                label="Search Term 2"
                                                filterInput fullWidth
                                            />
                                            <TextField
                                                field="filter3"
                                                label="Search Term 3"
                                                filterInput fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                columnData={columnDataUsers}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                            />
                            <TableBody>
                                {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                    return (
                                        <TableRow hover key={n.id}>
                                            <CustomTableCell className={classes.nowrap}>
                                                <Tooltip title="View Program">
                                                    <Button
                                                        color="primary"
                                                        className={classes.deleteWidth}
                                                        component={Link}
                                                        to={n._type === "Accomplishment Plan" ? "/accomplishment/" + n.id : n._type === "Proposal" ? "/proposal" + n.id : "/final/" + n.id}>
                                                        <Visibility color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            </CustomTableCell>
                                            <CustomTableCell>{n.name}</CustomTableCell>
                                            <CustomTableCell>{n.year}</CustomTableCell>
                                            <CustomTableCell>
                                                {n.results.map(o => {
                                                    return (
                                                        <>
                                                        <b>{o.field}:</b>
                                                        <div dangerouslySetInnerHTML={{ __html: o.value }} />
                                                        </>
                                                    )
                                                })}
                                            </CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {results.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                            {loading ? "Please Wait..." : "No Results Found"}
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            {results.length > 25 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={4}
                                            count={results.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                        <Button style={{visibility:"hidden"}}>Test</Button>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

Search = connect(
    (state, ownProps) => ({
        authState: state.auth,
    }),
)(Search);

export default withStyles(styles)(withRouter(Search));
