import {
    SW_CHECKING,
    SW_REGISTERED,
    SW_INSTALLED,
    SW_UPDATE,
    SW_ERROR,
    SW_DISABLED,
    STORE_READY,
    NAV_TOGGLE_PROPOSAL,
    NAV_TOGGLE_AP,
    NAV_TOGGLE_FINAL,
    NAV_TOGGLE_PARCEL,
    NAV_TOGGLE_STATUS
} from "./actions";

function navReducer(state = {}, action) {
    switch (action.type) {
        case SW_CHECKING:
            return {
                ...state,
                sw: { checking: true }
            };

        case SW_REGISTERED:
            return {
                ...state,
                sw: { registered: true }
            };

        case SW_INSTALLED:
            return {
                ...state,
                sw: { installed: true }
            };

        case SW_UPDATE:
            return {
                ...state,
                sw: { update: true }
            };

        case SW_ERROR:
            return {
                ...state,
                sw: { error: true }
            };

        case SW_DISABLED:
            return {
                ...state,
                sw: null
            };

        case STORE_READY:
            return {
                ...state,
                storeReady: true
            };

        case NAV_TOGGLE_PROPOSAL:
            return {
                ...state,
                proposalExpand: !state.proposalExpand
            };

        case NAV_TOGGLE_AP:
            return {
                ...state,
                apExpand: !state.apExpand
            };

        case NAV_TOGGLE_FINAL:
            return {
                ...state,
                finalExpand: !state.finalExpand
            };

        case NAV_TOGGLE_PARCEL:
            return {
                ...state,
                parcelExpand: !state.parcelExpand
            };

        case NAV_TOGGLE_STATUS:
            return {
                ...state,
                statusExpand: !state.statusExpand
            };

        default:
            return state;
    }
}

export default navReducer;
