import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";

import MuiTextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";

import { withStyles } from "@material-ui/core";

const styles = theme => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "8px 12px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(153,0,51,.50)"
        },
        backgroundColor: "#fff",
        fontSize: 14,
        fontWeight: 300
    },
    tableFormat: {
        padding: "4px 6px !important"
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #be0000"
        }
    },
    bootstrapSelectError: {
        // Select menus are a div not a input
        border: "1px solid #be0000"
    },
    rootLabel: {
        marginLeft: 12,
        marginTop: 3,
        whiteSpace: "nowrap"
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginBottom: -16,
        position: "relative",
        whiteSpace: "normal",
        lineHeight: 1.5
    },
    whiteSpace: {
        whiteSpace: "nowrap"
    },
    disabled: {
        backgroundColor: "#EEEEEE",
        color: "#000"
    },
    marginDense: {
        marginTop: -4
    },
    rightIcon: {
        right: 12
    },
    wordCount: {
        backgroundColor: "#EEEEEE",
        padding: 4,
        marginTop: -16,
        marginBottom: 4,
        border: "1px solid #979797",
        borderRadius: 4
    },
    wordCountSmall: {
        backgroundColor: "#EEEEEE",
        padding: 4,
        marginTop: -10,
        marginBottom: 4,
        border: "1px solid #979797",
        borderRadius: 4
    },
    marginDense2: {
        marginTop: 8,
        marginBottom: 4
    },
    green: {
        color: "green !important"
    },
    yellow: {
        color: "#b3b300 !important"
    },
    red: {
        color: "#be0000 !important"
    }
});

// Money no decimals
function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            prefix="$"
            allowNegative={false}
            decimalScale={0}
        />
    );
}
// number with 2 decimals
function NumberFormatCustom2(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            allowNegative={false}
            decimalScale={2}
        />
    );
}
// number with 0 decimals
function NumberFormatCustom4(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            allowNegative={false}
            decimalScale={0}
        />
    );
}

// number 4 digits
function NumberFormatCustom3(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            allowNegative={false}
            decimalScale={0}
            maxLength={4}
        />
    );
}

function PhoneFormat(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={"\u2000"}
            showMask
        />
    );
}

// See https://react-form.js.org/#/custom-input
class TextFieldWrapper extends Component {
    handleChange = e => {
        let value = e.target.value;

        const { fieldApi, eventHandle, readOnly, eventHandleProps1, eventHandleProps2 } = this.props;
        const { setValue, getFieldName } = fieldApi;

        if (!readOnly) {
            setValue(value);

            if (eventHandle) eventHandle(value, getFieldName(), eventHandleProps1, eventHandleProps2);
        }
    };
    handleClear = () => {
        const { fieldApi, eventHandle, eventHandleProps1, eventHandleProps2 } = this.props;
        const { setValue, getFieldName } = fieldApi;

        setValue(null);

        if (eventHandle) eventHandle(null, getFieldName(), eventHandleProps1, eventHandleProps2);
    };

    render() {
        const {
            fieldApi,
            helperText,
            SelectProps,
            classes,
            label,
            children,
            disabled,
            unitsVisual,
            maxWords,
            useTextFormat,
            select,
            phoneNumber,
            filterInput,
            useAcres,
            useYear,
            tableFormat,
            useNumber,
            onBlur,
            multiline,
            readOnly,
            selectHelp,
            color,
            eventHandleProps1, login,
            ...rest
        } = this.props;
        const { getValue, getError, getWarning, getSuccess, /*setTouched,*/ getFieldName  } = fieldApi;

        const value = getValue() || (SelectProps ? (SelectProps.multiple ? [] : "") : getValue() === 0 ? 0 : ""),
            error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success || helperText;

        let InputProps = {};

        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(classes.bootstrapInput, color === "green" && classes.green, color === "yellow" && classes.yellow, color === "red" && classes.red, tableFormat && classes.tableFormat, (disabled || readOnly) && classes.disabled),
            error: classes.bootstrapInputError,
            inputMarginDense: classes.marginDense
        };

        if (useTextFormat) {
            InputProps["inputComponent"] = NumberFormatCustom;
        } else if (phoneNumber) {
            InputProps["inputComponent"] = PhoneFormat;
        } else if (useAcres) {
            InputProps["inputComponent"] = NumberFormatCustom2;
        } else if (useYear) {
            InputProps["inputComponent"] = NumberFormatCustom3;
        } else if (useNumber) {
            InputProps["inputComponent"] = NumberFormatCustom4;
        }

        let sProps = {};
        if (SelectProps !== undefined) {
            sProps = SelectProps;
        }
        sProps["classes"] = {
            select: hasError ? classes.bootstrapSelectError : "",
            icon: tableFormat && classes.rightIcon
        };

        var val = value.toString();

        if (val && filterInput) {
            InputProps["endAdornment"] = (
                <InputAdornment position="end">
                    <Tooltip title="Clear Filter">
                        <IconButton aria-label="Clear Filter" onClick={this.handleClear}>
                            <Cancel />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            );
        }

        if (maxWords) {
            var count;
            var fullStr = val + " ";
            var initial_whitespace_rExp = /^[^A-Za-z0-9]+/gi;
            var left_trimmedStr = fullStr.replace(initial_whitespace_rExp, "");
            var non_alphanumerics_rExp = /[^A-Za-z0-9'-]+/gi;
            var cleanedStr = left_trimmedStr.replace(non_alphanumerics_rExp, " ");
            var splitString = cleanedStr.split(" ");
            count = splitString.length - 1;

            var textLimit = parseFloat(maxWords);

            if (splitString.length > textLimit) {
                var non_alphanumerics_rExp2 = /(<([^A-Za-z0-9'-])>)+/gi;
                var cleanedStr2 = left_trimmedStr.replace(non_alphanumerics_rExp2, " ");
                var splitString2 = cleanedStr2.split(" ");
                var text = "";
                for (var i=0;i<splitString2.length - (splitString.length - textLimit);i++) {
                    if (splitString2[i])
                        text += splitString2[i] + " ";
                }
                val = text.trim();
                fullStr = val + " ";
                left_trimmedStr = fullStr.replace(initial_whitespace_rExp, "");
                cleanedStr = left_trimmedStr.replace(non_alphanumerics_rExp, " ");
                splitString = cleanedStr.split(" ");
                count = splitString.length - 1;
            }

            var color2 = "auto";
            if (count >= textLimit)
                color2 = "#be0000";
        }

        return (
            <>
                <MuiTextField
                    value={val}
                    error={hasError}
                    label={select && selectHelp ? selectHelp : (!label && !select) ? children : label}
                    id={getFieldName() + Math.random()}
                    disabled={disabled}
                    helperText={errorText}
                    multiline={multiline}
                    onChange={this.handleChange}
                    onBlur={() => {
                        //setTouched();
                        if (onBlur)
                            onBlur(getFieldName(), eventHandleProps1);
                    }}
                    onKeyPress={e => {
                        if (e.key === "Enter" && !multiline && !login) e.preventDefault();
                    }}
                    SelectProps={sProps}
                    InputProps={InputProps}
                    InputLabelProps={{
                        shrink: true,
                        classes: {
                            root: classes.rootLabel,
                            shrink: classes.rootLabelShrink
                        }
                    }}
                    children={children}
                    readOnly
                    select={select}
                    margin={tableFormat ? "dense" : "normal"}
                    {...rest}
                />
                {maxWords && (
                    <Typography className={classes.wordCount} style={{color:color2}}>
                        Word Count: {count} out of {maxWords}
                    </Typography>
                )}
            </>
        );
    }
}

const TextField = FormField(TextFieldWrapper);

export default withStyles(styles)(TextField);
