import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import Menu from '@material-ui/core/Menu';
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import BuildIcon from '@material-ui/icons/Build';
import GetApp from '@material-ui/icons/GetApp';
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Warning from "@material-ui/icons/Warning";
import Close from "@material-ui/icons/Close";
import MailIcon from '@material-ui/icons/Mail';
import Done from "@material-ui/icons/Done";
import IconButton from "@material-ui/core/IconButton";

import DialogActions from "@material-ui/core/DialogActions";

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';

import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import Snackbar from "../common/Snackbar";
import GeneralTab from "./tabs/General";
import BudgetTab from "./tabs/Budget";
import OutputTab from "./tabs/Output";
import { 
    StatusUpdate, StatusUpdateAttachment
} from "./models";
import { SystemSetting } from "../proposal/models";

const getStatusUpdate = StatusUpdate.selectByUrlId(ap => ({
    formData: ap.ref,
    ...ap.ref
}));
const getSystemSettings = SystemSetting.selectMessage();


const styles = theme => ({
    titleContainer: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "center"
    },
    minWidth: {
        minWidth: 400
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    },
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 32
    },
    stepRoot: {
        backgroundColor: "#eeeeee",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingRight: 0
    },
    pointer: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        borderBottom: "8px solid transparent",
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        "&:hover": {
            borderBottom: "8px solid lightgray"
        }
    },
    pointerr: {
        borderBottom: "8px solid lightgray",
        marginBottom: "-8px !important"
    },
    pointer2: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        borderBottom: "8px solid " + theme.palette.primary.main
    },
    btnroot: {
        padding: 0,
        margin: 0,
    },
    stepColor: {
        "& svg path": {
            color: theme.palette.secondary.main // + " !important",
        }
    },
    lessMargin: {
        "& span": {
            marginTop: "8px !important",
            color: "#000"
        }
    },
    stepperWidth: {
        marginLeft: -20,
        marginRight: -20,
        maxWidth: "calc(100% + 40px)",
        flexBasis: "unset",
        width: "calc(100% + 40px)",
        padding: "0 !important"
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    topListText: {
        textAlign: "center",
        padding: 0
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
});

function getSteps() {
    return [
        "General",
        "Budget",
        "Output Tables"
    ];
}

class List extends Component {
    constructor(props) {
        super(props);
        this.appContainer = React.createRef();
    }
    state = {
        unsavedFields: false,
        snackbarOpen: false,
        anchorEl: null,
        submissionErrorOpen: false,
        submissionOpen: false,
        message: []
    };

    handleUnsavedFields = unsavedFields => {
        this.setState({ unsavedFields });
    };

    isLastStep(step) {
        return step === getSteps().length - 1;
    }

    handleNext = () => {
        let { activeStep } = this.props.status_update;
        if (this.isLastStep(activeStep)) {
            this.finalSubmit();
            //activeStep = 0;
            //history.push("/dashboard");
        } else {
            activeStep = (activeStep || 0) + 1;
        }
        this.handleStep(activeStep);
    };

    handleStep = (step, fromStepper) => {
        const { id } = this.props.status_update;

        this.handleUnsavedFields(false);

        // Hacky method to save form when using stepper
        if (fromStepper) {
            // Apparently just having a single "activeForm" works because only one stepper detail can be active at once
            if (this.activeForm) {
                // Pass true to the submit function
                // Seems to come out as the second argument in onSubmit
                // Don't do the handleNext's if integer (normally an event) - set activeStep to this number
                this.activeForm.setState({ submitClicked: true });
                const f = this;
                setTimeout(function() {
                    // The state is not set right away...
                    f.activeForm.form.submitForm(step);
                }, 50);
            }
        } else {
            this.props.ormStatusUpdateUpdateLocalOnly({
                id: id,
                activeStep: step
            });
        }
        this.setState({ snackbarOpen: true });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    componentDidMount() {
        const { 
            status_update, match, authState, ormStatusUpdateLoadDetail, ormStatusUpdateAttachmentLoadDetailChild
        } = this.props;
        if (authState && authState.user && (authState.user.role === "admin" || authState.user.role === "readonly" || (!status_update || !status_update.statusdate))) {
            ormStatusUpdateLoadDetail(match.params.id);
            ormStatusUpdateAttachmentLoadDetailChild(match.params.id);
        }
    };

    componentDidUpdate() {
        const { 
            status_update, match, authState, ormStatusUpdateLoadDetail, ormStatusUpdateAttachmentLoadDetailChild
        } = this.props;
        if ((!status_update || !status_update.statusdate) && authState && authState.user) {
            ormStatusUpdateLoadDetail(match.params.id);
            ormStatusUpdateAttachmentLoadDetailChild(match.params.id);
        }
    };

    actionMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    actionMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    finalSubmit = () => {
        const { status_update, ormStatusUpdateUpdate } = this.props;
        var missing = [];        
      
        if (status_update.output_acres_resourcetype_total_total_td !== status_update.output_acres_ecologicalsection_total_total_td)
            missing.push("The acre totals in Tables 1 and 3 do not match under the Output Tables tab.");
        if (status_update.output_dollars_resourcetype_total_total_td !== status_update.output_dollars_ecologicalsection_total_total_td)
            missing.push("The total request for funding in Tables 2 and 4 do not match under the Output Tables tab.");

        var spent = 0;
        spent += status_update.personnel_spent;
        spent += status_update.contracts_spent;
        spent += status_update.fee_with_pilt_spent;
        spent += status_update.fee_without_pilt_spent;
        spent += status_update.easement_acquisition_spent;
        spent += status_update.easement_stewardship_spent;
        spent += status_update.travel_spent;
        spent += status_update.professional_spent;
        spent += status_update.direction_support_spent;
        spent += status_update.land_acquisition_spent;
        spent += status_update.capital_equipment_spent;
        spent += status_update.other_equipment_spent;
        spent += status_update.supplies_spent;
        spent += status_update.dnr_idp_spent;
        
        var total_output = status_update.output_dollars_resourcetype_total_total_td;
        if (!total_output)
            total_output = 0;

        if (total_output !== spent)
            missing.push("The total budget request under the Budget tab does not match the total request funding for tables 2 and 4 under the Output Tables tab.");

        if (missing.length > 0) {
            this.setState({submissionErrorOpen: true, missing: missing})
        } else if(window.confirm("Your status update has been successully completed. Click OK to submit your update to the LSOHC staff.")){
            ormStatusUpdateUpdate({
                id: status_update.id,
                status: "Submitted"
            });
            this.setState({ submissionOpen: true });
        }
    }

    ActionMenu = ({ options, actionMenuOpen, actionMenuClose, anchorEl, status_update, classes, authState, ...rest }) => (
        <div style={{float:"right"}}>
            <Button
                variant="contained"
                color="primary"
                onClick={actionMenuOpen} >
                <BuildIcon />&nbsp;&nbsp;&nbsp;Select Action
            </Button>
            <Menu
                className={classes.list}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={actionMenuClose}
            >
                <MenuItem style={{borderBottom: "1px solid #666666",backgroundColor: "#eee",cursor: "default"}}>
                    <ListItemText
                        primary={<Typography variant="h3">Actions</Typography>}
                        classes={{
                            root: classes.topListText,
                            primary: classes.topListText
                        }}
                    />
                </MenuItem>
                <MenuItem onClick={() => {window.open("/reports/status_update/" + status_update.id + "/pdf");actionMenuClose();}} style={{ borderBottom: "1px solid #666666"}}>
                    <ListItemIcon>
                        <GetApp />
                    </ListItemIcon>
                    <ListItemText primary="Generate Report" />
                </MenuItem>
                {status_update.status === "Draft" && authState && authState.user && authState.user.role !== "readonly" && (
                    <MenuItem onClick={() => {this.finalSubmit();actionMenuClose();}} style={{ borderBottom: "1px solid #666666"}}>
                        <ListItemIcon>
                            <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Submit for Approval" />
                    </MenuItem>
                )}
                <MenuItem style={{borderBottom: "1px solid #666666",backgroundColor: "#eee",cursor: "default"}}>
                    <ListItemIcon>
                        <QuestionAnswer />
                    </ListItemIcon>
                    <ListItemText primary={"Status: " + status_update.status} />
                </MenuItem>
            </Menu>
        </div>
    );

    render() {
        const { classes, status_update, settings, history, authState } = this.props;
        const { snackbarOpen, missing, submissionErrorOpen, submissionOpen } = this.state;

        if (!status_update || !status_update.statusdate) {
            return (
                <AppContainer authenticated synced={true} />
            );
        }
        
        return (
            <AppContainer authenticated innerRef={this.appContainer}>
                <Grid container spacing={2}>
                    <PageHeader title={status_update.statusdate_label + " Status Update: ML " + status_update.ml_year + " " + status_update.project_name} />
                    <Grid item xs={12} className={classes.stepperWidth}>
                        <Stepper
                            nonLinear
                            className={classes.stepRoot}
                            alternativeLabel
                            activeStep={status_update.activeStep || 0}>
                            {getSteps().map((label, index) => {
                                return (
                                    <Step key={label} className={status_update.activeStep !== index ? classes.pointer : classes.pointer2}>
                                        <StepButton className={classes.btnroot} focusVisibleClassName={classes.pointerr} onClick={() => this.handleStep(index, true)}>
                                            <StepLabel
                                                classes={{
                                                    labelContainer: classes.lessMargin,
                                                    iconContainer: classes.stepColor
                                                }}>
                                                {label}
                                            </StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div style={{ marginRight: 30, marginLeft: 30 }}>
                            {(status_update.activeStep === 0 || !status_update.activeStep) && (
                                <GeneralTab
                                    onRef={el => (this.activeForm = el)}
                                    status_update={status_update}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} status_update={status_update} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {status_update.activeStep === 1 && (
                                <BudgetTab
                                    onRef={el => (this.activeForm = el)}
                                    status_update={status_update}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} status_update={status_update} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                            {status_update.activeStep === 2 && (
                                <OutputTab
                                    onRef={el => (this.activeForm = el)}
                                    status_update={status_update}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    ActionMenu={<this.ActionMenu authState={authState} classes={classes} status_update={status_update} actionMenuOpen={this.actionMenuOpen} anchorEl={this.state.anchorEl} actionMenuClose={this.actionMenuClose} />}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Snackbar
                    handleSnackbarClose={this.handleSnackbarClose}
                    isSuccess
                    snackbarOpen={snackbarOpen}
                />
                <Dialog open={submissionErrorOpen} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer}>
                        <Warning className={classes.supportIcon} />
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({submissionErrorOpen: false})} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{marginTop:24}}>
                            <Typography>
                                Please correct the following errors
                                <ul>
                                    {missing && missing.map(m => (
                                        <li>{m}</li>
                                    ))}
                                </ul>
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => this.setState({submissionErrorOpen: false})} color="primary" variant="contained">
                                Ok
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Dialog open={submissionOpen} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer}>
                        <Done className={classes.supportIcon} />
                        <IconButton aria-label="Close Dialog" onClick={() => history.push("/dashboard/")} className={classes.closeIcon}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{marginTop:24}}>
                            <Typography>
                                <div dangerouslySetInnerHTML={{ __html: settings.confirmation_message_status }} />
                            </Typography>
                        </DialogContentText>
                        <DialogActions className={classes.centerButtons}>
                            <Button onClick={() => history.push("/dashboard/")} color="primary" variant="contained">
                                Return to Dashboard
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </AppContainer>
        );
    }
}

List = connect(
    (state, ownProps) => ({
        status_update: getStatusUpdate(state, ownProps),
        settings: getSystemSettings(state),
        authState: state.auth
    }),
    {
        ...StatusUpdate.actions,
        ...StatusUpdateAttachment.actions,
    }
)(List);

export default withStyles(styles)(withRouter(List));
