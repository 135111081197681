import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { Form } from "react-form";
import ReCAPTCHA from "react-google-recaptcha";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

import AppContainer from "../common/AppContainer";
import Select from "../common/Select";
import states from "../common/states.json";
import Submit from "../common/Submit";
import TextField from "../common/TextField";
import HelpDialog from "../common/HelpDialog";

import * as authActions from "./actions";

const styles = {
    registerError: {
        color: "#be0000",
        marginTop: 8,
        marginBottom: -8
    },
    textAlignCenter: {
        textAlign: "center"
    },
    captchaError: {
        "& iframe": {
            border: "1px solid #be0000"
        }
    }
};

class RegisterPage extends Component {    
    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();
        this.state = {
            helpOpen: false,
            helpEmail: null,
            captchaError: null
        };
        this.inputs = [];
    };

    errorValidator = values => {
        const validateRequired = name => {
            return !name ? "Required" : null;
        };
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid email address" : null;
        };
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        var cap = this.recaptchaRef.current.getValue();
        this.setState({ captchaError: validateRequired(cap) });

        const objErrors = {
            name: validateRequired(values.name),
            email: validateEmail(values.email),
            password: validatePassword(values.password, values.password2),
            password2: validatePassword(values.password, values.password2),
            captcha: validateRequired(cap)
        };
        const entries = Object.entries(objErrors).reverse();
        for (const [k, v] of entries) {
            if (v && this.inputs[k]) this.inputs[k].focus();
        }
        return objErrors;
    };

    componentDidMount(){
        document.title = "Register New User - LSOHC Proposal and Grant Management System";
    }

    registerSubmit = values => {
        values.email = values.email.toLowerCase();
        values.captcha = this.recaptchaRef.current.getValue();
        this.props.authRegister(values).then(() => {
            if (this.props.register.success) {
                this.setState({ helpOpen: true, helpEmail: values.email });
            }
        });
    };

    render() {
        const { classes, register, history } = this.props;
        const { helpOpen, helpEmail, captchaError } = this.state;

        return (
            <AppContainer>
                <Form
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={this.errorValidator}
                    onSubmit={this.registerSubmit}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container justify="center" spacing={3}>
                                <Grid item xs={11} md={8} lg={6}>
                                    <Typography variant="h2">
                                        User Registration
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="center" spacing={3}>
                                <Grid item xs={11} md={8} lg={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="name" label="Name" fullWidth autoFocus
                                                inputRef={input => {
                                                    this.inputs["name"] = input;
                                                }} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="organization" label="Organization" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="address" label="Address" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="address2" label="Address 2" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="city" label="City" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Select field="state" label="State" options={states} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="zip" label="Zip Code" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                field="phone"
                                                label="Telephone"
                                                phoneNumber
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="email" label="Email (this is your user name)"
                                                inputRef={input => {
                                                    this.inputs["email"] = input;
                                                }} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography>
                                                Password Requirements:
                                                <ul>
                                                    <li>Minimum 8 characters</li>
                                                    <li>Cannot be part of your name or email</li>
                                                    <li>Cannot be entirely numeric</li>
                                                </ul>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="password" 
                                                inputRef={input => {
                                                    this.inputs["password"] = input;
                                                }} type="password" label="Password" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                field="password2"
                                                type="password"
                                                label="Retype Password"
                                                fullWidth
                                                inputRef={input => {
                                                    this.inputs["password2"] = input;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={captchaError && classes.captchaError}>
                                            <label htmlFor="g-recaptcha-response">
                                                <Typography>Please fill out to prove you are human</Typography>
                                            </label>
                                            <ReCAPTCHA
                                                ref={this.recaptchaRef}
                                                inputRef={input => {
                                                    this.inputs["captcha"] = input;
                                                }}
                                                sitekey="6LfcvsEUAAAAAHERYEt7J606Hbcu_oQ_r3vxasu9"
                                            />
                                            <FormHelperText className={classes.registerError}>
                                                {captchaError}
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Submit
                                                label="Register"
                                                altAction="/"
                                                altLabel="Return to Home Page"
                                                loading={register.pending}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                className={classNames(classes.textAlignCenter, classes.registerError)}>
                                                {register.error}
                                            </Typography>
                                            <Typography variant="h2" gutterBottom>Need Help?</Typography>
                                            <Typography>
                                                Please contact us at
                                                <br />
                                                <a href="mailto:lsohc@lsohc.leg.mn">lsohc@lsohc.leg.mn</a>
                                                <br />
                                                651-284-6430
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
                <HelpDialog
                    open={helpOpen}
                    onClose={() => history.push("/")}
                    question="Account Registered"
                    text={
                        <div>
                            Your account has been successfully registered in the Lessard-Sams Outdoor Hertiage Council
                            system. Return to the home page to log in with your user name <b>{helpEmail}</b>.
                        </div>
                    }
                />
            </AppContainer>
        );
    }
}

RegisterPage = connect(
    state => ({ register: (state.auth && state.auth.register) || {} }),
    authActions
)(RegisterPage);

export default withStyles(styles)(withRouter(RegisterPage));
