import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Menu from "@material-ui/core/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import HelpIcon from "@material-ui/icons/Help";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import SettingsIcon from "@material-ui/icons/Settings";

import header from "../../images/header.jpg";
import pack from "../../../package.json";

import { connect } from "react-redux";
import * as authActions from "../auth/actions";
import * as navActions from "./actions";
import { reloadAll, createSelector } from "./orm";
import { SystemSetting } from "../proposal/models";
import NewProposal from "../proposal/NewProposal";

const allProposals = createSelector(schema => {
    return schema.Proposal.all()
        .orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray();
});
const allAps = createSelector(schema => {
    return schema.AccomplishmentPlan.all()
        .orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray();
});
const getSystemSettings = SystemSetting.selectMessage();

const styles = theme => ({
    header: {
        height: "12rem",
        backgroundImage: "url(" + header + ")",
        backgroundPosition: "0% 62%",
        backgroundSize: "cover"
    },
    headerContainer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        background: "#333333"
    },
    updateReady: {
        backgroundColor: "#4e4e4e",
        width: "100%",
        height: "10em"
    },
    headerButton: {
        color: "#fff",
        "& svg": {
            fontSize: 23,
            marginRight: 4
        },
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.25)"
        }
    },
    listItem: {
        borderBottom: "1px solid #666666"
    },
    topListItem: {
        borderBottom: "1px solid #666666",
        backgroundColor: "#eee",
        cursor: "default"
    },
    topListText: {
        textAlign: "center",
        padding: 0
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    twoLine: {
        paddingBottom: 4,
        paddingTop: 4,
        borderTop: "1px dotted"
    },
    navBar: {
        textAlign: "right",
        backgroundColor: "#64000d"
    },
    heading: {
        fontSize: "2em",
        textDecoration: "none",
        fontFamily: "georgia, serif",
        textShadow: "1px 1px 1px #000",
        letterSpacing: ".15rem",
        color: "#fff !important",
        display: "inline-block"
    }
});

class Header extends Component {
    constructor() {
        super();
        this.state = {
            newDialogOpen: false,
            proposalMenuAnchor: false,
            apMenuAnchor: false,
            helpMenuAnchor: false,
            userMenuAnchor: false,
            adminMenuAnchor: false
        };
    }

    handleMenu = (event, menu) => {
        this.setState({ [menu]: event.currentTarget });
    };

    handleMenuClose = menu => {
        this.setState({ [menu]: false });
    };

    logOut = () => {
        this.props.authLogout();
        this.props.history.push("/");
    };

    render() {       
        const { classes, authenticated, authState, navState, settings, syncState, proposals, accomplishment_plans } = this.props;
        const {
            newDialogOpen,
            proposalMenuAnchor,
            apMenuAnchor,
            helpMenuAnchor,
            userMenuAnchor,
            adminMenuAnchor
        } = this.state;

        if (!syncState) return "...";


        const syncProgress = Math.round((syncState.progress / syncState.total) * 100);

        if (!authState) return "...";

        const updateReady = navState.sw && navState.sw.update;
        const proposals_open = settings && settings.funding_open;

        var is_admin = false;
        var is_read_only = false;
        if (authState && authState.user && authState.user.role === "admin")
            is_admin = true;
        if (authState && authState.user && authState.user.role === "readonly") {
            is_admin = false;
            is_read_only = true;
        }

        const UpdateHeader = () => (
            <Toolbar className={classes.updateReady}>
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="subheading" color="inherit">
                            A newer version of the application is available. Please save your work and refresh your
                            browser. &nbsp;
                            <Button variant="contained" onClick={() => window.location.reload()}>
                                Refresh Now
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        );

        return (
            <AppBar position="absolute" className={classes.header}>
                {updateReady && <UpdateHeader />}
                {!syncState.ready && <LinearProgress variant="determinate" value={syncProgress} />}
                <div className={classes.headerContainer}>
                    <Grid container justify="center">
                        <Grid item>
                            <Typography variant="h1" className={classes.heading}>
                                LSOHC Proposal and Grant Management System
                            </Typography>
                        </Grid>
                        {authenticated && !is_admin && !is_read_only && ( // Regular user navigation
                            <Grid item xs={12} className={classes.navBar}>
                                <IconButton className={classes.headerButton} component={Link} to="/dashboard">
                                    <HomeIcon />
                                    <Typography color="inherit">Dashboard</Typography>
                                </IconButton>
                                <IconButton
                                    className={classes.headerButton}
                                    onClick={e => this.handleMenu(e, "proposalMenuAnchor")}>
                                    <BookmarksIcon />
                                    <Typography color="inherit">Proposals</Typography>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <IconButton
                                    className={classes.headerButton}
                                    onClick={e => this.handleMenu(e, "apMenuAnchor")}>
                                    <ChromeReaderModeIcon />
                                    <Typography color="inherit">Accomplishment Plans</Typography>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <IconButton
                                    className={classes.headerButton}
                                    onClick={e => this.handleMenu(e, "helpMenuAnchor")}>
                                    <HelpIcon />
                                    <Typography color="inherit">Help</Typography>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <IconButton
                                    className={classes.headerButton}
                                    onClick={e => this.handleMenu(e, "userMenuAnchor")}>
                                    <AccountCircle />
                                    <Typography color="inherit">Account</Typography>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            </Grid>
                        )}
                        {authenticated && (is_admin || is_read_only) && ( // admin navigation
                            <Grid item xs={12} className={classes.navBar}>
                                <IconButton className={classes.headerButton} component={Link} to="/dashboard">
                                    <HomeIcon />
                                    <Typography color="inherit">Dashboard</Typography>
                                </IconButton>
                                <IconButton
                                    className={classes.headerButton}
                                    onClick={e => this.handleMenu(e, "helpMenuAnchor")}>
                                    <HelpIcon />
                                    <Typography color="inherit">Help</Typography>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <IconButton
                                    className={classes.headerButton}
                                    onClick={e => this.handleMenu(e, "adminMenuAnchor")}>
                                    <SettingsIcon />
                                    <Typography color="inherit">Administration</Typography>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <IconButton
                                    className={classes.headerButton}
                                    onClick={e => this.handleMenu(e, "userMenuAnchor")}>
                                    <AccountCircle />
                                    <Typography color="inherit">Account</Typography>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </div>
                <NewProposal returnDialog={() => this.handleMenuClose("newDialogOpen")} open={newDialogOpen} />
                <Toolbar>
                    <Menu
                        anchorEl={proposalMenuAnchor ? proposalMenuAnchor : null}
                        classes={{ paper: classes.list }}
                        open={proposalMenuAnchor}
                        onClose={() => this.handleMenuClose("proposalMenuAnchor")}>
                        <ListItem button className={classes.topListItem}>
                            <ListItemText
                                primary={<Typography variant="h3">Proposals</Typography>}
                                classes={{
                                    root: classes.topListText,
                                    primary: classes.topListText
                                }}
                            />
                        </ListItem>
                        {proposals_open && (
                            <ListItem
                                button
                                className={classes.listItem}
                                onClick={() => this.setState({ newDialogOpen: true })}>
                                <ListItemText primary="Create New Proposal" />
                            </ListItem>
                        )}
                        {proposals.length === 0 && (
                            <ListItem className={classes.topListItem}>
                                <ListItemText primary="No Proposals Created" />
                            </ListItem>
                        )}
                        {proposals.map(proposal => {
                            return (
                                <ListItem
                                    button
                                    key={proposal.id}
                                    classes={{ root: classes.twoLine }}
                                    component={Link}
                                    onClick={() => this.handleMenuClose("proposalMenuAnchor")}
                                    to={"/proposal/" + proposal.id}>
                                    <ListItemText
                                        primary={
                                            <p style={{ margin: 0 }}>
                                                <span style={{ display: "block" }}>ML {proposal.ml_year}</span>
                                                <span>{proposal.name}</span>
                                            </p>
                                        }
                                    />
                                </ListItem>
                            );
                        })}
                    </Menu>
                    <Menu
                        anchorEl={apMenuAnchor ? apMenuAnchor : null}
                        classes={{ paper: classes.list }}
                        open={apMenuAnchor}
                        onClose={() => this.handleMenuClose("apMenuAnchor")}>
                        <ListItem button className={classes.topListItem}>
                            <ListItemText
                                primary={<Typography variant="h3">Accomplishment Plans</Typography>}
                                classes={{
                                    root: classes.topListText,
                                    primary: classes.topListText
                                }}
                            />
                        </ListItem>
                        {accomplishment_plans.length === 0 && (
                            <ListItem className={classes.topListItem}>
                                <ListItemText primary="No Accomplishment Plans Created" />
                            </ListItem>
                        )}
                        {accomplishment_plans.map(ap => {
                            return (
                                <ListItem
                                    button
                                    key={ap.id}
                                    classes={{ root: classes.twoLine }}
                                    component={Link}
                                    onClick={() => this.handleMenuClose("apMenuAnchor")}
                                    to={"/accomplishment/" + ap.id}>
                                    <ListItemText
                                        primary={
                                            <p style={{ margin: 0 }}>
                                                <span style={{ display: "block" }}>ML {ap.ml_year}</span>
                                                <span>{ap.name}</span>
                                            </p>
                                        }
                                    />
                                </ListItem>
                            );
                        })}
                    </Menu>
                    <Menu
                        anchorEl={userMenuAnchor ? userMenuAnchor : null}
                        classes={{ paper: classes.list }}
                        open={userMenuAnchor}
                        onClose={() => this.handleMenuClose("userMenuAnchor")}>
                        <ListItem button className={classes.topListItem}>
                            <ListItemText
                                primary={<Typography variant="h3">Account Options</Typography>}
                                classes={{
                                    root: classes.topListText,
                                    primary: classes.topListText
                                }}
                            />
                        </ListItem>
                        <ListItem button component={Link} to="/updateuser" className={classes.listItem}>
                            <ListItemText primary="Update Profile" />
                        </ListItem>
                        <ListItem button onClick={this.logOut} className={classes.listItem}>
                            <ListItemText primary="Log Out" />
                        </ListItem>
                        <ListItem button className={classes.topListItem}>
                            <ListItemText primary={"Version " + pack.version} />
                        </ListItem>
                    </Menu>
                    <Menu
                        anchorEl={helpMenuAnchor ? helpMenuAnchor : null}
                        classes={{ paper: classes.list }}
                        open={helpMenuAnchor}
                        onClose={() => this.handleMenuClose("helpMenuAnchor")}>
                        <ListItem button className={classes.topListItem}>
                            <ListItemText
                                primary={<Typography variant="h3">Help</Typography>}
                                classes={{
                                    root: classes.topListText,
                                    primary: classes.topListText
                                }}
                            />
                        </ListItem>
                        <ListItem button className={classes.listItem}>
                            <ListItemText
                                primary="Program Manager Information & Resources"
                                onClick={() => window.open("https://www.lsohc.leg.mn/manager_info/index.html")}
                            />
                        </ListItem>
                        <ListItem button className={classes.listItem}>
                            <ListItemText
                                primary="Contact LSOHC Staff"
                                onClick={() => window.open("mailto:sandy.smith@lsohc.leg.mn")}
                            />
                        </ListItem>
                    </Menu>
                    <Menu
                        anchorEl={adminMenuAnchor ? adminMenuAnchor : null}
                        classes={{ paper: classes.list }}
                        open={adminMenuAnchor}
                        onClose={() => this.handleMenuClose("adminMenuAnchor")}>
                        <ListItem button className={classes.topListItem}>
                            <ListItemText
                                primary={<Typography variant="h3">Administration</Typography>}
                                classes={{
                                    root: classes.topListText,
                                    primary: classes.topListText
                                }}
                            />
                        </ListItem>
                        <ListItem button className={classes.listItem} component={Link} to="/adm/home">
                            <ListItemText primary="Home Page" />
                        </ListItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        );
    }
}

Header = connect(
    state => ({ 
        proposals: allProposals(state),
        accomplishment_plans: allAps(state),
        navState: state.nav,
        authState: state.auth,
        syncState: state.sync, 
        settings: getSystemSettings(state) 
    }),
    {
        ...authActions,
        ...navActions,
        reload: reloadAll
    }
)(Header);

export default withStyles(styles)(withRouter(Header));
