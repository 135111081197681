import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import TextField from "../common/TextField";
import Select from "../common/Select";
import states from "../common/states.json";
import PageHeader from "../common/PageHeader";
import * as authActions from "./actions";

const styles = {
    registerError: {
        color: "#be0000",
        textAlign: "center"
    }
};

class UpdatePage extends Component {
    errorValidator = values => {
        const validateName = name => {
            return !name ? "Name is required" : null;
        };
        return {
            name: validateName(values.name)
        };
    };
    errorValidatorPassword = values => {
        const validatePasswordSingle = password => {
            if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        return {
            current_password: validatePasswordSingle(values.current_password),
            new_password: validatePassword(values.new_password, values.re_new_password),
            re_new_password: validatePassword(values.new_password, values.re_new_password)
        };
    };

    submitForm = values => {
        this.props.authUpdate(values).then(() => {
            if (this.props.update.success) {
                this.props.history.push("/dashboard");
            }
        });
    };

    submitFormPassword = values => {
        this.props.authPasswordChange(values).then(() => {
            if (this.props.change.success) {
                this.props.history.push("/dashboard");
            }
        });
    };

    render() {
        const { classes, update, change, authState } = this.props;
        return (
            <AppContainer authenticated>
                <Grid container justify="center" spacing={5}>
                    <PageHeader title="Modify User Info" />
                    <Grid item xs={11} md={8} lg={6}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={authState.user}
                            validateError={this.errorValidator}
                            onSubmit={this.submitForm}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" gutterBottom>
                                                User Profile
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="email" label="Email" disabled fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="name" label="Name" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="organization" label="Organization" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="address" label="Address" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="address2" label="Address 2" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="city" label="City" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Select field="state" label="State" options={states} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="zip" label="Zip Code" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                field="phone"
                                                label="Telephone"
                                                fullWidth
                                                phoneNumber
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Submit
                                                label="Update User Information"
                                                altAction="/dashboard"
                                                altLabel="Return to Dashboard"
                                                loading={update.pending}
                                            />
                                            <Typography className={classes.registerError}>{update.error}</Typography>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                        <Grid container spacing={2} style={{marginTop:24, marginBottom:24}}>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidatorPassword}
                            onSubmit={this.submitFormPassword}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" gutterBottom>
                                                Update Password
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                field="current_password"
                                                label="Current Password"
                                                type="password"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography>
                                                Password Requirements:
                                                <ul>
                                                    <li>Minimum 8 characters</li>
                                                    <li>Cannot be part of your name or email</li>
                                                    <li>Cannot be entirely numeric</li>
                                                </ul>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                field="new_password"
                                                label="New Password"
                                                type="password"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                field="re_new_password"
                                                label="Retype New Password"
                                                type="password"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Submit
                                                label="Update Password"
                                                altAction="/dashboard"
                                                altLabel="Return to Dashboard"
                                                loading={change.pending}
                                            />
                                            <Typography className={classes.registerError}>{change.error}</Typography>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

UpdatePage = connect(
    state => ({
        authState: state.auth,
        update: (state.auth && state.auth.update) || {},
        change: (state.auth && state.auth.change) || {}
    }),
    authActions
)(UpdatePage);

export default withStyles(styles)(withRouter(UpdatePage));
