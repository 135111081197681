import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import MessagePreview from "../home/Messages";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import TextField from "../common/TextField";
import DatePicker from "../common/DatePicker";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import TablePaginationActionsWrapped from "../common/Paginator";
import { createSelector } from "../common/orm";
import { Message } from "../home/models";

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    lastItem: {
        borderBottom: "3px solid " + theme.palette.primary.main
    },
    dialogMin: {
        minWidth: 400
    },
    flex: {
        flex: 1
    }
});

const getMessages = createSelector(schema => {
    return schema.Message.all()
        .orderBy("id", "desc")
        .toRefArray();
});

const columnDataMessages = [
    { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" },
    { id: "id", numeric: true, label: "Message ID", allowSort: true },
    { id: "date_created", numeric: false, label: "Date Created", allowSort: true, date: true },
    { id: "date_expired", numeric: false, label: "Date Expiring", allowSort: true, date: true },
    { id: "status", numeric: false, label: "Status", allowSort: false },
    { id: "message", numeric: false, label: "Message", allowSort: false }
];

class Messages extends Component {
    state = {
        page: 0,
        rowsPerPage: 10,
        messageDialogOpen: false,
        messageId: null,
        order: "desc",
        orderBy: "id"
    };
    componentDidMount() {
        document.title = "Configure User Messages - LSOHC Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "admin") {
            history.push("/dashboard");
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.props.messages.sort(function(a, b) {
            var date = columnDataMessages.find(cD => cD.id === orderBy).date;
            var numeric = columnDataMessages.find(cD => cD.id === orderBy).numeric;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    formatDate(dateString) {
        if (!dateString) {
            return null;
        }
        const d = new Date(dateString + " 12:00:00");
        return d.toLocaleDateString();
    }

    render() {
        const { messages, classes, ormMessageDelete, ormMessageCreate, ormMessageUpdate } = this.props;
        const { page, rowsPerPage, messageId, messageDialogOpen, order, orderBy } = this.state;

        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <PageHeader title="Configure User Messages" />
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom>
                            Messages
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.setState({ messageDialogOpen: true, messageId: null })}>
                            <AddCircleIcon />
                            &nbsp;&nbsp;&nbsp;Create New Dashboard Message
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                columnData={columnDataMessages}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                            />
                            <TableBody>
                                {messages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                    return (
                                        <TableRow hover key={n.id}>
                                            <CustomTableCell className={classes.nowrap}>
                                                <Tooltip title="Edit Message">
                                                    <Button
                                                        color="primary"
                                                        className={classes.deleteWidth}
                                                        onClick={() =>
                                                            this.setState({ messageDialogOpen: true, messageId: n })
                                                        }>
                                                        <CreateIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Delete Message">
                                                    <Button
                                                        color="primary"
                                                        className={classes.deleteWidth}
                                                        onClick={() => ormMessageDelete(n.id)}>
                                                        <DeleteIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            </CustomTableCell>
                                            <CustomTableCell>{n.id}</CustomTableCell>
                                            <CustomTableCell>{this.formatDate(n.date_created)}</CustomTableCell>
                                            <CustomTableCell>{this.formatDate(n.date_expired)}</CustomTableCell>
                                            <CustomTableCell>
                                                {new Date(n.date_expired) >= new Date() ? "Active" : "Inactive"}
                                            </CustomTableCell>
                                            <CustomTableCell>{n.message}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {messages.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={6} className={classes.centerAlign}>
                                            No Messages Found
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            {messages.length > 10 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={6}
                                            count={messages.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                    </Grid>
                    <Grid item xs={12} lg={9} xl={7} className={classes.lastItem}>
                        <Typography variant="h3">Preview Active Dashboard Messages</Typography>
                    </Grid>
                    <MessagePreview />
                </Grid>
                <MessageDialog
                    ormMessageCreate={ormMessageCreate}
                    ormMessageUpdate={ormMessageUpdate}
                    classes={classes}
                    returnDialog={() => this.setState({ messageDialogOpen: false })}
                    open={messageDialogOpen}
                    messageId={messageId}
                />
            </AppContainer>
        );
    }
}

class MessageDialog extends Component {
    addMessage(values) {
        const { returnDialog, ormMessageCreate, ormMessageUpdate, messageId } = this.props;

        values.date_expired = values.date_expired.replace(" 12:00:00", "");
        if (messageId) {
            ormMessageUpdate({
                id: messageId.id,
                date_expired: values.date_expired,
                message: values.message
            });
        } else {
            ormMessageCreate(values);
        }
        returnDialog();
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            date_expired: isRequired(values.date_expired),
            message: isRequired(values.message)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, messageId } = this.props;

        var defaultv = Object.assign({}, messageId);
        if (defaultv && defaultv.date_expired) defaultv.date_expired = defaultv.date_expired + " 12:00:00";

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        {!messageId ? "Add New" : "Edit"} Message
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.minWidth}>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={defaultv}
                            onSubmit={values => this.addMessage(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <DatePicker field="date_expired" label="Expiration Date" />
                                            <TextField
                                                field="message"
                                                label="Message"
                                                multiline
                                                rows={4}
                                                rowsMax={20}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => returnDialog()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

Messages = connect(
    (state, ownProps) => ({
        authState: state.auth,
        messages: getMessages(state, ownProps)
    }),
    {
        ...Message.actions
    }
)(Messages);

export default withStyles(styles)(withRouter(Messages));
