import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";

import { withStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AddToPhotos from "@material-ui/icons/AddToPhotos";
import DeleteIcon from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
import { StatusUpdate, StatusUpdateAttachment } from "../models";

import TextField from "../../common/TextField";
import FileInput from "../../common/FileInput";
import Select from "../../common/Select";
import CustomTableCell from "../../common/TableCell";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import { createSelector } from "../../common/orm";

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    centerAlign: {
        textAlign: "center"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    flex: {
        flex: 1
    },    
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
});

const getAttachments = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.StatusUpdateAttachment.filter({ statusupdate: id }).orderBy("id").toModelArray();
    }
);

const columnData = [
    {
        id: "actions",
        numeric: false,
        label: "Actions",
        allowSort: false,
        width: "20px"
    },
    { id: "name", numeric: false, label: "Document Title", allowSort: false },
    { id: "type", numeric: false, label: "Document Type", allowSort: false },
    { id: "file", numeric: true, label: "Link to File", allowSort: false }
];

class GeneralTab extends Component {
    state = {
        uploadEdit: false,
        dialogOpen: false,
        key: null,
        attachmentId: null
    };
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Status Update: General - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
        this.formKey = window.performance.now();
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateStatusUpdate(values, fromStepper) {
        const { ormStatusUpdateUpdate, history, handleUnsavedFields, handleNext, status_update } = this.props;
        const { uploadEdit } = this.state;
        const { id } = status_update;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }
        if (!uploadEdit && status_update.conservation !== "") {
            values.conservation = status_update.conservation;
        }

        ormStatusUpdateUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);        
        
        if (!Number.isInteger(fromStepper)) {
            if (!uploadEdit) {
                if (this.state.draftClick) {
                    history.push("/dashboard/");
                } else {
                    handleNext();
                }
            } else {
                const f = this;
                setTimeout(function() {
                    // FIXME: Call this function after the update has returned from the server with the uploaded URL
                    f.formKey = window.performance.now();
                    f.setState({ uploadEdit: false });
                }, 5000);
            }
        }
    };

    render() {
        const { authState, classes, status_update, handleUnsavedFields, ActionMenu, attachments, ormStatusUpdateAttachmentCreate, ormStatusUpdateAttachmentDelete, ormStatusUpdateAttachmentUpdate } = this.props;
        const { key, dialogOpen, attachmentId } = this.state;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || status_update.status === "Draft")
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        const year = new Date(status_update.statusdate).getFullYear();
        const idp = status_update.ap_budgets.find(b => b.item === "DNR IDP").total;

        return (
            <>
            <Form
                getApi={el => (this.form = el)}
                key={this.formKey}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={status_update.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateStatusUpdate(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={10} xl={8}>
                                {ActionMenu}
                                <TextField 
                                    disabled={is_read_only}
                                    field="progress_todate"
                                    fullWidth
                                    multiline
                                    rows={8}
                                    rowsMax={20}
                                    label="Describe progress-to date in this reporting period, including successes and challenges. Is the program on track to accomplish the stated outcomes?" 
                                />
                                {year >= 2021 && idp > 0 && (
                                    <TextField 
                                        disabled={is_read_only}
                                        field="idp_money"
                                        fullWidth
                                        multiline
                                        rows={8}
                                        rowsMax={20}
                                        label="If your program has IDP budget money, please describe the amounts transferred to DNR, for what, and the expiration date of those monies." 
                                    />
                                )}
                                <FileInput
                                    field="conservation"
                                    id="conservation"
                                    showLoading={true}
                                    disabled={is_read_only}
                                    fullWidth
                                    eventHandle={() => {
                                        this.setState({ uploadEdit: true });
                                        formApi.submitForm({});
                                    }}
                                >    
                                    Attach written contact with Conservation Corps of Minnesota for possible use of restoration and enhancement services &nbsp;
                                    <a rel="noopener noreferrer" href=" https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.13" target="_blank">per 97A.056, subd 13(h)</a>&nbsp;
                                    for possible use of restoration and enhancement services.
                                </FileInput>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={8}>
                                <Typography gutterBottom>Public relations, media articles, maps, or other related material</Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={is_read_only}
                                    style={{marginBottom:16}}
                                    onClick={() =>
                                        this.setState({
                                            dialogOpen: true,
                                            attachmentId: null,
                                            key: window.performance.now()
                                        })
                                    }>
                                    <AddToPhotos />&nbsp;&nbsp;&nbsp;Add New Attachment
                                </Button>
                                <Table className={classes.table}>
                                    <EnhancedTableHead columnData={columnData} />
                                    <TableBody>
                                        {attachments.map(n => {
                                            return (
                                                <TableRow hover key={n.id}>
                                                    <CustomTableCell className={classes.nowrap}>
                                                        <Tooltip title="Edit Attachment">
                                                            <Button
                                                                color="primary"
                                                                disabled={is_read_only}
                                                                onClick={() => this.setState({ dialogOpen: true, attachmentId: n }) }
                                                                className={classes.deleteWidth}>
                                                                <CreateIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Delete Attachment">
                                                            <Button disabled={is_read_only} color="primary" className={classes.deleteWidth} onClick={() => ormStatusUpdateAttachmentDelete(n.id) }>
                                                                <DeleteIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomTableCell>
                                                    <CustomTableCell>{n.name}</CustomTableCell>
                                                    <CustomTableCell>{n.type ? n.type : "-"}</CustomTableCell>
                                                    <CustomTableCell>
                                                        <a href={n.file} target="_blank" rel="noopener noreferrer">
                                                            Open
                                                        </a>
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {attachments.length < 1 && (
                                            <TableRow>
                                                <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                                    No Other Attachments Found
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={8} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Budget <ChevronRightIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <AttachmentDialog
                open={dialogOpen}
                ormStatusUpdateAttachmentCreate={ormStatusUpdateAttachmentCreate}
                ormStatusUpdateAttachmentUpdate={ormStatusUpdateAttachmentUpdate}
                attachmentId={attachmentId}
                returnDialog={() => this.setState({ dialogOpen: false })}
                classes={classes}
                status_update={status_update}
                formkey={key}
            />
            </>
        );
    }
}

class AttachmentDialog extends Component {
    addAttachment(values) {
        const {
            returnDialog,
            ormStatusUpdateAttachmentCreate,
            ormStatusUpdateAttachmentUpdate,
            attachmentId,
            status_update,
        } = this.props;

        if (attachmentId) {
            ormStatusUpdateAttachmentUpdate({
                id: attachmentId.id,
                ...values
            });
        } else {
            ormStatusUpdateAttachmentCreate({
                statusupdate: status_update.id,
                ...values
            });
        }
        returnDialog();
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, attachmentId, formkey } = this.props;

        return (
            <Dialog open={open}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        {!attachmentId ? "Add New" : "Edit"} Attachment
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={attachmentId ? attachmentId.id : formkey}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={attachmentId}
                            onSubmit={values => this.addAttachment(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField style={{marginTop:0}} field="name" label="Name" fullWidth />
                                            <Select options={[
                                                {label: "Press Release", value: "Press Release"},
                                                {label: "News Article", value: "News Article"},
                                                {label: "Map", value: "Map"},
                                                {label: "Other", value: "Other"}
                                            ]} field="type" label="Type" fullWidth />
                                            <FileInput field="file" id="file" label="Upload File" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => returnDialog()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

GeneralTab = connect(
    (state, ownProps) => ({
        attachments: getAttachments(state, ownProps),
        authState: state.auth
    }),
    {
        ...StatusUpdate.actions,
        ...StatusUpdateAttachment.actions
    }
)(GeneralTab);

export default withStyles(styles)(withRouter(GeneralTab));
