export const SW_CHECKING = "SW_CHECKING";
export const SW_REGISTERED = "SW_REGISTERED";
export const SW_INSTALLED = "SW_INSTALLED";
export const SW_UPDATE = "SW_UPDATE";
export const SW_ERROR = "SW_ERROR";
export const SW_DISABLED = "SW_DISABLED";
export const STORE_READY = "STORE_READY";
export const NAV_TOGGLE_PROPOSAL = "NAV_TOGGLE_PROPOSAL";
export const NAV_TOGGLE_AP = "NAV_TOGGLE_AP";
export const NAV_TOGGLE_FINAL = "NAV_TOGGLE_FINAL";
export const NAV_TOGGLE_PARCEL = "NAV_TOGGLE_PARCEL";
export const NAV_TOGGLE_STATUS = "NAV_TOGGLE_STATUS";

export function swChecking() {
    return { type: SW_CHECKING };
}

export function swRegistered() {
    return { type: SW_REGISTERED };
}

export function swInstalled() {
    return { type: SW_INSTALLED };
}

export function swUpdate() {
    return { type: SW_UPDATE };
}

export function swError(error) {
    return { type: SW_ERROR, error: error };
}

export function swDisabled(reason) {
    return { type: SW_DISABLED, reason: reason };
}

export function storeReady() {
    return { type: STORE_READY };
}

export function navToggleProposal() {
    return {
        type: NAV_TOGGLE_PROPOSAL
    };
}

export function navToggleAp() {
    return {
        type: NAV_TOGGLE_AP
    };
}

export function navToggleFinal() {
    return {
        type: NAV_TOGGLE_FINAL
    };
}

export function navToggleParcel() {
    return {
        type: NAV_TOGGLE_PARCEL
    };
}

export function navToggleStatus() {
    return {
        type: NAV_TOGGLE_STATUS
    };
}
