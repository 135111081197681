import orm, { Model } from "../common/orm";
import { fk } from "redux-orm";

export class AccomplishmentPlan extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "AccomplishmentPlan";
    }
}
orm.register(AccomplishmentPlan);

export class AccomplishmentAmountNonOHF extends Model {
    static get fields() {
        return {
            accomplishment: fk("AccomplishmentPlan", "amountnonohf")
        };
    }
    static get modelName() {
        return "AccomplishmentAmountNonOHF";
    }
}

orm.register(AccomplishmentAmountNonOHF);

export class AccomplishmentFutureMaintain extends Model {
    static get fields() {
        return {
            accomplishment: fk("AccomplishmentPlan", "futuremaintain")
        };
    }
    static get modelName() {
        return "AccomplishmentFutureMaintain";
    }
}
orm.register(AccomplishmentFutureMaintain);

export class AccomplishmentTimeline extends Model {
    static get fields() {
        return {
            accomplishment: fk("AccomplishmentPlan", "timeline")
        };
    }
    static get modelName() {
        return "AccomplishmentTimeline";
    }
}
orm.register(AccomplishmentTimeline);

export class AccomplishmentBudget extends Model {
    static get fields() {
        return {
            accomplishment: fk("AccomplishmentPlan", "budget")
        };
    }
    static get modelName() {
        return "AccomplishmentBudget";
    }
}
orm.register(AccomplishmentBudget);

export class AccomplishmentBudgetPersonnel extends Model {
    static get fields() {
        return {
            budget: fk("AccomplishmentBudget", "budgetpersonnel")
        };
    }
    static get modelName() {
        return "AccomplishmentBudgetPersonnel";
    }
}
orm.register(AccomplishmentBudgetPersonnel);

export class AccomplishmentBudgetCapitalEquipment extends Model {
    static get fields() {
        return {
            budget: fk("AccomplishmentBudget", "budgetcapital")
        };
    }
    static get modelName() {
        return "AccomplishmentBudgetCapitalEquipment";
    }
}
orm.register(AccomplishmentBudgetCapitalEquipment);

export class AccomplishmentParcel extends Model {
    static get fields() {
        return {
            accomplishment: fk("AccomplishmentPlan", "parcel"),
            final: fk("FinalReport", "parcel")
        };
    }
    static get modelName() {
        return "AccomplishmentParcel";
    }
}
orm.register(AccomplishmentParcel);

export class AccomplishmentAttachment extends Model {
    static get fields() {
        return {
            accomplishment: fk("AccomplishmentPlan", "attachment")
        };
    }
    static get modelName() {
        return "AccomplishmentAttachment";
    }
}
orm.register(AccomplishmentAttachment);

export class AmendmentLog extends Model {
    static get fields() {
        return {
            accomplishment: fk("AccomplishmentPlan", "amendment")
        };
    }
    static get modelName() {
        return "AmendmentLog";
    }
}
orm.register(AmendmentLog);

export class AccomplishmentPlanView extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "AccomplishmentPlanView";
    }
    static get isUserData() {
        return false;
    }
}
orm.register(AccomplishmentPlanView);
