import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import TextField from "../common/TextField";
import RadioGroup from "../common/RadioGroup";
import FileInput from "../common/FileInput";
import Subquestion from "../common/Subquestion";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import Snackbar from "../common/Snackbar";
import { RealPropertyInterest } from "./models";
import { InitialLandReporting, InitialLandReportingView } from "../land_reporting/models"

const styles = theme => ({
    rightAlign: {
        textAlign: "center"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    gutterTop: {
        textAlign: "center",
        marginTop: ".6em"
    }
});

class RealPropertyForm extends Component {
    state = {
        snackbarOpen: false,
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        loading: false
    };

    applyOther = (value, element) => {
        this.setState({ [element]: value });
    };

    updateLandingReport = (values) => {
        const { ormRealPropertyInterestCreate, ormInitialLandReportingReload, history, location } = this.props;
        this.setState({loading: true});
        var parcels = location.pathname.replace("/real_property_batch/", "").split(",");

        var i = 0;
        var j = 0;
        parcels.forEach(function(p) {
            i++;
            ormRealPropertyInterestCreate({
                accomplishment_parcel_id: p,
                year: new Date().getFullYear(),
                ...values
            }).then((data) => {
                j++;
                if (i === j) {
                    ormInitialLandReportingReload();
                    history.goBack(); // Wait for reload...
                }
            });
        });
    };

    render() {
        const { classes, history, authState } = this.props;
        const { snackbarOpen, question1, question2, question3, question4, loading } = this.state;      
        
        var is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;
        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <PageHeader title="Batch Real Property Interest Report" />
                    <Grid item xs={12}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            onSubmit={this.updateLandingReport}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={10} lg={8} xl={6}>
                                            <Typography variant="h3" className={classes.gutterTop}>
                                                Real Property Interest Report
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="question1"
                                                        name="question1"
                                                        disabled={is_read_only}
                                                        eventHandle={this.applyOther}
                                                        fullWidth
                                                        label="1) Do you (or your organization/agency), currently own interest in these parcels?"
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No (Explain)", value: "false" }
                                                        ]}
                                                        alignment={true}
                                                    />
                                                    {question1 === false && (
                                                        <Subquestion component={
                                                            <TextField
                                                                field="question1explain"
                                                                label="Explain"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                                rowsMax={20}
                                                            />
                                                        } />
                                                    )}
                                                    <RadioGroup
                                                        field="question2"
                                                        name="question2"
                                                        disabled={is_read_only}
                                                        eventHandle={this.applyOther}
                                                        fullWidth
                                                        label="2) Has the contact information for these parcels remained the same?"
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No (Edit)", value: "false" }
                                                        ]}
                                                        alignment={true}
                                                    />
                                                    {question2 === false && (
                                                        <Subquestion component={
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <TextField disabled={is_read_only} label="Organization Name" field="organization" fullWidth />
                                                                    <TextField disabled={is_read_only} label="Manager's Name" field="manager_name" fullWidth />
                                                                    <TextField disabled={is_read_only} label="Address" field="address_1" fullWidth />
                                                                    <TextField disabled={is_read_only} label="City" field="city" fullWidth />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField disabled={is_read_only} label="State" field="state" fullWidth />
                                                                    <TextField disabled={is_read_only} label="Zip" field="zip" fullWidth />
                                                                    <TextField disabled={is_read_only} label="Email" field="email" fullWidth />
                                                                    <TextField disabled={is_read_only} label="Office Number" field="phone_office" fullWidth />
                                                                </Grid>
                                                            </Grid>
                                                        } />
                                                    )}
                                                    <RadioGroup
                                                        field="question3"
                                                        name="question3"
                                                        disabled={is_read_only}
                                                        eventHandle={this.applyOther}
                                                        fullWidth
                                                        label="3) Are these parcels currently being used for the purposes stated in the appropriation?"
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No (Explain and Give Instructions)", value: "false" }
                                                        ]}
                                                        alignment={true}
                                                    />
                                                    {question3 === false && (
                                                        <Subquestion component={
                                                            <TextField
                                                                field="question3explain"
                                                                label="Explain and Give Instructions"
                                                                fullWidth
                                                                disabled={is_read_only}
                                                                multiline
                                                                rows={4}
                                                                rowsMax={20}
                                                            />
                                                        } />
                                                    )}
                                                </Grid>
                                                <Grid item xs={6}> 
                                                    <RadioGroup
                                                        field="question4"
                                                        name="question4"
                                                        disabled={is_read_only}
                                                        eventHandle={this.applyOther}
                                                        fullWidth
                                                        label="4) Has the status of hunting & fishing as described in the A.P. changed?"
                                                        options={[
                                                            { label: "Yes (Explain)", value: "true" },
                                                            { label: "No", value: "false" }
                                                        ]}
                                                        alignment={true}
                                                    />
                                                    {question4 === true && (
                                                        <Subquestion component={
                                                            <TextField
                                                                field="question4explainedit"
                                                                label="Explain"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                                rowsMax={20}
                                                            />
                                                        } />
                                                    )}
                                                    <FileInput
                                                        field="easement_monitoring"
                                                        id="easement_monitoring"
                                                        disabled={is_read_only}
                                                        marginStyle={true}
                                                        fullWidth
                                                    >
                                                        <div>
                                                            5) Attach the annual financial easement monitoring & enforcement report&nbsp;
                                                            <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.17" target="_blank">per MS97A.056, subd 17</a> (Easement Parcels Only)
                                                        </div>
                                                    </FileInput>
                                                    <RadioGroup
                                                        field="status"
                                                        name="status"
                                                        fullWidth 
                                                        disabled={is_read_only}
                                                        options={[
                                                            { label: "Draft", value: "Draft" },
                                                            { label: "Completed", value: "Completed" }
                                                        ]}
                                                        alignment={true}
                                                    >
                                                        <div>
                                                            6) Status
                                                            <br/>
                                                            <i>Mark as completed when you are finished</i>
                                                        </div>
                                                    </RadioGroup>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={10} lg={8} xl={12} className={classes.rightAlign}>
                                            {loading ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        type="button"
                                                        onClick={() => history.goBack()}
                                                        className={classes.button}>
                                                        Return
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        disabled={is_read_only}
                                                        className={classes.button}>
                                                        Save and Return
                                                    </Button>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
                <Snackbar
                    handleSnackbarClose={() => this.setState({ snackbarOpen: false })}
                    isSuccess
                    snackbarOpen={snackbarOpen}
                />
            </AppContainer>
        );
    }
}

RealPropertyForm = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        ...RealPropertyInterest.actions,
        ...InitialLandReporting.actions,
        ...InitialLandReportingView.actions,
    }
)(RealPropertyForm);

export default withStyles(styles)(withRouter(RealPropertyForm));
