import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";


import { withStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { AccomplishmentPlan, AccomplishmentAmountNonOHF, AccomplishmentFutureMaintain } from "../models";

import TextField from "../../common/TextField";
import Checkbox from "../../common/Checkbox";
import CheckboxGroup from "../../common/CheckboxGroup";
import RadioGroup from "../../common/RadioGroup";
import Subquestion from "../../common/Subquestion";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
import { MAKE_OPTIONS } from "../../../api/constants";
import { getValue } from "../../../api/utils";


const getAmountNonOHFs = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.AccomplishmentAmountNonOHF.filter({ accomplishment: id }).orderBy("id").toModelArray();
    }
);
const getFutureMaintains = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.AccomplishmentFutureMaintain.filter({ accomplishment: id }).orderBy("id").toModelArray();
    }
);

const getBudgets = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.AccomplishmentBudget.filter({ accomplishment: id }).orderBy("id").toModelArray()
        .map(budget => ({
            _personnel: budget.budgetpersonnel.all().toRefArray(),
            _capital: budget.budgetcapital.all().toRefArray(),
            ...budget.ref
        }));
    }
);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    gutterTop: {
        textAlign: "center",
        marginTop: ".6em"
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#ffeff2"
        },
        marginBottom:16
    },
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    checkbox: {
        border: "1px solid",
        padding: 10,
        paddingBottom: 0,
        "&:hover": {
            backgroundColor: "#eee"
        }
    }
});

class NarrativeTab extends Component {
    lsohc_section_priorities = [];

    componentDidMount() {
        const { handleUnsavedFields, accomplishment_plan } = this.props;
        document.title = "Accomplishment Plan: Narrative - Lessard Sams Outdoor Heritage Council";
        this.props.onRef(this);

        var lsohc_section_priorities = [];
        var children = [];
        const year = parseInt(accomplishment_plan.ml_year);
        const eco_regions = getValue(accomplishment_plan, "eco_regions");
        if (eco_regions.indexOf("Forest / Prairie Transition") > -1) {
            children = [];
            children.push('Protect, enhance, and restore migratory habitat for waterfowl and related species, so as to increase migratory and breeding success');            
            children.push('Protect, enhance, and restore rare native remnant prairie');
            children.push('Protect, enhance, and restore wild rice wetlands, shallow lakes, wetland/grassland complexes, aspen parklands, and shoreland that provide critical habitat for game and nongame wildlife');
            if (year >= 2015)
                children.push('Protect from long-term or permanent endangerment from invasive species');
            if (year >= 2022)
                children.push('Protect, restore, and enhance habitat for waterfowl, upland birds, and species of greatest conservation need');
            lsohc_section_priorities.push({label: "Forest / Prairie Transition", children: children});
        }
        if (eco_regions.indexOf("Metro / Urban") > -1) {
            children = [];
            children.push('Enhance and restore coldwater fisheries systems');
            children.push('Protect, enhance, and restore remnant native prairie, Big Woods forests, and oak savanna with an emphasis on areas with high biological diversity');
            children.push('Protect, enhance, and restore riparian and littoral habitats on lakes to benefit game and nongame fish species');
            if (year >= 2015)
                children.push('Protect from long-term or permanent endangerment from invasive species');
            children.push('Protect habitat corridors, with emphasis on the Minnesota, Mississippi, and St. Croix rivers (bluff to floodplain)');
            lsohc_section_priorities.push({label: "Metro / Urban", children: children});
        }    
        if (eco_regions.indexOf("Northern Forest") > -1) {
            children = [];
            children.push('Protect shoreland and restore or enhance critical habitat on wild rice lakes, shallow lakes, cold water lakes, streams and rivers, and spawning areas');
            if (year <= 2014)
                children.push('Protect forestland though acquisition or easement to prevent parcelization and fragmentation and to provide the ability to access and manage landlocked public properties');
            else
                children.push('Provide access to manage habitat on landlocked public properties or protect forest land from parcelization and fragmentation through fee acquisition, conservation or access easement');
            children.push('Restore and enhance habitat on existing protected properties, with preference to habitat for rare, endangered, or threatened species identified by the Minnesota County Biological Survey');
            children.push('Restore forest-based wildlife habitat that has experienced substantial decline in area in recent decades');
            if (year >= 2015)
                children.push('Protect from long-term or permanent endangerment from invasive species');
            lsohc_section_priorities.push({label: "Northern Forest", children: children});
        }
        if (eco_regions.indexOf("Prairie") > -1) {
            children = [];
            children.push('Protect, enhance, and restore remnant native prairie, Big Woods forests, and oak savanna');
            children.push('Protect, enhance, and restore migratory habitat for waterfowl and related species, so as to increase migratory and breeding success');
            children.push('Protect, enhance, or restore existing wetland/upland complexes, or convert agricultural lands to new wetland/upland habitat complexes');
            children.push('Protect expiring CRP lands');
            if (year <= 2014)
                children.push('Convert agricultural land to wetland/upland to protect, enhance, or restore existing habitat complexes, such as WMAs');
            else
                children.push('Protect from long-term or permanent endangerment from invasive species');
            children.push('Protect, restore, and enhance shallow lakes');
            children.push('Restore or enhance habitat on public lands');
            lsohc_section_priorities.push({label: "Prairie", children: children});
        }
        if (eco_regions.indexOf("Southeast Forest") > -1) {
            children = [];
            children.push('Protect, enhance, and restore habitat for fish, game, and nongame wildlife in rivers, cold-water streams, and associated upland habitat');
            children.push('Protect, enhance, and restore remnant goat prairies');
            if (year >= 2015)
                children.push('Protect from long-term or permanent endangerment from invasive species');
            children.push('Protect forest habitat though acquisition in fee or easement to prevent parcelization and fragmentation and to provide the ability to access and manage landlocked public properties');
            children.push('Restore forest-based wildlife habitat that has experienced substantial decline in area in recent decades');
            lsohc_section_priorities.push({label: "Southeast Forest", children: children});
        }
        this.lsohc_section_priorities = lsohc_section_priorities;

        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 100);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateAP(values, fromStepper) {
        const { ormAccomplishmentPlanUpdate, history, handleUnsavedFields, handleNext, accomplishment_plan } = this.props;
        const { id } = accomplishment_plan;
        const year = parseInt(accomplishment_plan.ml_year);

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }
        values.federal_funds_confirmation_document = accomplishment_plan.federal_funds_confirmation_document;
        values.modify_date_label = accomplishment_plan.modify_date_label;
        values.signup_criteria = accomplishment_plan.signup_criteria;

        // Clear out fields that may no longer be shown
        if (values.other_plans_addressed.indexOf("Other") === -1) {
            values.other_plans_addressed_other = null;
        }
        if (values.relationship_other_funds.indexOf("Other") === -1) {
            values.relationship_other_funds_other = null;
        }
        if (values.leverage_funds !== "true" && values.leverage_funds !== true && year >= 2019) {
            values.leverage_ohf_appropriation = null;
        }

        ormAccomplishmentPlanUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    }

    maxChecked = (value, field, element) => {
        if (value.length >= 2) {
            var dis = [];
            element.props.options.forEach(function(row) {
                if (!value.includes(row.value)) dis[row.value] = true;
            });

            element.setState({ disabled: dis });
        } else {
            element.setState({ disabled: [] });
        }
        if (field === "other_plans_addressed")
            this.applyOther(value, "other_plans_addressed");
    };

    maxCheckedRegion = (value, field, element) => {
        var dis = [];
        element.props.options.forEach(function(row) {
            var group_disabled = false;
            row.children.forEach(function(child) {
                if (value.includes(row.label + " - " + child)) group_disabled = true;
            });
            if (group_disabled) {
                row.children.forEach(function(child) {
                    if (value.includes(row.label + " - " + child))
                        dis[row.label + " - " + child] = false;
                    else
                        dis[row.label + " - " + child] = true;
                });
            }
        });
        element.setState({ disabled: dis });
    };

    disableAll = (value, field, element) => {
        var dis = [];
        element.props.options.forEach(function(row) {
            row.children.forEach(function(child) {
                dis[row.label + " - " + child] = true;
            });
        });
        element.setState({ disabled: dis });
    };

    applyOther = (value, element) => {
        const { ormAccomplishmentPlanUpdateLocalOnly, accomplishment_plan} = this.props;
        const { id } = accomplishment_plan;
        ormAccomplishmentPlanUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    };

    render() {
        const { 
            classes,
            accomplishment_plan,
            budgets,
            handleUnsavedFields,
            ormAccomplishmentAmountNonOHFDelete,
            amountNonOHFs,
            futureMaintains,
            ormAccomplishmentAmountNonOHFUpdate,
            ormAccomplishmentAmountNonOHFCreate,
            ormAccomplishmentFutureMaintainCreate,
            ormAccomplishmentFutureMaintainDelete,
            ormAccomplishmentFutureMaintainUpdate,
            settings,
            ActionMenu, authState
        } = this.props;
        var is_read_only = true;
        if ((authState && authState.user && authState.user.role === "admin") || (accomplishment_plan.status === "First Draft" || accomplishment_plan.status === "Approved First Draft" || accomplishment_plan.status === "Started Request for Amendment Change"))
            is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        var anticipated_leverage = 0;
        var funding_request = 0;
        var budget_percent = "-";
        const groupedItem = this.groupBy(budgets, b => b.item);
        groupedItem.get("Grand Total").forEach(function(gi) {
            anticipated_leverage += gi.anticipated_leverage;
            funding_request += gi.funding_request;
        });
        if (funding_request > 0) {
            budget_percent = (Math.round(((anticipated_leverage/funding_request) * 100) * 100) / 100).toLocaleString('en') + "%";
        }

        const year = parseInt(accomplishment_plan.ml_year);

        const showOther = getValue(accomplishment_plan, "other_plans_addressed").indexOf("Other") > -1;
        const showOther3 = getValue(accomplishment_plan, "leverage_funds") === "true" || getValue(accomplishment_plan, "leverage_funds") === true;
        const showCheckbox = getValue(accomplishment_plan, "proposal_acknowledgement_narrative") === false;

        return (
            <Form
                getApi={el => (this.form = el)}
                key={accomplishment_plan.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={accomplishment_plan.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateAP(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HelpLabel
                                    inputLabel="Narrative"
                                    title={true}
                                    showLabel={true}
                                    helpText={settings.narrative_tab_help}
                                />
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12}>
                                {showCheckbox && (
                                    <div className={classes.checkbox}>
                                        <Checkbox
                                            field="proposal_acknowledgement_narrative"
                                            eventHandle={this.applyOther}
                                            disabled={is_read_only} 
                                            label="Please check this box to acknowledge that the information in this tab was brought over from your proposal and is still valid."
                                        />
                                    </div>
                                )}
                                <TextField
                                    field="abstract"
                                    maxWords={!is_read_only && year >= 2014 && "100"}
                                    multiline
                                    disabled={is_read_only}
                                    rows={4}
                                    rowsMax={20}
                                    fullWidth
                                >
                                    <HelpLabel
                                        inputLabel="Abstract"
                                        showLabel={true}
                                        helpText="Provide a clear, concise summary of the proposed program's activities and outcomes. This should include the Who, What, When, Where, Why and How. This is the most visible description of your program. The abstract will provide readers with an overview of program objectives and will be publicly visible on the LSOHC website and summary reports."
                                    />
                                </TextField>
                                <TextField
                                    field="design_scope"
                                    maxWords={!is_read_only && (year >= 2014 && year <= 2016) ? "1000" : year >= 2017 ? "500" : null}
                                    disabled={is_read_only}
                                    multiline
                                    rows={8}
                                    rowsMax={20}
                                    fullWidth
                                >
                                    <HelpLabel
                                        inputLabel="Design and scope of work"
                                        showLabel={true}
                                        helpText="This section describes the problem to be addressed, the scope of work, how priorities were set, and the urgency and opportunity of the proposed project/program. Be sure that the narrative answers what specific habitat will be affected and how the actions will directly restore, enhance, and/or protect prairies, wetlands, forests, or habitat for fish, game, and wildlife. Your narrative should also address the level of stakeholder involvement and partnership."
                                    />
                                </TextField>
                                {year >= 2015 && (
                                    <TextField
                                        field="habitats_significant_value"
                                        maxWords={!is_read_only && "350"}
                                        multiline
                                        disabled={is_read_only}
                                        rows={4}
                                        rowsMax={20}
                                        label={year >= 2017
                                            ? "How does the plan address habitats that have significant value for wildlife species of greatest conservation need, and/or threatened or endangered species, and list targeted species?"
                                            : "How does the request address MN habitats that have: historical value to fish and wildlife, wildlife species of greatest conservation need, MN County Biological Survey data, and/or rare, threatened and endangered species inventories?"
                                        }
                                        fullWidth
                                    />
                                )}
                                {year >= 2015 && (
                                    <TextField
                                        field="science_based_targeting"
                                        maxWords={!is_read_only && "350"}
                                        multiline
                                        disabled={is_read_only}
                                        rows={4}
                                        rowsMax={20}
                                        fullWidth
                                        label={ year >= 2017
                                            ? "Describe how the plan uses science-based targeting that leverages or expands corridors and complexes, reduces fragmentation or protects areas identified in the MN County Biological Survey."
                                            : "Describe the science based planning and evaluation model used."
                                        }
                                    />
                                )}
                                <CheckboxGroup
                                    field="mn_conservation_and_preservation"
                                    row={true}
                                    disabled={is_read_only}
                                    eventHandle={this.maxChecked}
                                    options={MAKE_OPTIONS(["H1 Protect priority land habitats", "H2 Protect critical shoreland of streams and lakes", "H3 Improve connectivity and access to recreation", "H4 Restore and protect shallow lakes", "H5 Restore land, wetlands and wetland-associated watersheds", "H6 Protect and restore critical in-water habitat of lakes and streams", "H7 Keep water on the landscape", "LU6 Reduce Upland and gully erosion through soil conservation practices", "LU8 Protect large blocks of forest land", "LU10 Support and expand sustainable practices on working forested lands"])}
                                >
                                    <HelpLabel
                                        inputLabel="Which two sections of the Minnesota Statewide Conservation and Preservation Plan are most applicable to this project?"
                                        showLabel={true}
                                        helpText={
                                            <div>
                                                Check the top <b>TWO</b> that apply.
                                            </div>
                                        }
                                    />
                                </CheckboxGroup>
                                <CheckboxGroup
                                    field="other_plans_addressed"
                                    row={true}
                                    disabled={is_read_only}
                                    eventHandle={this.maxChecked}
                                    options={MAKE_OPTIONS([
                                        'A Vision for Wildlife and Its Use -- Goals and Outcomes 2006-2012',
                                        'Driftless Area Restoration Effort',
                                        'Ducks Unlimited Living Lakes Initiative',
                                        'Grassland Bird Conservation Area',
                                        'Grassland Conservation Plan for Prairie Grouse',
                                        'Long Range Duck Recovery Plan',
                                        'Long Range Plan for Fisheries Management',
                                        'Long Range Plan for Muskellunge and Large Northern Pike Management Through 2020',
                                        'Long Range Plan for the Ring-Necked Pheasant in MN',
                                        'Long Range Plan for the Wild Turkey',
                                        'Lower St. Louis River Habitat Plan',
                                        'Managing Minnesota\'s Shallow Lakes for Waterfowl and Wildlife',
                                        'Midwest Glacial Lakes Partnership',
                                        'Minnesota DNR AMA Acquisition Plan',
                                        'Minnesota DNR Nongame Wildlife Plans',
                                        'Minnesota DNR Scientific and Natural Area\'s Long Range Plan',
                                        'Minnesota DNR Strategic Conservation Agenda',
                                        'Minnesota Forest Resource Council Landscape Plans',
                                        'Minnesota Prairie Conservation Plan',
                                        'Minnesota Sustainability Framework',
                                        'Minnesota\'s Wildlife Action Plan 2015-2025',
                                        'Minnesota\'s Wildlife Management Area Acquisition - The Next 50 Years',
                                        'Mississippi River Headwaters Comprehensive Plan',
                                        'Moose Advisory Committee Report to the Minnesota DNR',
                                        'National Audubon Society Top 20 Common Birds in Decline',
                                        'National Fish Habitat Action Plan',
                                        'North American Waterbird Conservation Plan',
                                        'North American Waterfowl Management Plan',
                                        'Northern Plains Prairie Potholes Regional Shorebird Conservation Plan',
                                        'Northern Tallgrass Prairie Ecoregion: A River and Stream Conservation Portfolio',
                                        'Northern Tallgrass Prairie Habitat Preservation Area (HPA) Final Environmental Impact Statement',
                                        'Outdoor Heritage Fund: A 25 Year Framework',
                                        'Partners in Flight Conservation Plans for States and Physiographic Regions',
                                        'Partners in Flight Grassland Bird Plan',
                                        'Red River of the North Fisheries Management Plan',
                                        'Ruffed Grouse in Minnesota: A Long-Range Plan for Management',
                                        'State Comprehensive Outdoor Recreation Plan',
                                        'Strategic Plan for Coldwater Resources Management in Southeastern Minnesota',
                                        'Superior National Forest Land and Resource Management Plan',
                                        'The Nature Conservancy\'s Superior Mixed Forest Ecoregional Plan',
                                        'U.S. Fish and Wildlife Service Strategic Habitat Conservation Model',
                                        'U.S. Prairie Pothole Joint Venture Plan',
                                        'Upper Mississippi River and Great Lakes Region Projects Joint Ventures Plan',
                                        'Other'
                                    ])}
                                >
                                    <HelpLabel
                                        inputLabel="Which two other plans are best addressed in this program?"
                                        showLabel={true}
                                        helpText={
                                            <div>
                                                Check the top <b>TWO</b> that apply.
                                            </div>
                                        }
                                    />
                                </CheckboxGroup>
                                {showOther && (
                                    <Subquestion component={
                                        <TextField
                                            field="other_plans_addressed_other"
                                            disabled={is_read_only}
                                            label="What is your other plan addressed?"
                                            fullWidth
                                        />
                                    } />
                                )}
                                {year <= 2014 && (
                                    <CheckboxGroup
                                        field="lsohc_state_wide_priorities"
                                        label="Which LSOHC state-wide priorities are addressed in this program?"
                                        row={true}
                                        disabled={is_read_only}
                                        options={MAKE_OPTIONS([
                                            'Address Minnesota landscapes that have historical vaulue to fish and wildlife, wildlife species of greatest conservation need, Minnesota County Biological Survey data, and rare, threatened and endangered species inventories in land and water decisions, as well as long-term or permanent solutions to aquatic invasive species',
                                            'Address conservation opportunities that will be lost if not immediately acted on',
                                            'Address wildlife species of greatest conservation need, Minnesota County Biological Survey data, and rare, threatened and endangered species inventories in land and water decisions, as well as permanent solutions to aquatic invasive species',
                                            'Allow public access. This comes into play when all other things about the request are approximately equal',
                                            'Are able to leverage effort and/or other funds to supplement any OHF appropriation',
                                            'Are ongoing, successful, transparent and accountable programs addressing actions and targets of one or more of the ecological sections',
                                            'Attempts to ensure conservation benefits are broadly distributed across the LSOHC sections',
                                            'Ensures activities for "protecting, restoring and enhancing" are coordinated among agencies, non profits and others while doing this important work',
                                            'Ensures activities for "protecting, restoring and enhancing" are coordinated among agencies, non profits and others while doing this important work; provides the most cost-effective use of financial resources; and where possible takes into consideration the value of local outreach, education, and community engagement to sustain project outcomes',
                                            'Leverage effort and/or other funds to supplement any OHF appropriation',
                                            'Produce multiple enduring conservation benefits',
                                            'Provide Minnesotans with greater public access to outdoor environments with hunting, fishing and other outdoor recreation opportunities',
                                            'Restore or enhance habitat on permanently protected land',
                                            'Restore or enhance habitat on state-owned WMAs, AMAs, SNAs, and state forests',
                                            'Target unique Minnesota landscapes that have historical value to fish and wildlife',
                                            'Use a science-based strategic planning and evaluation model to guide protection, restoration and enhancement, similar to the United States Fish and Wildlife Service\'s Strategic Habitat Conservation model'
                                        ])}
                                    />
                                )}
                                <CheckboxGroup
                                    field="lsohc_section_priorities"
                                    row={true}
                                    groups={true}
                                    disabled={is_read_only}
                                    eventHandle={!is_read_only ? this.maxCheckedRegion : this.disableAll}
                                    options={this.lsohc_section_priorities}
                                >
                                    <HelpLabel
                                        inputLabel="Select the top one LSOHC section priority addressed in this program for each section."
                                        showLabel={true}
                                        helpText={
                                            <div>
                                                Select a region under the general tab to answer this question.<br/><br/>
                                                Check the top <b>ONE</b> that apply for each region.
                                            </div>
                                        }
                                    />
                                </CheckboxGroup>
                                {year >= 2015 && (
                                    <>
                                    <HelpLabel inputLabel="Budget Values" special={true} helpText="These figures are auto populated from the budget page" />
                                    <Typography>
                                        Amount of Request: <b>${funding_request.toLocaleString('en')}</b>
                                    </Typography>
                                    <Typography>
                                        Amount of Leverage:	<b>${anticipated_leverage.toLocaleString('en')}</b>
                                    </Typography>
                                    <Typography gutterBottom>
                                        Leverage as a percent of the Request: <b>{budget_percent}</b>
                                    </Typography>
                                    </>
                                )}
                                {year >= 2019 && (
                                    <RadioGroup
                                        field="leverage_funds"
                                        name="leverage_funds"
                                        disabled={is_read_only}
                                        eventHandle={this.applyOther}
                                        options={[
                                            { label: "Yes (Explain)", value: "true" },
                                            { label: "No", value: "false" }
                                        ]}
                                        alignment={true}
                                        label="Does this program include leveraged funding?"
                                    />
                                )}
                                {showOther3 && year >= 2019 && (
                                    <Subquestion component={
                                        <TextField
                                            field="leverage_ohf_appropriation"
                                            disabled={is_read_only}
                                            label="Explain Leverage: amount, source, type (cash / in-kind / donation)"
                                            multiline
                                            rows={4}
                                            rowsMax={20}
                                            fullWidth
                                        />
                                    } />
                                )}
                                {year <= 2018 && (
                                    <TextField
                                        field="leverage_ohf_appropriation"
                                        maxWords={!is_read_only && "200"}
                                        disabled={is_read_only}
                                        multiline
                                        rows={4}
                                        rowsMax={20}
                                        fullWidth
                                        label={ year >= 2017 
                                            ? "How does this plan include leverage in funds or other effort to supplement any OHF appropriation?" 
                                            : "How does this plan accelerate or supplement your current efforts in this area?"
                                        }
                                    />
                                )}
                                {year >= 2019 && (
                                    <TextField
                                        field="direct_appropriation"
                                        maxWords={!is_read_only && "50"}
                                        multiline
                                        disabled={is_read_only}
                                        rows={4}
                                        rowsMax={20}
                                        label={
                                            <div>
                                                <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.24" target="_blank">Per MS 97A.056, Subd. 24</a>, 
                                                Any state agency or organization requesting a direct appropriation from the OHF must inform the LSOHC at the time the request for funding is made, whether the request is supplanting or is 
                                                a substitution for any previous funding that was not from a legacy fund and was used for the same purpose. Please explain in the text box provided. 
                                            </div>
                                        }
                                        fullWidth
                                    />
                                )}
                                {year >= 2015 && (
                                    <>
                                    <Typography gutterBottom>
                                        Describe in the following table the source and amount of non-OHF money spent for this work in the past and complete the table explaining the things you will do in the future to maintain project outcomes, list the year as approximate.
                                        <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormAccomplishmentAmountNonOHFCreate({accomplishment: accomplishment_plan.id})}>
                                            <AddCircleIcon />
                                            &nbsp;&nbsp;&nbsp;Add Row
                                        </Button>
                                    </Typography>
                                    <AmountNonOHFTable 
                                        ormAccomplishmentAmountNonOHFDelete={ormAccomplishmentAmountNonOHFDelete} 
                                        ormAccomplishmentAmountNonOHFUpdate={ormAccomplishmentAmountNonOHFUpdate}
                                        classes={classes}
                                        is_read_only={is_read_only}
                                        rows={amountNonOHFs}
                                    />
                                    </>
                                )}
                                <TextField
                                    field="sustain_after_expend"
                                    label="How will you sustain and/or maintain this work after the Outdoor Heritage Funds are expended?"
                                    maxWords={!is_read_only && year >= 2014 && "200"}
                                    disabled={is_read_only}
                                    multiline
                                    rows={4}
                                    rowsMax={20}
                                    fullWidth
                                />
                                {year >= 2014 && (
                                    <>
                                    <Typography gutterBottom>
                                        Complete the table explaining the actions you will take in the future to maintain project outcomes, list the year as approximate.
                                        <Button disabled={is_read_only} style={{marginLeft:16}} variant="contained" color="primary" onClick={() => ormAccomplishmentFutureMaintainCreate({accomplishment: accomplishment_plan.id})}>
                                            <AddCircleIcon />
                                            &nbsp;&nbsp;&nbsp;Add Row
                                        </Button>
                                    </Typography>
                                    <FutureMaintainTable
                                        ormAccomplishmentFutureMaintainUpdate={ormAccomplishmentFutureMaintainUpdate}
                                        ormAccomplishmentFutureMaintainDelete={ormAccomplishmentFutureMaintainDelete}
                                        rows={futureMaintains}
                                        is_read_only={is_read_only}
                                        classes={classes}
                                    />
                                    </>
                                )}
                                {year >= 2022 && (
                                    <TextField
                                        field="program_involve_bipoc"
                                        label="How will your program directly involve, engage, and benefit BIPOC (Black, Indigenous, People of Color) and diverse communities?"
                                        maxWords={!is_read_only && "350"}
                                        multiline
                                        disabled={is_read_only}
                                        rows={4}
                                        rowsMax={20}
                                        fullWidth
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}>
                                    Save and Proceed to Activity Detail <ChevronRight />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

class AmountNonOHFTable extends Component {
    columnData = [
        { id: "year", numeric: false, label: "Appropriation Year", allowSort: false },
        { id: "source", numeric: false, label: "Source", allowSort: false },
        { id: "amount", numeric: false, label: "Amount", allowSort: false },
        { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" },
    ];
    typingTimer = [];

    updateRecord = (value, element, id) => {
        const { ormAccomplishmentAmountNonOHFUpdate } = this.props;

        clearTimeout(this.typingTimer[element]);
        this.typingTimer[element] = setTimeout(function() {
            ormAccomplishmentAmountNonOHFUpdate({
                id: id,
                [element]: value,
            });
        }, 1000);
    };

    render() {
        const { rows, classes, ormAccomplishmentAmountNonOHFDelete, is_read_only } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {rows.map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    <Form key={n.id + "year"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="year" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "source"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="source" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "amount"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="amount" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title="Delete Record">
                                        <Button
                                            color="primary" 
                                            disabled={is_read_only}
                                            className={classes.deleteWidth}
                                            onClick={() => ormAccomplishmentAmountNonOHFDelete(n.id)}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {rows.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                No Records Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

class FutureMaintainTable extends Component {
    columnData = [
        { id: "year", numeric: false, label: "Year", allowSort: false },
        { id: "source", numeric: false, label: "Source of Funds", allowSort: false },
        { id: "step1", numeric: false, label: "Step 1", allowSort: false },
        { id: "step2", numeric: false, label: "Step 2", allowSort: false },
        { id: "step3", numeric: false, label: "Step 3", allowSort: false },
        { id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" },
    ];
    typingTimer = [];

    updateRecord = (value, element, id) => {
        const { ormAccomplishmentFutureMaintainUpdate } = this.props;

        clearTimeout(this.typingTimer[element]);
        this.typingTimer[element] = setTimeout(function() {
            ormAccomplishmentFutureMaintainUpdate({
                id: id,
                [element]: value,
            });
        }, 1000);
    };

    render() {
        const { rows, classes, ormAccomplishmentFutureMaintainDelete, is_read_only } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead columnData={this.columnData} />
                <TableBody>
                    {rows.map(n => {
                        return (
                            <TableRow hover key={n.id}>
                                <CustomTableCell>
                                    <Form key={n.id + "year"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="year" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "source"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="source" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "step1"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="step1" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "step2"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="step2" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Form key={n.id + "step3"} defaultValues={n}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <TextField disabled={is_read_only} multiline tableFormat eventHandle={(v, e) => this.updateRecord(v, e, n.id)} field="step3" label="" fullWidth />
                                            </form>
                                        )}
                                    </Form>
                                </CustomTableCell>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title="Delete Record">
                                        <Button
                                            color="primary"
                                            disabled={is_read_only}
                                            className={classes.deleteWidth}
                                            onClick={() => ormAccomplishmentFutureMaintainDelete(n.id)}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {rows.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={6} className={classes.centerAlign}>
                                No Records Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

NarrativeTab = connect(
    (state, ownProps) => ({
        amountNonOHFs: getAmountNonOHFs(state, ownProps),
        futureMaintains: getFutureMaintains(state, ownProps),
        budgets: getBudgets(state, ownProps),
        authState: state.auth
    }),
    {
        ...AccomplishmentPlan.actions,
        ...AccomplishmentAmountNonOHF.actions,
        ...AccomplishmentFutureMaintain.actions
    }
)(NarrativeTab);

export default withStyles(styles)(withRouter(NarrativeTab));
